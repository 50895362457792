import { AxiosInstanceWebsite } from './AxiosInstance';
import config from '../config';

const { api } = config;

const patientMessageTemplatesAppendPath = 'patient-message-templates';
const requestMessageTemplatesAppendPath = 'request-message-templates';
const internalNoteTemplatesAppendPath = 'internal-note-templates';

const messageTemplateAppendPath = 'template-message';
export default class MessageTemplateAPI {
  static fetchMessage(id: string, params: any) {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/${messageTemplateAppendPath}/${id}/set-values`,
      method: 'GET',
      params: params,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  static fetchPatientMessageTemplates(data: any) {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/${patientMessageTemplatesAppendPath}`,
      method: 'GET',
      params: data,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  static fetchPatientMessageTemplateById(id: string) {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/${patientMessageTemplatesAppendPath}/${id}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  static postPatientMessageTemplates(payload: any) {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/${patientMessageTemplatesAppendPath}`,
      method: 'POST',
      data: payload,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }
  static editPatientMessageTemplateById(payload: any, id: string) {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/${patientMessageTemplatesAppendPath}/${id}`,
      method: 'PUT',
      data: payload,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  static deletePatientMessageTemplateById(id: string) {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/${patientMessageTemplatesAppendPath}/${id}`,
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  static fetchRequestMessageTemplates(data: any) {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/${requestMessageTemplatesAppendPath}`,
      method: 'GET',
      params: data,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  static fetchRequestMessageTemplateById(id: string) {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/${requestMessageTemplatesAppendPath}/${id}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  static postRequestMessageTemplates(payload: any) {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/${requestMessageTemplatesAppendPath}`,
      method: 'POST',
      data: payload,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  static editRequestMessageTemplateById(payload: any, id: string) {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/${requestMessageTemplatesAppendPath}/${id}`,
      method: 'PUT',
      data: payload,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  static deleteRequestMessageTemplateById(id: string) {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/${requestMessageTemplatesAppendPath}/${id}`,
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  static fetchInternalNoteTemplates(data: any) {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/${internalNoteTemplatesAppendPath}`,
      method: 'GET',
      params: data,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  static fetchInternalNoteTemplateById(id: string) {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/${internalNoteTemplatesAppendPath}/${id}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  static postInternalNoteTemplates(payload: any) {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/${internalNoteTemplatesAppendPath}`,
      method: 'POST',
      data: payload,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  static editInternalNoteTemplateById(payload: any, id: string) {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/${internalNoteTemplatesAppendPath}/${id}`,
      method: 'PUT',
      data: payload,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  static deleteInternalNoteTemplateById(id: string) {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/${internalNoteTemplatesAppendPath}/${id}`,
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }
}
