import { useRef, useState } from 'react';

import { useClickAway } from 'react-use';

import InfiniteScroll from 'react-infinite-scroll-component';
import { ReactComponent as Lighting } from 'assets/icons/lighting.svg';

import { SMSFormProps } from './smsForm.types';
import { toast } from 'react-toastify';
import PatientAPI from 'api/PatientAPI';
import Button from 'components/common/Button';
import MessageTemplateAPI from 'api/MessageTemplateAPI';

const SMSForm: React.FC<SMSFormProps> = ({ patientId, doctorId, patientPhone, onSendMessage }) => {
  const [showTemplates, setShowTemplates] = useState(false);
  const [smsText, setSMSText] = useState('');
  const [templateList, setTemplateList] = useState([]);
  const [templateTotalCount, setTemplateTotalCount] = useState(0);
  const [templatePage, setTemplatePage] = useState(0);
  const [loadMoreTemplates, setLoadMoreTemplates] = useState(true);
  const [searchValue, setSearchValue] = useState('');
  const [disabledSendSMS, setDisabledSendSMS] = useState(true);

  const templatesRef = useRef(null);
  const ref = useRef<HTMLDivElement | null>(null);
  const replayTemplatesButton = 'replayTemplatesButton';

  useClickAway(ref, () => {
    setShowTemplates(false);
  });

  const getPatientMessageTemplates = (payload: any) => {
    MessageTemplateAPI.fetchPatientMessageTemplates(payload).then((res) => {
      const { data } = res.data;

      if (data.length !== 0) {
        const list: any = showTemplates ? [...templateList] : [];
        data.map((eachItem: any) => {
          list.push({ text: eachItem.message, shortcode: eachItem.shortCode, id: eachItem._id });
        });
        setTemplateList(list);
        setTemplateTotalCount(res.data.info.totalCount);
        if (list.length === res.data.info.totalCount) {
          setLoadMoreTemplates(false);
        } else {
          setLoadMoreTemplates(true);
        }
      } else {
        setTemplateTotalCount(res.data.info.totalCount);
        setTemplateList([]);
        setLoadMoreTemplates(false);
      }
    });
  };

  const onTemplateListClick = (text: string, id: string) => {
    setShowTemplates(false);
    const params = {
      type: 'Message',
      patientId: patientId,
      doctorId: doctorId,
    };
    MessageTemplateAPI.fetchMessage(id, params)
      .then((res) => {
        const { data } = res.data;
        setSMSText(data.message);
        setDisabledSendSMS(!data.message.trim());
      })
      .catch((e) => {
        toast.error(e.response.data.message);
      });
  };

  const handleKeyDownMessages: React.KeyboardEventHandler<HTMLTextAreaElement> = (e) => {
    if (e.key === '/') {
      setShowTemplates(true);
    }
  };

  const handleSendMessage = () => {
    const body = {
      message: smsText,
      phone: patientPhone,
    };

    PatientAPI.addSMS(body, patientId)
      .then(() => onSendMessage())
      .catch((errorData) => {
        toast.error(errorData?.response?.data?.message);
      });
    setSMSText('');
    setDisabledSendSMS(true);
  };

  const handleMessageText = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;
    setDisabledSendSMS(!value?.trim());
    setSMSText(value);
  };

  const loadMore = () => {
    const data = {
      limit: 10,
      pageNo: templatePage + 1,
      status: 'active',
      searchKey: searchValue,
    };
    const count: number = templatePage + 1;
    setTemplatePage(count);
    getPatientMessageTemplates(data);
  };

  useClickAway(templatesRef, (e: Event) => {
    if ((e.target as HTMLButtonElement).id !== replayTemplatesButton) {
      setShowTemplates(false);
    }
  });

  return (
    <div className="flex flex-col relative" ref={ref}>
      <textarea
        className="border-gray-200 text-sm border bg-white rounded-t-xl p-5 w-full h-16"
        placeholder="SMS to patient…"
        value={smsText}
        onChange={handleMessageText}
        onKeyDown={handleKeyDownMessages}
      />
      <div className="border-gray-200 bg-white border rounded-b-xl flex justify-between p-1 px-3">
        <div className="flex items-center">
          <Button
            onClick={() => setShowTemplates(!showTemplates)}
            ownClasses="font-normal text-myPlanLightGrey"
            id={replayTemplatesButton}
            type="depressed"
          >
            <Lighting className="w-4 mr-2 mt-1 cursor-pointer" />
            Reply templates
          </Button>
        </div>
        <Button onClick={handleSendMessage} disabled={disabledSendSMS} id={replayTemplatesButton} type="primary">
          Send SMS
        </Button>
      </div>
      {showTemplates && (
        <div
          className="absolute bg-lightGrey top-0 right-0 w-full h-60 transform -translate-y-2/3 rounded-lg rounded-b-none p-4 shadow-lg"
          ref={templatesRef}
        >
          <input
            placeholder="Search templates"
            className="search-input"
            type="search"
            onChange={(e) => setSearchValue(e.target.value)}
            autoFocus
          />
          <div className="flex justify-between my-2">
            <h4 className="font-bold text-sm text-dashboardPrimaryGrey">Template</h4>
            <h4 className="font-bold text-sm text-dashboardPrimaryGrey">Shortcode</h4>
          </div>
          <div className="h-36 overflow-y-auto" id="scrollTemplate">
            <InfiniteScroll
              dataLength={templateTotalCount}
              next={loadMore}
              hasMore={loadMoreTemplates}
              loader={<div className="loader"> Loading... </div>}
              scrollableTarget="scrollTemplate"
            >
              {templateList.map((template: any) => (
                <div
                  key={template.shortcode}
                  className="flex justify-between mb-2"
                  onClick={() => onTemplateListClick(template.text, template.id)}
                >
                  <span className="truncate cursor-pointer w-5/6 text-sm text-dashboardPrimaryGrey">
                    {template.text}
                  </span>
                  <span className="text-sm text-darkBlue font-bold">{template.shortcode}</span>
                </div>
              ))}
            </InfiniteScroll>
          </div>
        </div>
      )}
    </div>
  );
};

export default SMSForm;
