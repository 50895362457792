import { AsyncPaginate } from 'react-select-async-paginate';
import Label from '../Label';
import classNames from 'classnames';
import './asyncSelect.css';
import Select, { components, Props as SelectProps, StylesConfig } from 'react-select';
import { XIcon } from '@heroicons/react/solid';

interface IAsyncSelect {
  loadOptions?: string;
  labelText?: string;
  value?: string;
  placeholder?: string;
  inputClasses?: string;
  labelClasses?: string;
  wrapperClasses?: string;
  setValue: (value: any) => any;
  menuIsOpen?: boolean;
  onInputChange: (value: any) => any;
  onClickIconClear: () => void;
  closeIcon?: boolean;
}
const AsyncSelect = ({ onClickIconClear, closeIcon, ...props }: IAsyncSelect | SelectProps) => {
  return (
    <div className={classNames('flex items-center justify-between', props.wrapperClasses)}>
      <Label labelText={props.labelText} labelClasses={props.labelClasses} />
      <div className="relative">
        <AsyncPaginate
          cacheOptions
          isClearable={true}
          style={{ minHeight: '26px' }}
          onInputChange={props.onInputChange}
          menuIsOpen={props.menuIsOpen}
          loadOptions={props.loadOptions}
          className={classNames(
            'bg-background border border-lightBorder rounded-lg placeholder-text text-medium font-light',
            props.inputClasses,
          )}
          getOptionValue={(option: any) => option._id}
          getOptionLabel={(option: any) => option.name}
          isSearchable={true}
          value={props.value}
          onChange={(value) => props?.setValue(value)}
          placeholder={props.placeholder}
          additional={{
            page: 1,
          }}
        />
        {closeIcon && props.value && (
          <button
            className="rounded-full w-5 flex justify-center items-center absolute right-2 inset-y-0 mb-3.5"
            onClick={() => onClickIconClear()}
          >
            <XIcon className="h-5 w-5" />
          </button>
        )}
      </div>
    </div>
  );
};

export default AsyncSelect;
