import Header from 'components/Admin/Patient/Header';
import CaseDetails from 'components/Admin/Patient/CaseDetails';
import PatientDocuments from 'components/Admin/Patient/PatientDocuments';
import { ReactElement, useEffect, useState } from 'react';
import Messages from 'components/Admin/Patient/Messages';
import FrontDesk from 'components/Admin/Patient/FrontDesk';
import StaffNote from 'components/Admin/Patient/StaffNote';
import History from 'components/Admin/Patient/History';
import PatientAPI from 'api/PatientAPI';
import { useLocation, useParams } from 'react-router';
import { hasAccessToModule } from 'auth/authorization';
import { ViewAsEmr } from 'utils/ViewAsEmr';
import { RootStateOrAny, useSelector } from 'react-redux';
import { ReactComponent as Bag } from 'assets/icons/bag.svg';
import Button from 'components/common/Button';
import Chart from 'components/Admin/Patient/Chart';

export type ITabs = 'overview' | 'messages' | 'front desk' | 'patient documents' | 'staff notes' | 'chart' | 'history';

interface IObjectKeys {
  [key: string]: string[];
}
// interface IpatientDoc {
//   type: string;
//   data: [];
// }
interface IComponents {
  [key: string]: ReactElement | null;
}
const linksList: IObjectKeys = {
  overview: ['Case information', 'Medical information', 'Requested medication', 'Requested pharmacy'],
  messages: [],
  'patient documents': ['Lab reports', 'Imaging', 'Additional documents'],
  'staff notes': [],
};

export const tabs = {
  staffNotes: 'staff notes',
  overview: 'overview',
  messages: 'messages',
  frontdesk: 'front desk',
  patientDocuments: 'patient documents',
  history: 'history',
  chart: 'chart',
};

const PatientSummary: React.FC = () => {
  const { search } = useLocation();
  const activeTabParam = new URLSearchParams(search).get('active-tab');
  const currentTab = Object.values(tabs).includes(activeTabParam || '') ? activeTabParam : 'overview';
  const [activeTab, setActiveTab] = useState<ITabs>(currentTab as ITabs);
  const [tabLinks, setTabLinks] = useState<string[]>(linksList['overview']);
  const [activeLink, setActiveLink] = useState(tabLinks[0]);
  const [inputsActive, setInputsActive] = useState(false);
  const [patientDetails, setPatientDetails] = useState<any>([]);
  const [patientDocs, setPatientDocs] = useState<any>();
  const [patientDocsData, setPatientDocsData] = useState<any>();

  const { id }: any = useParams();
  const userInfo = useSelector((state: RootStateOrAny) => state.userProfile.userDetails);

  const handleActiveLink = (v: string) => {
    setActiveLink(v);
  };
  const getPatientDetials = () => {
    PatientAPI.fetchSinglePatient(id).then((res) => {
      const { data } = res.data;
      if (data) {
        setPatientDetails(data);
      }
    });
    PatientAPI.fetchPatientReport(id).then((res) => {
      const { data } = res.data;
      if (data) {
        console.log('getting reports', data);
        setPatientDocs(data?.results);
      }
    });
  };

  useEffect(() => {
    getPatientDetials();
  }, []);

  const contentList: IComponents = {
    [tabs.overview]: <CaseDetails inputsActive={inputsActive} patientDetails={patientDetails} />,
    [tabs.messages]: <Messages patientDetails={patientDetails} getPatientDetials={getPatientDetials} />,
    [tabs.frontdesk]: <FrontDesk patientDetails={patientDetails} />,
    [tabs.patientDocuments]: <PatientDocuments docData={patientDocsData} />,
    [tabs.staffNotes]: <StaffNote patientDetails={patientDetails} />,
    [tabs.history]: <History />,
    [tabs.chart]: <Chart />,
  };

  const handleTab = (tab: ITabs) => {
    setActiveTab(tab);
    const newLinks: string[] = linksList[tab] || [];
    setTabLinks(newLinks);
    setActiveLink(newLinks[0]);
  };

  useEffect(() => {
    const lab: any = [];
    const image: any = [];
    const other: any = [];

    console.log('patirent docs', patientDocs);
    patientDocs?.map((item: any) => {
      if (item?.report_type == 'Lab') lab?.push(item);
      else if (item?.report_type == 'Imaging') image.push(item);
      else other.push(item);
    });
    const combined = [
      { title: 'Lab', data: lab },
      { title: 'Imaging', data: image },
      { title: 'Other', data: other },
    ];
    setPatientDocsData(combined);
  }, [patientDocs]);

  return (
    <>
      <h2 className="text-black text-2xl font-semibold mb-8 pl-4 flex">
        <h1 className="text-xl font-bold mx-8">
          Patient:{' '}
          {patientDetails?.firstName ? (
            <span className="ml-1 text-xl font-bold">{patientDetails?.firstName + ' ' + patientDetails?.lastName}</span>
          ) : null}
        </h1>
        {hasAccessToModule(userInfo.userRoleInfo.pagePermissions, ['VIEW_PATIENT_EMR']) && (
          <Button ownClasses="font-normal" type="depressed" onClick={() => ViewAsEmr(patientDetails?._id)}>
            <Bag className="mr-2 w-5" />
            View EMR
          </Button>
        )}
      </h2>
      <div className="bg-white rounded-xl px-12 py-8 mx-8">
        <Header activeTab={activeTab} setActiveTab={handleTab} patientDetails={patientDetails} />
        <div className="border-b border-dividingLine my-6"></div>
        <div className="flex justify-between">
          <div className="flex-grow">{contentList[activeTab]}</div>
          {/* <div style={{ minWidth: '20%' }} className="w-1/5">
            {tabLinks.length ? (
              <div className="mt-4">
                <h3 className="font-semibold mb-8 text-xl">Quick links</h3>
                {tabLinks.map((link) => (
                  <h3
                    onClick={() => handleActiveLink(link)}
                    className={classNames('cursor-pointer text-xl mb-7', { 'text-darkBlue': activeLink === link })}
                    key={link}
                  >
                    {link}
                  </h3>
                ))}
                {activeTab === 'case details' && (
                  <Button
                    ownClasses="w-10 my-16 px-8 py-2 bg-lightGreen font-semibold uppercase text-darkBlue"
                    onClick={() => setInputsActive(!inputsActive)}
                    type="success"
                  >
                    <CheckIcon className="w-4 mr-4 mt-1 text-darkBlue font-normal" />
                    {inputsActive ? 'Save' : 'Edit entries'}
                  </Button>
                )}
              </div>
            ) : null}
          </div> */}
        </div>
      </div>
    </>
  );
};

export default PatientSummary;
