import Label from '../Label';
import classNames from 'classnames';
import 'react-datetime/css/react-datetime.css';
import Datetime from 'react-datetime';

interface DatePickerProps {
  labelText?: string;
  inputClasses?: string;
  labelClasses?: string;
  wrapperClasses?: string;
  name?: string;
  placeholder?: string;
  format?: string;
  value?: any;
  onChange?: (value?: any) => void;
}

const DateTimePicker: React.FC<DatePickerProps> = ({
  labelText,
  inputClasses,
  wrapperClasses,
  labelClasses,
  name,
  placeholder,
  format,
  value,
  onChange,
}) => {
  const inputProps = {
    placeholder: placeholder,
    name: name,
    className:
      'pl-4 py-1 w-52 bg-background border border-lightBorder rounded-lg placeholder-text text-medium font-light',
  };
  return (
    <div className={classNames('flex items-center justify-between', wrapperClasses)}>
      <Label labelText={labelText} labelClasses={labelClasses} />
      <Datetime
        inputProps={inputProps}
        className={classNames(inputClasses)}
        onChange={onChange}
        dateFormat={format}
        timeFormat={false}
        value={value}
      />
    </div>
  );
};

export default DateTimePicker;
