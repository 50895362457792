import { useEffect, useRef, useState } from 'react';
import poster from 'assets/icons/call/poster.svg';
import useVideoCall from '../../hooks/useVideoCall';
import Item from './Item';
import { SettingsPopupProps } from './settingsPopup.types';
import { pollAudioLevel } from 'utils/pollAudioLevel';

const SettingsPopup: React.FC<SettingsPopupProps> = ({
  audioTracks,
  videoTracks,
  changeVideoId,
  changeAudioId,
  audioEnabled,
  videoEnabled,
}) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);

  const [volume, setVolume] = useState(0);

  const { audioId, speakerId, videoId, setAudioId, setSpeakerId, setVideoId, devices } = useVideoCall();

  const isSelectedAudio = (deviceId: string) => {
    return audioId === deviceId;
  };

  const isSelectedSpeaker = (deviceId: string) => {
    return speakerId === deviceId;
  };

  const isSelectedVideo = (deviceId: string) => {
    return videoId === deviceId;
  };

  const setSelectedAudioId = (deviceId: string) => {
    if (audioId !== deviceId) {
      setAudioId(deviceId);
      changeAudioId(deviceId, audioEnabled);
    }
  };

  const setSelectedVideoId = (deviceId: string) => {
    if (videoId !== deviceId) {
      setVideoId(deviceId);
      changeVideoId(deviceId, videoEnabled);
    }
  };

  useEffect(() => {
    if (audioTracks[0]) {
      pollAudioLevel(audioTracks[0], (level) => {
        /* Update audio level indicator. */
        setVolume(level);
      });
    }
  }, [audioTracks]);

  /**
   * Attach video track to video tag
   */
  useEffect(() => {
    if (videoTracks.length && videoRef.current) {
      videoTracks[0]?.attach(videoRef.current);
    }
  }, [videoTracks]);

  return (
    <div className="absolute left-0 bottom-0 h-full w-full p-6 z-20 shadow-common bg-white md:h-fit md:left-auto md:bottom-full md:rounded-2xl md:mb-3 md:w-96">
      <div className="relative h-full md:min-h-fit md:max-h-70vh overflow-auto w-full z-10">
        {!!devices.audio.length && <div className="uppercase text-xl text-darkBlue mb-6">microphone</div>}
        {devices.audio.map((device) => (
          <Item
            label={device.label.split('(Built-in)')[0]}
            key={device.deviceId}
            device={device}
            isSelected={isSelectedAudio(device.deviceId)}
            setSelectedId={setSelectedAudioId}
            volume={volume}
          />
        ))}
        {!!devices.speaker.length && <div className="uppercase text-xl text-darkBlue mb-6">speaker</div>}
        {devices.speaker.map((device) => (
          <Item
            label={device.label.split('(Built-in)')[0]}
            key={device.deviceId}
            device={device}
            isSelected={isSelectedSpeaker(device.deviceId)}
            setSelectedId={setSpeakerId}
          />
        ))}

        <div className="uppercase text-xl text-darkBlue mb-6">camera</div>
        {devices.video.map((device) => (
          <Item
            label={device.label.split('(Built-in)')[0]}
            key={device.deviceId}
            device={device}
            isSelected={isSelectedVideo(device.deviceId)}
            setSelectedId={setSelectedVideoId}
          />
        ))}
        <video
          autoPlay
          muted
          playsInline
          ref={videoRef}
          className="mx-auto mt-8 w-full h-auto object-cover transform -scale-x-100"
          poster={poster}
        />
        <div className="uppercase text-center text-textGreyedOut text-sm mt-3">preview</div>
      </div>
      <i className="hidden absolute -bottom-1 inset-x-0 m-auto w-8 h-8 bg-white transform rotate-45 md:block" />
    </div>
  );
};

export default SettingsPopup;
