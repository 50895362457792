import { ArticleData } from './interfaces';
import types from './types';

export const setArticleLists = (data: ArticleData[]) => ({
  type: types.SET_ARTICLE_LIST,
  payload: data,
});

export const setTotalArticle = (data: number) => ({
  type: types.SET_TOTAL_ARTICLE,
  payload: data,
});
const actions = {
  setArticleLists,
  setTotalArticle,
};

export default actions;
