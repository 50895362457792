import config from '../config';
import { AxiosInstanceAuth } from './AxiosInstance';

const { api } = config;

const authAppendPath = 'auth';
export default class AuthAPI {
  static login(data: { email: string; password: string }) {
    return AxiosInstanceAuth({
      url: `${api.baseUrlAuth}/${authAppendPath}/login`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    });
  }

  static logout() {
    return AxiosInstanceAuth({
      url: `${api.baseUrlAuth}/${authAppendPath}/logout`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  static forgotPassword(data: { email: string }) {
    return AxiosInstanceAuth({
      url: `${api.baseUrlAuth}/${authAppendPath}/forgot-password-token`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    });
  }
  static resetPassword(data: { password: string; token: string }) {
    return AxiosInstanceAuth({
      url: `${api.baseUrlAuth}/${authAppendPath}/forgot-password`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    });
  }
}
