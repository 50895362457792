import Button from 'components/common/Button';
import Dropdown from 'components/common/Dropdown';
import { useEffect } from 'react';
import { useState } from 'react';
import AdminAppointmentAPI from 'api/AdminAppointmentApi';
import { toast } from 'react-toastify';
import LoadingOverlay from 'react-loading-overlay-ts';

export interface FormProps {
  setShowModal2: (value?: any) => void;
}
const lengthOptions = [
  { value: '15 minutes', label: '15 minutes' },
  { value: '20 minutes', label: '20 minutes' },
  { value: '30 minutes', label: '30 minutes' },
  { value: '45 minutes', label: '45 minutes' },
];

const LengthForm: React.FC<FormProps> = ({ setShowModal2 }) => {
  const [appointmentLength, setAppointmentLength] = useState([]);
  const [initialLength, setInitialLength] = useState([]);
  const [subsequentLength, setsubsequentLength] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const initial: any = [];
    const subsequent: any = [];
    setLoading(true);
    AdminAppointmentAPI.getAppointmentTypeLenghths({})
      .then((res) => {
        if (res?.data?.errorCode == 0) {
          setAppointmentLength(res?.data?.data);
          res?.data?.data?.map((item: any) => {
            initial.push({
              label: item.appointmentLengths.initial['label'],
              length: item.appointmentLengths.initial['length'],
            });
            subsequent.push({
              label: item.appointmentLengths.subsequent['label'],
              length: item.appointmentLengths.subsequent['length'],
            });
          });
          setInitialLength(initial);
          setsubsequentLength(subsequent);
        } else toast.error('Error! Could Not Add');
      })
      .catch((err) => {
        if (err?.response?.data?.message) {
          toast.error(err.response.data.message);
        } else toast.error(`Error! Could Not Add ${err?.response?.data?.message}`);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleSubmit = (e: any) => {
    const plans: any = [];
    appointmentLength.map((item: any) => {
      const data = item._id;
      initialLength.every((eachInitial: any, _id) => {
        const initial = {
          label: eachInitial.label,
          length: eachInitial.length,
        };
        subsequentLength.every((eachSubsequent: any, _id) => {
          const subsequent = {
            label: eachSubsequent.label,
            length: eachSubsequent.length,
          };
          plans.push({
            _id: data,
            appointmentLengths: {
              initial,
              subsequent,
            },
          });
          subsequentLength.shift();
          return false;
        });
        initialLength.shift();
        return false;
      });
    });
    const payloads = {
      plans: plans,
    };
    AdminAppointmentAPI.postAppointmentTypeLenghths(payloads)
      .then((res) => {
        if (res.data.errorCode == 0) {
          toast.success('Successfully updated');
        } else toast.error('Error! Could Not Add');
      })
      .catch((err) => {
        if (err?.response?.data?.message) {
          toast.error(err.response.data.message);
        } else toast.error(`Error! Could Not Add ${err?.response?.data?.message}`);
      });
    setShowModal2(false);
  };

  const onChangeDropdown1 = (plan: any, data: any) => {
    const initialData: any = [...initialLength];
    initialData.map((item: any) => {
      if (item.label === plan) {
        item.length = parseInt(data.label);
      }
    });
    setInitialLength(initialData);
  };

  const onChangeDropdown2 = (plan: any, data: any) => {
    const subsequentData: any = [...subsequentLength];
    subsequentData.map((item: any) => {
      if (item.label === plan) {
        item.length = parseInt(data.label);
      }
    });
    setsubsequentLength(subsequentData);
  };
  return (
    <>
      <LoadingOverlay active={loading} spinner>
        <form>
          <div className="flex flex-col lg:flex-row">
            <div className="w-full">
              {initialLength?.map((item: any) => {
                return (
                  <div key={item.label}>
                    <Dropdown
                      wrapperClasses="mb-4"
                      labelText={item.label}
                      labelClasses="mb-3.5 text-xl w-80"
                      options={lengthOptions}
                      value={{ label: item.length + ' minutes', value: item.length }}
                      onChange={(e: any) => onChangeDropdown1(item.label, e)}
                    />
                  </div>
                );
              })}
              {subsequentLength?.map((item: any) => {
                return (
                  <div key={item.label}>
                    <Dropdown
                      wrapperClasses="mb-4"
                      labelText={item.label}
                      labelClasses="mb-3.5 text-xl w-80"
                      options={lengthOptions}
                      value={{ label: item.length + ' minutes', value: item.length }}
                      onChange={(e: any) => onChangeDropdown2(item.label, e)}
                    />
                  </div>
                );
              })}
            </div>
          </div>
          <div className="flex items-center justify-end">
            <Button ownClasses="px-7 font-semibold rounded-full" type="success" onClick={(e) => handleSubmit(e)}>
              Save
            </Button>
          </div>
        </form>
      </LoadingOverlay>
    </>
  );
};

export default LengthForm;
