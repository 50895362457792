import TaskApi from 'api/TaskAPI';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { dateTimeFormatter } from 'utils/dateTimeFormatter';
import Button from '../../common/Button';
import * as Sentry from '@sentry/react';

export interface RequestDetailsProps {
  requestedDetails: any;
  fetchTaskDetails: () => void;
  category: string;
}
const RequestDetails: React.FC<RequestDetailsProps> = ({ requestedDetails, fetchTaskDetails, category }) => {
  const [taskId, setTaskId] = useState('');
  useEffect(() => {
    if (requestedDetails && requestedDetails[0]) {
      setTaskId(requestedDetails[0].taskId);
    }
  }, [requestedDetails]);

  const onClickMarkAsUrgent = () => {
    const payload = {
      markUrgent: true,
    };
    TaskApi.toogleStatus(taskId, payload)
      .then((res) => {
        const { data } = res.data;
        toast.success(data.message);
        fetchTaskDetails();
      })
      .catch((err) => {
        Sentry.captureException(err);
        toast.error(err.response.data.message);
      });
  };
  return (
    <div className="flex flex-col md:flex-row py-12">
      <h3 className="md:w-1/5 subheading mb-4">{category === 'Requests' ? 'Request' : 'Requested'}</h3>
      <div className=" md:w-4/5 flex flex-col">
        {requestedDetails.length !== 0 && requestedDetails[0].note
          ? requestedDetails.map((eachRequest: any) => {
              return (
                <>
                  <div className="flex mb-2">
                    <h4 className="mr-2 font-bold">{eachRequest.requestor ? eachRequest.requestor.displayName : ''}</h4>
                    <span className="text-myPlanLightGrey">
                      {eachRequest.createdAt && dateTimeFormatter(eachRequest.createdAt)}
                    </span>
                  </div>
                  <p className="text-xl">{eachRequest.note && eachRequest.note}</p>
                </>
              );
            })
          : 'No Requests'}
        {category !== 'Requests' && requestedDetails.length !== 0 && requestedDetails[0].note && (
          <div className="mt-5">
            <Button ownClasses="rounded-full" type="success" onClick={() => onClickMarkAsUrgent()}>
              Mark as Urgent
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default RequestDetails;
