import Button from 'components/common/Button';
import Dropdown from 'components/common/Dropdown';
import Input from 'components/common/Input';
import _ from 'lodash';
import { useEffect } from 'react';
import { useState } from 'react';

export interface FormProps {
  formHandler: (value?: any) => void;
  filterFormValues?: any;
  taskTypeOptions: any;
  audienceOptions: any;
}

const Form: React.FC<FormProps> = ({ formHandler, filterFormValues, taskTypeOptions, audienceOptions }) => {
  const [taskType, setTaskType] = useState<{ label: string; value: string } | null>();
  const [uniqueId, setUniqueId] = useState('');
  const [alertType, setAlertType] = useState<{ label: string; value: string } | null>();
  const [alertUnit, setAlertUnit] = useState<{ label: string; value: string } | null>();
  const [alertBasedOn, setAlertBasedOn] = useState<{ label: string; value: string } | null>();
  const [alertAmount, setAlertAmount] = useState('');
  const [name, setName] = useState('');

  const [audience, setAudience] = useState<{ label: string; value: string } | null>();
  const [status, setStatus] = useState<{ label: string; value: string } | null>();
  const [filterCount, setFilterCount] = useState(0);

  const alertUnitOptions = [
    { value: 'minbefore', label: 'Mins before' },
    { value: 'minafter', label: 'Mins after' },

    { value: 'hrsbefore', label: 'Hours before' },
    { value: 'hrsafter', label: 'Hours after' },
  ];

  const alertTypeOptions = [
    { value: 'Notification', label: 'Notification' },
    { value: 'Mark as urgent', label: 'Mark as Urgent' },
  ];

  const statuses = [
    { value: 'active', label: 'Active' },
    { value: 'inactive', label: 'Inactive' },
  ];

  const alertBasedOnOptions = [
    { value: 'createdAt', label: 'Created date' },
    { value: 'dueDate', label: 'Due date' },
  ];
  useEffect(() => {
    if (!_.isEmpty(filterFormValues)) {
      setTaskType(taskTypeOptions?.find((item: any) => item?.value === filterFormValues?.taskType));
      setAlertType(alertTypeOptions?.find((item: any) => item?.value === filterFormValues?.alertType));
      setStatus(statuses?.find((item) => item.value === filterFormValues?.status));
      setUniqueId(filterFormValues?.uniqueId);
      setAlertBasedOn(alertBasedOnOptions?.find((item: any) => item.value === filterFormValues?.criterionField));
      setAlertUnit(alertUnitOptions?.find((item: any) => item?.value === filterFormValues?.alertUnit));
      alertUnitSetter();
      setAlertAmount(filterFormValues?.alertAmount);
      setName(filterFormValues?.name);
      setAudience(audienceOptions?.find((item: any) => item?.value === filterFormValues?.audience));
    }
  }, [filterFormValues]);

  const alertUnitSetter = () => {
    alertUnitOptions?.map((item: any) => {
      item?.value?.includes(filterFormValues?.alertUnit) && item?.value?.includes(filterFormValues?.occurenceOrder)
        ? setAlertUnit(item)
        : null;
    });
  };

  useEffect(() => {
    console.log(alertUnit);
  }, [alertUnit]);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const filterData = {
      ...(taskType?.value && { taskType: taskType.value }),
      ...(alertType?.value && { alertType: alertType.value }),
      ...(status?.value && { status: status.value }),
      ...(uniqueId && { uniqueId: uniqueId }),
      ...(alertBasedOn?.value && { criterionField: alertBasedOn?.value }),
      ...(alertUnit?.value && { alertUnit: alertUnit.value }),
      ...(alertUnit?.value && { occurenceOrder: alertUnit?.label?.includes('before') ? 'before' : 'after' }),
      ...(alertAmount && { alertAmount: alertAmount }),
      ...(name && { name: name }),
      ...(audience?.value && { audience: audience?.value }),
    };
    formHandler(filterData);
  };

  const handleClear = (e: any) => {
    e.preventDefault();
    formHandler({});
  };

  const checkFilterApplied = () => {
    let count = 0;
    if (taskType?.value) {
      count += 1;
    }
    if (alertType?.value) {
      count += 1;
    }
    if (uniqueId) {
      count += 1;
    }
    if (alertBasedOn?.value) {
      count += 1;
    }
    if (alertUnit?.value) {
      count += 1;
    }
    if (alertAmount) {
      count += 1;
    }
    if (status?.value) {
      count += 1;
    }
    if (audience?.value) {
      count += 1;
    }
    if (name) {
      count += 1;
    }
    setFilterCount(count);
  };

  useEffect(() => {
    checkFilterApplied();
  }, [taskType, alertBasedOn, alertType, audience, status, uniqueId, alertUnit, alertAmount, name]);
  return (
    <form>
      <div className="flex flex-col lg:flex-row justify-center items-center">
        <div className="mr-20 mb-4">
          <Dropdown
            labelText="Task Type"
            placeholderText="Select task type"
            width="52"
            labelClasses="mr-10 text-lg  mb-4"
            options={taskTypeOptions}
            value={taskType}
            onChange={(e) => setTaskType(e)}
            onClickIconClear={() => setTaskType(null)}
            closeIcon={true}
          />
          <Dropdown
            labelText="Alert based on"
            placeholderText="Select Alert Based On"
            labelClasses="mr-10 text-lg  mb-4"
            width="52"
            options={alertBasedOnOptions}
            value={alertBasedOn}
            onClickIconClear={() => setAlertBasedOn(null)}
            closeIcon={true}
            onChange={(e: any) => setAlertBasedOn(e)}
          />
          <Dropdown
            labelText="Alert Type"
            placeholderText="Enter alert type"
            width="52"
            labelClasses="mr-10 text-lg  mb-4"
            options={alertTypeOptions}
            value={alertType}
            onClickIconClear={() => setAlertType(null)}
            closeIcon={true}
            onChange={(e) => setAlertType(e)}
          />
          <Dropdown
            labelText="Alert Unit"
            placeholderText="Select alert unit"
            labelClasses="mr-10 text-lg  mb-4"
            width="52"
            options={alertUnitOptions}
            value={alertUnit}
            onClickIconClear={() => setAlertUnit(null)}
            closeIcon={true}
            onChange={(e) => setAlertUnit(e)}
          />
          <Input
            labelText="Alert Amount"
            name="alertAmount"
            type="number"
            min="1"
            placeholder="Enter alert amount"
            inputClasses="w-52  mb-4"
            labelClasses="mr-10 text-lg  mb-4"
            value={alertAmount}
            onChange={(e) => setAlertAmount(e.target.value)}
          />
        </div>
        <div>
          <Input
            labelText="Name"
            name="name"
            type="string"
            placeholder="Enter name"
            inputClasses="w-52 mb-4 pr-8"
            labelClasses="mr-10 text-lg  mb-4"
            value={name}
            onChange={(e) => setName(e.target.value)}
            onClickIconClear={() => setName('')}
            closeIcon={true}
          />
          <Input
            labelText="Unique Id"
            name="created"
            placeholder="Enter Id"
            inputClasses="w-52 mb-4 pr-8"
            labelClasses="mr-10 text-lg  mb-4"
            value={uniqueId}
            onChange={(e) => setUniqueId(e.target.value)}
            onClickIconClear={() => setUniqueId('')}
            closeIcon={true}
          />

          <Dropdown
            labelText="Audience"
            placeholder="Select audience"
            labelClasses="mr-10 text-lg  mb-4"
            width="52"
            value={audience}
            options={audienceOptions}
            onClickIconClear={() => setAudience(null)}
            closeIcon={true}
            onChange={(e: any) => setAudience(e)}
          />

          <div className="mb-4">
            <Dropdown
              labelText="Status"
              placeholder="Status"
              labelClasses="mr-10 text-lg  mb-4"
              width="52"
              value={status}
              options={statuses}
              onClickIconClear={() => setStatus(null)}
              closeIcon={true}
              onChange={(e: any) => setStatus(e)}
            />
          </div>
        </div>
      </div>
      <div className="flex items-center justify-end">
        <p>{filterCount} filters applied</p>
        <Button
          ownClasses="px-7 mx-2.5 font-semibold text-dashboardPrimaryGrey"
          type="depressed"
          onClick={(e) => handleClear(e)}
        >
          Clear all
        </Button>
        <Button ownClasses="px-7 font-semibold rounded-full" type="success" onClick={(e) => handleSubmit(e)}>
          Update
        </Button>
      </div>
    </form>
  );
};

export default Form;
