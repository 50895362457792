import classNames from 'classnames';
import { Column, useTable } from 'react-table';
import { ChartProps } from './chart.types';

const columns: Column[] = [
  { Header: 'Type', accessor: 'type' },
  { Header: 'Value', accessor: 'value' },
  { Header: 'Unit', accessor: 'unit' },
  { Header: 'Date', accessor: 'date' },
];

const mockData = [
  { type: 'Body Weight', value: '82.3', unit: 'kg', date: '2017-11-05T11:27+00:00' },
  { type: 'Tobacco smoking status NHIS', value: '', unit: '', date: '2017-11-05T11:27+00:00' },
  {
    type: 'MHC [Entic mass] by Automoated count',
    value: '29.955291713982852',
    unit: 'pg',
    date: '2017-11-05T11:27+00:00',
  },
  { type: 'Respitory rate', value: '13', unit: '/min', date: '2017-11-05T11:27+00:00' },
  { type: 'High Density Lipoprotein Cholesterol', value: '77.17', unit: 'mg/dL', date: '2017-11-05T11:27+00:00' },
  {
    type: 'Complete blood count (hemogram) panel -Blood by Automated count',
    value: '9.580706138652136',
    unit: 'fL',
    date: '2017-11-05T11:27+00:00',
  },
  { type: 'Body Height', value: '167.5', unit: 'cm', date: '2017-11-05T11:27+00:00' },
  { type: 'Body Mass Index', value: '26.46', unit: 'kg/m2', date: '2017-11-05T11:27+00:00' },
  { type: 'Body Weight', value: '74.2', unit: 'kg', date: '2017-11-05T11:27+00:00' },
  { type: 'Body Weight', value: '78.1', unit: 'kg', date: '2017-11-05T11:27+00:00' },
  { type: 'Body Weight', value: '80.9', unit: 'kg', date: '2017-11-05T11:27+00:00' },
];

const thClasses = 'text-left font-bold pt-4 pb-3 pl-4';
const pClasses = 'font-semibold mr-6 cursor-pointer';
const selected = ' underline text-darkBlue';

const Chart: React.FC<ChartProps> = ({ data }) => {
  const { getTableProps, headerGroups, getTableBodyProps, rows, prepareRow } = useTable({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    columns,
    data: mockData,
  });

  const hiddenContent = classNames(data ? '' : 'hidden');
  const isNoData = classNames(data ? 'hidden' : 'section-subheading mr-4 text-center');

  return (
    <>
      <h1 className={isNoData}>Patient chart coming soon...</h1>
      <div className={`flex mb-4 ${hiddenContent}`}>
        <p className={pClasses}>AllergyIntolerance</p>
        <p className={pClasses}>Codition</p>
        <p className={pClasses}>Immunization</p>
        <p className={pClasses}>MedicationStatement</p>
        <p className={pClasses + selected}>Observation - Laboratory</p>
        <p className={pClasses}>Observation - Vital Signs</p>
      </div>
      <table
        {...getTableProps()}
        className={`table-auto w-full border-collapse overflow-hidden rounded-t-lg shadow-sm rounded-lg ${hiddenContent}`}
      >
        <thead className="bg-lightGreyBackground">
          {headerGroups.map((headerGroup, idx) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={idx}>
              {headerGroup.headers.map((column, idx) => (
                <th {...column.getHeaderProps()} key={idx} className={thClasses}>
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()} className="divide-y divide-gray-100">
          {rows.map((row, idx) => {
            prepareRow(row);
            return (
              <tr key={idx}>
                {row.cells.map((cell, idx) => (
                  <td {...cell.getCellProps()} key={idx} className="p-4 font-medium border-r border-gray-100">
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

export default Chart;
