import MessageTemplateAPI from 'api/MessageTemplateAPI';
import PatientMessageList from 'components/PatientMessageList';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import {
  setInternalNoteTemplatesCount,
  setInternalNoteTemplatesList,
  setPatientMessageTemplatesCount,
  setPatientMessageTemplatesList,
  setRequestMessageTemplatesCount,
  setRequestMessageTemplatesList,
} from 'redux/messageTemplates/actions';

export interface MessageTemplateProps {
  type: string;
}
const MessageTemplate: React.FC<MessageTemplateProps> = ({ type }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const fetchMessageTemplates = (value?: any) => {
    setLoading(true);
    if (type === 'Patient') {
      MessageTemplateAPI.fetchPatientMessageTemplates(value)
        .then((res) => {
          if (res.data.errorCode === 0) {
            dispatch(setPatientMessageTemplatesList(res.data.data));
            dispatch(setPatientMessageTemplatesCount(res.data.info.totalCount));
          }
        })
        .catch((e) => {
          toast.error(e.response.data.message);
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (type === 'Request') {
      MessageTemplateAPI.fetchRequestMessageTemplates(value)
        .then((res) => {
          if (res.data.errorCode === 0) {
            dispatch(setRequestMessageTemplatesList(res.data.data));
            dispatch(setRequestMessageTemplatesCount(res.data.info.totalCount));
          }
        })
        .catch((e) => {
          toast.error(e.response.data.message);
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (type === 'Internal') {
      MessageTemplateAPI.fetchInternalNoteTemplates(value)
        .then((res) => {
          if (res.data.errorCode === 0) {
            dispatch(setInternalNoteTemplatesList(res.data.data));
            dispatch(setInternalNoteTemplatesCount(res.data.info.totalCount));
          }
        })
        .catch((e) => {
          toast.error(e.response.data.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    fetchMessageTemplates({ pageNo: 0, limit: 10 });
  }, [dispatch]);
  return <PatientMessageList type={type} fetchMessageTemplates={fetchMessageTemplates} loading={loading} />;
};

export default MessageTemplate;
