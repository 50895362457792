import types from './types';

export const setuserTypeLists = (data: any) => ({
  type: types.SET_LOOK_UP_USER_TYPES_LIST,
  payload: data,
});

const actions = {
  setuserTypeLists,
};

export default actions;
