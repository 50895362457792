import actions from './actions';
import reducer from './reducer';

const staffManagement = {
  actions,
  reducer,
  name: 'staffManagement',
};

export default staffManagement;
