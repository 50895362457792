import StaffAPI from 'api/StaffManagementApi';
import Button from 'components/common/Button';
import Dropdown from 'components/common/Dropdown';
import Input from 'components/common/Input';
import MultiSelect from 'components/common/MultiSelect/MultiSelectV2';
import TextArea from 'components/common/TextArea';
import RepeatedInput from 'components/RepeatedInput';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import DateTimePicker from 'components/common/DateTimePicker';
import _ from 'lodash';
import moment from 'moment';
import classNames from 'classnames';
import { MAX_LENGTH_SHORT_BIO } from 'utils/constants';

export interface PhoneProps {
  countryCode: string;
  phoneNumber: string;
}
export interface userTypeProps {
  name: string;
  shortCode: string;
}

export interface StaffTypeProps {
  userRoleId: string;
  firstName: string;
  lastName: string;
  prefix: string;
  displayName: string;
  gender: string;
  dob: string;
  email: string;
  phone: PhoneProps;
  npi: string;
  timezone?: string;
  activeStates: string[] | string;
  specialtyAreas: string[] | string;
  aboutDoctor?: string;
  aboutNursePractitioner?: string;
  experience: string[] | string;
  status: string;
  staffCostId?: string;
  specialization: string;
}
export interface FormProps {
  handleFormSubmit?: ({ data }: any) => void;
  handleEditedFormSubmit?: (data: any, id: string) => void;
  handleDelete?: (id: string) => void;
  data?: any;
  isEdit?: any;
  userRoles?: any;
}

const Form = ({
  data,
  setShowModal,
  userRoles,
  isEdit,
  staffImage,
  fetchStaffs,
  setSelectedPage,
  setSearchText,
  searchFilterParams,
}: any) => {
  const payload: StaffTypeProps = {
    userRoleId: '',
    firstName: '',
    lastName: '',
    prefix: '',
    displayName: '',
    gender: '',
    dob: '',
    email: '',
    phone: { countryCode: 'US', phoneNumber: '' },
    npi: '',
    activeStates: [],
    specialtyAreas: [],
    experience: [],
    status: '',
    // staffCostId: '',
    specialization: '',
  };
  const [staffData, setStaffData] = useState<StaffTypeProps | any>(payload);
  const [userRoleValue, setUserRoleValue] = useState('');
  const [userType, setUserType] = useState([]);
  const [userTypeName, setUserTypeName] = useState<string>();
  const [payTypeData, setPayTypeData] = useState([{ value: '', label: '' }]);
  const [payType, setPayType] = useState<IOBJ[]>();
  const [creationDate, setCreationDate] = useState('');
  const [exp, setExp] = useState(['']);
  const [specialityArea, setSpecialityArea] = useState([]);
  const [specialityAreaData, setSpecialityAreaData] = useState<IOBJ[]>([{ value: '', label: '' }]);
  const [timeZoneData, setTimeZoneData] = useState<IOBJ[]>([{ value: '', label: '' }]);
  const [stateList, setStateList] = useState<IOBJ[]>([{ value: '', label: '' }]);
  const [stateListData, setStateListData] = useState<IOBJ[]>([{ value: '', label: '' }]);
  const [timezone, setTimeZone] = useState<IOBJ[]>([{ value: '', label: '' }]);
  const [biography, setBiography] = useState('');
  const [shortBiography, setShortBiography] = useState('');
  const [roleOptions, setRoleOptions] = useState<IOBJ[]>([{ value: '', label: '' }]);
  const [userId, setUserId] = useState('');
  const [phoneNumber, setPhoneNumber] = useState<any>();
  const [notformatNumber, setNotformatNumber] = useState<any>();
  const [statesSet, setStatesSet] = useState(false);

  const isPhysician = userTypeName === 'Physician';

  type IOBJ = {
    label: string;
    value: string;
  };
  const options1 = [
    { value: 'active', label: 'Active' },
    { value: 'inactive', label: 'Inactive' },
  ];

  const options4 = [
    { value: '0', label: 'Male' },
    { value: '1', label: 'Female' },
    { value: '2', label: 'Other' },
    { value: '3', label: 'Unknown' },
  ];

  useEffect(() => {
    if (!_.isEmpty(data?.[0])) {
      const eachStaff = data?.[0];
      let activeStateCopy: any = [];
      let spArea: any = [];
      spArea = eachStaff?.specialtyAreasInfo?.map((item: any) => {
        return { value: item._id, label: item.name };
      });
      activeStateCopy = eachStaff?.activeStates?.map((item: any) => {
        return { value: item, label: item };
      });
      setUserRoleValue(eachStaff?.userRoleInfo?.name);
      if (eachStaff?.aboutNursePractitioner) {
        setBiography(eachStaff?.aboutNursePractitioner);
      }
      if (eachStaff?.aboutDoctor) {
        setBiography(eachStaff?.aboutDoctor);
      }
      if (eachStaff?.shortBio) {
        setShortBiography(eachStaff?.shortBio);
      }
      setExp(eachStaff?.experience?.filter((x: any) => x !== ''));
      const phno = formatPhoneNumber(eachStaff?.phone?.phoneNumber);
      setPhoneNumber(phno);
      setCreationDate(moment.parseZone(eachStaff?.dob).format('YYYY-MM-DD'));
      const editPayload: any = {
        userRoleId: eachStaff?.userRoleInfo?._id,
        firstName: eachStaff?.firstName,
        lastName: eachStaff?.lastName,
        prefix: eachStaff?.prefix ?? '',
        displayName: eachStaff?.displayName,
        gender: eachStaff?.gender,
        dob: moment.parseZone(eachStaff?.dob).format('YYYY-MM-DD'),
        email: eachStaff?.email,
        phone: {
          countryCode: eachStaff?.phone?.countryCode ? eachStaff?.phone?.countryCode : 'US',
          phoneNumber: eachStaff?.phone?.phoneNumber,
        },
        specialization: eachStaff?.specialization,
        npi: eachStaff?.npi,
        activeStates: activeStateCopy,
        specialtyAreas: spArea,
        ...(eachStaff?.aboutDoctor && { aboutDoctor: eachStaff?.aboutDoctor }),
        ...(eachStaff?.aboutNursePractitioner && { aboutNursePractitioner: eachStaff?.aboutNursePractitioner }),
        experience: eachStaff?.experience,
        status: eachStaff?.status,
        staffCostId: eachStaff?.staffCostInfo?._id,
        payType: eachStaff?.staffCostInfo?.payType,
        payAmount: eachStaff?.staffCostInfo?.payAmount,
      };

      if (staffData?.timezone) {
        editPayload['timezone'] = staffData?.timezone;
      }

      setUserId(eachStaff?.userId);
      setStaffData(editPayload);
    }
  }, [data]);

  // INTIAL DATA SETTING
  useEffect(() => {
    if (_.isEmpty(data)) {
      setStaffData(payload);
    }
  }, [data]);

  // use effect for api call lookups
  useEffect(() => {
    StaffAPI.specialialityAreasLookup({}).then((res) => {
      setSpecialityArea(res?.data?.data);
      CustomDataMapper(res?.data?.data, 'specialityArea');
    });
    StaffAPI.stateListLookup({}).then((res) => {
      setStateList(res?.data?.data);
      CustomDataMapper(res?.data?.data, 'stateList');
    });
    StaffAPI.timezoneLookup({}).then((res) => {
      setTimeZone(res?.data?.data);
      CustomDataMapper(res?.data?.data, 'timezone');
    });
  }, []);

  // FETHCING PAYTYPE
  useEffect(() => {
    userTypeName &&
      StaffAPI.fetchStaffCostsConfigurations({ searchKey: userTypeName, status: 'active' }).then((res) => {
        setPayType(res?.data?.data);
        CustomDataMapper(res?.data?.data, 'payType');
      });
  }, [userTypeName]);

  useEffect(() => {
    if (
      timezone &&
      !staffData?.timezone &&
      (userTypeName === 'Physician' || userTypeName == 'Nurse Practitioner') &&
      !_.isEmpty(data?.[0])
    ) {
      const userDetails = data?.[0];
      const userDataValue = { ...staffData };
      const userTimeZoneValue = timezone.find((item) => item.value === userDetails?.timezone);
      userDataValue['timezone'] = userTimeZoneValue;
      setStaffData(userDataValue);
    }
  }, [timezone, staffData, userTypeName]);

  // SUBMIT FUNCTION

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const addedAboutDocKey: any = { ...(userTypeName === 'Physician' && { aboutDoctor: biography }) };
    const addedAboutNurseKey: any = {
      ...(userTypeName == 'Nurse Practitioner' && { aboutNursePractitioner: biography }),
    };
    const activeStateupdate = staffData?.activeStates;
    const specialtyAreasUpdated = staffData?.specialtyAreas;
    const data: any = [];
    const dataSpArea: any = [];
    activeStateupdate.map((item: any) => {
      data.push(item.value);
    });
    specialtyAreasUpdated.map((item: any) => {
      dataSpArea.push(item.value);
    });
    const stateCopy = { ...staffData };
    stateCopy['activeStates'] = data;
    stateCopy['timezone'] = staffData?.timezone?.value;
    stateCopy['dob'] = creationDate && moment(creationDate).format('YYYY-MM-DD');
    stateCopy['phone'] = {
      countryCode: 'US',
      phoneNumber: staffData?.phone?.phoneNumber?.replace(/[- )(]/g, '').slice(0, 10),
    };
    stateCopy['specialtyAreas'] = dataSpArea;
    stateCopy['experience'] = exp;
    if (addedAboutDocKey) {
      stateCopy['aboutDoctor'] = addedAboutDocKey?.aboutDoctor;
      stateCopy['shortBio'] = shortBiography;
    }
    if (addedAboutNurseKey) {
      stateCopy['aboutNursePractitioner'] = addedAboutNurseKey?.aboutNursePractitioner;
    }
    submitApi(stateCopy);
  };

  const submitApi = (data: any) => {
    setSelectedPage(1);
    setSearchText('');
    !isEdit
      ? StaffAPI.postStaff(data)
          .then((res: any) => {
            if (staffImage && res.data.errorCode == 0) {
              StaffAPI.postStaffImage(res?.data?.data?.userId, staffImage)
                .then((res: any) => {
                  toast.success('Successfully added');
                  fetchStaffs({ pageNo: 0, limit: 10, ...searchFilterParams });
                })
                .catch((err) => {
                  fetchStaffs({ pageNo: 0, limit: 10, ...searchFilterParams });
                  toast.error(err.response.data.message);
                });
              setShowModal?.(false);
            } else if (!staffImage && res.data.errorCode == 0) {
              toast.success('Successfully added');
              fetchStaffs({ pageNo: 0, limit: 10, ...searchFilterParams });
              setShowModal?.(false);
            }
          })
          .catch((err) => {
            if (err?.response?.data?.message) {
              toast.error(err.response.data.message);
            } else toast.error('Failed to Post data');
          })
      : StaffAPI.editStaffById(data, userId)
          .then((res: any) => {
            if (staffImage && res.data.errorCode == 0) {
              StaffAPI.postStaffImage(userId, staffImage)
                .then((res: any) => {
                  toast.success('Successfully edited');
                  fetchStaffs({ pageNo: 0, limit: 10, ...searchFilterParams });
                })
                .catch((err) => {
                  fetchStaffs({ pageNo: 0, limit: 10, ...searchFilterParams });
                  toast.error(err.response.data.message);
                });
              setShowModal?.(false);
            } else if (!staffImage && res.data.errorCode == 0) {
              toast.success('Successfully edited');
              fetchStaffs({ pageNo: 0, limit: 10, ...searchFilterParams });

              setShowModal?.(false);
            }
          })
          .catch((err) => {
            if (err?.response?.data?.message) {
              toast.error(err.response.data.message);
            } else toast.error('Failed to Edit data');
          });
  };
  // custom data mapper for dropsDowns to change to {value: '' ,label:''} format
  const CustomDataMapper = (input: any, key: any) => {
    const data: IOBJ[] = [];
    input.map((item: any) => {
      if (key === 'timezone') data.push({ value: item.value, label: item.label });
      else if (key === 'payType') data.push({ value: item?._id, label: item?.payType });
      else if (key === 'specialityArea' || key === 'specialization') data.push({ value: item?._id, label: item?.name });
      else data.push({ value: item?.name, label: item?.name });
    });
    if (key === 'timezone') setTimeZoneData(data);
    if (key === 'specialityArea') setSpecialityAreaData(data);
    if (key === 'payType') setPayTypeData(data);
    if (key === 'stateList') {
      setStateListData(data);
      setStatesSet(true);
    }
  };

  // DROPDOWN FOR USER ROLE OPTIONS DATA MAP
  useEffect(() => {
    const data: IOBJ[] = [];
    const userTypedata: any = [];
    userRoles.map((user: any) => {
      data.push({ value: user._id, label: user.name });
      userTypedata.push(user.userType);
    });
    setRoleOptions(data);
    setUserType(userTypedata);
  }, [userRoles]);

  useEffect(() => {
    userRoles.map((role: any) => {
      {
        if (role._id === staffData?.userRoleId) {
          setUserTypeName(role?.userType?.name);
          setUserRoleValue(role.name);
        }
      }
    });
  }, [staffData?.userRoleId, userRoles, data]);

  // Handler for normal input
  const handleChanges = (e: any) => {
    setStaffData({ ...staffData, [e.target.name]: e.target.value });
  };

  // handler for multiselect
  const multiselectHandler = (e: any, fieldName: any) => {
    const data: any = [];
    e.map((item: any) => {
      if (fieldName === 'activeStates') data.push({ value: item.value, label: item.label });
      else data.push({ value: item.value, label: item.label });
    });

    setStaffData({ ...staffData, [fieldName]: data });
  };

  // handler for repeated input
  const repeatedInputChange = (data: any, index: any) => {
    const item: any = [...exp];
    item[index] = data?.target?.value;
    const newdata = item.filter((x: any) => x !== undefined);
    setExp(Object.values?.(newdata));
  };

  const handleAdditon = () => {
    const expCopy: any = [...exp];
    expCopy.push('');
    setExp(expCopy);
  };
  // code for normal dropdown selection & unselection
  const onSelection = (e: any) => {
    const key = Object.keys(e)[0];
    const currentState = { ...staffData };
    if (key == 'payType') {
      currentState[key] = e[key].label;
      currentState.staffCostId = e.payType.value;
      payType?.map((item: any) => {
        if (item._id === e.payType.value) {
          currentState.payAmount = item.payAmount;
        }
      });
    } else if (key === 'timezone') {
      currentState[key] = e[key];
    } else {
      currentState[key] = e[key].value;
    }
    setStaffData(currentState);
  };

  /* HANDLING BIO ,phone,timezone seperately  */

  const handleBio = (e: any) => {
    setBiography(e.target.value);
  };

  const handleShortBio = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    if (value.length <= MAX_LENGTH_SHORT_BIO) {
      setShortBiography(e.target.value);
    }
  };

  function formatPhoneNumber(value: any) {
    // if input value is falsy eg if the user deletes the input, then just return
    if (!value) return value;

    // clean the input for any non-digit values.
    const phoneNumber = value.replace(/[^\d]/g, '');

    // phoneNumberLength is used to know when to apply our formatting for the phone number
    const phoneNumberLength = phoneNumber.length;

    // we need to return the value with no formatting if its less then four digits
    // this is to avoid weird behavior that occurs if you  format the area code to early

    if (phoneNumberLength < 4) return phoneNumber;

    // if phoneNumberLength is greater than 4 and less the 7 we start to return
    // the formatted number
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }

    // finally, if the phoneNumberLength is greater then seven, we add the last
    // bit of formatting and return it.
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
  }

  const handlePhone = (e: any) => {
    const formattedPhoneNumber = formatPhoneNumber(e.target.value);
    setStaffData({ ...staffData, phone: { ...staffData.phone, phoneNumber: e.target.value } });
    setPhoneNumber(formattedPhoneNumber);
    setNotformatNumber(e.target.value);
  };

  const handleDelete = (id: string) => {
    setSearchText('');
    setSelectedPage(1);
    StaffAPI.deleteStaff(id)
      .then((res: any) => {
        if (res.data.errorCode === 0) {
          fetchStaffs({ pageNo: 0, limit: 10 });
          setShowModal(false);
          toast.success('Successfully deleted');
        }
      })
      .catch((err) => {
        if (err?.response?.data?.message) {
          toast.error(err.response.data.message);
        } else toast.error('Failed to delete data');
      });
  };

  return (
    <>
      <form className="mt-10" onSubmit={handleSubmit}>
        <div className="flex justify-between items-center">
          <div className="mr-4 md:mr-0">
            <Dropdown
              labelText="Role"
              labelClasses="w-36 mr-12 text-medium"
              options={roleOptions}
              onChange={(e: any) => onSelection({ userRoleId: e })}
              value={{ value: userRoleValue, label: userRoleValue }}
              isDisabled={isEdit}
            />

            <Input
              labelText="First name"
              labelClasses="w-36 mr-12 text-medium"
              inputClasses="w-52"
              wrapperClasses="mb-4"
              onChange={handleChanges}
              name="firstName"
              value={staffData?.firstName}
            />
            <Input
              labelText="Prefix"
              labelClasses="w-36 mr-12 text-medium"
              inputClasses="w-52"
              wrapperClasses="mb-4"
              onChange={handleChanges}
              name="prefix"
              value={staffData?.prefix ?? ''}
              required={false}
            />

            <Dropdown
              labelText="Sex at birth"
              labelClasses="w-12 mr-20 text-medium"
              options={options4}
              onChange={(e: any) => onSelection({ gender: e })}
              value={{
                value: staffData?.gender || '',
                label: options4.find((el) => el.value === staffData?.gender)?.label || '',
              }}
            />

            <Input
              labelText="Email"
              labelClasses="w-36 mr-12 text-medium"
              inputClasses="w-52"
              wrapperClasses="mb-4"
              onChange={handleChanges}
              name="email"
              type="email"
              value={staffData?.email}
            />
            {(userTypeName === 'Nurse Practitioner' || userTypeName === 'Physician') && (
              <Input
                labelText="NPI #"
                labelClasses="w-52  text-medium"
                inputClasses="w-52"
                wrapperClasses="mb-4"
                onChange={handleChanges}
                name="npi"
                value={staffData?.npi}
                disabled={isEdit ? true : false}
              />
            )}
          </div>
          <div className="mr-4 md:mr-0">
            <Input
              labelText="Last name"
              labelClasses="w-36 text-medium"
              inputClasses="w-52"
              wrapperClasses={classNames(
                'mb-4',
                userTypeName === 'Nurse Practitioner' || userTypeName === 'Physician' ? 'mt-0' : 'mt-14',
              )}
              onChange={handleChanges}
              name="lastName"
              value={staffData?.lastName}
            />
            <Input
              labelText="Display name"
              labelClasses="w-32 mr-3 text-medium"
              inputClasses="w-52"
              wrapperClasses="mb-4"
              onChange={handleChanges}
              name="displayName"
              value={staffData?.displayName}
            />
            <DateTimePicker
              placeholder="Select date"
              name="Birthdate"
              labelText="Birthdate"
              wrapperClasses="mb-4"
              inputClasses="relative w-full"
              labelClasses="w-36 text-medium"
              format="MM-DD-YYYY"
              value={creationDate && moment(creationDate).format('MM-DD-YYYY')}
              onChange={(e) => setCreationDate(e)}
            />
            <Input
              labelText="Phone"
              labelClasses="w-36  text-medium"
              inputClasses="w-52"
              wrapperClasses="mb-4"
              onChange={(e) => {
                handlePhone(e);
              }}
              name="phoneNumber"
              value={phoneNumber}
            />
          </div>
        </div>
        {(userTypeName === 'Nurse Practitioner' || userTypeName === 'Physician') && (
          <>
            <Dropdown
              labelText="TimeZone"
              labelClasses="w-16 mr-16 text-medium adminlabelstaff"
              options={timeZoneData}
              onChange={(e: any) => onSelection({ timezone: e })}
              value={staffData?.timezone}
            />

            {/* <Input
              labelText="Specialization"
              labelClasses="w-28 mr-8 text-medium"
              inputClasses="w-full"
              wrapperClasses="mb-4"
              onChange={handleChanges}
              name="specialization"
              value={staffData?.specialization}
            /> */}

            <MultiSelect
              labelText="Licensed in"
              labelClasses="w-28 mr-10 text-medium"
              options={stateListData}
              value={statesSet ? staffData?.activeStates : null}
              handleChange={(data: any) => multiselectHandler(data, 'activeStates')}
              field="states"
              statesSet={statesSet}
            />
            <MultiSelect
              labelText="Specialty areas"
              labelClasses="w-40 mr-0 text-medium"
              options={specialityAreaData}
              value={staffData?.specialtyAreas}
              handleChange={(data: any) => multiselectHandler(data, 'specialtyAreas')}
              field="speciality areas"
            />

            {isPhysician && (
              <TextArea
                labelClasses="w-20 mr-14 text-medium adminstafftext"
                labelText="Short biography"
                handleChange={handleShortBio}
                name="Short biography"
                required={false}
                hintElement={'Limit 500 characters.'}
                value={shortBiography}
                rows={2}
              />
            )}

            <TextArea
              labelClasses="w-16 mr-16 text-medium adminstafftext"
              labelText={isPhysician ? 'Long biography' : 'Biography'}
              handleChange={handleBio}
              name="Biography"
              hintElement={
                <>
                  <span className="font-bold text-xs">{'*bold* _italic_ '}</span>
                  {'[Link text](https://linkurl.com)'}
                </>
              }
              value={biography}
              rows={4}
            />
            <RepeatedInput
              labelClasses="w-20 mr-14 text-medium"
              labelText="Education"
              onHandleChange={repeatedInputChange}
              value={exp}
              handleAdditon={handleAdditon}
            />
          </>
        )}
        <div
          className={classNames(
            'flex justify-between ',
            userTypeName === 'Nurse Practitioner' || userTypeName === 'Physician' ? 'items-end' : 'items-center',
          )}
        >
          <div className="mr-4 md:mr-0">
            <Dropdown
              labelClasses="mr-8 w-36 text-medium"
              width="52"
              ownClasses="adminmanagestaff"
              labelText="Pay type"
              options={payTypeData}
              onChange={(e: any) => onSelection({ payType: e })}
              value={{ value: staffData?.payType?.value, label: staffData?.payType }}
            />

            <Dropdown
              labelClasses="w-36 mr-8 text-medium adminstaff"
              width="52"
              labelText="Status"
              ownClasses="capitalize "
              options={options1}
              onChange={(e: any) => onSelection({ status: e })}
              value={{ value: staffData?.status, label: staffData?.status }}
            />
          </div>
          <div className="ml-5 mt-2 mb-8 self-start">
            <Input
              labelText="Pay amount($)"
              labelClasses="w-36 text-medium"
              inputClasses="w-52"
              wrapperClasses="mb-4"
              onChange={handleChanges}
              name="payAmount"
              value={staffData?.payAmount}
              disabled={true}
            />
          </div>
        </div>
        <div className="flex items-end justify-between mt-4 pt-4 pb-10 ">
          {isEdit ? (
            <Button ownClasses="rounded-full p-0 font-medium " type="depressed" onClick={() => handleDelete(userId)}>
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="red">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
              Delete staff member
            </Button>
          ) : (
            ''
          )}
          {isEdit ? (
            <Button ownClasses="rounded-full " type="success" formType>
              Save
            </Button>
          ) : (
            <Button ownClasses="rounded-full absolute right-0 " type="success" formType>
              Save
            </Button>
          )}
        </div>
      </form>
    </>
  );
};
export default Form;
