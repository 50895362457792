import types from './types';
import { LoggedActionType, LoggedUser } from './interfaces';

const initState: LoggedUser = {
  loggedIn: false,
  id: '',
  userName: '',
};

const reducer = (state: LoggedUser = initState, action: LoggedActionType): LoggedUser => {
  switch (action.type) {
    case types.SET_USER_LOGGED_IN:
      return {
        ...state,
        loggedIn: true,
        id: action.payload,
      };

    case types.SET_USER_LOGGED_OUT:
      return {
        ...state,
        loggedIn: false,
      };

    case types.SET_USER_NAME:
      return {
        ...state,
        userName: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
