import ManageRolesAPI from 'api/ManageRolesAPI';
import AdminManageRolesList from 'components/AdminManageRolesList';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setUserRolesCount, setUserRolesList } from 'redux/manageRoles/actions';

const AdminManageRoles: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const fetchUserRolesList = (value?: any) => {
    setLoading(true);
    ManageRolesAPI.fetchUserRoles(value)
      .then((res) => {
        if (res.data.errorCode === 0) {
          dispatch(setUserRolesList(res.data.data));
          dispatch(setUserRolesCount(res.data.info.totalCount));
        }
      })
      .finally(() => {
        setLoading(false);
      });
    // }
  };

  useEffect(() => {
    fetchUserRolesList({ pageNo: 0, limit: 10 });
  }, [dispatch]);
  return <AdminManageRolesList fetchUserRolesList={fetchUserRolesList} loading={loading} />;
};

export default AdminManageRoles;
