import Button from '../common/Button';
import Table from '../common/Table';
import { ReactComponent as PlusSvg } from '../../assets/icons/lifeMD-plus.svg';
import { ReactComponent as EditSvg } from '../../assets/icons/edit.svg';
import { useEffect, useRef, useState } from 'react';
import Modal from 'components/PatientMessagePopup';
import Form from '../PatientMessagePopup/Form';
import StandartHeader from '../PatientMessagePopup/Headers/StandartHeader';
import { RootStateOrAny, useSelector } from 'react-redux';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';
import SearchForm from './SearchForm/SearchForm';
import MessageTemplateAPI from 'api/MessageTemplateAPI';
import { toast } from 'react-toastify';
import LoadingOverlayWrapper from 'react-loading-overlay-ts';
import { hasAccessToModule } from 'auth/authorization';
import { CheckCategoryColor } from 'utils/CheckCategoryColor';

export interface MessageTemplateProps {
  type: string;
  fetchMessageTemplates: (value?: any) => void;
  loading: boolean;
}

const StandardTable: React.FC<MessageTemplateProps> = ({ type, fetchMessageTemplates, loading }) => {
  const userInfo = useSelector((state: RootStateOrAny) => state.userProfile.userDetails);

  const messageTemplatesList = useSelector((state: RootStateOrAny) =>
    type === 'Internal'
      ? state.messageTemplates.internalNoteTemplatesList
      : type === 'Patient'
      ? state.messageTemplates.patientMessageTemplatesList
      : state.messageTemplates.requestMessageTemplatesList,
  );
  const messageTemplatesCount = useSelector((state: RootStateOrAny) =>
    type === 'Internal'
      ? state.messageTemplates.internalNoteTemplatesCount
      : type === 'Patient'
      ? state.messageTemplates.patientMessageTemplatesCount
      : state.messageTemplates.requestMessageTemplatesCount,
  );

  const [inlineFilterActive, setInlineFilterActive] = useState('All');
  const [selectedPage, setSelectedPage] = useState(1);
  const [searchFilterParams, setSearchFilterParams] = useState({});
  const [pageItems, setPageItems] = useState([]);
  const [formData, setFormData] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [templateId, setTemplateId] = useState('');
  const [params, setParams] = useState<any>();

  const [sortKey, setSortKey] = useState<string | undefined>('');
  const [sortOrder, setSortOrder] = useState<string | undefined>();
  const searchRef = useRef<any>();

  const [hasEditAccess, setHasEditAccess] = useState(false);
  const [hasDeleteAccess, setHasDeleteAccess] = useState(false);

  const totalPage =
    messageTemplatesCount % 10 === 0
      ? Math.floor(messageTemplatesCount / 10)
      : Math.floor(messageTemplatesCount / 10) + 1;

  const headerItems = [
    {
      title: 'Message',
      sortKey: 'message',
    },
    {
      title: 'Shortcode',
      sortKey: 'shortCode',
    },
    {
      title: 'Unique id',
      sortKey: 'uniqueId',
    },
    {
      title: 'Status',
      sortKey: 'status',
    },
    {
      title: 'Actions',
      align: 'center',
    },
  ];

  const onSortClick = (title?: string, key?: string, sortOrder?: string) => {
    setSelectedPage(1);
    setSortKey(key);
    setSortOrder(sortOrder);
    const filterParams: any = {
      ...searchFilterParams,
      ...(key && { sortField: key }),
      ...(sortOrder && { sortOrder }),
      ...(inlineFilterActive && inlineFilterActive !== 'All' && { status: inlineFilterActive }),
    };
    filterParams['pageNo'] = 0;
    filterParams['limit'] = 10;
    fetchMessageTemplates(filterParams);
    setParams(filterParams);
  };

  const inlineStatusHandler = (inlineCategoryValue: string) => {
    setInlineFilterActive(inlineCategoryValue);

    const filterParams: any = {
      ...searchFilterParams,
      ...(sortKey && { sortField: sortKey }),
      ...(sortOrder && { sortOrder }),
    };
    filterParams['limit'] = 10;
    filterParams['pageNo'] = 0;
    filterParams['status'] = inlineCategoryValue !== 'All' ? inlineCategoryValue : null;

    setSelectedPage(1);
    fetchMessageTemplates(filterParams);
    setParams(filterParams);
  };

  const filterSearchTextHandler = (searchTextFilterValue?: any) => {
    setSearchFilterParams(searchTextFilterValue);
    const filterParams: any = {
      ...searchTextFilterValue,
      ...(sortKey && { sortField: sortKey }),
      ...(sortOrder && { sortOrder }),
      ...(inlineFilterActive && inlineFilterActive !== 'All' && { status: inlineFilterActive }),
    };
    filterParams['pageNo'] = 0;
    filterParams['limit'] = 10;
    setSelectedPage(1);
    fetchMessageTemplates(filterParams);
    setParams(filterParams);
  };

  const onPageClick = (e: any, value: number) => {
    e.preventDefault();
    setSelectedPage(value);
    const filterParams: any = {
      ...searchFilterParams,
      ...(sortKey && { sortField: sortKey }),
      ...(sortOrder && { sortOrder }),
      ...(inlineFilterActive && inlineFilterActive !== 'All' && { status: inlineFilterActive }),
    };
    filterParams['limit'] = 10;
    filterParams['pageNo'] = value - 1;
    fetchMessageTemplates(filterParams);
  };

  const onNextClick = (e: any) => {
    e.preventDefault();
    if (selectedPage !== totalPage) {
      setSelectedPage(selectedPage + 1);
      const filterParams: any = {
        ...searchFilterParams,
        ...(sortKey && { sortField: sortKey }),
        ...(sortOrder && { sortOrder }),
        ...(inlineFilterActive && inlineFilterActive !== 'All' && { status: inlineFilterActive }),
      };
      filterParams['limit'] = 10;
      filterParams['pageNo'] = selectedPage;
      fetchMessageTemplates(filterParams);
    }
  };

  const onPreviousClick = (e: any) => {
    e.preventDefault();
    if (selectedPage !== 1) {
      setSelectedPage(selectedPage - 1);
      const filterParams: any = {
        ...searchFilterParams,
        ...(sortKey && { sortField: sortKey }),
        ...(sortOrder && { sortOrder }),
        ...(inlineFilterActive && inlineFilterActive !== 'All' && { status: inlineFilterActive }),
      };
      filterParams['limit'] = 10;
      filterParams['pageNo'] = selectedPage - 2;
      fetchMessageTemplates(filterParams);
    }
  };

  const handleFormSubmit = (data: any) => {
    setSelectedPage(1);
    searchRef.current.clearSearch();
    if (type === 'Patient') {
      // Add New - for Patient Message Template
      MessageTemplateAPI.postPatientMessageTemplates(data)
        .then((res) => {
          if (res.data.errorCode === 0) {
            fetchMessageTemplates(params);
            setShowModal(false);
            toast.success('Success');
          }
        })
        .catch((err) => {
          if (err?.response?.data?.message) {
            toast.error(err.response.data.message);
          } else toast.error('Failed to create message template');
        });
    } else if (type === 'Request') {
      // Add New - for Request Message Template
      MessageTemplateAPI.postRequestMessageTemplates(data)
        .then((res) => {
          if (res.data.errorCode === 0) {
            fetchMessageTemplates(params);
            setShowModal(false);
            toast.success('Success');
          }
        })
        .catch((err) => {
          if (err?.response?.data?.message) {
            toast.error(err.response.data.message);
          } else toast.error('Failed to create message template');
        });
    } else if (type === 'Internal') {
      // Add New - for Internal Note Template
      MessageTemplateAPI.postInternalNoteTemplates(data)
        .then((res) => {
          if (res.data.errorCode === 0) {
            fetchMessageTemplates(params);
            setShowModal(false);
            toast.success('Success');
          }
        })
        .catch((err) => {
          if (err?.response?.data?.message) {
            toast.error(err.response.data.message);
          } else toast.error('Failed to create message template');
        });
    }
  };

  const handleEditedFormSubmit = (data: any, id: string) => {
    setSelectedPage(1);
    searchRef.current.clearSearch();
    if (type === 'Patient') {
      // Edit - Patient Message Template
      MessageTemplateAPI.editPatientMessageTemplateById(data, id)
        .then((res) => {
          if (res.data.errorCode === 0) {
            fetchMessageTemplates(params);
            setShowModal(false);
            toast.success('Success');
          }
        })
        .catch((err) => {
          if (err?.response?.data?.message) {
            toast.error(err.response.data.message);
          } else toast.error('Failed to update message template');
        });
    } else if (type === 'Request') {
      // Edit - Request Message Template
      MessageTemplateAPI.editRequestMessageTemplateById(data, id)
        .then((res) => {
          if (res.data.errorCode === 0) {
            fetchMessageTemplates(params);
            setShowModal(false);
            toast.success('Success');
          }
        })
        .catch((err) => {
          if (err?.response?.data?.message) {
            toast.error(err.response.data.message);
          } else toast.error('Failed to update message template');
        });
    } else if (type === 'Internal') {
      // Edit - Internal Note Template
      MessageTemplateAPI.editInternalNoteTemplateById(data, id)
        .then((res) => {
          if (res.data.errorCode === 0) {
            fetchMessageTemplates(params);
            setShowModal(false);
            toast.success('Success');
          }
        })
        .catch((err) => {
          if (err?.response?.data?.message) {
            toast.error(err.response.data.message);
          } else toast.error('Failed to update message template');
        });
    }
  };

  const handleEdit = (eachTemplate: any) => {
    setFormData(eachTemplate);
    setTemplateId(eachTemplate._id);
    setIsEditMode(true);
    setShowModal(true);
  };

  const handleDelete = (id: string) => {
    setSelectedPage(1);
    searchRef.current.clearSearch();
    if (type === 'Patient') {
      // Delete - Patient Message Template
      MessageTemplateAPI.deletePatientMessageTemplateById(id)
        .then((res) => {
          if (res.data.errorCode === 0) {
            fetchMessageTemplates(params);
            setShowModal(false);
            toast.success('Successfully deleted');
          }
        })
        .catch((err) => {
          if (err?.response?.data?.message) {
            toast.error(err.response.data.message);
          } else toast.error('Failed to delete message template');
        });
    } else if (type === 'Request') {
      // Delete - Request Message Template
      MessageTemplateAPI.deleteRequestMessageTemplateById(id)
        .then((res) => {
          if (res.data.errorCode === 0) {
            fetchMessageTemplates(params);
            setShowModal(false);
            toast.success('Successfully deleted');
          }
        })
        .catch((err) => {
          if (err?.response?.data?.message) {
            toast.error(err.response.data.message);
          } else toast.error('Failed to delete message template');
        });
    } else if (type === 'Internal') {
      // Delete - Internal Note Template
      MessageTemplateAPI.deleteInternalNoteTemplateById(id)
        .then((res) => {
          if (res.data.errorCode === 0) {
            fetchMessageTemplates(params);
            setShowModal(false);
            toast.success('Successfully deleted');
          }
        })
        .catch((err) => {
          if (err?.response?.data?.message) {
            toast.error(err.response.data.message);
          } else toast.error('Failed to delete message template');
        });
    }
  };

  useEffect(() => {
    if (!showModal) {
      setFormData({});
      setIsEditMode(false);
    }
  }, [showModal]);

  useEffect(() => {
    setHasEditAccess(hasAccessToModule(userInfo.userRoleInfo.editingPermissions, ['TEMPLATE_MESSAGE_EDIT']));
    setHasDeleteAccess(hasAccessToModule(userInfo.userRoleInfo.editingPermissions, ['TEMPLATE_MESSAGE_DELETE']));
  }, []);

  useEffect(() => {
    let leftSide = selectedPage - 2;
    if (leftSide <= 0) leftSide = 1;
    let rightSide = selectedPage + 2;
    if (rightSide > totalPage) rightSide = totalPage;
    const pages: any = [];
    for (let number = leftSide; number <= rightSide; number++) {
      pages.push(number);
    }
    setPageItems(pages);
  }, [selectedPage, messageTemplatesCount]);

  return (
    <div className="flex flex-col flex-auto p-11 pt-4 bg-background">
      <div className="flex flex-row items-center my-5">
        {type === 'Internal' ? (
          <h2 className="text-black text-2xl font-medium mr-8">Internal note templates</h2>
        ) : (
          <h2 className="text-black text-2xl font-medium mr-8">{`${type} message templates`}</h2>
        )}
        {hasAccessToModule(userInfo.userRoleInfo.editingPermissions, ['TEMPLATE_MESSAGE_ADD']) && (
          <Button
            type="primary"
            ownClasses=" px-4 mx-0.5 bg-white text-sm text-darkBlue rounded-full shadow-lg font-normal"
            onClick={() => setShowModal(true)}
          >
            <PlusSvg className="w-3.5 h-3.5 mr-1" />
            New
          </Button>
        )}
        {showModal && (
          <Modal showModal={showModal} setShowModal={setShowModal}>
            <div className="px-12 py-16 patientmsgup">
              <StandartHeader type={type} isEditMode={isEditMode} templateId={templateId} />
              <div className="relative  flex-auto">
                <Form
                  type={type}
                  formData={formData}
                  handleFormSubmit={handleFormSubmit}
                  handleEditedFormSubmit={handleEditedFormSubmit}
                  handleDelete={handleDelete}
                  isEditMode={isEditMode}
                  hasDeleteAccess={hasDeleteAccess}
                />
              </div>
            </div>
          </Modal>
        )}
      </div>
      <div className="flex flex-row flex-wrap gap-y-3 items-center justify-between my-5">
        <div className="flex flex-row flex-wrap items-center">
          <Button
            type={`${inlineFilterActive == 'All' ? 'success' : 'secondary'}`}
            ownClasses="mr-3 pl-4 pr-5 py-1 text-lg rounded-full font-normal"
            onClick={() => inlineStatusHandler('All')}
          >
            <i className="w-2.5 h-2.5 mr-2 bg-white rounded-full" />
            All
          </Button>
          <Button
            type={`${inlineFilterActive == 'active' ? 'success' : 'secondary'}`}
            ownClasses="mr-3 pl-4 pr-5 py-1 text-lg rounded-full font-normal"
            onClick={() => inlineStatusHandler('active')}
          >
            <i className={`w-2.5 h-2.5 mr-2 ${CheckCategoryColor('active')} rounded-full`} />
            Active
          </Button>
          <Button
            type={`${inlineFilterActive == 'inactive' ? 'success' : 'secondary'}`}
            ownClasses="mr-3 pl-4 pr-5 py-1 text-lg rounded-full font-normal"
            onClick={() => inlineStatusHandler('inactive')}
          >
            <i className={`w-2.5 h-2.5 mr-2 ${CheckCategoryColor('inactive')} rounded-full`} />
            Inactive
          </Button>
        </div>
        <SearchForm
          filterSearchTextHandler={filterSearchTextHandler}
          type={type}
          sortField={sortKey}
          sortOrder={sortOrder}
          inlineFilterActive={inlineFilterActive}
          ref={searchRef}
        />
      </div>
      <LoadingOverlayWrapper active={loading} spinner>
        <Table headerItems={headerItems} onSortClick={onSortClick}>
          {messageTemplatesList?.map((eachTemplate: any) => (
            <tr className="h-14 border-t border-lightGreyBackground" key={eachTemplate?._id}>
              <td className="px-6 pb-4 pt-5 border-r border-lightGreyBackground">
                <p className="max-w-lg truncate">{eachTemplate?.message}</p>
              </td>
              <td className="px-6 pb-4 pt-5 border-r border-lightGreyBackground">
                <p>{eachTemplate?.shortCode}</p>
              </td>
              <td className="px-6 pb-4 pt-5 border-r border-lightGreyBackground" id="uniqueId">
                <p>{eachTemplate?._id}</p>
              </td>
              <td className="px-6 pb-4 pt-5 border-r border-lightGreyBackground">
                <p className="text-midBlue capitalize">{eachTemplate?.status}</p>
              </td>
              <td className="px-6 pb-4 pt-5 text-center">
                {hasEditAccess || hasDeleteAccess ? (
                  <button onClick={() => handleEdit(eachTemplate)}>
                    <EditSvg className="w-4" />
                  </button>
                ) : (
                  <p>NA</p>
                )}
              </td>
            </tr>
          ))}
        </Table>
      </LoadingOverlayWrapper>

      <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
        <div className="flex-1 flex justify-between sm:hidden">
          <a
            href="#"
            className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
          >
            Previous
          </a>
          <a
            href="#"
            className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
          >
            Next
          </a>
        </div>

        <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
          <div>
            {messageTemplatesCount > 0 ? (
              <p className="text-sm text-gray-700">
                Showing{' '}
                <span className="font-medium">{selectedPage === 1 ? selectedPage : (selectedPage - 1) * 10 + 1}</span>{' '}
                to{' '}
                <span className="font-medium">
                  {selectedPage * 10 > messageTemplatesCount ? messageTemplatesCount : selectedPage * 10}
                </span>{' '}
                of <span className="font-medium">{messageTemplatesCount}</span> messages
              </p>
            ) : (
              <p className="text-sm text-gray-700">
                Showing <span className="font-medium">{messageTemplatesCount}</span> messages
              </p>
            )}
          </div>
          <div>
            <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
              <a
                onClick={(e) => onPreviousClick(e)}
                href="#"
                className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
              >
                <span className="sr-only">Previous</span>
                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
              </a>
              {pageItems.map((item: any) => (
                <a
                  href="#"
                  key={item}
                  aria-current="page"
                  onClick={(e) => onPageClick(e, item)}
                  className={`z-10 ${
                    selectedPage === item ? 'bg-green text-black' : 'border-gray-300 text-gray-500 bg-white'
                  }  relative inline-flex items-center px-4 py-2 border text-sm font-medium`}
                >
                  {item}
                </a>
              ))}
              <a
                href="#"
                onClick={(e) => onNextClick(e)}
                className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
              >
                <span className="sr-only">Next</span>
                <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
              </a>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StandardTable;
