import classNames from 'classnames';

export interface ButtonProps extends React.HTMLProps<HTMLButtonElement> {
  type: 'primary' | 'secondary' | 'depressed' | 'success';
  ownClasses?: string;
  disabled?: boolean;
  onClick?: (e: any) => React.MouseEventHandler<HTMLButtonElement> | void;
  formType?: boolean;
}
const Button: React.FC<ButtonProps> = ({ disabled, type, children, ownClasses, onClick, formType, ...otherProps }) => {
  const btnStyle = classNames(
    ownClasses,
    'btn',
    { 'opacity-50': disabled },
    'flex',
    'items-center',
    `btn-${type}`,
    'rounded-lg',
  );

  return (
    <button
      {...otherProps}
      onClick={onClick}
      disabled={disabled}
      className={btnStyle}
      type={`${formType ? 'submit' : 'button'}`}
    >
      {children}
    </button>
  );
};

export default Button;
