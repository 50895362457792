import Button from 'components/common/Button';
import { ReactComponent as Eye } from 'assets/icons/eye.svg';
import { ReactComponent as Bag } from 'assets/icons/bag.svg';
import { ReactComponent as Acc } from 'assets/icons/acc.svg';
import moment from 'moment';
import { ViewAsEmr } from 'utils/ViewAsEmr';
import { LoginAsPatient } from 'utils/LoginAsPatient';
import { ViewChart } from 'utils/ViewChart';
import { RootStateOrAny, useSelector } from 'react-redux';
import { hasAccessToModule } from 'auth/authorization';
export interface AppointmentProps {
  upComingAppointment: any;
  patientId: string;
}

const Appointments: React.FC<AppointmentProps> = ({ upComingAppointment, patientId }) => {
  const userInfo = useSelector((state: RootStateOrAny) => state.userProfile.userDetails);
  return (
    <div className="py-8">
      <h3 className="section-subheading">Upcoming appointments</h3>
      {upComingAppointment[0] ? (
        <div className="shadow-cardShadow max-w-xl px-9 py-6 mb-10 flex flex-wrap rounded-xl items-center bg-white">
          <div className="flex">
            <div className="m-auto flex flex-col p-1.5 px-4 mr-8 bg-green text-center text-white font-bold">
              <span className="text-xs text-white">
                {' '}
                {moment(upComingAppointment[0].appointmentTime.startTime).format('MMM')}
              </span>
              <span className="text-xl text-white">
                {moment(upComingAppointment[0].appointmentTime.startTime).format('Do')}
              </span>
            </div>
          </div>
          <div className="flex flex-wrap flex-grow justify-between items-center">
            <div className="flex-flex-col mr-14 mb-2">
              <h4 className="font-bold text-lg text-text">{upComingAppointment[0].appointmentDescription}</h4>
              <span>{upComingAppointment[0].staffName}</span>
            </div>
            <h4 className="text-lg">
              {moment(upComingAppointment[0].appointmentTime.startTime).format('LT')} -{' '}
              {moment(upComingAppointment[0].appointmentTime.endTime).format('LT')}
            </h4>
          </div>
        </div>
      ) : (
        <div className="text-lg mb-5">No Upcoming Appointments</div>
      )}

      <div className="flex flex-col md:flex-row">
        {hasAccessToModule(userInfo.userRoleInfo.pagePermissions, ['PATIENT_DETAILS_VIEW']) && (
          <Button ownClasses="font-normal" type="depressed" onClick={() => ViewChart(patientId)}>
            <Eye className="mr-2 w-5" />
            View chart
          </Button>
        )}
        {hasAccessToModule(userInfo.userRoleInfo.pagePermissions, ['VIEW_PATIENT_EMR']) && (
          <Button ownClasses="font-normal" type="depressed" onClick={() => ViewAsEmr(patientId)}>
            <Bag className="mr-2 w-5" />
            View in EMR
          </Button>
        )}
        {hasAccessToModule(userInfo.userRoleInfo.pagePermissions, ['VIEW_AS_PATIENT']) && (
          <Button ownClasses="font-normal" type="depressed" onClick={() => LoginAsPatient(patientId)}>
            <Acc className="mr-2 w-5" />
            Log in as Patient
          </Button>
        )}
      </div>
    </div>
  );
};

export default Appointments;
