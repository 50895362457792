import actions from './actions';
import reducer from './reducer';

const appointmentTypesData = {
  actions,
  reducer,
  name: 'appointmentTypesData',
};

export default appointmentTypesData;
