import { ReactComponent as CameraIcon } from 'assets/icons/camera.svg';
import { useEffect, useState } from 'react';

const StandartHeader = ({ isEdit, staffData, staffImage, onImageChange, staffId }: any) => {
  const [image, setImage] = useState(staffImage);
  useEffect(() => {
    if (staffImage) {
      const img = staffImage;
      const reader = new FileReader();
      const url = reader.readAsDataURL(img);
      reader.onloadend = function (e) {
        console.log();
      };
      setImage([reader.result]);
    }
  }, []);
  return (
    <div className="w-full">
      <span className="flex justify-between items-center">
        <div className="flex flex-col">
          <h2 className="text-2xl text-green font-bold">{!isEdit ? ' New staff member' : staffData[0]?.displayName}</h2>
        </div>
        {isEdit && <p className="text-text text-sm">ID: {staffId}</p>}
      </span>
      <div className="flex items-center mt-7">
        <span className="text-text mr-6">Profile photo</span>
        <div className="flex justify-center items-center border  border-textGreyedOut-100 rounded-full w-16 h-16">
          {staffImage ? (
            <img src={staffImage ? URL.createObjectURL(staffImage) : ''} className="h-full	rounded-full	" />
          ) : (
            <CameraIcon width="1.5rem" height="1.5rem" />
          )}
        </div>
        <span className="underline ml-6 text-sm text-dashboardPrimaryGrey">
          <label htmlFor="fileimage">Upload picture</label>

          <input
            id="fileimage"
            type="file"
            accept="image/*"
            onChange={onImageChange}
            style={{ visibility: 'hidden' }}
          />
        </span>
      </div>
    </div>
  );
};

export default StandartHeader;
