import openSocket from 'socket.io-client';
import config from '../config';

const { webSocket } = config;
const userToken = () => {
  const token: any = window.localStorage.getItem('accessToken');
  return token;
};

const socket = openSocket(webSocket.baseUrlWebSocket, {
  secure: true,
  autoConnect: false,
});

export default socket;

export function socket_init() {
  socket.io.opts['extraHeaders'] = { Authorization: userToken() };
  socket.connect();
  socket.on('connect', () => {
    console.log('==================== connected to socket message =================================');
  });
}

export function socket_close() {
  socket.io.opts['extraHeaders'] = { Authorization: '' };
  socket.close();
  socket.disconnect();
  socket.on('disconnect', () => {
    console.log('====socket disconnected========');
  });
}
