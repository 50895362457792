import { useState } from 'react';
import TaskApi from 'api/TaskAPI';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import { LinkToPreConsultTaskProps } from './linkToPreConsultTask.type';
import { useEffect } from 'react';

const LinkToTask: React.FC<LinkToPreConsultTaskProps> = ({
  taskId,
  onClickLinkToPreConsult,
  isOnboardingOrPreConsult,
}) => {
  const [isComplete, setIsComplete] = useState(false);
  const [titleText, setTitleText] = useState('Task');

  const statusClasses = classNames('font-bold', isComplete ? 'text-green' : 'text-red');

  const fetchPreConsultTaskDetails = () => {
    TaskApi.fetchSingleTask(taskId)
      .then((res) => {
        const { data } = res.data;
        if (data) {
          setTitleText(data.category || '');
          if (isOnboardingOrPreConsult) {
            const isCompleteIntakeItems = data.taskIntakeItems?.length === data.intakeItemsInfo?.length;
            setIsComplete(isCompleteIntakeItems);
          }
        }
      })
      .catch((e) => {
        if (e.response?.data?.message) {
          toast.error(e.response.data.message);
        }
      });
  };

  useEffect(() => {
    fetchPreConsultTaskDetails();
  }, []);

  return (
    <div className="flex">
      <h1 className="md:w-1/5 text-lg font-bold">{titleText}:</h1>
      <div className="flex gap-2">
        {isOnboardingOrPreConsult && <p className={statusClasses}>{isComplete ? 'Complete' : 'Incomplete'}</p>}
        <p className="underline cursor-pointer" onClick={() => onClickLinkToPreConsult(taskId)}>
          View task
        </p>
      </div>
    </div>
  );
};

export default LinkToTask;
