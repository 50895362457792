import { useState } from 'react';

import classNames from 'classnames';
import Button from 'components/common/Button';
import NotificationAPI from 'api/NotificationAPI';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { setSelectedTaskId } from 'redux/tasks/actions';
import { toast } from 'react-toastify';
import Modal from 'components/PatientMessagePopup';
import { useSelector, RootStateOrAny } from 'react-redux';
import PatientAPI from 'api/PatientAPI';
import { PatientDetails } from 'components/Admin/Patient/PatientInfo/patientInfo.types';
import SMSModal from 'components/Admin/Patient/PatientInfo/SMSModal';

export interface NotificationProps {
  ownClasses?: string;
  title: string;
  text: string;
  type: string;
  date: string;
  btnData: { label: string; referenceId: string; variable: string };
  notificationId: string;
  afterDismiss: () => void;
  onCancel: () => void;
}
import { ReactComponent as Bell } from 'assets/icons/bell.svg';

const Notification: React.FC<NotificationProps> = ({
  ownClasses,
  title,
  text,
  type,
  date,
  btnData,
  notificationId,
  afterDismiss,
  onCancel,
}) => {
  const notificationList = useSelector((state: RootStateOrAny) => state.notifications.notificationList);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [patientDetails, setPatientDetails] = useState<PatientDetails | null>(null);

  const cardStyles = classNames(
    'bg-white',
    'rounded-lg',
    'shadow-full',
    'py-3',
    'z-50',
    'px-8',
    'w-full',
    'max-w-md',
    ownClasses,
  );

  const history = useHistory();
  const dispatch = useDispatch();

  const onClickNotificationSection = (referenceId: any) => {
    if (referenceId) {
      if (type !== 'SMS') {
        dispatch(setSelectedTaskId(referenceId));
        history.push('/dashboard/tasks');
      } else {
        PatientAPI.fetchSinglePatient(referenceId).then((res) => {
          const { data } = res.data;
          if (data) {
            setPatientDetails(data);
            setShowModal(true);
          }
        });
      }
    }
  };

  const onDismiss = (referenceId: any) => {
    if (referenceId) {
      const payload = {
        notificationIds: [referenceId],
      };
      NotificationAPI.markSeenNotifications(payload)
        .then((res) => {
          afterDismiss();
          onCancel();
        })
        .catch((e) => {
          toast.error(e?.response?.data?.message);
        });
    }
  };

  return (
    <div className={cardStyles}>
      {showModal && patientDetails && (
        <div className="fixed top-0 left-0 right-0 h-screen z-50">
          <Modal showModal={showModal} setShowModal={setShowModal}>
            <SMSModal patientDetails={patientDetails} />
          </Modal>
        </div>
      )}

      <div className="flex mb-3">
        <Bell className="mx-2 mr-6 mt-4 w-6 max-w-m h-8" />
        <div className="flex flex-col">
          <h4 className="text-text font-bold text-lg">{title}</h4>
          <h5 className="text-lg">{text}</h5>
          <span className="text-sm text-dashboardLightGrey">{date}</span>
        </div>
      </div>

      <div className="flex items-center mb-2">
        {btnData ? (
          <Button
            onClick={() => onClickNotificationSection(btnData?.referenceId)}
            ownClasses="m-2 rounded-lg"
            type="primary"
          >
            {btnData?.label}
          </Button>
        ) : null}

        <Button onClick={() => onDismiss(notificationId)} ownClasses="m-2 rounded-lg" type="secondary">
          Dismiss
        </Button>
      </div>
    </div>
  );
};

export default Notification;
