import Dropdown from 'components/common/Dropdown';
import Input from 'components/common/Input';
import MultiSelect from 'components/common/MultiSelect';
import { useEffect, useState } from 'react';
import * as _ from 'lodash';
import { toast } from 'react-toastify';
import Button from 'components/common/Button';
import classNames from 'classnames';
import AdminAutomationAPI from 'api/AdminAutomationAPI';

const Form = ({
  fetchRequestType,
  handleFormSubmit,
  handleEditedFormSubmit,
  handleDelete,
  hasDeleteAccess,
  formData,
  isEditMode,
}: any) => {
  const options1 = [
    { value: 'Labs', label: 'Labs' },
    { value: 'Imaging', label: 'Imaging' },
    { value: 'Referrals', label: 'Referrals' },
  ];
  const options2 = [
    { value: 'California', label: 'California' },
    { value: 'Florida', label: 'Florida' },
    { value: 'New York', label: 'New York' },
    { value: 'South Carolina', label: 'South Carolina' },
    { value: 'South Dakota', label: 'South Dakota' },
  ];

  const options3 = [
    { value: 'active', label: 'Active' },
    { value: 'inactive', label: 'Inactive' },
  ];
  const options4 = [
    // { value: 'NP', label: 'NP' },
    { value: 'CS', label: 'CS' },
    { value: 'MA', label: 'MA' },
  ];
  const [request, setRequest] = useState('');
  const [requestTypeData, setRequestTypeData] = useState<any>('');

  const [category, setCategory] = useState<{ label: string; value: string } | null>();
  const [routeTo, setRouteTo] = useState<{ label: string; value: string } | null>();
  const [trigger, setTrigger] = useState<any | null>();
  const [status, setStatus] = useState<{ label: string; value: string } | null>();
  const [triggerData, setTriggerData] = useState<{ label: string; value: string } | null | any>();
  const [triggerOption, setTriggerOption] = useState<any>([]);
  const handleTriggerSelection = (e: any) => {
    setTrigger(e);
  };

  useEffect(() => {
    // let triggerTime = { occurenceOrder: '' };
    AdminAutomationAPI.fetchAutomations({}).then((res) => {
      setTriggerOption(res?.data?.data);
    });
  }, [isEditMode]);

  useEffect(() => {
    const data: any = [];
    triggerOption?.map((item: any) => {
      if (item?.triggerTime?.occurenceOrder == 'after') data.push({ value: item._id, label: item.name });
    });
    setTriggerData(data);
  }, [triggerOption]);

  useEffect(() => {
    if (!_.isEmpty(formData)) {
      setRequest(formData.requestType);
      setStatus(options3.find((item) => item.value === formData.status));
      setRouteTo(options4.find((item) => item.value === formData.routeTo));
      let triggerNew: any = [];

      triggerNew = formData?.triggers?.map((item: any) => {
        return { value: item._id, label: item.name };
      });
      setTrigger(triggerNew);
    } else if (_.isEmpty(formData)) {
      setRequest('');
    }
  }, [formData, triggerData]);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const triggerNew: any = [];

    trigger?.map((item: any) => {
      triggerNew.push(item?.value);
    });
    const data = {
      requestType: request,
      routeTo: routeTo?.value,
      triggerId: triggerNew,
      status: status?.value,
    };
    isEditMode ? handleEditedFormSubmit?.(data, formData._id) : handleFormSubmit?.(data);
  };

  return (
    <form className="mt-10 requestlist" onSubmit={handleSubmit}>
      <div className="mb-4"></div>
      <Input
        labelText="Request"
        inputClasses="h-10"
        labelClasses="mr-10 text-medium"
        wrapperClasses="mb-4"
        value={request}
        placeholder="Select Request"
        onChange={(e) => setRequest(e.target.value)}
      />
      <div className="mb-4 requestdrop">
        <Dropdown
          labelText="Route to"
          labelClasses="mr-10 text-medium"
          options={options4}
          placeholder="Select Route to"
          onChange={(e) => setRouteTo(e)}
          value={routeTo}
        />
      </div>
      <MultiSelect
        labelText="Triggers"
        placeholder="Select Triggers"
        labelClasses="mr-10 text-medium"
        options={triggerData}
        handleChange={handleTriggerSelection}
        value={trigger}
      />

      <div className="mb-8 requestdrop">
        <Dropdown
          labelText="Status"
          placeholder="Select Status"
          labelClasses="mr-10 text-medium"
          options={options3}
          onChange={(e) => setStatus(e)}
          value={status}
        />
      </div>
      <div
        className={classNames('flex items-center mt-4 pb-14', {
          'justify-end': !isEditMode,
          ' justify-between': isEditMode,
        })}
      >
        {!isEditMode && (
          <div>
            <Button ownClasses="rounded-full " type="success" formType>
              Save
            </Button>
          </div>
        )}

        {isEditMode && (
          <>
            <div className="flex items-center justify-between ">
              <div>
                <Button
                  ownClasses="rounded-full p-0 font-medium"
                  type="depressed"
                  onClick={() => handleDelete(formData?._id)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="red"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                  </svg>
                  Delete request type
                </Button>
              </div>
              <div className="absolute right-0 ">
                <Button ownClasses="rounded-full " type="success" formType>
                  Save
                </Button>
              </div>
            </div>
          </>
        )}
      </div>
    </form>
  );
};

export default Form;
