import Button from 'components/common/Button';
import { ReactComponent as Bag } from 'assets/icons/bag.svg';
import { ViewAsEmr } from 'utils/ViewAsEmr';
import { hasAccessToModule } from 'auth/authorization';
import { useSelector, RootStateOrAny } from 'react-redux';

export interface ResultProps {
  results: any;
  patientId: string;
}
const Result: React.FC<ResultProps> = ({ results, patientId }) => {
  const userInfo = useSelector((state: RootStateOrAny) => state.userProfile.userDetails);
  return (
    <>
      {results && results[0]
        ? results.map((eachItem: any) => {
            return (
              <div className="bg-lightGreyBackground mb-12" key={eachItem._id}>
                <div className="px-16 py-8 flex sm:flex-row justify-between ">
                  <p className="text-xl font-bold">
                    {eachItem.title} :{' '}
                    <span className={`text-xl ${eachItem.abnormalFlag === null ? 'text-green' : 'text-red'} `}>
                      {eachItem.abnormalFlag === null ? 'In range' : 'out of range'}
                    </span>
                  </p>
                  {hasAccessToModule(userInfo.userRoleInfo.pagePermissions, ['VIEW_PATIENT_EMR']) && (
                    <Button ownClasses="font-normal" type="depressed" onClick={() => ViewAsEmr(patientId)}>
                      <Bag className="mr-2 w-5" />
                      View EMR
                    </Button>
                  )}
                </div>
              </div>
            );
          })
        : results.title && (
            <div className="bg-lightGreyBackground mb-12">
              <div className="px-16 py-8 flex sm:flex-row justify-between ">
                <p className="text-xl font-bold">
                  {results.title} :{' '}
                  <span className={`text-xl ${results.abnormalFlag === null ? 'text-green' : 'text-red'} `}>
                    {results.abnormalFlag === null ? 'In range' : 'out of range'}
                  </span>
                </p>
                <Button ownClasses="font-normal" type="depressed" onClick={() => ViewAsEmr(patientId)}>
                  <Bag className="mr-2 w-5" />
                  View EMR
                </Button>
              </div>
            </div>
          )}
    </>
  );
};

export default Result;
