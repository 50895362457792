import React, { useContext, useEffect } from 'react';
import { ReactComponent as PhoneSvg } from 'assets/icons/patientInfo/phone.svg';
import Button from '../Button';
import { PhoneCallProps } from './phoneCall.types';
import { CallContext } from 'contexts/CallContext';

const PhoneCall: React.FC<PhoneCallProps> = ({ token, patientId }) => {
  const { setPatientId, setShowCallCard, setToken } = useContext(CallContext);

  const handleCall = () => {
    setShowCallCard(true);
  };

  useEffect(() => {
    setPatientId(patientId);
    setToken(token);
  }, [patientId, token]);

  return (
    <>
      <Button tooltip="Call patient" onClick={handleCall}>
        <PhoneSvg />
      </Button>
    </>
  );
};

export default PhoneCall;
