import Button from '../Button';
import { ReactComponent as SearchSvg } from '../../../assets/icons/search.svg';
import { useState } from 'react';
import { XIcon } from '@heroicons/react/solid';
import Form from './Form';

const SearchForm: React.FC<{ showSearchBar?: boolean }> = ({ showSearchBar }) => {
  const [showModal, setShowModal] = useState(false);
  return (
    <form className="flex flex-row flex-wrap items-center">
      <Button type="success" ownClasses="px-8 py-1 text-lg rounded-full" onClick={() => setShowModal(true)}>
        Filter
      </Button>
      {showSearchBar && (
        <div className="flex ml-2 flex-row items-center px-5 bg-lightGreyBackground rounded-full">
          <SearchSvg className="w-4" />
          <input
            className="w-64 px-2 py-1 text-lg bg-lightGreyBackground focus:ring-transparent border-none"
            type="search"
            name="search"
            placeholder="Search"
          />
        </div>
      )}
      {showModal ? (
        <>
          <div className="flex items-center justify-center inset-0 fixed z-50 p-12">
            <div className="relative bg-white rounded-lg shadow-lg">
              <button
                className="absolute -top-6 -right-7 z-10 p-4 rounded-full bg-darkBlue"
                onClick={() => setShowModal(false)}
              >
                <XIcon className="text-white h-6 w-6" />
              </button>
              <div className="relative px-16 py-14 flex-auto">
                <h2 className="text-2xl text-green font-bold">Filters</h2>
                <div className="my-8">
                  <Form />
                </div>
                <div className="flex items-center justify-end">
                  <p>2 filters applied</p>
                  <Button ownClasses="px-7 mx-2.5 font-semibold text-dashboardPrimaryGrey" type="depressed">
                    Clear all
                  </Button>
                  <Button ownClasses="px-7 font-semibold rounded-full" type="success">
                    Update
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-50 fixed inset-0 z-40 bg-darkBlue" />
        </>
      ) : null}
    </form>
  );
};

export default SearchForm;
