import React from 'react';

import { IChatInputFile } from './ChatInputFile.types';

import { ReactComponent as AttachSvg } from '../../../assets/icons/chat/attach.svg';

const ChatInputFile = ({ setFiles }: IChatInputFile) => {
  return (
    <label className="cursor-pointer mr-4 ml-2 w-4 relative">
      <div className="flex justify-center align-center">
        <AttachSvg className="w-4 h-5" />
        {/*eslint-disable-next-line  @typescript-eslint/no-explicit-any*/}
        <input
          className="invisible w-0 h-0"
          type="file"
          accept="image/*"
          multiple
          onChange={(e: any) => {
            setFiles(e.target.files);
            setTimeout(() => {
              e.target.value = '';
            });
          }}
        />
      </div>
    </label>
  );
};

export default ChatInputFile;
