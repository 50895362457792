import Dropdown from 'components/common/Dropdown';
import Input from 'components/common/Input';
import MultiSelect from 'components/common/MultiSelect';
import TextArea from 'components/common/TextArea';
import RepeatedInput from 'components/RepeatedInput';

const Form = () => {
  const options1 = [
    { value: 'one', label: 'one' },
    { value: 'two', label: 'two' },
    { value: 'three', label: 'three' },
  ];
  const options2 = [
    { value: 'California', label: 'California' },
    { value: 'Florida', label: 'Florida' },
    { value: 'New York', label: 'New York' },
    { value: 'South Carolina', label: 'South Carolina' },
    { value: 'South Dakota', label: 'South Dakota' },
  ];

  const options3 = [
    { value: 'General health', label: 'General health' },
    { value: 'Children', label: 'Children' },
    { value: 'Dietrition', label: 'Dietrition' },
  ];

  return (
    <form className="mt-10">
      <div className="flex flex-col lg:flex-row lg:justify-between items-center">
        <div className="lg:mr-7">
          <Input wrapperClasses="mb-4" />
          <Input wrapperClasses="mb-4" />
          <div className="mb-4">
            <Dropdown labelText="Label" options={options1} />
          </div>
          <Input wrapperClasses="mb-4" />
          <Input wrapperClasses="mb-4" />
        </div>
        <div>
          <Input wrapperClasses="mb-4" />
          <Input wrapperClasses="mb-4" />
          <Input wrapperClasses="mb-4" />
          <Input wrapperClasses="mb-4" />
          <Input wrapperClasses="mb-4" />
        </div>
      </div>
      <MultiSelect options={options2} />
      <MultiSelect options={options3} />
      <TextArea />
      <RepeatedInput />
    </form>
  );
};

export default Form;
