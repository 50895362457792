import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { ReactComponent as ArrowRight } from 'assets/icons/patientInfo/arrow-right.svg';
import Button from 'components/common/Button';
import { ActionButtonProps } from './actionButton.types';

const ActionButton: React.FC<ActionButtonProps> = ({ isStartsSoon, _id, missedAppointment, isAllAppointmentsPage }) => {
  const history = useHistory();

  const joinButtonClasses = classNames(
    'rounded-full self-start ml-8 text-white',
    !isAllAppointmentsPage && 'py-1 ml-8 text-lg',
  );

  const goToReschedule = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    history.push(`/`);
  };

  const joinCall = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    history.push(`/`);
  };
  return (
    <>
      {isStartsSoon && !missedAppointment ? (
        <Button onClick={joinCall} type="primary" ownClasses={joinButtonClasses}>
          Start
        </Button>
      ) : missedAppointment ? (
        <Button
          onClick={goToReschedule}
          type="primary"
          ownClasses="md:ml-auto mt-4 md:mt-0 self-start rounded-full bg-red text-white"
        >
          Reschedule
          <ArrowRight className="w-4 ml-2 mt-1 font-bold" />
        </Button>
      ) : null}
    </>
  );
};

export default ActionButton;
