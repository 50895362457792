import { LobbyProps } from './lobby.types';
import AppointmentTimer from '../AppointmentTimer';
import { useState } from 'react';

const Lobby: React.FC<LobbyProps> = ({
  callDetails,
  handlePhoneCall,
  showCallCard,
  isRemoteParticipantDisconnected,
}) => {
  const [timerCompleted, setTimerCompleted] = useState(false);

  return (
    <>
      <div className="h-full flex flex-col items-center justify-center">
        {!showCallCard ? (
          <>
            {callDetails.appointmentTime && !timerCompleted ? (
              isRemoteParticipantDisconnected ? (
                <div className="flex mt-7 items-center gap-3 font-semibold text-primary text-xl">
                  {callDetails.patientName} has left the appointment.
                </div>
              ) : (
                <AppointmentTimer
                  actionTime={callDetails.appointmentTime.startTime}
                  handleCompleted={() => setTimerCompleted(true)}
                />
              )
            ) : (
              <p className="mt-14 font-semibold text-darkBlue sm:text-xl md:text-2xl">
                Waiting for {callDetails.patientName || 'Patient'} to arrive...
              </p>
            )}
            <p className="text-darkBorder text-sm my-5">
              Technical issues?{' '}
              <button className="underline" onClick={handlePhoneCall}>
                Call {callDetails.patientName || 'patient'}’s phone
              </button>
            </p>
          </>
        ) : (
          <>
            <p className="mt-14 font-semibold text-darkBlue sm:text-xl md:text-2xl">
              You’re calling {callDetails.patientName || 'Patient'}’s phone. End the call to return to video controls.
            </p>
          </>
        )}
      </div>
    </>
  );
};

export default Lobby;
