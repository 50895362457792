import { motion } from 'framer-motion';
import { ReactComponent as PlusLight } from 'assets/icons/plus-light.svg';
import { LoaderProps } from './loader.types';

const textContainer = {
  animate: {
    scale: [0.75, 1, 0.75, 1, 0.75, 0.75],
    transition: {
      repeat: Infinity,
      duration: 1,
    },
  },
};

const Loader: React.FC<LoaderProps> = ({ isVisible }) => {
  return (
    <>
      {isVisible && (
        <motion.div
          className="w-20 h-20 absolute inset-0 m-auto z-10"
          initial="initial"
          animate="animate"
          variants={textContainer}
        >
          <PlusLight className="text-darkBlue w-full h-full" />
        </motion.div>
      )}
    </>
  );
};

export default Loader;
