import { nanoid } from 'nanoid';

import { HeaderProps } from './header.types';

const Header: React.FC<HeaderProps> = ({ width, hours }) => {
  return (
    <header className="flex py-1.5 pl-56 mt-5 border-b">
      {hours.map((el) => (
        <div key={nanoid()} className="text-2xs text-gray40" style={{ width: width + '%' }}>
          {el}
        </div>
      ))}
    </header>
  );
};

export default Header;
