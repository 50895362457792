import { ReactComponent as Plus } from 'assets/icons/patientInfo/plus.svg';
import { NewButtonProps } from './newButton.types';
import Button from 'components/common/Button';

const NewButton: React.FC<NewButtonProps> = ({ disabled, onClick }) => {
  return (
    <Button
      onClick={onClick}
      disabled={disabled}
      type="primary"
      ownClasses="rounded-full shadow-common bg-white text-darkBlue text-sm px-3.5 py-1.5"
    >
      <Plus className="mr-1 w-auto" />
      New
    </Button>
  );
};

export default NewButton;
