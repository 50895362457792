import RequestTypeAPI from 'api/RequestTypeAPI';
import AdminRequestList from 'components/AdminRequestList';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  setFrontDeskRequestTypeLists,
  setPhysicianRequestTypeLists,
  setTotalFrontDeskRequestType,
  setTotalPhysicianRequestType,
} from 'redux/requestType/actions';

export interface RequestTypeProps {
  type: string;
}
const RequestType: React.FC<RequestTypeProps> = ({ type }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const fetchRequestType = (value?: any) => {
    if (type === 'physician') {
      RequestTypeAPI.fetchPhysicianRequestTypes(value)
        .then((res) => {
          if (res.data.errorCode === 0) {
            dispatch(setPhysicianRequestTypeLists(res.data.data));
            dispatch(setTotalPhysicianRequestType(res.data.info.totalCount));
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (type === 'front-desk') {
      RequestTypeAPI.fetchFrontDeskRequestTypes(value)
        .then((res) => {
          if (res.data.errorCode === 0) {
            dispatch(setFrontDeskRequestTypeLists(res.data.data));
            dispatch(setTotalFrontDeskRequestType(res.data.info.totalCount));
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    fetchRequestType({ pageNo: 0, limit: 10 });
  }, [dispatch]);
  return <AdminRequestList type={type} fetchRequestType={fetchRequestType} loading={loading} />;
};

export default RequestType;
