import types from './types';
import { TaskListDataActionType, TaskDetails } from './interfaces';

const initState: TaskDetails = {
  taskList: null,
  totalCount: 0,
  selectedTask: null,
  urgentTaskCount: null,
};

const reducer = (state: TaskDetails = initState, action: TaskListDataActionType): TaskDetails => {
  switch (action.type) {
    case types.SET_TASK_LIST:
      return {
        ...state,
        taskList: action.payload,
      };

    case types.SET_TOTAL_TASK:
      return {
        ...state,
        totalCount: action.payload,
      };

    case types.SET_SELECTED_TASK:
      return {
        ...state,
        selectedTask: action.payload,
      };

    case types.SET_URGENT_TASK_COUNT:
      return {
        ...state,
        urgentTaskCount: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
