import actions from './actions';
import reducer from './reducer';

const notifications = {
  actions,
  reducer,
  name: 'notifications',
};

export default notifications;
