import { combineReducers } from 'redux';
import articles from './articles';
import authentication from './authentication';
import patients from './patient';
import tasks from './tasks';
import userProfile from './userProfile';
import staffsManagement from './staffManagement';
import lookUp from './lookUp';
import adminAutomations from './adminAutomation';
import requestType from './requestType';
import channels from './channels';
import messageTemplates from './messageTemplates';
import appointmentTypesData from './appointmentTypes';
import userRoles from './manageRoles';
import notifications from './channels copy';
import agingAlerts from './agingAlerts';
import staffCostManagement from './staffCosts';
import notificationCount from './notification';

const rootReducer = combineReducers({
  userProfile: userProfile.reducer,
  authentication: authentication.reducer,
  tasks: tasks.reducer,
  patients: patients.reducer,
  articles: articles.reducer,
  agingAlerts: agingAlerts.reducer,
  requestType: requestType.reducer,
  appointmentTypesData: appointmentTypesData.reducer,
  messageTemplates: messageTemplates.reducer,
  adminAutomations: adminAutomations.reducer,
  channels: channels.reducer,
  staffs: staffsManagement.reducer,
  staffcost: staffCostManagement.reducer,
  lookUp: lookUp.reducer,
  userRoles: userRoles.reducer,
  notifications: notifications.reducer,
  notificationCount: notificationCount.reducer,
});

export default rootReducer;
