import { StarIcon } from '@heroicons/react/solid';
import CircleAvatar from 'components/common/CircleAvatar';
import defaultpic from 'assets/images/profile-generic.png';

const AdvancedHeader = ({ staffData, onImageChange, uploadedImage, staffImage, handleImageUpload }: any) => {
  return (
    <div className="flex mb-10 relative">
      <CircleAvatar
        ownClasses="w-16 h-16"
        src={staffData[0]?.profileImage ? staffData[0]?.profileImage : staffImage ? staffImage : defaultpic}
        onImageChange={onImageChange}
        uploadedImage={uploadedImage}
        handleImageUpload={handleImageUpload}
        type={true}
      />
      <div className="ml-5">
        <div className="flex items-center justify-start">
          {staffData?.[0] && (
            <>
              <p className="text-3xl font-semibold">
                {staffData?.[0]?.prefix ? staffData?.[0]?.prefix + ' ' : ''}
                {staffData?.[0]?.firstName + ' ' + staffData?.[0]?.lastName}
              </p>
            </>
          )}
          {staffData?.[0]?.rating && (
            <div className="flex justify-center items-center bg-green h-4 ml-4 text-white rounded p-4">
              <>
                <span className="text-white">{staffData?.[0]?.rating}</span>
                <StarIcon className="h-4 w-4 ml-1" />
              </>
            </div>
          )}
        </div>
        <p className="text-xl text-textGreyedOut">{staffData[0]?.userRoleInfo?.name}</p>
      </div>
      <p className="text-sm text-textGreyedOut absolute right-0 top-4">ID:{staffData[0]?.userId}</p>
    </div>
  );
};

export default AdvancedHeader;
