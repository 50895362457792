import AppointmentAPI from 'api/AppointmentAPI';
import Cookies from 'js-cookie';
import React, { useState, useEffect, useCallback } from 'react';
import Room from './ChatVideoComponents/Room';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';
import * as Sentry from '@sentry/react';

export interface VideoProps {
  type?: string;
  callDetails: any;
  clearCallDetails: () => void;
}

const ChatVideo: React.FC<VideoProps> = ({ type, callDetails, clearCallDetails }) => {
  const [roomName, setRoomName] = useState('');
  const [token, setToken] = useState('');
  const history = useHistory();

  const handleLogout = useCallback(() => {
    setToken('');
    clearCallDetails();
  }, []);

  const getRoomToken = () => {
    const id: any = Cookies.get('appointmentId');
    AppointmentAPI.fetchCallToken(id)
      .then((res) => {
        const { data } = res.data;
        if (data) {
          setRoomName(data.roomName);
          setToken(data.token);
        } else {
          history.push('/dashboard/tasks');
          toast.error('Unable to intiate appointment, Please try again!');
        }
      })
      .catch((e) => {
        Sentry.captureException(e);
        history.push('/dashboard/tasks');
        toast.error('Unable to intiate appointment, Please try again!');
        handleLogout();
      });
  };

  useEffect(() => {
    getRoomToken();
  }, []);

  return (
    <>
      <Room
        roomName={roomName}
        token={token}
        consulationType={callDetails.appointmentMethod}
        handleLogout={handleLogout}
        callDetails={callDetails}
      />
    </>
  );
};

export default ChatVideo;
