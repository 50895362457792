import { StaffTypeProps } from './interface';
import types from './types';

export const setStaffLists = (data: StaffTypeProps[]) => ({
  type: types.SET_STAFF_LIST,
  payload: data,
});

export const setTotalStaff = (data: number) => ({
  type: types.SET_TOTAL_STAFF,
  payload: data,
});
const actions = {
  setStaffLists,
  setTotalStaff,
};

export default actions;
