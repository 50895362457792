import types from './types';
import { AppointmentTypeDetails, AppointmentTypeDataActionType } from './interfaces';

const initState: AppointmentTypeDetails = {
  appointmentType: null,
  totalCount: 0,
};

const reducer = (
  state: AppointmentTypeDetails = initState,
  action: AppointmentTypeDataActionType,
): AppointmentTypeDetails => {
  switch (action.type) {
    case types.SET_APPOINTMENT_TYPE_LIST:
      return {
        ...state,
        appointmentType: action.payload,
      };

    case types.SET_TOTAL_APPOINTMENT_TYPE:
      return {
        ...state,
        totalCount: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
