import React, { useEffect, useState } from 'react';
import { useToggle } from 'react-use';
import classNames from 'classnames';
import right from 'assets/icons/call/right.svg';
import ChatHeader from 'components/Chat/ChatHeader';
import ChatWindow from 'components/Chat/ChatWindow';
import ChatInput from 'components/Chat/ChatInput';
import PatientAPI from 'api/PatientAPI';
import socket, { socket_init } from 'socket/socket';
import { RootStateOrAny, useSelector } from 'react-redux';

const Chat: React.FC<{ callDetails: any; handlePhoneCall: () => void }> = ({ callDetails, handlePhoneCall }) => {
  const { userType } = useSelector((state: RootStateOrAny) => state.userProfile.userDetails);
  const [isOpenChat, setIsOpenChat] = useState(false);
  const [patientChat, setPatientChat] = useState([] as any);
  const [tab, setTab] = useState<'messages' | 'chat'>('messages');
  const [patientInformation, setPatientInformation] = useState(null as any);
  const [messageRecvied, setMessageRecived] = useState(false);
  const [messagesRecivedList, setMessagesRecivedList] = useState<any>();
  const [newMessageNotification, setNewMessageNotification] = useState(false);
  const [newMessageSend, setNewMessageSend] = useState(false);
  const [messageSent, setMessageSend] = useState(false);

  const fetchPatientDetails = () => {
    PatientAPI.fetchSinglePatient(callDetails.patientId)
      .then((res) => {
        if (res.data) {
          const { data } = res.data;
          setPatientInformation(data);
        }
      })
      .finally(() => {
        console.log('loaded...');
      });
  };

  const chatSocketInit = () => {
    socket.emit(
      'getNextMessage',
      {
        limit: 10,
        skip: 0,
        channelId: callDetails.channelId,
      },
      (data: any) => {
        console.log(data, 'soketemmit');
      },
    );
  };

  const onNewMessageSend = () => {
    setNewMessageSend(false);
  };

  const chatSendMessages = (message: string, imageInput: any) => {
    socket.emit(
      'newMessageToServer',
      {
        message: message,
        fileName: imageInput ? (imageInput.fileName ? imageInput.fileName : null) : null,
        filePath: imageInput ? (imageInput.filePath ? imageInput.filePath : null) : null,
        receiverDetails: [{ id: callDetails.patientId }],
        senderDetails: { id: callDetails.doctorId, userType },
        channelId: callDetails.channelId,
      },
      true,
    );
    setTimeout(() => {
      chatSocketInit();
    }, 1000);
  };

  const clearNotification = () => {
    socket.emit(
      'markSeen',
      {
        channelId: callDetails.channelId,
        userId: callDetails.doctorId,
      },
      (data: any) => {
        setNewMessageNotification(false);
      },
    );
  };

  useEffect(() => {
    if (messageRecvied) {
      chatSocketInit();
      setMessageRecived(false);
    }
  }, [messageRecvied]);

  useEffect(() => {
    if (isOpenChat) {
      clearNotification();
    }
  }, [isOpenChat]);

  useEffect(() => {
    socket_init();
    fetchPatientDetails();
    socket.emit('joinRoom', callDetails.channelId, () => {
      setTimeout(chatSocketInit, 1000);
    });
    socket.on('oldMessages', (data) => {
      if (data[0]) {
        const listData = data.slice(0).reverse();
        setPatientChat(listData);
      }
    });
    socket.on('historyCatchUp', (data) => {
      if (data[0]) {
        const listData = data.slice(0).reverse();
        setPatientChat(listData);
      }
    });
    socket.on('messageToClients', (data) => {
      setMessageRecived(true);
      setMessagesRecivedList(data);
    });
    socket.on('unreadMessageUpdated', (data) => {
      setIsOpenChat((prevIsOpenChat) => {
        chatSocketInit();
        if (prevIsOpenChat) {
          clearNotification();
        }
        if (data.channelId === callDetails.channelId) {
          setNewMessageNotification(data.unreadMessageCount > 0 && !prevIsOpenChat);
        }
        return prevIsOpenChat;
      });
    });
  }, []);

  return (
    <div
      className={classNames(
        'h-full bg-white relative shadow-common z-20',
        isOpenChat ? 'min-w-full md:min-w-96 md:w-96' : 'w-0',
      )}
    >
      <button
        type="button"
        onClick={() => setIsOpenChat(!isOpenChat)}
        className={classNames(
          'bg-gray-100 w-8 p-2 h-32 flex flex-col justify-center items-center absolute inset-y-0 m-auto z-10 gap-2',
          isOpenChat ? '-left-4 hidden sm:flex' : '-left-8',
        )}
      >
        <div className={`${newMessageNotification ? '' : 'hidden'} bg-red rounded-full h-4 w-4`} />
        <img
          src={right}
          alt="right"
          className={classNames({
            'transform rotate-180': !isOpenChat,
          })}
        />
      </button>
      <div className="h-full overflow-hidden flex flex-col justify-between">
        {patientInformation && (
          <ChatHeader
            tab={tab}
            setTab={setTab}
            patientInformation={patientInformation}
            handlePhoneCall={handlePhoneCall}
          />
        )}
        {tab === 'messages' && (
          <ChatWindow patientChat={patientChat} newMessageSend={newMessageSend} onNewMessageSend={onNewMessageSend} />
        )}
        {tab === 'messages' && <ChatInput chatSendMessage={chatSendMessages} clearNotification={clearNotification} />}
      </div>
    </div>
  );
};

export default Chat;
