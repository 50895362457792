import classNames from 'classnames';
import Select, { StylesConfig } from 'react-select';
import { Editor } from '@tinymce/tinymce-react';
import Button from 'components/common/Button';
import Dropdown from 'components/common/Dropdown';
import { useRef, useState, useEffect } from 'react';
import tinymce from 'tinymce/tinymce';
import 'tinymce/icons/default';
import 'tinymce/themes/silver';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/link';
import 'tinymce/plugins/image';
import 'tinymce/plugins/preview';
import 'tinymce/plugins/media';
import 'tinymce/plugins/table';
import 'tinymce/plugins/wordcount';
import 'tinymce/plugins/imagetools';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/pagebreak';
import 'tinymce/plugins/anchor';
import 'tinymce/plugins/charmap';
import 'tinymce/plugins/spellchecker';
import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/emoticons';
import 'tinymce/plugins/legacyoutput';
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/textpattern';
import 'tinymce/plugins/visualblocks';
import 'tinymce/plugins/visualchars';
import 'tinymce/plugins/charmap';
import 'tinymce/plugins/directionality';
import 'tinymce/skins/ui/oxide/skin.min.css';
// import 'tinymce/skins/ui/oxide/content.min.css';
// import 'tinymce/skins/content/default/content.min.css';
import ArticleAPI from 'api/ArticleAPI';

export interface ArticleProps {
  audienceOptions: any;
  authorOptions: any;
  addArticle: (value: any) => void;
  type: string;
  articleData: any;
  editArticle: (value: any) => void;
  deleteArticle: (value: any) => void;
}

const Articles: React.FC<ArticleProps> = ({
  audienceOptions,
  authorOptions,
  addArticle,
  type,
  articleData,
  editArticle,
  deleteArticle,
}) => {
  const [articleTitle, setArticleTitle] = useState('');
  const [articleDescription, setArticleDescription] = useState('');
  const [articleCategory, setArticleCategory] = useState({} as any);
  const [articleAuthor, setArticleAuthor] = useState({} as any);
  const [articleContent, setArticleContent] = useState('');
  const [articleAudience, setArticleAudience] = useState([] as any);
  const editorRef: any = useRef(null);

  type MyOptionType = {
    label: string;
    value: string;
  };
  type IsMulti = boolean;
  const dropdownStyles: StylesConfig<MyOptionType, IsMulti> = {
    placeholder: (base) => ({
      ...base,
      color: '#00698B',
    }),
    multiValueRemove: () => ({
      position: 'absolute',
      left: '0.25rem',
      top: '2px',
      color: '#00698B',
      outline: 'none',
    }),
    singleValue: (base) => ({
      ...base,
      color: '#00698B',
      outline: 'none',
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: 'none',
    }),
    dropdownIndicator: (base) => ({
      ...base,
      color: '#00698B',
    }),
    control: (base, { isMulti }) => {
      return {
        ...base,
        borderRadius: '0.5rem',
        background: '#fafbfc',
        height: isMulti ? '6rem' : 'auto',
        alignItems: isMulti ? 'flex-start' : 'center',
        outline: 'none',
      };
    },
    menu: (base) => ({
      ...base,
      zIndex: 10,
    }),
    multiValue: (base) => ({
      ...base,
      backgroundColor: '#E0F4F4',
      color: 'red',
      paddingLeft: '1rem',
      position: 'relative',
    }),
    multiValueLabel: (base) => ({
      ...base,
      color: '#00698B',
      fontSize: '1rem',
    }),
    indicatorsContainer: (base, { isMulti }) => ({
      ...base,
      alignItems: isMulti ? 'flex-start' : 'center',
    }),
  };
  const labOptions: MyOptionType[] = [
    { label: 'Training', value: 'training' },
    { label: 'Protocol', value: 'protocol' },
  ];
  const inputCN = classNames(
    'p-4 w-full mb-4 h-8 border border-lightBorder rounded-lg placeholder-darkBorder outline-none',
    'bg-white',
  );

  const onPublishArticle = () => {
    const audienceData: any = [];
    articleAudience.map((eachItem: any) => {
      audienceData.push(eachItem['value']);
    });
    const publishData = {
      title: articleTitle,
      description: articleDescription,
      authorId: articleAuthor.value,
      category: articleCategory.value,
      status: 'active',
      audience: audienceData,
      content: editorRef.current.getContent().toString(),
    };

    if (type === 'add') {
      addArticle(publishData);
    } else if (type === 'edit') {
      editArticle(publishData);
    }
  };

  const onSaveArticle = () => {
    const audienceData: any = [];
    articleAudience.map((eachItem: any) => {
      audienceData.push(eachItem['value']);
    });
    const saveData = {
      title: articleTitle,
      description: articleDescription,
      authorId: articleAuthor.value,
      category: articleCategory.value,
      status: 'inactive',
      audience: audienceData,
      content: editorRef.current.getContent().toString(),
    };
    if (type === 'add') {
      addArticle(saveData);
    } else if (type === 'edit') {
      editArticle(saveData);
    }
  };

  useEffect(() => {
    if (articleData) {
      const audienceData: any = [];
      articleData.audience.map((eachItem: any) => {
        audienceData.push(audienceOptions.find((item: any) => item.value === eachItem.name));
      });
      setArticleDescription(articleData.description);
      setArticleContent(articleData.content);
      setArticleTitle(articleData.title);
      setArticleAudience(audienceData);
      setArticleCategory(labOptions.find((item: any) => item.value === articleData.category));
      setArticleAuthor(authorOptions.find((item: any) => item.value === articleData.author.userId));
      setArticleAudience(audienceData);
    }
  }, [articleData]);

  const filePickerCallBack = (callback: any, value: any, meta: any) => {
    const input: any = document.createElement('input') as HTMLInputElement;
    input.setAttribute('type', 'file');
    if (meta.filetype === 'image') {
      input.setAttribute('accept', '.jpg,.png,|image/*');
    }

    // if (meta.filetype === "media") {
    //   input.setAttribute("accept", "video/*");
    // }

    input.onchange = function () {
      const file: any = this.files[0];
      const reader = new FileReader();

      reader.onload = function () {
        // var id = "blobid" + new Date().getTime();

        // var blobCache = tinymce.activeEditor.editorUpload.blobCache;
        // var base64 = reader.result.split(",")[1];

        // var url=mediaUploader(file)

        // console.log(file,"file")
        // var blobInfo = blobCache.create(id, file, base64);
        // blobCache.add(blobInfo);

        // /* call the callback and populate the Title field with the file name */

        if (meta.filetype === 'image') {
          console.log(file, 'file');
          const isLt5M = file.size / 1024 / 1024 < 2;
          if (!isLt5M) {
            tinymce.activeEditor.windowManager.alert('Media must smaller than 2MB!');
          } else {
            const data = {
              articleImage: file,
            };
            console.log(data, 'data');
            tinymce.activeEditor.windowManager.alert('Media Uploading will take time ,please wait!');

            ArticleAPI.uploadImages(data)
              .then((res) => {
                const { data } = res.data;

                if (data && data.imageUrl) {
                  callback(data.imageUrl);
                  tinymce.activeEditor.windowManager.alert('Media Uploaded Successfully');
                } else {
                  tinymce.activeEditor.windowManager.alert('Unable to upload media, Please try again!');
                }
              })
              .catch(() => {
                tinymce.activeEditor.windowManager.alert('Unable to upload media, Please try again!');
              });
          }
        }

        // if (meta.filetype === "media") {
        //   const isLt5M = file.size / 1024 / 1024 < 10;
        //   if (!isLt5M) {
        //     tinymce.activeEditor.windowManager.alert(
        //       "Media must smaller than 10MB!"
        //     );
        //   } else {
        //     const data = {
        //       uploadContent: file,
        //     };
        //     tinymce.activeEditor.windowManager.alert(
        //       "Media Uploading will take time ,please wait!"
        //     );

        //     uploadMedia(data)
        //       .then((res) => {
        //         const { data } = res;

        //         if (data && data.payload) {
        //           callback(data.payload);
        //           tinymce.activeEditor.windowManager.alert(
        //             "Media Uploaded Successfully"
        //           );
        //         } else {
        //           tinymce.activeEditor.windowManager.alert(
        //             "Unable to upload media, Please try again!"
        //           );
        //         }
        //       })
        //       .catch(() => {
        //         tinymce.activeEditor.windowManager.alert(
        //           "Unable to upload media, Please try again!"
        //         );
        //       });
        //   }
        // }
      };
      reader.readAsDataURL(file);
    };

    input.click();
  };
  return (
    <>
      <div className="bg-white rounded-xl px-16 py-12">
        <div className="w-2/3 pr-20">
          <div className="flex justify-between">
            <span className="w-52 font-semibold text-xl mr-4">Title:</span>
            <input
              placeholder="Enter the Title"
              name="heartRate"
              className={inputCN}
              type="text"
              value={articleTitle && articleTitle}
              onChange={(e) => setArticleTitle(e.target.value)}
            />
          </div>
          <div className="flex justify-between">
            <span className="w-52 font-semibold text-xl mr-4">Description:</span>
            <textarea
              className="border-lightBorder placeholder-darkBorder border rounded-xl p-3 py-0 w-full h-24"
              placeholder="Enter the Description"
              value={articleDescription && articleDescription}
              onChange={(e) => setArticleDescription(e.target.value)}
            ></textarea>
          </div>
          <div className="flex my-5 items-center justify-between">
            <span className="w-52 font-semibold text-xl mr-4">Category:</span>
            <div className="w-full z-20">
              <Dropdown
                options={labOptions}
                value={articleCategory?.value && articleCategory}
                labelClasses={'w-16 mr-4 text-xl'}
                // defaultValue={labOptions[0]}
                onChange={(e: any) => setArticleCategory(e)}
                placeholderText="Select a Category"
              />
            </div>
          </div>
          <div className="flex my-5 items-center justify-between">
            <span className="w-52 font-semibold text-xl mr-4">Author:</span>
            <div className="w-full z-10">
              <Dropdown
                options={authorOptions}
                value={articleAuthor?.value && articleAuthor}
                labelClasses={'w-16 mr-4 text-xl'}
                onChange={(e: any) => setArticleAuthor(e)}
                placeholder="Select an Author"
              />
            </div>
          </div>
          <div className="flex justify-between mb-6">
            <span className="w-52 font-semibold text-xl mr-4">Audience:</span>
            <Select
              isClearable={false}
              isMulti
              value={articleAudience && articleAudience}
              styles={dropdownStyles}
              className="w-full mb-5"
              options={audienceOptions}
              onChange={(e) => setArticleAudience(e)}
              placeholder="Select Audiences"
            />
          </div>
        </div>
        <div className="max-w-screen-xl">
          <div className="mr-28">
            <Editor
              initialValue={articleContent && articleContent}
              onInit={(evt, editor) => (editorRef.current = editor)}
              init={{
                branding: false,
                height: 600,
                imagetools_cors_hosts: ['picsum.photos'],
                menubar: 'file edit view insert format tools table help',
                plugins: [
                  'preview paste importcss searchreplace autolink directionality code visualblocks',
                  'visualchars fullscreen image link template codesample table charmap hr nonbreaking',
                  'anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable charmap quickbars autoresize',
                ],

                file_picker_callback: filePickerCallBack,
                toolbar:
                  'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | ' +
                  'outdent indent | bullist numlist | forecolor backcolor | charmap emoticons | fullscreen  preview template link anchor codesample |' +
                  'ltr rtl | insertfile image removeformat',
                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                toolbar_sticky: true,
                image_advtab: true,
                importcss_append: true,
                image_caption: true,
                quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
                noneditable_noneditable_class: 'mceNonEditable',
                toolbar_mode: 'sliding',
                contextmenu: 'link image imagetools table',
              }}
            />
          </div>
          <div
            className={classNames(
              'flex  items-center mt-8',
              { 'justify-between': type === 'edit' },
              { 'justify-end': type !== 'edit' },
            )}
          >
            {type === 'edit' && (
              <Button
                ownClasses="rounded-full p-0 font-medium"
                type="depressed"
                onClick={() => deleteArticle?.(articleData._id)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="red"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
                Delete Article
              </Button>
            )}
            <div className="flex justify-end">
              <Button
                ownClasses="px-7 mx-2.5 font-semibold text-dashboardPrimaryGrey"
                type="depressed"
                onClick={() => onSaveArticle()}
              >
                Save draft
              </Button>
              <Button ownClasses="px-7 font-semibold rounded-full" type="success" onClick={() => onPublishArticle()}>
                Publish
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Articles;
