import Button from 'components/common/Button';
import DateTimePicker from 'components/common/DateTimePicker';
import Dropdown from 'components/common/Dropdown';
import Input from 'components/common/Input';
import { useEffect } from 'react';
import { useState } from 'react';
import moment from 'moment';
import LookUpAPI from 'api/LookUpsAPI';
import { RootStateOrAny, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { AsyncPaginate } from 'react-select-async-paginate';
import AsyncSelect from 'components/common/AsyncSelect';

export interface FormProps {
  formHandler: (value?: any) => void;
  filterFormValues?: any;
  asyncData?: any;
  setAsyncData: (value?: any) => void;
  categoryOptions?: any;
}

const Form: React.FC<FormProps> = ({ formHandler, filterFormValues, asyncData, setAsyncData, categoryOptions }) => {
  const userRole = useSelector((state: RootStateOrAny) => state.userProfile.userDetails.userType.name);
  const [patient, setPatient] = useState('');
  const [patientValue, setPatientValue] = useState(null);
  const [dueDate, setDueDate] = useState('');
  const [physician, setPhysician] = useState('');
  const [physicianValue, setphysicianValue] = useState(null);
  const [assignedTo, setAssignedTo] = useState('');
  const [assignedToValue, setAssignedToValue] = useState(null);
  const [category, setCategory] = useState<{ label: string; value: string } | null>();
  const [state, setState] = useState<{ label: string; value: string } | null>();
  const [status, setStatus] = useState<{ label: string; value: string } | null>();
  const [client, setClient] = useState<{ label: string; value: string } | null>();

  const [menuOpenDoctor, setMenuOpenDoctor] = useState(false);
  const [menuOpenPatient, setMenuOpenPatient] = useState(false);
  const [menuOpenAssignedTo, setMenuOpenAssignedTo] = useState(false);
  const [filterCount, setFilterCount] = useState(0);

  const clients = [
    { value: 'client 1', label: 'client 1' },
    { value: 'client 2', label: 'client 2' },
    { value: 'client 3', label: 'client 3' },
  ];
  const states = [
    { value: 'California', label: 'California' },
    { value: 'Florida', label: 'Florida' },
    { value: 'New York', label: 'New York' },
    { value: 'South Carolina', label: 'South Carolina' },
    { value: 'South Dakota', label: 'South Dakota' },
  ];

  const statuses = [
    { value: 'URGENT', label: 'URGENT' },
    { value: 'New', label: 'New' },
    { value: 'Active', label: 'Active' },
    { value: 'Sent', label: 'Sent' },
    { value: 'Open', label: 'Open' },
  ];

  useEffect(() => {
    if (!_.isEmpty(filterFormValues)) {
      setStatus(statuses.find((item) => item.value === filterFormValues.status));
      setDueDate(filterFormValues.dueDate && moment(filterFormValues.dueDate).format('MM/DD/YYYY'));
      // setCategory({ label: filterFormValues.category, value: filterFormValues.category });
      setCategory(categoryOptions && categoryOptions.find((item: any) => item.value === filterFormValues.category));
    }
    if (!_.isEmpty(asyncData)) {
      setPatient(asyncData?.patient?._id);
      setPhysician(asyncData?.physician?._id);
      setAssignedTo(asyncData?.assignedTo?._id);
      setPatientValue(asyncData.patient);
      setphysicianValue(asyncData.physician);
      setAssignedToValue(asyncData.assignedTo);
    }
  }, [filterFormValues, asyncData, categoryOptions]);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setAsyncData({ patient: patientValue, physician: physicianValue, assignedTo: assignedToValue });
    const filterData = {
      ...(patient && { patientId: patient }),
      ...(physician && { doctorId: physician }),
      ...(assignedTo && { assignedToId: assignedTo }),
      ...(status && { status: status.value }),
      ...(dueDate && { dueDate: dueDate && moment(dueDate).format('YYYY-MM-DD') }),
      ...(category?.value && { category: category?.value }),
    };
    console.log('Submitted filter data: ', filterData);
    formHandler(filterData);
  };

  const handleClear = (e: any) => {
    e.preventDefault();
    formHandler({});
    setAsyncData({});
  };

  const checkFilterApplied = () => {
    let count = 0;
    if (patientValue) {
      count += 1;
    }
    if (physicianValue) {
      count += 1;
    }
    if (assignedToValue) {
      count += 1;
    }

    if (status) {
      count += 1;
    }
    if (dueDate) {
      count += 1;
    }
    if (category?.value) {
      count += 1;
    }

    setFilterCount(count);
  };

  useEffect(() => {
    checkFilterApplied();
  }, [patientValue, physician, physicianValue, status, dueDate, category, assignedTo, assignedToValue]);
  const loadOptionsPatient = async (searchQuery: string, loadedOptions: any, { page }: any) => {
    const payload = {
      limit: 50,
      pageNo: page - 1,
      userType: 'Patient',
      searchKey: searchQuery,
    };
    let responseJSON: any = {};
    await LookUpAPI.fetchUsers(payload).then((res) => {
      responseJSON = res?.data?.data;
    });
    return {
      options: responseJSON,
      hasMore: responseJSON.length >= 1,
      additional: {
        page: searchQuery ? 2 : page + 1,
      },
    };
  };

  const loadOptionsPhysician = async (searchQuery: string, loadedOptions: any, { page }: any) => {
    const payload = {
      limit: 50,
      pageNo: page - 1,
      userType: 'Physician',
      searchKey: searchQuery,
    };
    let responseJSON: any = {};
    await LookUpAPI.fetchUsers(payload).then((res) => {
      responseJSON = res?.data?.data;
    });
    return {
      options: responseJSON,
      hasMore: responseJSON.length >= 1,
      additional: {
        page: searchQuery ? 2 : page + 1,
      },
    };
  };

  const loadOptionsAssignedTo = async (searchQuery: string, loadedOptions: any, { page }: any) => {
    const payload = {
      limit: 50,
      pageNo: page - 1,
      userType: 'Staff',
      searchKey: searchQuery,
    };
    let responseJSON: any = {};
    await LookUpAPI.fetchUsers(payload).then((res) => {
      responseJSON = res?.data?.data;
    });
    return {
      options: responseJSON,
      hasMore: responseJSON.length >= 1,
      additional: {
        page: searchQuery ? 2 : page + 1,
      },
    };
  };

  const patientData = (value: any) => {
    setPatient(value._id);
    setPatientValue(value);
  };

  const physicianData = (value: any) => {
    setPhysician(value._id);
    setphysicianValue(value);
  };
  const assignedToData = (value: any) => {
    setAssignedTo(value._id);
    setAssignedToValue(value);
  };

  const onInputChangeDoctor = (value: any) => {
    if (value) {
      setMenuOpenDoctor(true);
    } else {
      setMenuOpenDoctor(false);
    }
  };

  const onInputChangePatient = (value: any) => {
    if (value) {
      setMenuOpenPatient(true);
    } else {
      setMenuOpenPatient(false);
    }
  };

  const onInputChangeAssignedTo = (value: any) => {
    if (value) {
      setMenuOpenAssignedTo(true);
    } else {
      setMenuOpenAssignedTo(false);
    }
  };

  return (
    <form>
      <div className="flex flex-col lg:flex-row justify-center items-center">
        <div className="mr-20 mb-4">
          <AsyncSelect
            loadOptions={menuOpenDoctor && loadOptionsPatient}
            labelText="Patient"
            name="patient"
            placeholder="Patient"
            inputClasses="w-52 mb-4 pr-8"
            labelClasses="mr-10 text-xl  mb-4"
            value={patientValue}
            setValue={patientData}
            onInputChange={onInputChangeDoctor}
            menuIsOpen={menuOpenDoctor}
            onClickIconClear={() => setPatientValue(null)}
            closeIcon={true}
          />

          <AsyncSelect
            loadOptions={loadOptionsPhysician}
            labelText="Doctor"
            name="physician"
            placeholder="Physician"
            inputClasses="w-52 mb-4 pr-8"
            labelClasses="mr-10 text-xl  mb-4"
            value={physicianValue}
            setValue={physicianData}
            menuIsOpen={menuOpenPatient}
            onInputChange={onInputChangePatient}
            onClickIconClear={() => setphysicianValue(null)}
            closeIcon={true}
          />

          <DateTimePicker
            labelText="Due"
            name="due"
            placeholder="Enter due date"
            inputClasses="w-52  mb-4"
            labelClasses="mr-10 text-xl  mb-4"
            value={dueDate}
            format="MM/DD/YYYY"
            onChange={(e) => setDueDate(e)}
          />
          {/* <div className="mb-4">
            <Dropdown
              labelText="Client"
              placeholder="Select Client"
              labelClasses="mr-10 text-xl  mb-4"
              width="52"
              value={client}
              options={clients}
              onClickIconClear={() => setClient(null)}
              onChange={(e: any) => setClient(e)}
            />
          </div> */}
        </div>
        <div>
          <div className="mb-4">
            <AsyncSelect
              loadOptions={loadOptionsAssignedTo}
              labelText="Assigned To"
              name="assignedTo"
              placeholder="Assigned To"
              inputClasses="w-52 mb-4 pr-8"
              labelClasses="mr-10 text-xl  mb-4"
              value={assignedToValue}
              setValue={assignedToData}
              menuIsOpen={menuOpenAssignedTo}
              onInputChange={onInputChangeAssignedTo}
              onClickIconClear={() => setAssignedToValue(null)}
              closeIcon={true}
            />
            <Dropdown
              labelText="Category"
              placeholder="Select Category"
              labelClasses="mr-10 text-xl mb-4"
              width="52"
              options={categoryOptions}
              value={category}
              onChange={(e: any) => setCategory(e)}
              onClickIconClear={() => setCategory(null)}
              closeIcon={true}
            />
          </div>
          {/* <div className="mb-4">
            <Dropdown
              labelText="States"
              placeholder="Select States"
              labelClasses="mr-10 text-xl"
              width="52"
              value={state}
              options={states}
              onClickIconClear={() => setState(null)}
              closeIcon={true}
              onChange={(e: any) => setState(e)}
            />
          </div>         */}
          <div className="mb-4">
            <Dropdown
              labelText="Status"
              placeholder="Status"
              labelClasses="mr-10 text-xl  mb-4"
              width="52"
              value={status}
              options={statuses}
              onChange={(e: any) => setStatus(e)}
              onClickIconClear={() => setStatus(null)}
              closeIcon={true}
            />
          </div>
        </div>
      </div>
      <div className="flex items-center justify-end">
        <p>{filterCount} filters applied</p>
        <Button
          ownClasses="px-7 mx-2.5 font-semibold text-dashboardPrimaryGrey"
          type="depressed"
          onClick={(e) => handleClear(e)}
        >
          Clear all
        </Button>
        <Button ownClasses="px-7 font-semibold rounded-full" type="success" onClick={(e) => handleSubmit(e)}>
          Update
        </Button>
      </div>
    </form>
  );
};

export default Form;
