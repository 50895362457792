interface ILabel {
  labelText?: string;
  labelClasses?: string;
}

const Label: React.FC<ILabel> = ({ labelText = 'Label', labelClasses = 'mr-4 lg:mr-20 text-medium' }) => {
  return <label className={labelClasses}>{labelText}</label>;
};

export default Label;
