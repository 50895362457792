import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';

import Message from '../Message';

// import { dataMessages } from './Messages.data';
import { IDataMessages, IMessage } from './Messages.types';
export interface ChatWindowProps {
  patientChat: any;
  newMessageSend: boolean;
  onNewMessageSend: () => void;
}

const ChatWindow: React.FC<ChatWindowProps> = ({ patientChat, newMessageSend, onNewMessageSend }) => {
  const [dataMessages, setDataMessages] = useState<IMessage[]>();
  const [scrollHeight, setScrollHeight] = useState<any>();
  const inCallMessageListingWrapperRef = useRef<any>(null);
  const messagesEndRef = useRef<any>(null);
  useEffect(() => {
    if (patientChat.length != 0) {
      let chatMesssges: IMessage[] = [];
      chatMesssges = patientChat
        .map((eachItem: any) => {
          return {
            id: eachItem._id,
            date: eachItem.messageStatus['sent'],
            userId: eachItem.senderDetails.id,
            message: eachItem.message,
            author: eachItem.senderDetails.name,
            seen: eachItem.messageStatus['seen'],
            imageUrl: eachItem.filePath,
          };
        })
        .sort((a: any, b: any) => (a.date > b.date ? 1 : -1));

      setDataMessages(chatMesssges);
    }
  }, [patientChat]);

  const scrollToTopSection = () => {
    inCallMessageListingWrapperRef.current.scrollTop =
      inCallMessageListingWrapperRef.current.scrollHeight - scrollHeight;
    setScrollHeight(inCallMessageListingWrapperRef.current.scrollHeight);
  };

  const scrollToBottomDiv = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottomDiv();
  }, [dataMessages]);
  return (
    <div className="bg-white overflow-auto h-full px-8" ref={inCallMessageListingWrapperRef}>
      {dataMessages ? (
        dataMessages.map((message: IMessage) => <Message key={message.id} message={message} />)
      ) : (
        <p className="align-middle text-lg text-center">No messages</p>
      )}
      <div ref={messagesEndRef} />
    </div>
  );
};

export default ChatWindow;
