import classNames from 'classnames';
import Select, { components, Props as SelectProps, StylesConfig } from 'react-select';
import Label from '../Label';
import { ReactComponent as DropdownIndicator } from 'assets/icons/chevron-down.svg';
import { XIcon } from '@heroicons/react/solid';

interface IDropdown {
  name?: string;
  labelText?: string;
  placeholderText?: string;
  value?: string;
  width?: number | string;
  height?: number | string;
  labelClasses?: string;
  options?: { value: string; label: string }[];
  ownClasses?: string;
  wrapperClasses?: string;
  handleChange?: (value: any) => any;
  defaultValue?: {
    label: string;
    value: string;
  };
  onClickIconClear: (value: any) => any;
  closeIcon?: boolean;
}

type MyOptionType = {
  label: string;
  value: string;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Indicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <DropdownIndicator />
    </components.DropdownIndicator>
  );
};
type isSelected = boolean;

const styles: StylesConfig<MyOptionType, isSelected> = {
  // container: (base) => ({
  //   ...base,
  //   border: '1px solid ',
  //   borderRadius: '0.5rem',
  //   borderColor: `rgba(236, 236, 240, 1)`,
  // }),
  option: (base, { isSelected }) => ({
    ...base,
    color: isSelected ? '#fff' : '#00698B',
    backgroundColor: isSelected ? '#00698B' : '',
  }),
  indicatorSeparator: (base) => ({
    ...base,
    margin: 0,
    height: '2.2rem',
    background: '#ECEEF0',
  }),
  singleValue: (base) => ({
    ...base,
    color: '#00698B',
  }),
  clearIndicator: (base) => ({
    ...base,
  }),
  input: (base) => ({
    ...base,
    margin: 0,
  }),
  valueContainer: (base) => ({
    ...base,
    // paddingLeft: '1rem',
    paddingTop: 0,
  }),
  placeholder: (base) => ({
    ...base,
    color: '#00698B',
    fontWeight: 500,
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: '#00698B',
    padding: '1rem',
  }),
  control: (base) => ({
    ...base,
    height: '2.5rem',
    minHeight: '2.3rem',
    borderRadius: '0.5rem',
    background: '#fafbfc',
    border: 0,
    // This line disable the blue border
    boxShadow: 'none',
  }),
};

const Dropdown = ({
  options,
  labelText,
  labelClasses,
  width = 'full',
  // height = 7,
  ownClasses = '',
  wrapperClasses = '',
  handleChange,
  onClickIconClear,
  closeIcon,
  name,
  ...props
}: IDropdown | SelectProps) => {
  const dropdownClasses = classNames(`w-${width}`, 'mb-3.5', ownClasses);

  return (
    <div className={`flex items-center ${wrapperClasses} justify-between`}>
      {labelText && <Label labelText={labelText} labelClasses={labelClasses} />}
      {closeIcon ? (
        <div className={`relative w-${width}`}>
          <Select
            name={name}
            options={options}
            onChange={handleChange}
            isClearable={true}
            className={dropdownClasses}
            components={{
              DropdownIndicator: Indicator,
            }}
            styles={styles}
            {...(props.placeholderText && { placeholder: props.placeholderText })}
            {...props}
          />
          {closeIcon && props?.value && (
            <button
              className="rounded-full w-5 flex justify-center items-center absolute right-2 inset-y-0 mb-3.5"
              onClick={() => {
                onClickIconClear();
              }}
            >
              <XIcon className="h-5 w-5" />
            </button>
          )}
        </div>
      ) : (
        <div className={`relative w-full`}>
          <Select
            name={name}
            options={options}
            onChange={handleChange}
            isClearable={true}
            className={dropdownClasses}
            components={{
              DropdownIndicator: Indicator,
            }}
            styles={styles}
            {...(props.placeholderText && { placeholder: props.placeholderText })}
            {...props}
          />
        </div>
      )}
    </div>
  );
};

export default Dropdown;
