import LookUpAPI from 'api/LookUpsAPI';
import classNames from 'classnames';
import Button from 'components/common/Button';
import Dropdown from 'components/common/Dropdown';
import Input from 'components/common/Input';
import TextArea from 'components/common/TextArea';
import * as _ from 'lodash';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

const options1 = [
  { value: 'Button: Schedule appointment', label: 'Button: Schedule appointment' },
  { value: 'Button: Order labs', label: 'Button: Order labs' },
  { value: 'Variable: First name', label: 'Variable: First name' },
  { value: 'Variable: Full name', label: 'Variable: Full name' },
  { value: 'Variable: Doctor name', label: 'Variable: Doctor name' },
];

const statusOptions = [
  { value: 'active', label: 'Active' },
  { value: 'inactive', label: 'Inactive' },
];

export interface FormProps {
  handleFormSubmit?: (data: any) => void;
  handleEditedFormSubmit?: (data: any, id: string) => void;
  handleDelete?: (id: string) => void;
  hasDeleteAccess?: boolean;
  formData?: any;
  isEditMode?: any;
  type?: string;
}

const Form = ({
  handleFormSubmit,
  handleEditedFormSubmit,
  handleDelete,
  hasDeleteAccess,
  formData,
  isEditMode,
  type,
}: FormProps) => {
  const [message, setMessage] = useState('');
  const [variables, setVariables] = useState<{ label: string; value: string } | null>();
  const [button, setButton] = useState<{ label: string; value: string } | null>();
  const [variablesOptions, setVariablesOptions] = useState<{ label: string; value: string }[]>();
  const [buttonOptions, setButtonOptions] = useState<{ label: string; value: string }[]>();
  const [shortCode, setShortCode] = useState('');
  const [status, setStatus] = useState<{ label: string; value: string } | null>();

  const handleChange = (e: any) => {
    e.target.name === 'message' ? setMessage(e.target.value) : setShortCode(e.target.value);
  };

  const handleStatusChange = (e: any) => {
    setStatus(e);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    console.log('Submitted');
    const data = {
      message,
      shortCode,
      status: status?.value,
    };
    isEditMode ? handleEditedFormSubmit?.(data, formData._id) : handleFormSubmit?.(data);
  };

  useEffect(() => {
    if (!_.isEmpty(formData)) {
      setMessage(formData.message);
      setShortCode(formData.shortCode);
      // setVariables(formData.variables);
      setStatus(statusOptions.find((item) => item.value === formData.status));
    }
  }, [formData]);

  useEffect(() => {
    const lookupParams = {
      type: type === 'Internal' ? 'Staff note' : 'Message',
    };
    console.log('LookupAPI type is', lookupParams);
    if (lookupParams) {
      LookUpAPI.fetchVariablesAndButtons(lookupParams)
        .then((res) => {
          if (res.data.errorCode === 0) {
            if (res?.data?.data?.variables) {
              console.log('LookupAPI variables', res?.data?.data?.variables);
              const variables = res.data.data?.variables;
              const variableList = variables.map((v: any) => ({
                label: v.label,
                value: v.variable,
              }));
              setVariablesOptions(variableList);
            }
            if (res?.data?.data?.buttons) {
              const buttons = res?.data?.data?.buttons;
              const buttonsList = buttons.map((b: any) => ({
                label: b.label,
                value: b.variable,
              }));
              setButtonOptions(buttonsList);
            }
          }
        })
        .catch((err) => {
          if (err?.response?.data?.message) {
            toast.error(err.response.data.message);
          } else toast.error('Failed to fetch Variables');
        });
    }
  }, []);

  useEffect(() => {
    console.log('Selected Variable: ', variables);
    if (variables?.value) {
      setMessage((prevMessage) => `${prevMessage} [${variables?.value}]`);
    }
  }, [variables]);

  useEffect(() => {
    console.log('Selected Button: ', button);
    if (button?.value) {
      setMessage((prevMessage) => `${prevMessage} [${button?.value}]`);
    }
  }, [button]);

  return (
    <form className="mt-10 listmsgform" onSubmit={handleSubmit}>
      <TextArea
        labelText="Message"
        labelClasses="w-20 mr-12 text-medium"
        placeholder="Enter message here"
        handleChange={handleChange}
        name="message"
        value={message}
      />
      <div className="mb-4">
        <Dropdown
          name="variables"
          labelText="Variables"
          placeholderText="Insert Variables"
          labelClasses="w-20 mr-11 text-medium"
          options={variablesOptions}
          handleChange={(e) => setVariables(e)}
          value={variables}
        />
      </div>
      <div className="mb-4">
        <Dropdown
          name="buttons"
          labelText="Buttons"
          placeholderText="Insert Button"
          labelClasses="w-20 mr-11 text-medium"
          options={buttonOptions}
          handleChange={(e) => setButton(e)}
          value={button}
        />
      </div>
      <Input
        labelText="Shortcode"
        wrapperClasses="mb-5"
        inputClasses="h-10"
        labelClasses="w-20 mr-9 text-medium"
        placeholder="/set-shortCode"
        handleChange={handleChange}
        name="shortCode"
        value={shortCode}
      />
      <div className="mb-9 listpopmsg">
        <Dropdown
          name="status"
          labelText="Status"
          placeholderText="Select Status"
          labelClasses="w-20 mr-12 text-medium"
          options={statusOptions}
          handleChange={handleStatusChange}
          value={status}
        />
      </div>
      <div
        className={classNames('flex items-center mt-4 pb-14', {
          'justify-end': !isEditMode,
          ' justify-between': isEditMode,
        })}
      >
        {isEditMode && hasDeleteAccess && (
          <Button
            ownClasses="rounded-full p-0 font-medium"
            type="depressed"
            onClick={() => handleDelete?.(formData._id)}
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="red">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
            Delete template
          </Button>
        )}
        <Button ownClasses="rounded-full " type="success" formType>
          Save
        </Button>
      </div>
    </form>
  );
};

export default Form;
