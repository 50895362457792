import axios, { AxiosRequestConfig } from 'axios';
import config from '../config';
import Cookies from 'js-cookie';
const { api, key } = config;

const AxiosInstanceAuth = axios.create({
  baseURL: api.baseUrlAuth,
});

const AxiosInstanceWebsite = axios.create({
  baseURL: api.baseUrlAuth,
});

// Intercepting the request for adding headers and common query params
const requestHandler = (request: AxiosRequestConfig) => {
  // Modify request here
  request.headers['API-KEY'] = key.apiKey;
  const userToken = window.localStorage.getItem('accessToken');
  if (userToken) {
    request.headers.Authorization = `${userToken}`;
  }

  return request;
};

AxiosInstanceAuth.interceptors.request.use((request) => requestHandler(request));
AxiosInstanceWebsite.interceptors.request.use((request) => requestHandler(request));

export { AxiosInstanceAuth, AxiosInstanceWebsite };
