import Dropdown from 'components/common/Dropdown';
import Input from 'components/common/Input';
import MultiSelect from 'components/common/MultiSelect';
import Checkbox from 'components/common/Checkbox';
import { useEffect, useState } from 'react';
import AdminAppointmentAPI from 'api/AdminAppointmentApi';
import { toast } from 'react-toastify';
import Button from 'components/common/Button';
import AdminAutomationAPI from 'api/AdminAutomationAPI';
import LoadingOverlay from 'react-loading-overlay-ts';
import { isDefaultClause } from 'typescript';
interface AppointmentTypeProps {
  data: any;
  isEdit: boolean;
  fetchAppointmentList: (value?: any) => void;
  setShowModal: (value?: any) => void;
  handleDelete: (value?: any) => void;
  appoinments: any;
  setSelectedPage: (value?: any) => void;
  setSearchText: (value?: any) => void;
  filterParams: any;
  appointmentLength: any;
  initialLength: any;
  subsequentLength: any;
}
type IOBJ = {
  label: string;
  value: string;
};
const options1 = [
  { value: 'active', label: 'Active' },
  { value: 'inactive', label: 'Inactive' },
];
const options2 = [
  { value: 'California', label: 'California' },
  { value: 'Florida', label: 'Florida' },
  { value: 'New York', label: 'New York' },
  { value: 'South Carolina', label: 'South Carolina' },
  { value: 'South Dakota', label: 'South Dakota' },
];

const options3 = [
  { value: 'Use default length', label: 'Use default length' },
  { value: '15 minutes', label: '15 minutes' },
  { value: '20 minutes', label: '20 minutes' },
  { value: '30 minutes', label: '30 minutes' },
  { value: '45 minutes', label: '45 minutes' },
];
const options4 = [
  { value: 'Primary care', label: 'Primary care' },
  { value: 'Urgent care', label: 'Urgent care' },
  { value: 'Chronic care', label: 'Chronic care' },
];

const Form: React.FC<AppointmentTypeProps> = ({
  data,
  isEdit,
  fetchAppointmentList,
  setShowModal,
  handleDelete,
  appoinments,
  setSelectedPage,
  setSearchText,
  filterParams,
  appointmentLength,
  initialLength,
  subsequentLength,
}) => {
  let payload: any = {
    category: '',
    displayName: '',
    triggers: [],
    lengthByPlan: [
      {
        planId: '',
        appointmentLengths: {
          initial: {
            isDefault: true,
            length: 0,
          },
          subsequent: {
            isDefault: true,
            length: 0,
          },
        },
      },
    ],
    uploadRequired: false,
    status: '',
  };
  const [appointmentTypeData, setAppointmenttypedata] = useState<any>(payload);
  const [triggeroption, settriggeroption] = useState([]);
  const [triggerData, setTriggerData] = useState<IOBJ[]>([{ value: '', label: '' }]);
  const [multiSelectData, setMultiSelectData] = useState<IOBJ[]>([{ value: '', label: '' }]);
  const [initialCreate, setInitialCreate] = useState([]);
  const [subsequentCreate, setSubsequentCreate] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    {
      isEdit &&
        (setLoading(true),
        AdminAppointmentAPI.fetchSingleAppointmentType(data?._id)
          .then((res) => {
            payload = { ...res.data.data };
            const copy = payload?.triggers?.map((item: any) => {
              return { value: item._id, label: item.name };
            });
            payload.triggers = copy;
            const copied = payload?.lengthByPlan?.map((item: any) => {
              return {
                appointmentLength: {
                  initial: {
                    isDefault: item?.appointmentLengths?.initial?.isDefault,
                    length: item?.appointmentLengths?.initial?.length,
                  },
                  subsequent: {
                    isDefault: item?.appointmentLengths?.subsequent?.isDefault,
                    length: item?.appointmentLengths?.subsequent?.length,
                  },
                },
              };
            });
            payload.lengthByPlan = copied;
            setAppointmenttypedata(payload);
            const initial: any = [];
            const subsequent: any = [];
            const copyInitialLength = [...initialLength];
            const copySubsequentLength = [...subsequentLength];
            copied?.map((item: any) => {
              copyInitialLength.every((eachInitial: any) => {
                initial.push({
                  label: eachInitial.label,
                  length: item.appointmentLength.initial['length'],
                });
                setInitialCreate(initial);
                copyInitialLength.shift();
                return false;
              });
              copySubsequentLength.every((eachSubsequent: any) => {
                subsequent.push({
                  label: eachSubsequent.label,
                  length: item.appointmentLength.subsequent['length'],
                });
                setSubsequentCreate(subsequent);
                copySubsequentLength.shift();
                return false;
              });
            });
          })
          .finally(() => {
            setLoading(false);
          }));
    }
    return () => {
      setAppointmenttypedata({});
    };
  }, [data, initialLength]);

  useEffect(() => {
    const initial: any = [];
    const subsequent: any = [];
    AdminAutomationAPI.fetchAutomations({ status: 'active' }).then((res) => {
      settriggeroption(res.data.data);
    });
    initialLength.map((item: any) => {
      initial.push({
        label: item.label,
        length: null,
      });
    });
    setInitialCreate(initial);
    subsequentLength.map((item: any) => {
      subsequent.push({
        label: item.label,
        length: null,
      });
    });
    setSubsequentCreate(subsequent);
  }, []);

  useEffect(() => {
    const data: any = [];
    triggeroption.map((item: any) => {
      data.push({ value: item._id, label: item.name });
    });
    setTriggerData(data);
  }, [triggeroption]);

  const handleChanges = (e: any) => {
    setAppointmenttypedata({ ...appointmentTypeData, [e.target.name]: e.target.value });
  };
  const handleFormSubmit = (event: any) => {
    const copyPayload = { ...appointmentTypeData };
    const triggerUpdated: any = [];
    copyPayload.triggers.map((item: any) => {
      triggerUpdated.push(item.value);
    });
    const plans: any = [];
    const copyInitialCreate = [...initialCreate];
    const copySubsequentCreate = [...subsequentCreate];
    appointmentLength.map((item: any) => {
      const data = item._id;
      copyInitialCreate.every((eachInitial: any) => {
        if (eachInitial.length === 'Use default length' || eachInitial.length === null) {
          eachInitial.isDefault = true;
          eachInitial.length = 0;
        }
        copySubsequentCreate.every((eachSubsequent: any) => {
          if (eachSubsequent.length === 'Use default length' || eachSubsequent.length === null) {
            eachSubsequent.isDefault = true;
            eachSubsequent.length = 0;
          }
          plans.push({
            planId: data,
            appointmentLengths: {
              initial: {
                isDefault: eachInitial.isDefault,
                length: eachInitial.length,
              },
              subsequent: {
                isDefault: eachSubsequent.isDefault,
                length: eachSubsequent.length,
              },
            },
          });
          copySubsequentCreate.shift();
          return false;
        });
        copyInitialCreate.shift();
        return false;
      });
    });
    copyPayload['lengthByPlan'] = plans;
    copyPayload['triggers'] = triggerUpdated;
    isEdit
      ? AdminAppointmentAPI.editAppointmentType(copyPayload, appointmentTypeData._id)
          .then((res) => {
            if (res.data.errorCode == 0) {
              setSelectedPage(1);
              setSearchText('');
              fetchAppointmentList(data?.filterParams);

              setShowModal(false);
              toast.success('Successfully updated');
            } else toast.error('Error! Could Not Update');
          })
          .catch((err) => {
            if (err?.response?.data?.message) {
              toast.error(err.response.data.message);
            } else toast.error(`Error! Could Not Update${err?.response?.data?.message}`);
          })
      : AdminAppointmentAPI.postAppointmentTypes(copyPayload)
          .then((res) => {
            if (res.data.errorCode == 0) {
              setSelectedPage(1);
              setSearchText('');
              fetchAppointmentList(data?.filterParams);

              setShowModal(false);
              toast.success('Successfully added');
            } else toast.error('Error! Could Not Add');
          })
          .catch((err) => {
            if (err?.response?.data?.message) {
              toast.error(err.response.data.message);
            } else toast.error(`Error! Could Not Add${err?.response?.data?.message}`);
          });
    event.preventDefault();
  };

  // code for check box selection & unselection
  const onChecked = (value: boolean) => {
    const currentState = { ...appointmentTypeData };
    if (value) currentState.uploadRequired = true;
    else currentState.uploadRequired = false;
    setAppointmenttypedata(currentState);
  };
  // code for normal dropdown selection & unselection
  const onSelection = (e: any, label?: any, length?: any) => {
    const key = Object.keys(e)[0];
    const currentState = { ...appointmentTypeData };
    const num = e[key]?.value?.match(/\d+/g);
    if (key === 'length') {
      if (num === null) {
        initialCreate.map((item: any) => {
          if (item.label === label) {
            item.isDefault = true;
            item.length = 'Use default length';
          }
        });
        subsequentCreate.map((item: any) => {
          if (item.label === label) {
            item.isDefault = true;
            item.length = 'Use default length';
          }
        });
      } else {
        const num_updated = num[0];
        initialCreate.map((item: any) => {
          if (item.label === label) {
            item.isDefault = false;
            item.length = num_updated;
          }
        });
        subsequentCreate.map((item: any) => {
          if (item.label === label) {
            item.isDefault = false;
            item.length = num_updated;
          }
        });
      }
    } else {
      currentState[key] = e[key].value;
    }
    setAppointmenttypedata(currentState);
  };
  const onHandleSelect = (data: any) => {
    setMultiSelectData(data);
  };

  // handler for multiselect
  const multiselectHandler = (e: any, fieldName: any) => {
    const data: any = [];
    e.map((item: any) => {
      data.push({ value: item.value, label: item.label });
    });
    setAppointmenttypedata({ ...appointmentTypeData, [fieldName]: data });
  };

  return (
    <>
      <LoadingOverlay active={loading} spinner>
        <form className="mt-10 admin-popup relative" onSubmit={handleFormSubmit}>
          <div className="mb-4">
            <Dropdown
              width="80"
              labelText="Category"
              placeholderText="Select Category"
              labelClasses="mr-10 text-medium"
              ownClasses="z-20"
              options={options4}
              onChange={(e: any) => onSelection({ category: e })}
              value={
                appointmentTypeData?.category
                  ? { value: appointmentTypeData?.category, label: appointmentTypeData?.category }
                  : appointmentTypeData?.category
              }
            />
          </div>
          <div className="mt-4">
            <Input
              labelText="Display name"
              placeholder="Enter Display name"
              inputClasses="h-10"
              onChange={handleChanges}
              labelClasses="mr-10 text-medium"
              wrapperClasses="mb-4"
              name="displayName"
              value={appointmentTypeData?.displayName}
            />
          </div>
          <div className="mb-4 multiselect-admin">
            <MultiSelect
              labelText="Triggers"
              placeholder="Select Triggers"
              options={triggerData}
              handleChange={(data: any) => multiselectHandler(data, 'triggers')}
              value={appointmentTypeData?.triggers}
            />
          </div>
          <div className="mb-4">
            {initialCreate?.map((item: any) => {
              return (
                <div key={item.label}>
                  <Dropdown
                    width="80"
                    labelText={item.label}
                    placeholder="Use default length"
                    labelClasses="mr-10 text-medium"
                    options={options3}
                    onChange={(e: any) => onSelection({ length: e }, item.label, item.length)}
                    value={
                      item.length
                        ? {
                            value: item.length,
                            label:
                              item.length &&
                              (item.length === 'Use default length' || item.length === 0
                                ? 'Use default length'
                                : item.length + ' minutes'),
                          }
                        : item.length
                    }
                  />
                </div>
              );
            })}
            {subsequentCreate?.map((item: any) => {
              return (
                <div key={item.label}>
                  <Dropdown
                    width="80"
                    labelText={item.label}
                    placeholderText="Use default length"
                    labelClasses="mr-10 text-medium"
                    options={options3}
                    onChange={(e: any) => onSelection({ length: e }, item.label)}
                    value={
                      item.length
                        ? {
                            value: item.length,
                            label:
                              item.length &&
                              (item.length === 'Use default length' || item.length === 0
                                ? 'Use default length'
                                : item.length + ' minutes'),
                          }
                        : item.length
                    }
                  />
                </div>
              );
            })}
          </div>
          <div className="uploadbox">
            <span>Upload required</span>
            <span className="ml-6">
              <Checkbox onChecked={onChecked} value={appointmentTypeData?.uploadRequired} />
            </span>
          </div>
          <div className="mb-6 appointment-popup">
            <Dropdown
              width="80"
              labelClasses="mr-10 text-medium"
              labelText="Status"
              placeholder="Select Status"
              options={options1}
              name="status"
              onChange={(e: any) => onSelection({ status: e })}
              value={
                appointmentTypeData?.status
                  ? { value: appointmentTypeData?.status, label: appointmentTypeData?.status }
                  : appointmentTypeData?.status
              }
            />
          </div>
          <div className="flex items-center justify-between mt-4 pb-14">
            {isEdit ? (
              <>
                <Button
                  ownClasses="rounded-full p-0 font-medium"
                  type="depressed"
                  onClick={() => handleDelete(data._id)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="red"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                  </svg>
                  Delete appointment type
                </Button>
                <Button ownClasses="rounded-full " type="success" formType>
                  Save
                </Button>
              </>
            ) : (
              <Button ownClasses="rounded-full absolute right-0 bottom-0 " type="success" formType>
                Save
              </Button>
            )}
          </div>
        </form>
      </LoadingOverlay>
    </>
  );
};

export default Form;
