import actions from './actions';
import reducer from './reducer';

const messageTemplates = {
  actions,
  reducer,
  name: 'messageTemplates',
};

export default messageTemplates;
