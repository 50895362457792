import { AxiosInstanceWebsite } from './AxiosInstance';
import config from '../config';

const { api } = config;

const channelAppendPath = 'channels';

export default class ChannelApi {
  static createChannel(data: any) {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/${channelAppendPath}`,
      method: 'POST',
      data: data,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  static uploadImage(data: any) {
    const bodyFormData = new FormData();
    for (const key in data) {
      bodyFormData.append(key, data[key]);
    }
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/${channelAppendPath}/upload-file`,
      method: 'POST',
      data: bodyFormData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }
}
