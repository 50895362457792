import Button from '../common/Button';
import Table from '../common/Table';
import { ReactComponent as PlusSvg } from '../../assets/icons/lifeMD-plus.svg';
import { ReactComponent as EditSvg } from '../../assets/icons/edit.svg';
import { useEffect, useState } from 'react';
import Modal from 'components/AdminManageStaffModals';
import Form from '../AdminStaffCostModal/Form';
import { RootStateOrAny, useSelector } from 'react-redux';
import StaffAPI from 'api/StaffManagementApi';
import LoadingOverlay from 'react-loading-overlay-ts';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';
import { toast } from 'react-toastify';
import React from 'react';
import { CheckCategoryColor } from 'utils/CheckCategoryColor';
import LookUpAPI from 'api/LookUpsAPI';
import UserRolesApi from 'api/UserRolesApi';
import SearchForm from './SearchForm';

export interface StaffProps {
  fetchStaffCost?: (value?: any) => void;
  loading?: boolean;
}

type IOBJ = {
  label: string;
  value: string;
};

const StandardTable: React.FC<StaffProps> = ({ fetchStaffCost, loading }) => {
  const headerItems = [
    {
      title: 'Staff',
      sortKey: 'staff',
    },
    {
      title: 'Pay amount',
      sortKey: 'payAmount',
    },
    {
      title: 'Pay type',
      sortKey: 'payType',
    },
    {
      title: 'Unique ID',
      sortKey: '_id',
    },
    {
      title: 'Status',
      sortKey: 'status',
    },
    {
      title: 'Actions',
      align: 'center',
    },
  ];
  const [showModal, setShowModal] = useState(false);
  const staffCostList = useSelector((state: RootStateOrAny) => state.staffcost?.staffCostList);
  const [staffData, setStaffData] = useState<any>(null);
  const [inlineFilterActive, setInlineFilterActive] = useState('All');
  const [selectedPage, setSelectedPage] = useState(1);
  const [searchFilterParams, setSearchFilterParams] = useState({});
  const [pageItems, setPageItems] = useState([]);
  const [sortKey, setSortKey] = useState<string | undefined>('');
  const [sortOrder, setSortOrder] = useState<string | undefined>();
  const totalStaffCount = useSelector((state: RootStateOrAny) => state.staffcost?.totalStaffCost);
  const [isEdit, setIsEdit] = useState(false);
  const totalPage =
    totalStaffCount % 10 === 0 ? Math.floor(totalStaffCount / 10) : Math.floor(totalStaffCount / 10) + 1;
  const [userRole, setUserRole] = useState([]);
  const [roleOptions, setRoleOptions] = useState<IOBJ[]>([{ value: '', label: '' }]);
  const [payTypeOptions, setPayTypeOptions] = useState<IOBJ[]>([{ value: '', label: '' }]);
  const [params, setParams] = useState<any>();

  const handleFormSubmit = (data: any) => {
    StaffAPI.postStaffCostsConfigurations(data)
      .then((res: any) => {
        if (res.data.errorCode === 0) {
          console.log('post check');
          setSelectedPage(1);
          fetchStaffCost?.(params);
          setShowModal(false);
          toast.success('Success');
        }
      })
      .catch((err: any) => {
        if (err?.response?.data?.message) {
          toast.error(err.response.data.message);
        } else toast.error('Failed to create request type');
      });
  };

  const handleEditedFormSubmit = (data: any, id: string) => {
    StaffAPI.editStaffCostsConfigurations(data, id)
      .then((res: any) => {
        if (res.data.errorCode === 0) {
          console.log('edit check');
          setSelectedPage(1);
          fetchStaffCost?.(params);
          setShowModal(false);
          toast.success('Successfully edited');
        }
      })
      .catch((err: any) => {
        if (err?.response?.data?.message) {
          toast.error(err.response.data.message);
        } else toast.error('Failed to edit staff cost');
      });
  };

  const handleDelete = (id: string) => {
    StaffAPI.deleteStaffCostsConfigurations(id)
      .then((res) => {
        if (res.data.errorCode === 0) {
          setSelectedPage(1);
          fetchStaffCost?.(params);
          setShowModal(false);
          toast.success('Successfully deleted');
        }
      })
      .catch((err) => {
        if (err?.response?.data?.message) {
          toast.error(err.response.data.message);
        } else toast.error('Failed to delete staff cost configuration');
      });
  };

  const onSortClick = (title?: string, key?: string, sortOrder?: string) => {
    setSelectedPage(1);
    setSortKey(key);
    setSortOrder(sortOrder);
    const filterParams: any = {
      ...searchFilterParams,
      ...(key && { sortField: key }),
      ...(sortOrder && { sortOrder }),
      ...(inlineFilterActive && inlineFilterActive !== 'All' && { status: inlineFilterActive }),
    };
    filterParams['pageNo'] = 0;
    filterParams['limit'] = 10;
    console.log('filter params is', filterParams);
    fetchStaffCost?.(filterParams);
    setParams(filterParams);
  };

  const inlineStatusHandler = (inlineCategoryValue: string) => {
    setInlineFilterActive(inlineCategoryValue);

    const filterParams: any = {
      ...searchFilterParams,
      ...(sortKey && { sortField: sortKey }),
      ...(sortOrder && { sortOrder }),
    };
    filterParams['limit'] = 10;
    filterParams['pageNo'] = 0;
    filterParams['status'] = inlineCategoryValue !== 'All' ? inlineCategoryValue : null;

    setSelectedPage(1);
    console.log('filter params is', filterParams);
    fetchStaffCost?.(filterParams);
    setParams(filterParams);
  };

  useEffect(() => {
    console.log('checkerrr', totalStaffCount);
    UserRolesApi.fetchUser({}).then((res) => {
      setUserRole(res?.data?.data);
    });
    LookUpAPI.fetchPayTypes({}).then((res) => {
      const data: IOBJ[] = [];
      res.data.data.map((item: any) => {
        data.push({ value: item, label: item });
      });
      setPayTypeOptions(data);
    });
  }, []);

  useEffect(() => {
    if (!showModal) {
      setStaffData({});
      setIsEdit(false);
    }
  }, [showModal]);

  const filterSearchTextHandler = (searchTextFilterValue?: any) => {
    setSearchFilterParams(searchTextFilterValue);
    const filterParams: any = {
      ...searchTextFilterValue,
      ...(sortKey && { sortField: sortKey }),
      ...(sortOrder && { sortOrder }),
      ...(inlineFilterActive && inlineFilterActive !== 'All' && { status: inlineFilterActive }),
    };
    filterParams['pageNo'] = 0;
    filterParams['limit'] = 10;
    setSelectedPage(1);
    fetchStaffCost?.(filterParams);
    setParams(filterParams);
  };

  const onPageClick = (e: any, value: number) => {
    e.preventDefault();
    setSelectedPage(value);
    const filterParams: any = {
      ...searchFilterParams,
      ...(sortKey && { sortField: sortKey }),
      ...(sortOrder && { sortOrder }),
      ...(inlineFilterActive && inlineFilterActive !== 'All' && { status: inlineFilterActive }),
    };
    filterParams['limit'] = 10;
    filterParams['pageNo'] = value - 1;
    fetchStaffCost?.(filterParams);
  };

  const onNextClick = (e: any) => {
    e.preventDefault();
    if (selectedPage !== totalPage) {
      setSelectedPage(selectedPage + 1);
      const filterParams: any = {
        ...searchFilterParams,
        ...(sortKey && { sortField: sortKey }),
        ...(sortOrder && { sortOrder }),
        ...(inlineFilterActive && inlineFilterActive !== 'All' && { status: inlineFilterActive }),
      };
      filterParams['limit'] = 10;
      filterParams['pageNo'] = selectedPage;
      fetchStaffCost?.(filterParams);
    }
  };

  const onPreviousClick = (e: any) => {
    e.preventDefault();
    if (selectedPage !== 1) {
      setSelectedPage(selectedPage - 1);
      const filterParams: any = {
        ...searchFilterParams,
        ...(sortKey && { sortField: sortKey }),
        ...(sortOrder && { sortOrder }),
        ...(inlineFilterActive && inlineFilterActive !== 'All' && { status: inlineFilterActive }),
      };
      filterParams['limit'] = 10;
      filterParams['pageNo'] = selectedPage - 2;
      fetchStaffCost?.(filterParams);
    }
  };

  useEffect(() => {
    let leftSide = selectedPage - 2;
    if (leftSide <= 0) leftSide = 1;
    let rightSide = selectedPage + 2;
    if (rightSide > totalPage) rightSide = totalPage;
    const pages: any = [];
    for (let number = leftSide; number <= rightSide; number++) {
      pages.push(number);
    }
    setPageItems(pages);
  }, [selectedPage, totalStaffCount]);

  return (
    <div className="flex flex-col flex-auto p-11 pt-4 bg-background">
      <div className="flex flex-row items-center my-5">
        <h2 className="text-black text-2xl font-medium mr-8">Staff costs configuration</h2>
        <Button
          type="primary"
          ownClasses=" px-4 mx-0.5 bg-white text-sm text-darkBlue rounded-full shadow-lg font-normal"
          onClick={() => setShowModal(true)}
        >
          <PlusSvg className="w-3.5 h-3.5 mr-1" />
          New
        </Button>
        {showModal && (
          <Modal showModal={showModal} setShowModal={setShowModal} type={true}>
            <div className="px-12 py-16 adminstaffmodals sm:min-modal-size lg:max-modal-size">
              <div className="flex flex-col">
                <h2 className="text-2xl text-green font-bold">{!isEdit ? ' New staff cost' : 'Edit staff costs'}</h2>
              </div>

              <div className="relative  flex-auto">
                <Form
                  data={staffData}
                  isEdit={isEdit}
                  fetchStaffCost={fetchStaffCost}
                  setShowModal={setShowModal}
                  setSelectedPage={setSelectedPage}
                  roleOptions={roleOptions}
                  payTypeOptions={payTypeOptions}
                  handleFormSubmit={handleFormSubmit}
                  handleEditedFormSubmit={handleEditedFormSubmit}
                  handleDelete={handleDelete}
                />
              </div>
            </div>
          </Modal>
        )}
      </div>
      <div className="flex flex-row flex-wrap gap-y-3 items-center justify-between my-5">
        <div className="flex flex-row flex-wrap items-center">
          <Button
            type={`${inlineFilterActive == 'All' ? 'success' : 'secondary'}`}
            ownClasses="mr-3 pl-4 pr-5 py-1 text-lg rounded-full font-normal"
            onClick={() => inlineStatusHandler('All')}
          >
            <i className="w-2.5 h-2.5 mr-2 bg-white rounded-full" />
            All
          </Button>
          <Button
            type={`${inlineFilterActive == 'active' ? 'success' : 'secondary'}`}
            ownClasses="mr-3 pl-4 pr-5 py-1 text-lg rounded-full font-normal"
            onClick={() => inlineStatusHandler('active')}
          >
            <i className={`w-2.5 h-2.5 mr-2 ${CheckCategoryColor('active')} rounded-full`} />
            Active
          </Button>
          <Button
            type={`${inlineFilterActive == 'inactive' ? 'success' : 'secondary'}`}
            ownClasses="mr-3 pl-4 pr-5 py-1 text-lg rounded-full font-normal"
            onClick={() => inlineStatusHandler('inactive')}
          >
            <i className={`w-2.5 h-2.5 mr-2 ${CheckCategoryColor('inactive')} rounded-full`} />
            Inactive
          </Button>
        </div>
        <SearchForm
          filterSearchTextHandler={filterSearchTextHandler}
          sortField={sortKey}
          sortOrder={sortOrder}
          inlineFilterActive={inlineFilterActive}
        />
      </div>
      <LoadingOverlay active={loading} spinner>
        <Table headerItems={headerItems} onSortClick={onSortClick}>
          {staffCostList &&
            staffCostList.map((eachCost: any) => {
              return (
                <tr className="h-14 border-t border-lightGreyBackground" key={eachCost._id}>
                  <td className="px-6 pb-4 pt-5 border-r border-lightGreyBackground">
                    <p className="font-medium">{eachCost.staff}</p>
                  </td>
                  <td className="px-6 pb-4 pt-5 border-r border-lightGreyBackground">
                    <p>${eachCost?.payAmount}</p>
                  </td>
                  <td className="px-6 pb-4 pt-5 border-r border-lightGreyBackground">
                    <p>{eachCost.payType}</p>
                  </td>

                  <td className="px-6 pb-4 pt-5 border-r border-lightGreyBackground">
                    <p>{eachCost?._id}</p>
                  </td>
                  <td className="px-6 pb-4 pt-5 border-r border-lightGreyBackground capitalize">
                    <p>{eachCost?.status}</p>
                  </td>
                  <td className="px-6 pb-4 pt-5 text-center">
                    <button
                      onClick={() => {
                        // handleEdit(eachCost._id);
                        setStaffData(eachCost);
                        setIsEdit(true);
                        setShowModal(true);
                      }}
                    >
                      <EditSvg className="w-4" />
                    </button>
                  </td>
                </tr>
              );
            })}
        </Table>
      </LoadingOverlay>
      <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
        <div className="flex-1 flex justify-between sm:hidden">
          <a
            href="#"
            className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
          >
            Previous
          </a>
          <a
            href="#"
            className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
          >
            Next
          </a>
        </div>
        <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
          <div>
            {totalStaffCount > 0 ? (
              <p className="text-sm text-gray-700">
                Showing{' '}
                <span className="font-medium">{selectedPage === 1 ? selectedPage : (selectedPage - 1) * 10 + 1}</span>{' '}
                to{' '}
                <span className="font-medium">
                  {selectedPage * 10 > totalStaffCount ? totalStaffCount : selectedPage * 10}
                </span>{' '}
                of <span className="font-medium">{totalStaffCount}</span> Staff cost configuration.
              </p>
            ) : (
              <p className="text-sm text-gray-700">
                Showing <span className="font-medium">{totalStaffCount}</span> Staff cost configuration.
              </p>
            )}
          </div>
          <div>
            <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
              <a
                onClick={(e) => onPreviousClick(e)}
                href="#"
                className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
              >
                <span className="sr-only">Previous</span>
                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
              </a>
              {pageItems.map((item: any) => (
                <a
                  href="#"
                  key={item}
                  aria-current="page"
                  onClick={(e) => onPageClick(e, item)}
                  className={`z-10 ${
                    selectedPage === item ? 'bg-green text-black' : 'border-gray-300 text-gray-500 bg-white'
                  }  relative inline-flex items-center px-4 py-2 border text-sm font-medium`}
                >
                  {item}
                </a>
              ))}
              <a
                href="#"
                onClick={(e) => onNextClick(e)}
                className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
              >
                <span className="sr-only">Next</span>
                <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
              </a>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StandardTable;
