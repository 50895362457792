import openSocket from 'socket.io-client';
import config from '../config';

const { webSocket } = config;
const userToken = () => {
  const token: any = window.localStorage.getItem('accessToken');
  return token;
};

const socketFrontDesk = openSocket(webSocket.baseUrlWebSocketFrontDesk, {
  secure: true,
  autoConnect: false,
});

export default socketFrontDesk;

export function socket_init_frontDesk() {
  socketFrontDesk.io.opts['extraHeaders'] = { Authorization: userToken() };
  socketFrontDesk.connect();
  socketFrontDesk.on('connect', () => {
    console.log('==================== connected to socket Frontdesk=================================');
  });
}

export function socket_close_frontDesk() {
  socketFrontDesk.io.opts['extraHeaders'] = { Authorization: '' };
  socketFrontDesk.close();
  socketFrontDesk.disconnect();
  socketFrontDesk.on('disconnect', () => {
    console.log('====socket disconnected========');
  });
}
