import types from './types';
import { UserRolesDataActionType, UserRolesDetails } from './interfaces';

const initState: UserRolesDetails = {
  userRolesList: null,
  userRolesCount: 0,
};

const reducer = (state: UserRolesDetails = initState, action: UserRolesDataActionType): UserRolesDetails => {
  switch (action.type) {
    case types.SET_USER_ROLES_LIST:
      return {
        ...state,
        userRolesList: action.payload,
      };

    case types.SET_USER_ROLES_COUNT:
      return {
        ...state,
        userRolesCount: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
