import { AxiosInstanceWebsite } from './AxiosInstance';
import config from '../config';

const { api } = config;

const staffAppendPath = 'staffs';
const staffElationAppendPath = 'Staffs-elation';
const staffCostsConfigurations = 'staff-costs-configurations';
const profileImage = 'profile-image';

export default class StaffAPI {
  static fetchStaff(data: any) {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/${staffAppendPath}`,
      method: 'GET',
      params: data,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }
  // /staffs/{staffId} DELETE
  static deleteStaff(staffId: string) {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/${staffAppendPath}/${staffId}`,
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }
  //edit staff
  static editStaffById(payload: any, staffId: string) {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/${staffAppendPath}/${staffId}`,
      method: 'PUT',
      data: payload,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  static fetchSingleStaff(id: string) {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/${staffAppendPath}/${id}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  // --------------------------------------------------------------------->>>
  // STAFF COST APIS
  static fetchStaffCostsConfigurations(data: any) {
    console.log('data check xx:', data);
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/${staffCostsConfigurations}`,
      method: 'GET',
      params: data,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }
  static deleteStaffCostsConfigurations(configId: string) {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/${staffCostsConfigurations}/${configId}`,
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  static postStaffCostsConfigurations(data: any) {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/${staffCostsConfigurations}`,
      method: 'POST',
      // params: { staff: data },
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    });
  }

  static editStaffCostsConfigurations(data: any, configurationId: any) {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/${staffCostsConfigurations}/${configurationId}`,
      method: 'PUT',
      // params: { staff: data },
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    });
  }

  // -------------------------------------------------------------------------------->>>
  static postStaff(data: any) {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/${staffAppendPath}`,
      method: 'POST',
      // params: { staff: data },
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    });
  }

  //   /staffs/{staffId}/profile-image

  static postStaffImage(staffId: any, data: any) {
    console.log('incoming data for image upload', data);
    const formData = new FormData();
    formData.append('profileImage', data);
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/${staffAppendPath}/${staffId}/${profileImage}`,
      method: 'POST',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  // /staffs/{staffId}/reports
  static fetchStaffReports(staffId: any) {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/${staffAppendPath}/${staffId}/reports`,
      method: 'GET',
      // params: data,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  // LOOKUPS
  static specializationLookup(data: any) {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/lookup/specializations`,
      method: 'GET',
      params: data,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  static timezoneLookup(data: any) {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/lookup/time-zones`,
      method: 'GET',
      params: data,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }
  static specialialityAreasLookup(data: any) {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/lookup/specialty-areas
      `,
      method: 'GET',
      params: data,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }
  static stateListLookup(data: any) {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/lookup/state-list`,
      method: 'GET',
      params: data,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }
}
