import { XIcon } from '@heroicons/react/solid';
import classNames from 'classnames';

interface IModal {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  type?: boolean;
  isEndCallModal?: boolean;
}

const Modal: React.FC<IModal> = ({ children, showModal, setShowModal, type, isEndCallModal }) => {
  return (
    <>
      {showModal ? (
        <>
          <div className="absolute inset-0 z-50 overflow-auto p-12 h-screen no-scrollbar ">
            <div
              className={classNames('flex', type ? 'justify-center' : 'absolute left-0', {
                'h-full items-center': isEndCallModal,
              })}
            >
              <div className="relative bg-white rounded-lg shadow-lg">
                <button
                  className="absolute -top-6 -right-6 z-10 p-4 rounded-full bg-darkBlue"
                  onClick={() => setShowModal(false)}
                >
                  <XIcon className="text-white h-5 w-5" />
                </button>
                <div className="relative flex-auto">
                  {/*content*/}
                  {children}
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-50 fixed inset-0 z-40 bg-darkBlue" />
        </>
      ) : null}
    </>
  );
};

export default Modal;
