import Button from '../common/Button';
import Table from '../common/Table';
import SearchForm from './SearchForm';
import { ReactComponent as PlusSvg } from '../../assets/icons/lifeMD-plus.svg';
import { ReactComponent as EditSvg } from '../../assets/icons/edit.svg';
import { useEffect, useState } from 'react';
import Modal from 'components/AdminManageStaffModals';
import Form from '../AdminManageStaffModals/Form';
import { RootStateOrAny, useSelector } from 'react-redux';
import StaffAPI from 'api/StaffManagementApi';
import LoadingOverlay from 'react-loading-overlay-ts';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';
import { toast } from 'react-toastify';
import AdvancedHeader from 'components/AdminManageStaffModals/Headers/AdvancedHeader';
import StandartHeader from 'components/AdminManageStaffModals/Headers/StandartHeader';
import React from 'react';
import { CheckCategoryColor } from 'utils/CheckCategoryColor';
import UserRolesApi from 'api/UserRolesApi';
import { dateTimeFormatter } from 'utils/dateTimeFormatter';

export interface StaffProps {
  fetchStaffs?: (value?: any) => void;
  loading?: boolean;
}

const StandardTable: React.FC<StaffProps> = ({ fetchStaffs, loading }) => {
  const headerItems = [
    {
      title: 'User',
      sortKey: 'name',
    },
    {
      title: 'Last active',
      sortKey: 'lastLogin',
    },
    {
      title: 'User type',
      sortKey: 'userType',
    },
    {
      title: 'Licensed in',
      sortKey: 'activeStates',
    },
    {
      title: 'Email',
      sortKey: 'email',
    },
    {
      title: 'Unique id',
      sortKey: 'uniqueId',
    },
    {
      title: 'Status',
      sortKey: 'status',
    },
    {
      title: 'Actions',
      align: 'center',
    },
  ];
  const [showModal, setShowModal] = useState(false);
  const staffList = useSelector((state: RootStateOrAny) => state.staffs?.staffManagementList);
  const [staffData, setStaffData] = useState<any>(null);
  const [staffReport, setStaffReport] = useState<any>();
  const [staffId, setStaffId] = useState<any>();
  const [staffImage, setStaffImage] = useState<any>(null);
  const [inlineFilterActive, setInlineFilterActive] = useState('All');
  const [selectedPage, setSelectedPage] = useState(1);
  const [searchFilterParams, setSearchFilterParams] = useState({});
  const [searchText, setSearchText] = useState('');
  const [pageItems, setPageItems] = useState([]);
  const [sortKey, setSortKey] = useState<string | undefined>('');
  const [sortOrder, setSortOrder] = useState<string | undefined>();
  const totalStaffCount = useSelector((state: RootStateOrAny) => state.staffs?.staffManagementListTotalCount);
  const [isEdit, setIsEdit] = useState(false);
  const [userRole, setUserRole] = useState([]);
  const totalPage =
    totalStaffCount % 10 === 0 ? Math.floor(totalStaffCount / 10) : Math.floor(totalStaffCount / 10) + 1;
  const uploadedImage = React.useRef(null);

  const onSortClick = (title?: string, key?: string, sortOrder?: string) => {
    setSelectedPage(1);
    setSortKey(key);
    setSortOrder(sortOrder);
    const filterParams: any = {
      ...searchFilterParams,
      ...(key && { sortField: key }),
      ...(sortOrder && { sortOrder }),
      ...(inlineFilterActive && inlineFilterActive !== 'All' && { status: inlineFilterActive }),
    };
    filterParams['pageNo'] = 0;
    filterParams['limit'] = 10;
    fetchStaffs?.(filterParams);
  };

  const inlineStatusHandler = (inlineCategoryValue: string) => {
    setInlineFilterActive(inlineCategoryValue);

    const filterParams: any = {
      ...searchFilterParams,
      ...(sortKey && { sortField: sortKey }),
      ...(sortOrder && { sortOrder }),
    };
    filterParams['limit'] = 10;
    filterParams['pageNo'] = 0;
    filterParams['status'] = inlineCategoryValue !== 'All' ? inlineCategoryValue : null;

    setSelectedPage(1);
    fetchStaffs?.(filterParams);
  };

  useEffect(() => {
    if (!showModal) {
      setStaffData({});
      setIsEdit(false);
      setStaffImage(null);
    }
  }, [showModal]);

  useEffect(() => {
    UserRolesApi.fetchUser({}).then((res: any) => {
      setUserRole(res?.data?.data);
    });
  }, []);
  const filterSearchTextHandler = (searchTextFilterValue?: any) => {
    setSearchFilterParams(searchTextFilterValue);
    const filterParams: any = {
      ...searchTextFilterValue,
      ...(sortKey && { sortField: sortKey }),
      ...(sortOrder && { sortOrder }),
      ...(inlineFilterActive && inlineFilterActive !== 'All' && { status: inlineFilterActive }),
    };
    filterParams['pageNo'] = 0;
    filterParams['limit'] = 10;
    setSelectedPage(1);
    fetchStaffs?.(filterParams);
  };

  const onPageClick = (e: any, value: number) => {
    e.preventDefault();
    setSelectedPage(value);
    const filterParams: any = {
      ...searchFilterParams,
      ...(sortKey && { sortField: sortKey }),
      ...(sortOrder && { sortOrder }),
      ...(inlineFilterActive && inlineFilterActive !== 'All' && { status: inlineFilterActive }),
    };
    filterParams['limit'] = 10;
    filterParams['pageNo'] = value - 1;
    fetchStaffs?.(filterParams);
  };

  const onNextClick = (e: any) => {
    e.preventDefault();
    if (selectedPage !== totalPage) {
      setSelectedPage(selectedPage + 1);
      const filterParams: any = {
        ...searchFilterParams,
        ...(sortKey && { sortField: sortKey }),
        ...(sortOrder && { sortOrder }),
        ...(inlineFilterActive && inlineFilterActive !== 'All' && { status: inlineFilterActive }),
      };
      filterParams['limit'] = 10;
      filterParams['pageNo'] = selectedPage;
      fetchStaffs?.(filterParams);
    }
  };

  const onPreviousClick = (e: any) => {
    e.preventDefault();
    if (selectedPage !== 1) {
      setSelectedPage(selectedPage - 1);
      const filterParams: any = {
        ...searchFilterParams,
        ...(sortKey && { sortField: sortKey }),
        ...(sortOrder && { sortOrder }),
        ...(inlineFilterActive && inlineFilterActive !== 'All' && { status: inlineFilterActive }),
      };
      filterParams['limit'] = 10;
      filterParams['pageNo'] = selectedPage - 2;
      fetchStaffs?.(filterParams);
    }
  };

  const staffIdSetter = (id: any) => {
    setStaffId(id);
  };
  useEffect(() => {
    let leftSide = selectedPage - 2;
    if (leftSide <= 0) leftSide = 1;
    let rightSide = selectedPage + 2;
    if (rightSide > totalPage) rightSide = totalPage;
    const pages: any = [];
    for (let number = leftSide; number <= rightSide; number++) {
      pages.push(number);
    }
    setPageItems(pages);
  }, [selectedPage, totalStaffCount]);

  const handleEdit = (id: any) => {
    setStaffId(id);

    StaffAPI.fetchStaffReports(id).then((res) => {
      setStaffReport(res.data);
    });
    StaffAPI.fetchSingleStaff(id)
      .then((res) => {
        if (res.data.errorCode === 0) {
          setStaffData(res?.data?.data);
          setIsEdit(true);
          setShowModal(true);
        }
      })
      .catch((err) => {
        if (err?.response?.data?.message) {
          toast.error(err?.response?.data?.message);
        } else toast.error('Failed to fetch data');
      });
  };

  const onImageChange = (event: any) => {
    if (event.target.files && event.target.files[0]) {
      const img = event.target.files[0];
      setStaffImage(img);
    }
  };
  const handleImageUpload = (e: any) => {
    onImageChange(e);
    const [file] = e?.target?.files;
    if (file) {
      const reader = new FileReader();
      const { current }: any = uploadedImage;
      current.file = file;
      reader.onload = (e) => {
        current.src = e?.target?.result;
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="flex flex-col flex-auto p-11 pt-4 bg-background">
      <div className="flex flex-row items-center my-5">
        <h2 className="text-black text-2xl font-medium mr-8">Staff</h2>
        <Button
          type="primary"
          ownClasses=" px-4 mx-0.5 bg-white text-sm text-darkBlue rounded-full shadow-lg font-normal"
          onClick={() => setShowModal(true)}
        >
          <PlusSvg className="w-3.5 h-3.5 mr-1" />
          New
        </Button>
        {showModal && (
          <Modal showModal={showModal} setShowModal={setShowModal} type={true}>
            <div className="px-12 py-16 adminstaffmodals sm:min-modal-size lg:max-modal-size">
              {isEdit ? (
                <AdvancedHeader
                  handleImageUpload={handleImageUpload}
                  staffData={staffData}
                  staffImage={staffImage}
                  isEdit={isEdit}
                  staffId={staffId}
                  uploadedImage={uploadedImage}
                />
              ) : (
                <StandartHeader onImageChange={onImageChange} staffData={staffData} staffImage={staffImage} />
              )}
              <div className="relative  flex-auto">
                <Form
                  data={staffData}
                  isEdit={isEdit}
                  fetchStaffs={fetchStaffs}
                  setShowModal={setShowModal}
                  staffImage={staffImage}
                  setSelectedPage={setSelectedPage}
                  userRoles={userRole}
                  setSearchText={setSearchText}
                  searchFilterParams={searchFilterParams}
                />
              </div>
            </div>
          </Modal>
        )}
      </div>
      <div className="flex flex-row flex-wrap gap-y-3 items-center justify-between my-5">
        <div className="flex flex-row flex-wrap items-center">
          <Button
            type={`${inlineFilterActive == 'All' ? 'success' : 'secondary'}`}
            ownClasses="mr-3 pl-4 pr-5 py-1 text-lg rounded-full font-normal"
            onClick={() => inlineStatusHandler('All')}
          >
            <i className="w-2.5 h-2.5 mr-2 bg-white rounded-full" />
            All
          </Button>
          <Button
            type={`${inlineFilterActive == 'active' ? 'success' : 'secondary'}`}
            ownClasses="mr-3 pl-4 pr-5 py-1 text-lg rounded-full font-normal"
            onClick={() => inlineStatusHandler('active')}
          >
            <i className={`w-2.5 h-2.5 mr-2 ${CheckCategoryColor('active')} rounded-full`} />
            Active
          </Button>
          <Button
            type={`${inlineFilterActive == 'inactive' ? 'success' : 'secondary'}`}
            ownClasses="mr-3 pl-4 pr-5 py-1 text-lg rounded-full font-normal"
            onClick={() => inlineStatusHandler('inactive')}
          >
            <i className={`w-2.5 h-2.5 mr-2 ${CheckCategoryColor('inactive')} rounded-full`} />
            Inactive
          </Button>
        </div>
        <SearchForm
          filterSearchTextHandler={filterSearchTextHandler}
          sortField={sortKey}
          sortOrder={sortOrder}
          inlineFilterActive={inlineFilterActive}
          searchText={searchText}
          setSearchText={setSearchText}
        />
      </div>
      <LoadingOverlay active={loading} spinner>
        <Table headerItems={headerItems} onSortClick={onSortClick}>
          {staffList &&
            staffList.map((eachStaff: any) => {
              return (
                <tr className="h-14 border-t border-lightGreyBackground" key={eachStaff._id}>
                  <td className="px-6 pb-4 pt-5 border-r border-lightGreyBackground">
                    <p className="font-medium">{eachStaff.name}</p>
                  </td>
                  <td className="px-6 pb-4 pt-5 border-r border-lightGreyBackground">
                    <p>{eachStaff?.lastLogin ? dateTimeFormatter(eachStaff?.lastLogin) : ''}</p>
                  </td>
                  <td className="px-6 pb-4 pt-5 border-r border-lightGreyBackground">
                    <p>{eachStaff.userType?.name}</p>
                  </td>
                  <td className="px-6 pb-4 pt-5 border-r border-lightGreyBackground flex flex-row flex-wrap	">
                    {eachStaff?.activeStates?.length > 0 ? (
                      <p>{eachStaff?.activeStates?.map((state: any) => state?.stateCode)?.join(', ')}</p>
                    ) : (
                      <p>-</p>
                    )}
                  </td>
                  <td className="px-6 pb-4 pt-5 border-r border-lightGreyBackground">
                    <p>{eachStaff?.email}</p>
                  </td>
                  <td className="px-6 pb-4 pt-5 border-r border-lightGreyBackground">
                    <p>{eachStaff?._id}</p>
                  </td>
                  <td className="px-6 pb-4 pt-5 border-r border-lightGreyBackground">
                    <p className="text-midBlue capitalize">{eachStaff?.status}</p>
                  </td>
                  <td className="px-6 pb-4 pt-5 text-center">
                    <button
                      onClick={() => {
                        handleEdit(eachStaff._id);
                        setIsEdit(true);
                        setShowModal(true);
                      }}
                    >
                      <EditSvg className="w-4" />
                    </button>
                  </td>
                </tr>
              );
            })}
        </Table>
      </LoadingOverlay>
      <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
        <div className="flex-1 flex justify-between sm:hidden">
          <a
            href="#"
            className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
          >
            Previous
          </a>
          <a
            href="#"
            className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
          >
            Next
          </a>
        </div>
        <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
          <div>
            {totalStaffCount > 0 ? (
              <p className="text-sm text-gray-700">
                Showing{' '}
                <span className="font-medium">{selectedPage === 1 ? selectedPage : (selectedPage - 1) * 10 + 1}</span>{' '}
                to{' '}
                <span className="font-medium">
                  {selectedPage * 10 > totalStaffCount ? totalStaffCount : selectedPage * 10}
                </span>{' '}
                of <span className="font-medium">{totalStaffCount}</span> Staffs
              </p>
            ) : (
              <p className="text-sm text-gray-700">
                Showing <span className="font-medium">{totalStaffCount}</span> staffs
              </p>
            )}
          </div>
          <div>
            <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
              <a
                onClick={(e) => onPreviousClick(e)}
                href="#"
                className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
              >
                <span className="sr-only">Previous</span>
                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
              </a>
              {pageItems.map((item: any) => (
                <a
                  href="#"
                  key={item}
                  aria-current="page"
                  onClick={(e) => onPageClick(e, item)}
                  className={`z-10 ${
                    selectedPage === item ? 'bg-green text-black' : 'border-gray-300 text-gray-500 bg-white'
                  }  relative inline-flex items-center px-4 py-2 border text-sm font-medium`}
                >
                  {item}
                </a>
              ))}
              <a
                href="#"
                onClick={(e) => onNextClick(e)}
                className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
              >
                <span className="sr-only">Next</span>
                <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
              </a>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StandardTable;
