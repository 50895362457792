import classNames from 'classnames';
import React from 'react';

interface ICircleAvatar {
  src: string | '';
  ownClasses?: string;
  onImageChange?: any;
  uploadedImage?: any;
  handleImageUpload?: any;
  type?: boolean;
}

const CircleAvatar = ({ src, ownClasses, onImageChange, uploadedImage, type, handleImageUpload }: ICircleAvatar) => {
  const imageUploader: any = React.useRef(null);
  const avatarClasses = classNames('inline-block rounded-full ring-2 ring-white object-cover', ownClasses);
  const styles = classNames(ownClasses);

  return (
    <>
      <input
        type="file"
        accept="image/*"
        onChange={handleImageUpload}
        ref={type && imageUploader}
        style={{
          display: 'none',
        }}
      />
      <div className={styles} onClick={() => imageUploader?.current?.click()}>
        <img src={src} className={avatarClasses} ref={uploadedImage} />
      </div>
    </>
  );
};

export default CircleAvatar;
