import { AxiosInstanceWebsite } from './AxiosInstance';
import config from '../config';

const { api } = config;

export default class ManageRolesAPI {
  static fetchUserRoles(data: any) {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/user-roles`,
      method: 'GET',
      params: data,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  static fetchUserRolesById(id: string) {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/user-roles/${id}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  static postUserRoles(payload: any) {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/user-roles`,
      method: 'POST',
      data: payload,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }
  static editUserRolesById(payload: any, id: string) {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/user-roles/${id}`,
      method: 'PUT',
      data: payload,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  static deleteUserRolesById(id: string) {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/user-roles/${id}`,
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  static fetchPermissions() {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/lookup/permissions`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }
}
