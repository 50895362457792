import { AxiosInstanceWebsite } from './AxiosInstance';
import config from '../config';

const { api } = config;

const appointmentAppendPath = 'appointment-categories';

export default class AppointmentCategoryAPI {
  static fetchAppointmentCategories() {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/${appointmentAppendPath}`,
      method: 'GET',
      // params: data,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  static editAppointmentCategories(data: any) {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/${appointmentAppendPath}/reference-links`,
      method: 'PATCH',
      data: data,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }
}
