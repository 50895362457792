import Button from 'components/common/Button';
import { ReactComponent as PlusSvg } from 'assets/icons/lifeMD-plus.svg';
import ExpandableElement from '../ExpandableContent';
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { ReactComponent as ArrowUpSvg } from '../../../assets/icons/arrow-up.svg';
import MessageCard, { IMessage } from 'components/Admin/Patient/Messages/MessageItem';
import avatar from 'assets/images/avatar.png';
import MessagePatientForm from './Messages/MessagePatientForm';
import socket from 'socket/socket';
import { dateTimeFormatter } from 'utils/dateTimeFormatter';
import { useSelector, RootStateOrAny } from 'react-redux';
import { toast } from 'react-toastify';
import socketFrontDesk from 'socket/socketFrontDesk';
import Modal from '../../PatientMessagePopup/Modal';
import Input from 'components/common/Input';
import ChannelApi from 'api/ChannelApi';
import channels from 'redux/channels';
import { timeInSecondsFromNow } from 'utils/timeInSecondsFromNow';
import PatientAPI from 'api/PatientAPI';
import * as Sentry from '@sentry/react';
import { isUserHasPermission } from 'components/PopoverTask/Popover';
import { usersHasPermissionToSentMessage } from './Messages';

export interface IThread {
  subject: string;
  id: string;
  newMessagesAmount: number;
  messages: IMessage[];
}

export interface MessageProps {
  patientDetails: any;
}
export interface receiverDetailsItem {
  id: string;
}

const FrontDesk: React.FC<MessageProps> = ({ patientDetails }) => {
  const [activeThread, setActiveThread] = useState('');
  const [channelListInfo, setChannelListInfo] = useState<IThread[] | []>([]);
  const [messages, setMessages] = useState([]);
  const userInfo = useSelector((state: RootStateOrAny) => state.userProfile.userDetails);
  const [messageSend, setMessageSend] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [channelName, setChannelName] = useState<any>('');
  const [channelAdded, setChannelAdded] = useState<any>(false);
  const [channelData, setChannelData] = useState(false);
  const [loadings, setLoadings] = useState(false);

  useEffect(() => {
    if (patientDetails && patientDetails._id) {
      PatientAPI.fetchPatientFrontDeskChannels(patientDetails._id).then((res) => {
        const { data } = res.data.data;
        const frontDeskData: IThread[] = [];
        data.map((eachItem: any) => {
          frontDeskData.push({
            subject: eachItem.frontDeskRequestType,
            id: eachItem.channelId,
            newMessagesAmount: eachItem.unreadMessageCount,
            messages: [],
          });
        });
        setChannelListInfo(frontDeskData);
        setChannelData(true);
      });
    }
  }, [patientDetails]);

  const socketEmitMessage = (id: string) => {
    try {
      socketFrontDesk.emit('joinRoom', id, (data: any) => {
        console.log('=======joined FRONTDESK channel==========');
      });
      socketFrontDesk.emit('markSeen', {
        channelId: id,
        userId: userInfo._id,
      });
    } catch (e) {
      Sentry.captureException(e);
      console.log('error');
    }
    socketFrontDesk.emit(
      'getOldMessage',
      {
        limit: 10,
        skip: 0,
        channelId: id,
      },
      (data: any) => {
        console.log(data, 'frontdesk-soketemmit');
      },
    );
  };

  useEffect(() => {
    if (channelData && channelListInfo) {
      try {
        socketFrontDesk.on('unreadMessageUpdated', (unreadMessageUpdatedData) => {
          if (channelListInfo?.length !== 0) {
            const channels: any = [...channelListInfo];
            channels.map((eachItem: any) => {
              if (eachItem.id == unreadMessageUpdatedData.channelId) {
                eachItem.newMessagesAmount = unreadMessageUpdatedData.unreadMessageCount;
              }
            });
            setChannelListInfo(channels);
          }
        });
      } catch (e) {
        Sentry.captureException(e);
        toast.error('Something went wrong...');
      }
      setChannelData(false);
    }
  }, [channelListInfo, channelData]);

  useEffect(() => {
    if (channelListInfo) {
      const channelActiveThread = channelListInfo.find((item) => item.id === activeThread);
      if (channelActiveThread?.newMessagesAmount !== 0) {
        markSeenData();
      }
    }
  }, [channelListInfo]);

  const onSendMessage = (channelId: string, channelMessage: string, buttons: any) => {
    setMessageSend(false);
    try {
      socketFrontDesk.emit(
        'sendMessage',
        {
          message: channelMessage,
          receiverDetails: [{ id: patientDetails._id }],
          senderDetails: { id: userInfo._id, userType: userInfo.userType },
          channelId: activeThread,
          buttons: buttons,
        },
        function (data: any) {
          if (data.status) {
            setMessageSend(true);
            markSeenData();
          } else {
            toast.error(data.message);
            setMessageSend(false);
          }
        },
      );
    } catch (e) {
      Sentry.captureException(e);
      toast.error('Unable to send message, Please try again!');
      setMessageSend(false);
    }
  };

  const markSeenMessage = () => {
    console.log('===focus===');
  };

  const markSeenData = () => {
    if (activeThread !== '') {
      try {
        socketFrontDesk.emit('markSeen', {
          channelId: activeThread,
          userId: userInfo._id,
        });
      } catch (e) {
        Sentry.captureException(e);
        console.log('Unable to mark seen');
      }
    }
  };

  const Header: React.FC<IThread> = ({ id, subject, newMessagesAmount }) => {
    return (
      <>
        <div className="cursor-pointer flex py-6 px-10 justify-between items-center bg-lightGreyBackground">
          <div>
            <div className="flex items-center">
              <h3 className="text-textDarkGrey font-semibold text-xl">Subject: {subject}</h3>
              {newMessagesAmount ? (
                <span className="mx-4 bg-red w-6 p-1 text-xs text-white text-center rounded-full">
                  {newMessagesAmount}
                </span>
              ) : null}
            </div>
          </div>
          <ArrowUpSvg
            className={classNames(
              'text-darkBlue stroke-current h-4 w-4 transition-all transform ml-2',
              activeThread === id ? 'rotate-0' : '-rotate-90',
            )}
          />
        </div>
      </>
    );
  };
  const Content: React.FC<IThread> = (props) => {
    setLoadings(false);
    const index = props.messages.findIndex((message) => message.isNew);
    const oldMessages = [...props.messages];
    let newMessages: IMessage[] = [];
    if (index !== -1) {
      newMessages = oldMessages.splice(index);
    }

    return (
      <div className="px-20 w-full">
        {props.messages.length ? (
          <div className="w-full flex flex-col mt-12 mb-6">
            {props.messages
              .filter((m) => !m.isNew)
              .map((m) => (
                <MessageCard key={m.id} {...m} />
              ))}
            {!!newMessages.length && (
              <div className="flex">
                <span className="text-red font-semibold">NEW</span>
                <div className="border-b flex-grow border-red my-4 ml-2"></div>
              </div>
            )}
            {props.messages
              .filter((m) => m.isNew)
              .map((m) => (
                <MessageCard key={m.id} {...m} />
              ))}
          </div>
        ) : (
          loadings && <p className="text-textGreyedOut text-xl px-20 py-6">No messages yet</p>
        )}
        <div className="mb-8">
          {isUserHasPermission(usersHasPermissionToSentMessage, userInfo.userType?.name) && (
            <MessagePatientForm
              onSendMessage={onSendMessage}
              markSeenMessage={markSeenMessage}
              messsageSend={messageSend}
              patientId={patientDetails && patientDetails._id}
              doctorId={userInfo._id}
            />
          )}
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (activeThread !== '') {
      setMessages([]);
      socketEmitMessage(activeThread);
    }
    setLoadings(true);
  }, [activeThread]);

  useEffect(() => {
    if (activeThread !== '') {
      try {
        socketFrontDesk.off('messageHistory');
        socketFrontDesk.on('messageHistory', (data) => {
          if (data?.data?.[0]) {
            const sortedData = data.data.sort(
              (prev: any, curr: any) =>
                timeInSecondsFromNow(curr.messageStatus.sent) - timeInSecondsFromNow(prev.messageStatus.sent),
            );
            setMessages(sortedData);
          }
        });
        socketFrontDesk.off('messageToClients');
        socketFrontDesk.on('messageToClients', () => {
          setMessageSend(true);
          socketFrontDesk.emit(
            'getOldMessage',
            {
              limit: 10,
              skip: 0,
              channelId: activeThread,
            },
            (data: any) => {
              console.log(data, 'frontdesk-soketemmit');
            },
          );
        });
      } catch (err) {
        Sentry.captureException(err);
        console.log(err);
      }
    }
  }, [activeThread]);

  useEffect(() => {
    const messageData: any = [];
    if (messages[0]) {
      messages.map((eachMessage: any) => {
        const seenInfo = eachMessage.receiverDetails.find(
          (receiverDetailsItem: receiverDetailsItem) => receiverDetailsItem.id === userInfo._id,
        );
        messageData.push({
          id: eachMessage._id,
          authorName: eachMessage.senderDetails.name,
          authorRole: eachMessage.senderDetails.userType.shortCode,
          messageDate: dateTimeFormatter(eachMessage.messageStatus.sent),
          isNew: seenInfo ? !seenInfo.messageStatus?.seen : false,
          text: eachMessage.message,
          avatarUrl: eachMessage.senderDetails.profileImage,
          filePath: eachMessage.filePath,
          fileName: eachMessage.fileName,
          authorId: eachMessage.senderDetails.id,
        });
      });

      if (channelListInfo?.length !== 0) {
        const channels: any = [...channelListInfo];
        if (channels) {
          channels.map((eachItem: any) => {
            if (eachItem.id === activeThread) {
              eachItem['messages'] = messageData;
            }
          });
        }

        setChannelListInfo(channels);
      }
    }
  }, [messages]);
  return (
    <div className="lg:w-9/12">
      {/* <h1 className="text-2xl font-semibold">Front Desk</h1> */}
      <div>
        {channelListInfo &&
          channelListInfo.map((el) => (
            <div key={el.id} className="my-8">
              <ExpandableElement
                header={<Header {...el} />}
                isShowContent={activeThread === el.id}
                toggleContent={() => setActiveThread(el.id === activeThread ? '' : el.id)}
                content={<Content {...el} />}
                loadings={loadings}
              />
            </div>
          ))}
      </div>
    </div>
  );
};

export default FrontDesk;
