import dayjs from 'dayjs';
import { ReactComponent as ArrowRight } from '../../../assets/icons/sidebar/arrow-right.svg';
import { WeekSwitchProps } from './weekSwitch.types';

const WeekSwitch: React.FC<WeekSwitchProps> = ({ week, onChange }) => {
  const dateWeek = dayjs().week(week);
  const firstDay = dateWeek.startOf('week').format('MMM D');
  const lastDay = dateWeek.endOf('week').format('MMM D');
  const year = dateWeek.format('YYYY');

  const nextWeek = () => {
    onChange(week + 1);
  };
  const prevWeek = () => {
    onChange(week - 1);
  };

  return (
    <div className="flex items-center gap-4">
      <div className="flex items-center rounded overflow-hidden bg-gray10">
        <button className="py-2.5 px-5" onClick={prevWeek}>
          <ArrowRight className="w-4 h-4 transform rotate-180 text-primary" />
        </button>
        <div className="h-5 bg-gray20 w-px" />
        <button className="py-2.5 px-5" onClick={nextWeek}>
          <ArrowRight className="w-4 h-4 text-primary" />
        </button>
      </div>
      <p className="text-2xl">
        {firstDay} - {lastDay} • {year}
      </p>
    </div>
  );
};

export default WeekSwitch;
