import Footer from './Footer';
import Form from './Form';
import Links from './Links';
import Modal from './Modal';
import { useState } from 'react';
import AdvancedHeader from './Headers/AdvancedHeader';
import StandartHeader from './Headers/StandartHeader';

const ModalPage = () => {
  const [showAdvancedModal, setShowAdvancedModal] = useState(false);
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <button
        className="bg-pink-500 text-white active:bg-pink-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
        type="button"
        onClick={() => setShowAdvancedModal(true)}
      >
        Open popup with advanced header
      </button>
      <button
        className="bg-pink-500 text-white active:bg-pink-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
        type="button"
        onClick={() => setShowModal(true)}
      >
        Open standart popup
      </button>

      <Modal showModal={showAdvancedModal} setShowModal={setShowAdvancedModal}>
        <div className="px-12 py-16">
          <AdvancedHeader />
          <Links />
          <div className="relative  flex-auto border-t border-solid border-blueGray-200 rounded-b">
            <Form />
          </div>
          <Footer />
        </div>
      </Modal>

      <Modal showModal={showModal} setShowModal={setShowModal}>
        <div className="px-12 py-16">
          <StandartHeader />
          <div className="relative  flex-auto">
            <Form />
          </div>
          <Footer />
        </div>
      </Modal>
    </>
  );
};

export default ModalPage;
