import { useContext, useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';

import { PhoneIcon } from '@heroicons/react/outline';
import defaultpic from 'assets/images/profile-generic.png';
import { ReactComponent as Call } from 'assets/icons/call/call.svg';
import { ReactComponent as Mic } from 'assets/icons/call/mic.svg';
import { ReactComponent as Blocked } from 'assets/icons/call/blocked.svg';
import PatientAPI from 'api/PatientAPI';
import { CallContext } from 'contexts/CallContext';
import { Device } from 'twilio-client';
import { formatPhoneNumber, states } from 'utils/phone';
import StopWatch from './StopWatch';
import classNames from 'classnames';

const CallNotificationCard: React.FC<{
  position?: string;
}> = ({ position }) => {
  const { patientId, device, setDevice, setShowCallCard, showCallCard, token } = useContext(CallContext);
  const [, setState] = useState(states.CONNECTING);
  const [call, setCall] = useState(null);
  const [profileImage, setProfileImage] = useState('');
  const [patientDetails, setPatientDetails] = useState<any | null>(null);
  const [isMute, setIsMute] = useState(false);

  const handleCall = async () => {
    if (device) {
      device.connect({ To: patientDetails.phone.internationalFormat });
    }
  };

  const handleHangup = () => {
    if (device) {
      device.disconnectAll();
    }
    setShowCallCard(false);
  };

  const handleCancel = () => {
    setShowCallCard(false);
  };

  const toggleMute = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    isMute ? call?.unmute() : call?.mute();
  };

  useEffect(() => {
    if (patientId) {
      PatientAPI.fetchSinglePatient(patientId).then((res) => {
        const { data } = res.data;
        if (data) {
          setPatientDetails(data);
        }
      });
    }
  }, [patientId]);

  useEffect(() => {
    if (patientDetails?.profileImage) {
      setProfileImage(patientDetails?.profileImage);
    } else {
      setProfileImage(defaultpic);
    }
  }, [patientDetails]);

  useEffect(() => {
    const device = new Device();

    if (token) {
      device.setup(token, { debug: true });

      device.on('ready', () => {
        setDevice(device);
        setState(states.READY);
      });
      device.on('connect', (call) => {
        setCall(call);
        setState(states.ON_CALL);
        call.on('mute', setIsMute);
      });
      device.on('disconnect', () => {
        setState(states.READY);
        setCall(null);
      });
      device.on('incoming', (call) => {
        setState(states.INCOMING);
        setCall(call);
        call.on('reject', () => {
          setState(states.READY);
          setCall(null);
        });
      });
      device.on('cancel', () => {
        setState(states.READY);
        setCall(null);
      });
      device.on('error', () => {
        setState(states.READY);
        setCall(null);
      });
    }

    return () => {
      device.destroy();
      setDevice(null);
      setState(states.OFFLINE);
    };
  }, [token]);

  return (
    <AnimatePresence>
      {showCallCard && (
        <motion.div
          className={classNames(
            'fixed bottom-10 right-10 bg-white w-max flex justify-between shadow-xl rounded-lg p-5 ml-auto z-50',
            { 'inset-x-0 mx-auto': position === 'center' },
          )}
          initial={position === 'center' ? { y: '100vh' } : { x: '100vw' }}
          animate={position === 'center' ? { y: 0 } : { x: 0 }}
          transition={{ delay: 0.2, duration: 0.5 }}
          exit={position === 'center' ? { y: '100vh' } : { x: '100vw' }}
        >
          <img src={profileImage} className="w-12 h-12 object-cover rounded-full mr-3" alt="Patient image" />
          <div className="flex flex-col mr-9">
            <span className="text-darkBlue font-semibold">
              {patientDetails?.firstName} {patientDetails?.lastName}
            </span>
            <span className="text-tableLightGrey">{formatPhoneNumber(patientDetails?.phone?.internationalFormat)}</span>
          </div>
          <div className="flex items-center">
            {call ? (
              <>
                <span className="relative">
                  {isMute && <Blocked className="absolute t-0 -r-0 w-4 h-4 cursor-pointer" onClick={toggleMute} />}
                  <Mic className="text-tableLightGrey w-4 h-4 mr-4 cursor-pointer" onClick={toggleMute} />
                </span>
                <StopWatch />
                <button className="flex items-center bg-red py-2 pr-5 pl-6 rounded-lg" onClick={handleHangup}>
                  <Call className="w-4 h-4 text-white mr-2" />
                  <span className="text-white">End</span>
                </button>
              </>
            ) : (
              <>
                <button className="text-tableLightGrey text-semibold underline mr-4" onClick={handleCancel}>
                  Cancel
                </button>
                <button className="flex items-center bg-green py-2 pr-5 pl-6 rounded-lg" onClick={handleCall}>
                  <PhoneIcon className="w-4 h-4 text-white mr-2" />
                  <span className="text-white">Call</span>
                </button>
              </>
            )}
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default CallNotificationCard;
