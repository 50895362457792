import actions from './actions';
import reducer from './reducer';

const agingAlerts = {
  actions,
  reducer,
  name: 'agingAlerts',
};

export default agingAlerts;
