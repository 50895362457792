import React, { useEffect, useRef, useState } from 'react';

import Draggable from 'react-draggable';
import classNames from 'classnames';
import Participant from '../Participant';
import { LocalParticipantProps } from './localParticipant.types';
import DoctorAvatar from 'assets/icons/doc-avatar.svg';
import { RootStateOrAny, useSelector } from 'react-redux';
import ProfileAPI from 'api/profileApi';

const LocalParticipant: React.FC<LocalParticipantProps> = ({ participant, videoTracks, audioTracks }) => {
  const nodeRef = useRef(null);
  const [profileImage, setProfileImage] = useState(
    useSelector((state: RootStateOrAny) => state.userProfile.userDetails.profileImage),
  );
  const fetchProfileData = () => {
    ProfileAPI.fetchProfile().then((res) => {
      setProfileImage(res.data.data[0].profileImage);
    });
  };

  useEffect(fetchProfileData, []);
  return (
    <Draggable bounds="parent" nodeRef={nodeRef}>
      <div
        ref={nodeRef}
        className={classNames(
          'z-10 flex items-center justify-center overflow-hidden absolute border rounded-xl h-20 w-36 left-4 bottom-32 sm:h-28 sm:w-40 sm:bottom-40 md:h-36 md:w-60 lg:h-52 lg:w-80',
        )}
      >
        <Participant
          participant={participant}
          profileImage={profileImage || DoctorAvatar}
          videoTrack={videoTracks[0]}
          audioTrack={audioTracks[0]}
          isLocal
        />
      </div>
    </Draggable>
  );
};

export default LocalParticipant;
