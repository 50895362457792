import types from './types';
import { ChannelListDataActionType, ChannelDetails } from './interfaces';

const initState: ChannelDetails = {
  channelList: null,
};

const reducer = (state: ChannelDetails = initState, action: ChannelListDataActionType): ChannelDetails => {
  switch (action.type) {
    case types.SET_CHANNEL_LIST:
      return {
        ...state,
        channelList: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
