import React from 'react';
import { Transition } from '@tailwindui/react';
import LoadingOverlay from 'react-loading-overlay-ts';

export interface ExpandableElementProps {
  content: React.ReactFragment;
  header: React.ReactFragment;
  toggleContent: () => void;
  isShowContent: boolean;
  loadings?: boolean;
}

const ExpandableElement: React.FC<ExpandableElementProps> = ({
  content,
  header,
  toggleContent,
  isShowContent,
  loadings,
}) => {
  return (
    <>
      <header onClick={toggleContent}>{header}</header>
      <Transition
        show={isShowContent}
        enter="transition-all transform duration-200"
        enterFrom="opacity-0 -translate-y-20"
        enterTo="opacity-100 -translate-y-0"
        leave="transition-all transform duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <LoadingOverlay active={loadings} spinner>
          <div className="content">{content}</div>
        </LoadingOverlay>
      </Transition>
    </>
  );
};

export default ExpandableElement;
