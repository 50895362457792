import { useEffect, useState, useCallback, useRef } from 'react';
import { LightningBoltIcon } from '@heroicons/react/outline';
import Button from 'components/common/Button';
import Checkbox from 'components/common/StyledCheckbox';
import { dateTimeFormatter } from 'utils/dateTimeFormatter';
import LoadingOverlay from 'react-loading-overlay-ts';
import MessageTemplateAPI from 'api/MessageTemplateAPI';
import InfiniteScroll from 'react-infinite-scroll-component';
import { debounce } from 'lodash';
import TaskApi from 'api/TaskAPI';
import { ReactComponent as ArrowsSVG } from 'assets/icons/arrows-angle-expand.svg';
import { toast } from 'react-toastify';
import { useSelector, RootStateOrAny } from 'react-redux';
import classNames from 'classnames';
import PatientAPI from 'api/PatientAPI';
import { IMessage } from 'components/Admin/Patient/Messages/MessageItem';
import { useClickAway } from 'react-use';
import { useHistory } from 'react-router-dom';
import { tabs } from 'pages/Admin/PatientSummary';
export interface StaffNoteProps {
  taskId: string;
  fetchTaskDetails: () => void;
  assignedTo?: any;
  patientId: any;
  status?: string;
  category?: any;
}

const StaffNotes: React.FC<StaffNoteProps> = ({
  taskId,
  fetchTaskDetails,
  assignedTo,
  patientId,
  status,
  category,
}) => {
  const pathToPatient = '/dashboard/patient-details';
  const [showTemplates, setShowTemplates] = useState(false);
  const [staffNote, setStaffNote] = useState('');
  const [templateList, setTemplateList] = useState([] as any);
  const [loadMoreTemplates, setLoadMoreTemplates] = useState(true);
  const [staffNotesList, setStaffNoteList] = useState<IMessage[]>([]);
  const [templateTotalCount, setTemplateTotalCount] = useState(0);
  const [templatePage, setTemplatePage] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const [markUrgent, setMarkUrgent] = useState(false);
  const [createNP, setCreateNP] = useState(true);
  const [disabled, setDisabled] = useState(true);
  const [messageText, setMessageText] = useState('');
  const [loading, setLoading] = useState(false);
  const userInfo = useSelector((state: RootStateOrAny) => state.userProfile.userDetails);
  const [messageButton, setMessageButton] = useState<any>();

  const messagesEndRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [isLargeView, setIsLargeView] = useState(false);

  const history = useHistory();

  const templatesRef = useRef(null);

  const replayTemplatesButton = 'replayTemplatesButton';

  useClickAway(templatesRef, (e: Event) => {
    if ((e.target as HTMLButtonElement).id !== replayTemplatesButton) {
      setShowTemplates(false);
    }
  });

  const getRequestMessageTemplates = (payload: any) => {
    MessageTemplateAPI.fetchInternalNoteTemplates(payload).then((res) => {
      const { data } = res.data;
      if (data?.length !== 0) {
        const list: any = showTemplates ? [...templateList] : [];
        data.map((eachItem: any) => {
          list.push({ text: eachItem.message, shortcode: eachItem.shortCode, id: eachItem._id });
        });
        setTemplateList(list);
        setTemplateTotalCount(res?.data?.info?.totalCount);
        if (list.length === res.data.info.totalCount) {
          setLoadMoreTemplates(false);
        } else {
          setLoadMoreTemplates(true);
        }
      } else {
        setTemplateTotalCount(res?.data?.info?.totalCount);
        setTemplateList([]);
        setLoadMoreTemplates(false);
      }
    });
  };

  const fetchStaffNotes = (data: any) => {
    setLoading(true);
    PatientAPI.fetchStaffNotes(data, patientId)
      .then((res) => {
        const { data } = res.data;

        const messageData: IMessage[] = [];
        const messages = staffNotesList;

        // TODO: remove reverse to backend
        data.reverse().map((eachItem: any) => {
          messageData.push({
            id: eachItem._id,
            authorName: eachItem.author.displayName,
            authorRole: eachItem.author.userType.shortCode,
            messageDate: dateTimeFormatter(eachItem.createdAt),
            isNew: false,
            text: eachItem.note,
            avatarUrl: eachItem.avatar,
            authorId: eachItem.author._id,
          });
        });

        const totalMessageList = [...messages, ...messageData];
        setStaffNoteList(totalMessageList);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setStaffNoteList([]);
  }, []);

  useEffect(() => {
    if (patientId) {
      const data = {
        limit: 10,
        pageNo: 0,
      };
      fetchStaffNotes(data);
    }
  }, [patientId]);

  const onClickReplayTemplates = () => {
    setTemplateList([]);
    if (!showTemplates) {
      const data = {
        limit: 10,
        pageNo: 0,
        status: 'active',
        searchKey: searchValue,
      };
      setTemplatePage(0);
      getRequestMessageTemplates(data);
    } else {
      setTemplateList([]);
      setTemplateTotalCount(0);
      setTemplatePage(0);
    }
    setShowTemplates(!showTemplates);
  };

  const submitStaffNotes = () => {
    setDisabled(true);
    const data: any = {
      note: staffNote,
      isUrgent: markUrgent,
    };

    if (
      createNP &&
      userInfo.userType.name === 'Physician' &&
      category !== 'Results' &&
      category !== 'Front desk' &&
      category !== 'Requests'
    ) {
      data['audience'] = ['MA'];
    }

    const staffParams = {
      limit: 10,
      pageNo: 0,
    };
    TaskApi.sendTaskStaffNotes(taskId, data)
      .then((res) => {
        fetchStaffNotes(staffParams);
        setStaffNote('');
        toast.success(res.data.message);
      })
      .catch((e) => {
        toast.error(e.response.data.message);
      });
  };

  const loadMore = () => {
    const data = {
      limit: 10,
      pageNo: templatePage + 1,
      status: 'active',
      searchKey: searchValue,
    };
    const count: number = templatePage + 1;
    setTemplatePage(count);
    getRequestMessageTemplates(data);
  };

  const onSearchTemplate = (value: string) => {
    setTemplatePage(0);
    const data = {
      limit: 10,
      pageNo: 0,
      status: 'active',
      searchKey: value,
    };
    setTemplateList([]);
    getRequestMessageTemplates(data);
  };

  const debounceSearch = useCallback(debounce(onSearchTemplate, 1500), []);
  useEffect(() => {
    if (searchValue) {
      debounceSearch(searchValue);
    }
  }, [searchValue]);

  const onTemplateListClick = (text: string, id: string) => {
    setShowTemplates(false);
    const params = {
      type: 'Internal Note',
      patientId: patientId,
      doctorId: userInfo._id,
    };
    MessageTemplateAPI.fetchMessage(id, params)
      .then((res) => {
        const { data } = res.data;
        setStaffNote(data.message);
        setDisabled(!data.message);
        setMessageButton(data.buttons);
      })
      .catch((e) => {
        toast.error(e.response.data.message);
      });
  };

  const scrollToBottom = () => {
    containerRef.current?.scrollTo({ top: messagesEndRef.current?.offsetTop });
  };

  const MAX_LOADED_ONCE_MESSAGES = 7;

  const messagesWrapperClasses = classNames(
    'relative md:w-full flex flex-col overflow-y-auto',
    isLargeView ? (staffNotesList.length > MAX_LOADED_ONCE_MESSAGES ? 'h-80' : 'h-72') : 'h-36',
  );

  useEffect(() => {
    scrollToBottom();
  }, [staffNotesList]);

  const handleLargeView = () => {
    setIsLargeView((prevState) => !prevState);
  };

  const redirectToStaffNotes = () => {
    history.push(`${pathToPatient}/${patientId}?active-tab=${tabs.staffNotes}`);
  };

  const onChangeStaffNote = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newVal = e.target.value;
    setDisabled(!newVal);
    setStaffNote(newVal);
  };

  return (
    <>
      <div className="relative py-10">
        <h2 className="section-heading md:w-1/5 mb-16">Staff notes</h2>
        <div className="flex flex-col md:flex-row mb-4">
          <div className="md:w-1/5 subheading mb-4">
            <h3 className="font-bold text-lg">Existing notes</h3>
            <h4
              className="text-base font-bold text-inputSuccess underline cursor-pointer text-green 2xl:text-lg"
              onClick={redirectToStaffNotes}
            >
              View all
            </h4>
          </div>
          <div className=" md:w-4/5 flex flex-col">
            <LoadingOverlay active={loading} spinner>
              {staffNotesList.length ? (
                <div className={messagesWrapperClasses} ref={containerRef}>
                  <div
                    className="sticky top-2 self-end mr-4 w-6 h-6 bg-white shadow-lg rounded-lg cursor-pointer"
                    onClick={handleLargeView}
                  >
                    <ArrowsSVG className=" w-full h-full p-1" />
                  </div>
                  {staffNotesList.map((eachNotes: any) => {
                    const isYourMessage = userInfo._id === eachNotes.authorId;

                    const messageWrapperClasses = classNames(
                      'w-4/5 py-2 pl-3 pr-20 mb-3 rounded-lg',
                      isYourMessage ? 'self-end bg-lightGreen bg-opacity-50' : `self-start bg-whiteWithOpacity`,
                    );

                    return (
                      <div className={messageWrapperClasses} key={eachNotes.id}>
                        <div className="flex mb-2">
                          <h4 className="mr-2 font-bold">{isYourMessage ? <>Me</> : eachNotes.authorName}</h4>
                          <span className="text-myPlanLightGrey ">{eachNotes.messageDate}</span>
                        </div>
                        <p>{eachNotes.text}</p>
                      </div>
                    );
                  })}

                  <div ref={messagesEndRef} />
                </div>
              ) : (
                'No Staff Notes'
              )}
            </LoadingOverlay>
          </div>
        </div>
        {userInfo.userType.name !== 'Admin' && assignedTo && assignedTo._id === userInfo._id && status !== 'Completed' && (
          <div className="flex flex-col md:flex-row">
            <h3 className="md:w-1/5 font-bold text-lg mb-4">Add note</h3>
            <div className="relative md:w-4/5 flex flex-col">
              <textarea
                className="border-gray-200 border bg-white rounded-t-xl p-5 w-full h-20"
                placeholder="Add notes…"
                value={staffNote}
                onChange={onChangeStaffNote}
              ></textarea>
              <div className="border-gray-200 bg-white border rounded-b-xl flex flex-col sm:flex-row justify-between p-1">
                <Button
                  ownClasses="font-normal text-myPlanLightGrey"
                  type="depressed"
                  onClick={() => onClickReplayTemplates()}
                  id={replayTemplatesButton}
                >
                  <LightningBoltIcon className="w-4 mr-2 cursor-pointer" />
                  Reply templates
                </Button>
                <div className="staffpopupnote">
                  {userInfo.userType.name === 'Physician' &&
                    category !== 'Results' &&
                    category !== 'Front desk' &&
                    category !== 'Requests' && (
                      <>
                        <Checkbox
                          label="Mark as urgent"
                          labelClasses="mr-8 form-checkbox text-green-500"
                          checked={markUrgent}
                          onChange={() => setMarkUrgent(!markUrgent)}
                        />
                        <Checkbox
                          label="Create task for MA"
                          labelClasses="mr-6"
                          checked={createNP}
                          onChange={() => setCreateNP(!createNP)}
                        />
                      </>
                    )}
                  <Button type="success" onClick={submitStaffNotes} disabled={disabled}>
                    {userInfo.userType.name === 'Nurse Practitioner' ? `Add Note` : `Message staff`}
                  </Button>
                </div>
              </div>
              {showTemplates && (
                <div
                  ref={templatesRef}
                  className="absolute bg-lightGrey top-0 right-0 w-full h-60 transform -translate-y-2/3 rounded-lg rounded-b-none p-4 shadow-lg	"
                >
                  <input
                    placeholder="Search templates"
                    className="search-input"
                    type="search"
                    onChange={(e) => setSearchValue(e.target.value)}
                  />
                  <div className="flex justify-between my-2">
                    <h4 className="font-bold text-sm text-dashboardPrimaryGrey">Template</h4>
                    <h4 className="font-bold text-sm text-dashboardPrimaryGrey">Shortcode</h4>
                  </div>
                  <div className="h-36 overflow-y-auto" id="scrollTemplate">
                    <InfiniteScroll
                      dataLength={templateTotalCount}
                      next={loadMore}
                      hasMore={loadMoreTemplates}
                      loader={<div className="loader"> Loading... </div>}
                      scrollableTarget="scrollTemplate"
                    >
                      {templateList.map((template: any) => (
                        <div
                          key={template.shortcode}
                          className="flex justify-between mb-2"
                          onClick={() => onTemplateListClick(template.text, template.id)}
                        >
                          <span className="truncate cursor-pointer w-5/6 text-sm text-dashboardPrimaryGrey">
                            {template.text}
                          </span>
                          <span className="text-sm text-darkBlue font-bold">{template.shortcode}</span>
                        </div>
                      ))}
                    </InfiniteScroll>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default StaffNotes;
