import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import rootReducer from './rootReducer';
import { createLogger } from 'redux-logger';
import { composeWithDevTools } from 'redux-devtools-extension';
import LogRocket from 'logrocket';

const middlewares = [thunkMiddleware];
/* eslint-disable  @typescript-eslint/no-explicit-any */
const loggerMiddleware: any = createLogger();
middlewares.push(loggerMiddleware);

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunkMiddleware, LogRocket.reduxMiddleware())),
);

export type AppState = ReturnType<typeof rootReducer>;
export default store;
