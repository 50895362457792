import actions from './actions';
import reducer from './reducer';

const channels = {
  actions,
  reducer,
  name: 'channels',
};

export default channels;
