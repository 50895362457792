import actions from './actions';
import reducer from './reducer';

const adminAutomations = {
  actions,
  reducer,
  name: 'adminAutomations',
};

export default adminAutomations;
