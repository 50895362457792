import { useEffect, useState } from 'react';

export interface CheckboxProps {
  labelText?: string;
  labelClasses?: string;
  onChecked?: (value: boolean, name?: string) => void;
  value?: boolean;
  name?: string;
}

const checkbox: React.FC<CheckboxProps> = ({ labelText, labelClasses, onChecked, value, name }) => {
  const [isChecked, setIsChecked] = useState(value);

  // useEffect(() => {
  //   console.log('checked value', isChecked);
  //   // onChecked?.(checked);
  // }, [isChecked]);

  useEffect(() => {
    setIsChecked(value);
  }, [value]);

  const handleChange = (e: any) => {
    setIsChecked(!isChecked);
    // console.log('e for checked', e.target.name, !isChecked);
    onChecked?.(!isChecked, e.target.name);
  };
  return (
    <>
      <label className="flex justify-start items-center">
        <div className="bg-white border-2 rounded border-gray-400 w-5 h-5 flex flex-shrink-0 justify-center items-center mr-2 focus-within:border-blue-500">
          <input
            type="checkbox"
            className="opacity-0 absolute"
            name={name}
            checked={isChecked ? true : false}
            onChange={handleChange}
          />
          <svg className="fill-current hidden w-4 h-4 text-green-500 pointer-events-none" viewBox="0 0 20 20">
            <path d="M0 11l2-2 5 5L18 3l2 2L7 18z" />
          </svg>
        </div>
        <span className={labelClasses}>{labelText}</span>
      </label>
    </>
  );
};

export default checkbox;
