import { NotificationListDataActionType } from './interfaces';
import types from './types';

const initState: any = {
  notificationList: null,
};

const reducer = (state: any = initState, action: NotificationListDataActionType): any => {
  switch (action.type) {
    case types.SET_NOTIFICATION_LIST:
      return {
        ...state,
        notificationList: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
