import { IDocItem } from '../PatientDocuments';
import { ReactComponent as Eye } from 'assets/icons/eye.svg';
import { ReactComponent as Download } from 'assets/icons/download.svg';
import moment from 'moment';

const DocItem: React.FC<IDocItem> = ({ custom_title, size, chart_date, id, reportViewHandler, downloadPDF }: any) => {
  return (
    <>
      <div className="flex justify-between py-6">
        <div>
          <h3 className="lab-title mb-1">{custom_title}</h3>
          <span className="text-sm text-tableLightGrey">{size}</span>
        </div>
        <div className="flex items-center">
          <span> {moment(chart_date).format('MMMM Do,YYYY')}</span>
          <Eye
            className="stroke-current text-darkBlue cursor-pointer mx-12 w-8"
            onClick={() => reportViewHandler(id)}
          />
          <Download className="cursor-pointer mr-2 w-8" onClick={() => downloadPDF(id)} />
        </div>
      </div>
    </>
  );
};

export default DocItem;
