import React, { useEffect, useState } from 'react';
import StaffAPI from 'api/StaffManagementApi';
import { useDispatch } from 'react-redux';
import AdminStaffCost from 'components/AdminStaffCost';
import { setStaffCostList, setTotalStaffCost } from 'redux/staffCosts/actions';

function StaffCost() {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const fetchStaffCost = (value?: any) => {
    setLoading(true);
    StaffAPI.fetchStaffCostsConfigurations(value)
      .then((res) => {
        console.log('response from staff cost api', res);
        if (res.data.errorCode === 0) {
          dispatch(setStaffCostList(res.data.data));
          dispatch(setTotalStaffCost(res.data.info.totalCount));
          setLoading(false);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    fetchStaffCost();
  }, [dispatch]);
  return (
    <div>
      <AdminStaffCost fetchStaffCost={fetchStaffCost} loading={loading} />
    </div>
  );
}

export default StaffCost;
