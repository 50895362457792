import Button from 'components/common/Button';
import DateTimePicker from 'components/common/DateTimePicker';
import Dropdown from 'components/common/Dropdown';
import Input from 'components/common/Input';
import { useEffect } from 'react';
import { useState } from 'react';
import moment from 'moment';

export interface FormProps {
  formHandler: (value?: any) => void;
  filterFormValues?: any;
}
const lengthOptions = [
  { value: '20 minutes', label: '20 minutes' },
  { value: '40 minutes', label: '40 minutes' },
  { value: '25 minutes', label: '25 minutes' },
];
const lengthValues = [
  { value: '20', label: '20 minutes' },
  { value: '40', label: '40 minutes' },
  { value: '25', label: '25 minutes' },
];

const clients = [
  { value: 'client 1', label: 'client 1' },
  { value: 'client 2', label: 'client 2' },
  { value: 'client 3', label: 'client 3' },
];
const categories = [
  { value: 'Primary care', label: 'Primary care' },
  { value: 'Urgent care', label: 'Urgent care' },
  { value: 'Chronic care', label: 'Chronic care' },
];
const statesData = [
  { value: 'California', label: 'California' },
  { value: 'Florida', label: 'Florida' },
  { value: 'New York', label: 'New York' },
  { value: 'South Carolina', label: 'South Carolina' },
  { value: 'South Dakota', label: 'South Dakota' },
];

const statuses = [
  { value: 'active', label: 'Active' },
  { value: 'inactive', label: 'Inactive' },
];

const Form: React.FC<FormProps> = ({ formHandler, filterFormValues }) => {
  const [appointmentType, setAppointmentType] = useState('');
  const [length, setLength] = useState<{ label: string; value: string } | null>();
  const [uniqueId, setuniqueId] = useState('');
  const [client, setClients] = useState<{ label: string; value: string } | null>();
  const [category, setCategory] = useState<{ label: string; value: string } | null>();
  const [states, setStates] = useState<{ label: string; value: string } | null>();
  const [creationDate, setCreationDate] = useState('');
  const [status, setStatus] = useState<{ label: string; value: string } | null>();
  const [filterCount, setFilterCount] = useState(Number);

  useEffect(() => {
    console.log('filterForm values', filterFormValues);

    setStatus(statuses?.find((item) => item.value === filterFormValues.status));
    setCategory(categories?.find((item) => item.value === filterFormValues.category));
    setStates(statesData?.find((item) => item.value === filterFormValues.state));
    setClients(clients?.find((item) => item.value === filterFormValues.client));
    setCreationDate(filterFormValues.createdAt);
    setAppointmentType(filterFormValues.appointmentType);
    setuniqueId(filterFormValues.uniqueId);
    setLength(lengthValues?.find((item) => item.value == filterFormValues.length));
  }, [filterFormValues]);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const lengthValue = length?.value?.match(/\d+/g);
    const filterData: any = {
      ...(category?.value && { category: category?.value }),
      ...(appointmentType && { displayName: appointmentType }),
      ...(client?.value && { client: client?.value }),
      ...(status?.value && { status: status?.value }),
      ...(lengthValue && { length: parseInt(lengthValue[0]) }),
      ...(uniqueId && { uniqueId }),
      states: states ? states.value : null,
      createdAt: creationDate && moment(creationDate).format('YYYY-MM-DD'),
    };
    formHandler(filterData);
  };

  const handleClear = (e: any) => {
    e.preventDefault();
    formHandler({});
  };

  const checkFilterApplied = () => {
    let count = 0;
    if (appointmentType) {
      count += 1;
    }
    if (length) {
      count += 1;
    }
    if (uniqueId) {
      count += 1;
    }

    if (category) {
      count += 1;
    }
    if (creationDate) {
      count += 1;
    }
    if (status) {
      count += 1;
    }
    setFilterCount(count);
  };

  useEffect(() => {
    checkFilterApplied();
    console.log('status', appointmentType);
  }, [category, creationDate, uniqueId, length, status, appointmentType]);
  return (
    <form>
      <div className="flex flex-col lg:flex-row justify-center items-center">
        <div className="mr-20">
          <Input
            wrapperClasses="mb-4"
            labelText="Appointment Type"
            placeholder="Enter Appointment Type"
            inputClasses="w-52 pr-8"
            labelClasses="mr-10 text-xl"
            value={appointmentType}
            onChange={(e) => setAppointmentType(e.target.value)}
            onClickIconClear={() => setAppointmentType('')}
            closeIcon={true}
          />

          <Dropdown
            wrapperClasses="mb-4"
            labelText="length"
            placeholder="Enter Length"
            labelClasses="mr-10 text-xl"
            width="52"
            options={lengthOptions}
            value={length}
            onChange={(e) => setLength(e)}
            onClickIconClear={() => setLength(null)}
            closeIcon={true}
          />
          <Input
            wrapperClasses="mt-4"
            labelText="unique ID"
            placeholder="Enter unique ID…"
            inputClasses="w-52 pr-8"
            labelClasses="mr-10 text-xl"
            value={uniqueId}
            onChange={(e) => setuniqueId(e.target.value)}
            onClickIconClear={() => setuniqueId('')}
            closeIcon={true}
          />
        </div>
        <div>
          <div className="mb-4">
            <Dropdown
              labelText="Category"
              placeholder="Select Category"
              labelClasses="mr-10 text-xl"
              width="52"
              options={categories}
              value={category}
              onClickIconClear={() => setCategory(null)}
              onChange={(e: any) => setCategory(e)}
              closeIcon={true}
            />
          </div>

          <div className="mb-4">
            <Dropdown
              labelText="Status"
              placeholder="Status"
              labelClasses="mr-10 text-xl"
              width="52"
              value={status && { label: status.label, value: status.value }}
              options={statuses}
              onClickIconClear={() => setStatus(null)}
              closeIcon={true}
              onChange={(e: any) => setStatus(e)}
            />
          </div>
        </div>
      </div>
      <div className="flex items-center justify-end">
        <p>{filterCount} filters applied</p>
        <Button
          ownClasses="px-7 mx-2.5 font-semibold text-dashboardPrimaryGrey"
          type="depressed"
          onClick={(e) => handleClear(e)}
        >
          Clear all
        </Button>
        <Button ownClasses="px-7 font-semibold rounded-full" type="success" onClick={(e) => handleSubmit(e)}>
          Update
        </Button>
      </div>
    </form>
  );
};

export default Form;
