import { AxiosInstanceWebsite } from './AxiosInstance';
import config from '../config';

const { api } = config;

export default class AgingAlertsAPI {
  static fetchAgingAlerts(data: any) {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/aging-alerts`,
      method: 'GET',
      params: data,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  static fetchAgingAlertById(id: string) {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/aging-alerts/${id}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  static postAgingAlert(payload: any) {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/aging-alerts`,
      method: 'POST',
      data: payload,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }
  static editAgingAlertById(payload: any, id: string) {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/aging-alerts/${id}`,
      method: 'PUT',
      data: payload,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  static deleteAgingAlertById(id: string) {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/aging-alerts/${id}`,
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }
}
