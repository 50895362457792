import { StaffCostProps } from './interface';
import types from './types';

export const setStaffCostList = (data: StaffCostProps[]) => ({
  type: types.SET_STAFF_COST_LIST,
  payload: data,
});

export const setTotalStaffCost = (data: number) => ({
  type: types.SET_TOTAL_STAFF_COST,
  payload: data,
});
const actions = {
  setStaffCostList,
  setTotalStaffCost,
};

export default actions;
