import Header from '../components/Header';
import { Switch, Route, useRouteMatch, useHistory } from 'react-router-dom';
import SlimSidebar from '../components/SlimSidebar';
import classNames from 'classnames';
import Task from './Task';
import React, { useEffect, useState } from 'react';
import Patient from './Patient';
import Articles from './Article';
import RolesManagement from 'pages/Admin/RolesManagement';
import Cookies from 'js-cookie';

import Availability from 'components/Availability/Availability';
import PhysicianAppointment from './Appointment/Physician';
import { setProfileInformation } from '../redux/userProfile/actions';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { setLoggedIn } from 'redux/authentication/actions';
import { getUserDetails, hasAccessToModule } from 'auth/authorization';
import AdminNav from 'pages/Admin/AdminNavigation';
import RequestType from './RequestType';
import ModalPage from 'components/Modal/ModalPage';
import AppointmentTypes from './AppointmentTypes';
import MessageTemplate from './MessageTemplate';
import AdminAutomation from './AdminAutomation';
import socket, { socket_init } from 'socket/socket';
import SocketContext from 'socket/socketContext';
import { setChannelLists } from 'redux/channels/actions';
import StaffsManagement from './StaffsManagement';
import PatientSummary from 'pages/Admin/PatientSummary';

import { setuserTypeLists } from 'redux/lookUp/actions';
import AdminManageRoles from './AdminManageRoles';
import NotificationAPI from 'api/NotificationAPI';
import { setNotificationInformation } from 'redux/notification/actions';
import { setNotificationLists } from 'redux/channels copy/actions';
import LookUpAPI from 'api/LookUpsAPI';
import socketFrontDesk, { socket_init_frontDesk } from 'socket/socketFrontDesk';
import AgingAlerts from './AdminAgingAlerts/AgingAlerts';
import StaffCost from './StaffCost/StaffCost';
import CallNotificationCard from '../components/CallNotificationCard';
import { Transition } from '@tailwindui/react';
import Notification from 'components/PopupNotification/Notification';
import Item from 'pages/VideoCall/videoCall/SettingsPopup/Item';

const ContentComponent: React.FC = () => {
  const { path } = useRouteMatch();
  const history = useHistory();
  const [showNotification, setShowNotification] = useState(false);
  const [notificationContent, setNotificationContent] = useState({
    title: 'title',
    text: '',
    type: '',
    btnData: { label: '', referenceId: '', variable: '' },
    _id: '',
    showNotification: false,
  });
  const [notificationContentList, setNotificationContentList] = useState<any>([]);
  const [validatePermissions, setValidatePermissions] = useState(false);
  const [toggle, setToggle] = useState(false);
  const mainClasses = classNames('transition-all duration-500 flex-grow', toggle ? 'ml-24' : 'm-sb');
  const dispatch = useDispatch();
  const userInfo = useSelector((state: RootStateOrAny) => state.userProfile.userDetails);
  const unReadCount = useSelector((state: RootStateOrAny) => state.notificationCount.notificationCount);

  useEffect(() => {
    console.log('userInfo.userRoleInfo.pagePermissions', userInfo.userRoleInfo);
    if (userInfo.userRoleInfo && userInfo.userRoleInfo.pagePermissions.length !== 0) {
      setValidatePermissions(true);
    }
  }, [userInfo]);

  useEffect(() => {
    if (notificationContent && notificationContent._id !== '') {
      let data: any = [];
      if (notificationContentList.length !== 0) {
        data = [...new Set(notificationContentList)];
      }
      data.push(notificationContent);
      setNotificationContentList(data);
    }
  }, [notificationContent]);

  const deleteNotification = (id: any) => {
    let data: any = [];
    if (notificationContentList.length !== 0) {
      data = [...new Set(notificationContentList)];
    }
    for (let i = 0; i < data.length; i++) {
      if (data[i]._id === id) {
        data.splice(i, 1);
      }
    }
    setNotificationContentList(data);
  };

  const intiateSocket = () => {
    socket_init_frontDesk();
    socket_init();

    socket.on('newNotificationSend', (data) => {
      setNotificationContent({
        title: data.notificationTitle,
        text: data.notificationContent,
        type: data.notificationType,
        btnData: data.buttons[0],
        _id: data._id,
        showNotification: true,
      });
      setTimeout(() => {
        deleteNotification(data._id);
      }, 15000);
      console.log('========notification refreshed=========');
      fetchNotificationLists();
    });
  };

  const fetchLookUps = () => {
    LookUpAPI.fetchUserTypes().then((res) => {
      const { data } = res.data;
      const list = Object.values(data);
      dispatch(setuserTypeLists(list));
    });
  };

  const fetchNotificationLists = () => {
    NotificationAPI.fetchNotificationByLimit({ limit: 20, page: 0 }).then((res) => {
      const { data } = res.data;
      dispatch(setNotificationLists(data));
    });
  };

  const fetchCount = () => {
    NotificationAPI.fetchUnreadCount()
      .then((res) => {
        {
          res?.data?.data['unreadNotificationsCount'] >= 0 &&
            (console.log('after dismiss count is', res.data.data['unreadNotificationsCount']),
            dispatch(setNotificationInformation(res.data.data['unreadNotificationsCount'])));
        }
      })
      .catch((err) => {
        console.log(err?.data?.message);
      });
  };

  const afterDismissPopup = () => {
    fetchCount();
    fetchNotificationLists();
  };

  useEffect(() => {
    const userData: any = getUserDetails();
    dispatch(setProfileInformation(userData));
    dispatch(setLoggedIn(userData._id));
    fetchNotificationLists();
    afterDismissPopup();
    fetchLookUps();
    intiateSocket();
  }, []);

  return validatePermissions ? (
    <SocketContext.Provider value={socket}>
      <SocketContext.Provider value={socketFrontDesk}>
        <div className="relative min-h-screen w-full bg-gray-50">
          <div>
            <SlimSidebar toggle={toggle} setToggle={() => setToggle(!toggle)} />
          </div>
          <div className={mainClasses}>
            <Header fetchNotificationLists={fetchNotificationLists} />
            <div className="absolute flex flex-col-reverse right-8">
              {notificationContentList.map((eachItem: any) => {
                return (
                  <Transition
                    key={eachItem._id}
                    id={eachItem._id}
                    className="z-30 mb-5 w-screen-sm 2xl:w-screen-md"
                    show={eachItem.showNotification}
                    appear={true}
                    enter="transition ease-in-out duration-500 transform"
                    enterFrom="opacity-0 translate-x-full"
                    leave="transition ease-in-out duration-1000 transform"
                    leaveTo="opacity-0"
                  >
                    <Notification
                      afterDismiss={() => afterDismissPopup()}
                      onCancel={() => deleteNotification(eachItem._id)}
                      title={eachItem.title}
                      text={eachItem.text}
                      type={eachItem.type}
                      date="1 min ago"
                      btnData={eachItem.btnData}
                      notificationId={eachItem._id}
                    />
                  </Transition>
                );
              })}
            </div>
            <Switch>
              <Route path={`${path}/modal`}>
                <ModalPage />
              </Route>

              <Route path={`${path}/age-configuration`}>
                <AgingAlerts />
              </Route>

              {hasAccessToModule(userInfo.userRoleInfo.pagePermissions, [], userInfo.userType.name) && (
                <Route path={`${path}/admin`}>
                  <AdminNav />
                </Route>
              )}

              {hasAccessToModule(userInfo.userRoleInfo.pagePermissions, ['TASK_LIST_VIEW']) && (
                <Route path={`${path}/tasks`}>
                  <Task />
                </Route>
              )}

              {hasAccessToModule(userInfo.userRoleInfo.pagePermissions, ['APPOINTMENT_TYPE_LIST_VIEW']) && (
                <Route path={`${path}/appointment-types`}>
                  <AppointmentTypes />
                </Route>
              )}

              {hasAccessToModule(userInfo.userRoleInfo.pagePermissions, ['ARTICLES_LIST_VIEW']) && (
                <Route path={`${path}/articles`}>
                  <Articles />
                </Route>
              )}

              {userInfo.userType.name === 'Admin' &&
                hasAccessToModule(userInfo.userRoleInfo.pagePermissions, ['TEMPLATE_MESSAGE_LIST_VIEW']) && (
                  <Route path={`${path}/patient-message-templates`}>
                    <MessageTemplate type="Patient" />
                  </Route>
                )}
              {userInfo.userType.name === 'Admin' &&
                hasAccessToModule(userInfo.userRoleInfo.pagePermissions, ['TEMPLATE_MESSAGE_LIST_VIEW']) && (
                  <Route path={`${path}/request-message-templates`}>
                    <MessageTemplate type="Request" />
                  </Route>
                )}

              {userInfo.userType.name === 'Admin' &&
                hasAccessToModule(userInfo.userRoleInfo.pagePermissions, ['TEMPLATE_MESSAGE_LIST_VIEW']) && (
                  <Route path={`${path}/internal-note-templates`}>
                    <MessageTemplate type="Internal" />
                  </Route>
                )}
              {userInfo.userType.name === 'Admin' &&
                hasAccessToModule(userInfo.userRoleInfo.pagePermissions, ['FRONT_DESK_REQUEST_TYPE_LIST_VIEW']) && (
                  <Route path={`${path}/front-desk-request-type`}>
                    <RequestType type="front-desk" />
                  </Route>
                )}
              {userInfo.userType.name === 'Admin' &&
                hasAccessToModule(userInfo.userRoleInfo.pagePermissions, ['PHYSICIAN_REQUEST_TYPE_LIST_VIEW']) && (
                  <Route path={`${path}/physician-request-type`}>
                    <RequestType type="physician" />
                  </Route>
                )}

              {hasAccessToModule(userInfo.userRoleInfo.pagePermissions, ['PATIENT_LIST_VIEW']) && (
                <Route path={`${path}/patients`}>
                  <Patient />
                </Route>
              )}

              {hasAccessToModule(userInfo.userRoleInfo.pagePermissions, ['PATIENT_LIST_VIEW']) && (
                <Route path={`${path}/patient-details/:id`}>
                  <PatientSummary />
                </Route>
              )}

              {hasAccessToModule(userInfo.userRoleInfo.pagePermissions, ['STAFFS_LIST_VIEW']) && (
                <Route path={`${path}/manage-staff`}>
                  <StaffsManagement />
                </Route>
              )}

              {/* {hasAccessToModule(userInfo.userRoleInfo.pagePermissions, ['STAFFS_LIST_VIEW']) && ( */}
              <Route path={`${path}/staff-cost`}>
                <StaffCost />
              </Route>
              {/* )} */}

              {hasAccessToModule(userInfo.userRoleInfo.pagePermissions, ['USER_ROLES_LIST_VIEW']) && (
                <Route path={`${path}/manage-roles`}>
                  <AdminManageRoles />
                </Route>
              )}

              {hasAccessToModule(userInfo.userRoleInfo.pagePermissions, [
                'USER_ROLES_LIST_VIEW',
                'USER_ROLE_DETAILS_VIEW',
              ]) && (
                <Route path={`${path}/edit-manage-role/:id`}>
                  <RolesManagement />
                </Route>
              )}

              {hasAccessToModule(userInfo.userRoleInfo.pagePermissions, ['TRIGGER_LIST_VIEW']) && (
                <Route path={`${path}/internal-automations`}>
                  <AdminAutomation automationType="internal" />
                </Route>
              )}
              {hasAccessToModule(userInfo.userRoleInfo.pagePermissions, ['TRIGGER_LIST_VIEW']) && (
                <Route path={`${path}/patient-automations`}>
                  <AdminAutomation automationType="patient" />
                </Route>
              )}
              {/* @TODO : update route component render, when we'll have permission from the backend*/}
              {['Physician', 'Medical Assistant', 'Admin', 'Customer Service Rep'].includes(userInfo.userType.name) &&
                hasAccessToModule(userInfo.userRoleInfo.pagePermissions, ['APPOINTMENT_CALENDAR']) && (
                  <>
                    <Route path={`${path}/appointments`}>
                      <PhysicianAppointment />
                    </Route>
                    <Route path={`${path}/appointment-availablity/:id`}>
                      <Availability />
                    </Route>
                  </>
                )}
            </Switch>
          </div>
          <CallNotificationCard />
        </div>
      </SocketContext.Provider>
    </SocketContext.Provider>
  ) : (
    <></>
  );
};

export default ContentComponent;
