import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as Mic } from 'assets/icons/call/mic.svg';
import { ReactComponent as Call } from 'assets/icons/call/call.svg';
import { ReactComponent as Camera } from 'assets/icons/call/camera.svg';
import { ReactComponent as Blocked } from 'assets/icons/call/blocked.svg';
import { ReactComponent as Settings } from 'assets/icons/call/settings.svg';
import { ReactComponent as FullScreen } from 'assets/icons/call/fullScreen.svg';

import useOnClickOutside from '../../hooks/useOnClickOutside';
import { useToggle } from 'react-use';
import classNames from 'classnames';
import SettingsPopup from '../SettingsPopup';
import { ControlsProps } from './controls.types';
import { LocalAudioTrack, LocalVideoTrack } from 'twilio-video';
import Modal from '../../../../components/AdminManageStaffModals';
import { useHistory } from 'react-router-dom';

const defaultBtnStyle =
  'flex items-center justify-center rounded-full shadow-common bg-white text-textGreyedOut hover:bg-green hover:text-white relative m-1 w-12 h-12 sm:w-16 sm:h-16 sm:m-2';

const Controls: React.FC<ControlsProps> = ({
  room,
  handleLogout,
  handleFullScreen,
  audioTracks,
  videoTracks,
  changeVideoId,
  changeAudioId,
  showCallCard,
  callDetails,
}) => {
  const history = useHistory();
  const refSettings = useRef<HTMLDivElement>(null);
  const [isOpenSettings, setIsOpenSettings] = useToggle(false);
  const [videoEnabled, toggleVideoEnabled] = useToggle(true);
  const [audioEnabled, toggleAudioEnabled] = useToggle(true);
  const [showConfirm, setShowConfirm] = useToggle(false);
  /**
   * Close settings popup when patient click outside the settings popup
   */
  useOnClickOutside(refSettings, () => setIsOpenSettings(false));

  const startTrack = (track: LocalAudioTrack | LocalVideoTrack | null) => {
    track?.restart();
    track?.enable();
  };

  const stopTrack = (track: LocalAudioTrack | LocalVideoTrack | null) => {
    track?.disable();
    track?.stop();
  };
  const handleShowConfirm = () => {
    if (callDetails.category === 'Post-consult') {
      exitButNotEnd();
    } else {
      setShowConfirm();
    }
  };

  const exitButNotEnd = () => {
    history.push('/dashboard/tasks');
  };

  useEffect(() => {
    if (videoEnabled) {
      videoTracks.forEach(startTrack);
    } else {
      videoTracks.forEach(stopTrack);
    }
  }, [videoEnabled]);

  useEffect(() => {
    if (audioEnabled) {
      audioTracks.forEach(startTrack);
    } else {
      audioTracks.forEach(stopTrack);
    }
  }, [audioEnabled]);
  useEffect(() => {
    if (showCallCard) {
      audioEnabled && audioTracks.forEach(stopTrack);
      videoEnabled && videoTracks.forEach(stopTrack);
    } else {
      audioEnabled && audioTracks.forEach(startTrack);
      videoEnabled && videoTracks.forEach(startTrack);
    }
  }, [showCallCard]);

  return (
    <>
      <Modal showModal={showConfirm} setShowModal={setShowConfirm} type={true} isEndCallModal>
        <div className="p-8 flex flex-col items-center">
          <p className="text-darkBlue text-2xl">End this appointment?</p>
          <p className="text-lg font-normal">You won’t be able to come back in.</p>
          <div className="flex items-center gap-2 mt-4">
            <button
              onClick={handleLogout}
              className="text-sm bg-red text-white font-semibold rounded-lg whitespace-nowrap py-2 px-7"
            >
              End appointment
            </button>
            <button
              onClick={exitButNotEnd}
              className="text-sm bg-lightGreyBackground text-darkBlue font-semibold rounded-lg whitespace-nowrap py-2 px-7 "
            >
              Exit, but do not end
            </button>
          </div>
        </div>
      </Modal>
      <div
        className={classNames(
          'absolute inset-x-0 flex justify-center items-center gap-4',
          isOpenSettings ? 'z-30 h-full bottom-0 md:bottom-14 md:h-fit' : 'z-20 bottom-14',
          { hidden: !room || showCallCard },
        )}
      >
        <button type="button" className={defaultBtnStyle} onClick={handleFullScreen}>
          <FullScreen className="w-4 h-4 sm:w-6 sm:h-6" />
        </button>
        <button type="button" onClick={toggleAudioEnabled} className={defaultBtnStyle}>
          {!audioEnabled && <Blocked className="absolute w-6 h-6 sm:w-9 sm:h-9" />}
          <Mic className="w-4 h-4 sm:w-6 sm:h-6" />
        </button>
        <div className="relative flex justify-center">
          <button
            type="button"
            onClick={handleShowConfirm}
            className="flex items-center justify-center rounded-full shadow-common bg-red text-white w-16 h-16 sm:w-20 sm:h-20"
          >
            <Call className="w-8 h-8 sm:w-10 sm:h-10" />
          </button>
        </div>
        <button type="button" onClick={toggleVideoEnabled} className={defaultBtnStyle}>
          {!videoEnabled && <Blocked className="absolute w-6 h-6 sm:w-9 sm:h-9" />}
          <Camera className="w-4 h-4 sm:w-6 sm:h-6" />
        </button>
        <div className="md:relative flex justify-center" ref={refSettings}>
          {isOpenSettings && (
            <SettingsPopup
              videoTracks={videoTracks}
              audioTracks={audioTracks}
              changeVideoId={changeVideoId}
              changeAudioId={changeAudioId}
              videoEnabled={videoEnabled}
              audioEnabled={audioEnabled}
            />
          )}
          <button type="button" className={defaultBtnStyle} onClick={setIsOpenSettings}>
            <Settings className="w-4 h-4 sm:w-6 sm:h-6" />
          </button>
        </div>
      </div>
    </>
  );
};

export default Controls;
