import React from 'react';

import { ISeen } from './Seen.types';
import classNames from 'classnames';
import moment from 'moment';

const Seen = ({ self, author, seen, date }: ISeen) => {
  const getTime = (date: number) => {
    const timeZone = moment.tz.guess();
    const dateString = new Date(date).toLocaleString('en-US', { timeZone });
    const formattedString = moment(dateString).format('hh:mma');
    const abr = moment.tz(timeZone).format('z');
    return formattedString + ' ' + abr;
  };
  return (
    <div className={classNames('text-sm text-tableLightGrey mt-4')}>
      {self ? (seen ? 'Seen: ' : 'Sent: ') : author}
      <span className="pl-2 text-sm text-tableLightGrey">{seen ? getTime(seen) : getTime(date)}</span>
    </div>
  );
};

export default Seen;
