import ExpandableElement from '../ExpandableContent';
import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { ReactComponent as ArrowUpSvg } from '../../../assets/icons/arrow-up.svg';
import MessageCard, { IMessage } from 'components/Admin/Patient/Messages/MessageItem';
import avatar from 'assets/images/avatar.png';
import MessagePatientForm from './Messages/MessagePatientForm';
import StaffNoteForm from './StaffNote/StaffNoteForm';
import { useParams } from 'react-router';
import PatientAPI from 'api/PatientAPI';
import { dateTimeFormatter } from 'utils/dateTimeFormatter';
import InfiniteScroll from 'react-infinite-scroll-component';
import { timeInSecondsFromNow } from 'utils/timeInSecondsFromNow';
import { MessagesFromBackend } from './Messages/message.types';

export interface IThread {
  messages: IMessage[];
}
export interface StaffInterface {
  patientDetails: any;
}

const StaffNote: React.FC<StaffInterface> = ({ patientDetails }) => {
  const [staffNotesList, setStaffNoteList] = useState({ messages: [] } as IThread);
  const [totalStaffNotes, setTotalStaffNotes] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [loadStaffNotes, setLoadStaffNotes] = useState(true);
  const [scrollTo, setScrollTo] = useState(0);
  const [pageNo, setPageNo] = useState(0);

  const { id }: any = useParams();

  const messagesTopRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const noteContainerStyles = { height: '500px', maxHeight: '100%' };

  const scrollToTop = () => {
    setScrollTo(containerRef.current?.scrollTop ?? 0);
  };

  const fetchStaffNotes = (data: any, isRefreshData?: boolean) => {
    PatientAPI.fetchStaffNotes(data, id).then((res) => {
      const { data, info } = res.data;

      setTotalStaffNotes(info.totalCount);

      const messageData: IMessage[] = [];
      const messages = staffNotesList.messages;

      data.map((eachItem: any) => {
        messageData.push({
          id: eachItem._id,
          authorName: eachItem.author.displayName,
          authorRole: eachItem.author.userType.shortCode,
          messageDate: dateTimeFormatter(eachItem.createdAt),
          isNew: false,
          text: eachItem.note,
          avatarUrl: avatar,
          authorId: eachItem.author._id,
        });
      });

      const totalMessageList = isRefreshData ? messageData : messages.concat(messageData);

      setStaffNoteList({
        messages: totalMessageList,
      });
      console.log(info.totalCount, totalMessageList.length);
      if (totalMessageList.length === info.totalCount) {
        setLoadStaffNotes(false);
      } else {
        setLoadStaffNotes(true);
      }
    });
  };

  const loadMore = () => {
    const data = {
      limit: 10,
      pageNo: pageNo + 1,
    };
    if (
      staffNotesList.messages.length !== totalStaffNotes &&
      Math.ceil(staffNotesList.messages.length / data.limit) === pageNo + 1
    ) {
      const count: number = pageNo + 1;
      setPageNo(count);
      fetchStaffNotes(data);
      scrollToTop();
    }
  };

  useEffect(() => {
    setPageNo(0);
    setStaffNoteList({ messages: [] });
  }, []);

  useEffect(() => {
    if (totalStaffNotes === 0) {
      const data = {
        limit: 10 * (pageNo + 1),
        pageNo: 0,
      };
      fetchStaffNotes(data, true);
    }
  }, [totalStaffNotes]);

  const onSendStaffNote = () => {
    setPageNo(0);
    setTotalStaffNotes(0);
  };

  const Content: React.FC<IThread> = (props) => (
    <div className="px-20 lg:w-9/12">
      {props.messages.length ? (
        <div
          id="scrollStaffNotes"
          className="overflow-y-auto flex flex-col-reverse"
          style={noteContainerStyles}
          ref={containerRef}
        >
          <InfiniteScroll
            dataLength={totalStaffNotes}
            next={loadMore}
            hasMore={loadStaffNotes}
            inverse
            initialScrollY={scrollTo}
            loader={<div className="loader"> Loading... </div>}
            style={{ display: 'flex', flexDirection: 'column-reverse' }}
            scrollableTarget="scrollStaffNotes"
          >
            {props.messages
              .filter((m) => !m.isNew)
              .map((m) => (
                <MessageCard key={m.id} {...m} />
              ))}

            {props.messages
              .filter((m) => m.isNew)
              .map((m) => (
                <MessageCard key={m.id} {...m} />
              ))}
          </InfiniteScroll>
          <div ref={messagesTopRef} />
        </div>
      ) : (
        <div className="h-64 flex justify-center ">
          <p className="text-textGreyedOut text-xl px-20 py-6 mt-auto mb-auto">No messages yet</p>
        </div>
      )}
      <div className="mb-8">
        <StaffNoteForm
          allowToPickChannel
          id={id}
          onSendStaffNote={onSendStaffNote}
          patientId={patientDetails && patientDetails._id}
        />
      </div>
    </div>
  );

  return <Content {...staffNotesList} />;
};

export default StaffNote;
