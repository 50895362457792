import React, { ReactElement, useEffect, useState } from 'react';
import Dropdown from 'components/common/Dropdown';
import Input from 'components/common/Input';
import Button from 'components/common/Button';
import _ from 'lodash';

// interface Props {}

export function Form({
  data,
  roleOptions,
  payTypeOptions,
  handleFormSubmit,
  fetchStaffCost,
  setShowModal,
  isEdit,
  handleDelete,
  handleEditedFormSubmit,
}: any): ReactElement {
  const statusOptions = [
    { value: 'active', label: 'Active' },
    { value: 'inactive', label: 'Inactive' },
  ];
  const [staff, setStaff] = useState<{ label: string; value: string } | null>();
  const [paytype, setPayType] = useState<{ label: string; value: string } | null>();
  const [status, setStatus] = useState<{ label: string; value: string } | null>();
  const [payAmount, setPayAmount] = useState<any | null>();
  const [id, setId] = useState<any | null>();

  const userTypeData = [
    { value: 'Nurse Practitioner', label: 'Nurse Practitioner' },
    { value: 'Physician', label: 'physician' },
    { value: 'Medical Assistant', label: 'Medical Assistant' },
    { value: 'Admin', label: 'Admin' },
    { value: 'Customer Service Rep', label: 'Customer Service Rep' },
  ];

  useEffect(() => {
    if (!_.isEmpty(data)) {
      setPayType(payTypeOptions?.find((item: any) => item.value === data.payType));
      setStaff(userTypeData.find((item) => item.value === data.staff));
      setStatus(statusOptions.find((item) => item.value === data.status));
      setPayAmount(data.payAmount);
      setId(data._id);
    }
  }, [data]);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const data = {
      staff: staff?.value,
      payType: paytype?.value,
      payAmount: payAmount,
      id: id,
      status: status?.value,
    };
    isEdit ? handleEditedFormSubmit?.(data, id) : handleFormSubmit?.(data);
  };

  return (
    <>
      <form className="mt-10" onSubmit={handleSubmit}>
        <div className="flex justify-between items-center">
          <div className="w-3/5">
            <Dropdown
              labelText="Staff"
              placeholderText="Select Staff"
              labelClasses="mr-28 text-medium whitespace-nowrap	"
              options={userTypeData}
              onChange={(e) => setStaff(e)}
              value={staff}
            />

            <Dropdown
              labelText="Pay type"
              placeholderText="Select Pay type"
              labelClasses="mr-20 text-medium whitespace-nowrap	"
              options={payTypeOptions}
              onChange={(e) => setPayType(e)}
              value={paytype}
            />
            <Input
              labelText="Pay amount($)"
              placeholder="Select Pay amount"
              labelClasses=" mr-10 text-medium whitespace-nowrap	"
              inputClasses="w-full	"
              wrapperClasses="mb-4"
              onChange={(e: any) => setPayAmount(e.target.value)}
              name="payAmount"
              value={payAmount}
            />
            <Dropdown
              labelText="Status"
              placeholderText="Select Status"
              labelClasses=" mr-24 text-medium whitespace-nowrap"
              options={statusOptions}
              onChange={(e) => setStatus(e)}
              value={status}
            />
          </div>
        </div>
        <div className="flex items-center justify-between mt-4 pb-14">
          {isEdit ? (
            <>
              <Button ownClasses="rounded-full p-0 font-medium" type="depressed" onClick={() => handleDelete(id)}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="red"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
                Delete configuration
              </Button>
              <Button ownClasses="rounded-full " type="success" formType>
                Save
              </Button>
            </>
          ) : (
            <Button ownClasses="rounded-full absolute right-0 bottom-0 " type="success" formType>
              Save
            </Button>
          )}
        </div>
      </form>
    </>
  );
}
export default Form;
