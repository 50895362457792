import { ReactComponent as Email } from 'assets/icons/patientInfo/email.svg';
import { ReactComponent as Emr } from 'assets/icons/patientInfo/emr.svg';
import { ReactComponent as Chat } from 'assets/icons/patientInfo/chat.svg';
import { ReactComponent as Sms } from 'assets/icons/patientInfo/sms.svg';
import Button from 'components/Admin/Patient/PatientInfo/Button';
import { ViewAsEmr } from 'utils/ViewAsEmr';
import { createRef, useEffect, useState } from 'react';
import { ContactButtonsProps } from './contactButtons.types';
import classNames from 'classnames';
import PhoneCall from '../PhoneCall';
import VoiceCallAPI from 'api/VoiceCallAPI';
import Modal from 'components/PatientMessagePopup';
import SMSModal from '../SMSModal';

const ContactButtons: React.FC<ContactButtonsProps> = ({
  patientDetails,
  RouteViewButtonText,
  onClickRouteViewButton,
  RouteViewImagineSVG,
  linkClasses,
}) => {
  const emailRef = createRef<HTMLAnchorElement>();

  const [token, setToken] = useState<string | null>(null);
  const [showModal, setShowModal] = useState<boolean>(false);

  const handleOnClickEmail = () => {
    emailRef.current?.click();
  };

  const handleViewEMR = () => {
    ViewAsEmr(patientDetails._id);
  };

  const link = classNames('flex gap-2 items-center justify-center underline', linkClasses);

  const getCallToken = () => {
    VoiceCallAPI.fetchCallToken(patientDetails._id).then((res) => {
      const { data } = res.data;
      if (data) {
        setToken(data.token);
      }
    });
  };

  useEffect(() => {
    if (patientDetails._id) {
      getCallToken();
    }
  }, [patientDetails._id]);

  return (
    <div className="flex items-center gap-4">
      {showModal && (
        <Modal showModal={showModal} setShowModal={setShowModal}>
          <SMSModal patientDetails={patientDetails} />
        </Modal>
      )}
      <button onClick={onClickRouteViewButton} className={link}>
        {RouteViewImagineSVG}
        {RouteViewButtonText}
      </button>
      <button onClick={handleViewEMR} className={link}>
        <Emr />
        View EMR
      </button>
      <Button tooltip="Message patient" onClick={() => setShowModal(true)}>
        <Chat />
      </Button>
      <Button tooltip="Email patient" onClick={handleOnClickEmail}>
        <Email />
        <a ref={emailRef} href={`mailto:${patientDetails.email}`} />
      </Button>
      {/*<Button tooltip="Sms patient">*/}
      {/*  <Sms />*/}
      {/*</Button>*/}
      {patientDetails.phone && token && <PhoneCall token={token} patientId={patientDetails._id} />}
    </div>
  );
};

export default ContactButtons;
