import { useEffect, useState, useCallback, useRef, useLayoutEffect, KeyboardEventHandler } from 'react';
import { useClickAway } from 'react-use';
import Button from 'components/common/Button';
import { ReactComponent as Lighting } from 'assets/icons/lighting.svg';
import PatientAPI from 'api/PatientAPI';
import MessageTemplateAPI from 'api/MessageTemplateAPI';
import InfiniteScroll from 'react-infinite-scroll-component';
import { debounce } from 'lodash';
import { toast } from 'react-toastify';
import { useSelector, RootStateOrAny } from 'react-redux';

interface IFormProps {
  allowToPickChannel?: boolean;
  id: string;
  onSendStaffNote: () => void;
  patientId: string;
}
type IOBJ = {
  label: string;
  value: string;
};
const StaffNoteForm: React.FC<IFormProps> = ({ allowToPickChannel, id, onSendStaffNote, patientId }) => {
  const templatesRef = useRef(null);

  const [showTemplates, setShowTemplates] = useState(false);
  const [staffNote, setStaffNote] = useState('');
  const [templateList, setTemplateList] = useState([] as any);
  const [loadMoreTemplates, setLoadMoreTemplates] = useState(true);
  const [templateTotalCount, setTemplateTotalCount] = useState(0);
  const [templatePage, setTemplatePage] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [messageButton, setMessageButton] = useState<any>();
  const userInfo = useSelector((state: RootStateOrAny) => state.userProfile.userDetails);

  const replayTemplatesButton = 'replayTemplatesButton';

  useClickAway(templatesRef, (e: Event) => {
    if ((e.target as HTMLButtonElement).id !== replayTemplatesButton) {
      setShowTemplates(false);
    }
  });

  const getRequestMessageTemplates = (payload: any) => {
    MessageTemplateAPI.fetchInternalNoteTemplates(payload).then((res) => {
      const { data } = res.data;

      if (data.length !== 0) {
        const list: any = showTemplates ? [...templateList] : [];
        data.map((eachItem: any) => {
          list.push({ text: eachItem.message, shortcode: eachItem.shortCode, id: eachItem._id });
        });
        setTemplateList(list);
        setTemplateTotalCount(res.data.info.totalCount);
        if (list.length === res.data.info.totalCount) {
          setLoadMoreTemplates(false);
        } else {
          setLoadMoreTemplates(true);
        }
      } else {
        setTemplateTotalCount(res.data.info.totalCount);
        setTemplateList([]);
        setLoadMoreTemplates(false);
      }
    });
  };

  const onClickReplayTemplates = () => {
    setTemplateList([]);
    if (!showTemplates) {
      const data = {
        limit: 10,
        pageNo: 0,
        status: 'active',
        searchKey: searchValue,
      };
      setTemplatePage(0);
      getRequestMessageTemplates(data);
    } else {
      setTemplateList([]);
      setTemplateTotalCount(0);
      setTemplatePage(0);
    }
    setShowTemplates(!showTemplates);
  };

  const submitStaffNotes = () => {
    setDisabled(true);

    if (staffNote) {
      const data = {
        note: staffNote,
      };

      PatientAPI.AddStaffNotes(data, id)
        .then((res) => {
          onSendStaffNote();
        })
        .catch((e) => {
          console.log(e.response.data);
        });
    }
  };

  const onTemplateListClick = (text: string, id: string) => {
    setShowTemplates(false);
    const params = {
      type: 'Internal Note',
      patientId: patientId,
      doctorId: userInfo._id,
    };
    MessageTemplateAPI.fetchMessage(id, params)
      .then((res) => {
        const { data } = res.data;
        setStaffNote(data.message);
        setDisabled(!data.message);
        setMessageButton(data.buttons);
      })
      .catch((e) => {
        toast.error(e.response.data.message);
      });
  };

  const loadMore = () => {
    const data = {
      limit: 10,
      pageNo: templatePage + 1,
      status: 'active',
      searchKey: searchValue,
    };
    const count: number = templatePage + 1;
    setTemplatePage(count);
    getRequestMessageTemplates(data);
  };

  const onSearchTemplate = (value: string) => {
    setTemplatePage(0);
    const data = {
      limit: 10,
      pageNo: 0,
      status: 'active',
      searchKey: value,
    };
    setTemplateList([]);
    getRequestMessageTemplates(data);
  };

  const debounceSearch = useCallback(debounce(onSearchTemplate, 1500), []);
  useEffect(() => {
    if (searchValue) {
      debounceSearch(searchValue);
    }
  }, [searchValue]);

  const handleKeyDownStaffNote: KeyboardEventHandler<HTMLTextAreaElement> = (e) => {
    if (e.key === '/') {
      setShowTemplates(true);
    }
  };

  const handleChangeStaffNotes: React.ChangeEventHandler<HTMLTextAreaElement> = (e) => {
    const newVal = e.target.value;
    setDisabled(!newVal);
    setStaffNote(newVal);
  };

  return (
    <>
      <div className="relative">
        <div className="flex flex-col">
          <textarea
            className="border-gray-200 border rounded-t-xl px-4 py-2 w-full h-20"
            placeholder="Add notes..."
            value={staffNote}
            onChange={handleChangeStaffNotes}
            onKeyDown={handleKeyDownStaffNote}
          ></textarea>
          <div className="border-gray-200 border border-t-0 rounded-b-xl flex flex-col sm:flex-row justify-between p-1">
            <Button
              onClick={onClickReplayTemplates}
              ownClasses="font-normal text-myPlanLightGrey"
              type="depressed"
              id={replayTemplatesButton}
            >
              <Lighting className="w-4 mr-2 mt-1 cursor-pointer" />
              Reply templates
            </Button>

            <Button ownClasses="mr-2" type="success" onClick={() => submitStaffNotes()} disabled={disabled}>
              Add Notes
            </Button>
          </div>
        </div>
        {showTemplates && (
          <div
            className="absolute bg-lightGrey top-0  right-0 w-full h-60 transform -translate-y-2/3 rounded-lg rounded-b-none p-4 shadow-lg	"
            ref={templatesRef}
          >
            <input
              placeholder="Search templates"
              className="search-input"
              type="search"
              onChange={(e) => setSearchValue(e.target.value)}
              autoFocus
            />
            <div className="flex justify-between my-2">
              <h4 className="font-bold text-sm text-dashboardPrimaryGrey">Template</h4>
              <h4 className="font-bold text-sm text-dashboardPrimaryGrey">Shortcode</h4>
            </div>
            <div className="h-36 overflow-y-auto" id="scrollTemplate">
              <InfiniteScroll
                dataLength={templateTotalCount}
                next={loadMore}
                hasMore={loadMoreTemplates}
                loader={<div className="loader"> Loading... </div>}
                scrollableTarget="scrollTemplate"
              >
                {templateList.map((template: any) => (
                  <div
                    key={template.shortcode}
                    className="flex justify-between mb-2"
                    onClick={() => onTemplateListClick(template.text, template.id)}
                  >
                    <span className="truncate cursor-pointer w-5/6 text-sm text-dashboardPrimaryGrey">
                      {template.text}
                    </span>
                    <span className="text-sm text-darkBlue font-bold">{template.shortcode}</span>
                  </div>
                ))}
              </InfiniteScroll>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default StaffNoteForm;
