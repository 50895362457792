import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import NotificationsList from '../HeaderNotifications/List';
import CircleAvatar from '../common/CircleAvatar/CircleAvatar';
import { ChevronUpIcon } from '@heroicons/react/outline';
import { StarIcon } from '@heroicons/react/solid';
import { ReactComponent as Bell } from '../../assets/icons/bell-gray.svg';
import { Transition } from '@tailwindui/react';
import AuthAPI from 'api/AuthAPI';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';
import { socket_close } from 'socket/socket';
import { useSelector, RootStateOrAny, useDispatch } from 'react-redux';
import Modal from 'components/AdminManageStaffModals';
import Form from 'components/userProfile/Form';
import ProfileAPI from 'api/profileApi';
import { socket_close_frontDesk } from 'socket/socketFrontDesk';
import { toast } from 'react-toastify';
import avatar from 'assets/images/avatar.png';
import NotificationAPI from 'api/NotificationAPI';
import { setNotificationInformation } from 'redux/notification/actions';
import socket from 'socket/socket';
import defaultpic from 'assets/images/profile-generic.png';
import { setProfileInformation } from '../../redux/userProfile/actions';
import Button from 'components/common/Button';
import { useFlag } from '@unleash/proxy-client-react';

export interface HeaderProps {
  fetchNotificationLists: () => void;
}

const Header: React.FC<HeaderProps> = ({ fetchNotificationLists }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const userName = useSelector(
    (state: RootStateOrAny) => state.userProfile.userDetails.firstName + ' ' + state.userProfile.userDetails.lastName,
  );
  const userInfo = useSelector((state: RootStateOrAny) => state.userProfile.userDetails);
  const userRole = useSelector((state: RootStateOrAny) => state.userProfile.userDetails.userType.name);
  const notificationList = useSelector((state: RootStateOrAny) => state.notifications.notificationList);
  const userProfileImage2 = useSelector((state: RootStateOrAny) => state.userProfile.userDetails.profileImage);
  const [showNotifications, setShowNotifications] = useState(false);
  const [expandMenu, setExpandMenu] = useState(false);
  const chevronclasses = classNames('w-6', { transform: !expandMenu, 'rotate-180': !expandMenu });
  const accountClasses = classNames(
    expandMenu ? 'translate-y-0 opacity-100 visible' : '-translate-y-20 opacity-0 invisible',
    'text-sm 2xl:text-base transform transition-gpu transition-all ease flex z-10 bg-white max-w-xs w-full rounded-lg flex-col shadow-full absolute  right-14 md:right-auto top-16 border-none',
  );
  const isEnabled = useFlag('switch-portal-btn');
  const [userProfileImage, setUserProfileImage] = useState();
  const [showModal, setShowModal] = useState(false);
  const [staffImage, setStaffImage] = useState<any>(null);
  const [isEdit, setIsEdit] = useState(false);
  const [profileData, setProfileData] = useState<any>();
  const uploadedImage = React.useRef(null);
  const unReadCount = useSelector((state: RootStateOrAny) => state.notificationCount.notificationCount);

  const handleClick = () => {
    window.open(process.env.REACT_APP_NEW_PHYSICIAN_URL, '_self');
  };

  const logOutHandler = () => {
    AuthAPI.logout()
      .then(() => {
        toast.success('Logout Successfully');
        window.localStorage.removeItem('accessToken');
        window.localStorage.removeItem('refreshToken');
        Cookies.remove('newTaskId');
        history.push('/');
        socket_close();
        socket_close_frontDesk();
      })
      .catch((e) => {
        toast.error(e.response.data.message);
      });
  };
  useEffect(() => {
    console.log('user profile image', userProfileImage);
    fetchProfileData();
  }, []);
  useEffect(() => {
    console.log('user profile info ccc', userInfo);
    dispatch(setProfileInformation(userInfo));
  }, [userInfo]);

  useEffect(() => {
    fetchNotificationCount();
    socket.on('newNotificationSend', (data) => {
      console.log('========notification refreshed=========');
      console.log('data on socket', data);
      fetchNotificationCount();
    });
  }, []);

  const fetchNotificationCount = () => {
    NotificationAPI.fetchUnreadCount()
      .then((res) => {
        {
          res?.data?.data['unreadNotificationsCount'] >= 0 &&
            (console.log(res.data.data['unreadNotificationsCount']),
            dispatch(setNotificationInformation(res.data.data['unreadNotificationsCount'])));
        }
      })
      .catch((err) => {
        console.log(err.data?.message);
      });
  };

  const fetchProfileData = () => {
    console.log('fetch profile called');
    ProfileAPI.fetchProfile().then((res) => {
      const newData = { ...userInfo, timezone: res?.data?.data[0]?.timezone };
      dispatch(setProfileInformation(newData));
      setProfileData(res?.data?.data);
      setUserProfileImage(res?.data?.data[0]?.profileImage);
    });
  };
  const onImageChange = (event: any) => {
    if (event.target.files && event.target.files[0]) {
      const img = event.target.files[0];
      setStaffImage(img);
    }
  };
  const handleImageUpload = (e: any) => {
    onImageChange(e);
    const [file] = e?.target?.files;
    if (file) {
      const reader = new FileReader();
      const { current }: any = uploadedImage;
      current.file = file;
      reader.onload = (e) => {
        current.src = e?.target?.result;
      };
      reader.readAsDataURL(file);
    }
  };

  const markSeenCount = () => {
    NotificationAPI.markSeenNotificationCount().then((res) => {
      console.log('inside markseen');
      if (res.data.errorCode === 0) {
        dispatch(setNotificationInformation(0));
      }
    });
  };

  return (
    <>
      {showModal && (
        <div>
          <Modal showModal={showModal} setShowModal={setShowModal} type={true}>
            <div className="sm:min-modal-size lg:max-modal-size">
              <div className="flex  relative px-12 pt-16">
                <CircleAvatar
                  ownClasses="w-16 h-16"
                  src={
                    profileData?.[0]?.profileImage
                      ? profileData?.[0]?.profileImage
                      : profileData?.[0]?.profileImage
                      ? profileData?.[0]?.profileImage
                      : defaultpic
                  }
                  onImageChange={onImageChange}
                  uploadedImage={uploadedImage}
                  handleImageUpload={handleImageUpload}
                  type={true}
                />
                <div className="ml-5">
                  {profileData?.[0] && (
                    <>
                      <p className="text-3xl font-semibold">
                        {profileData?.[0]?.prefix ? profileData?.[0]?.prefix + ' ' : ''}
                        {profileData?.[0]?.firstName + ' ' + profileData?.[0]?.lastName}
                      </p>
                      <p className="text-xl text-textGreyedOut">{profileData?.[0]?.userType?.name + ' '}</p>
                    </>
                  )}
                </div>
                {profileData?.[0]?.rating && (
                  <div className="flex justify-center items-center bg-green h-7 ml-4 text-white rounded p-4">
                    <>
                      <span className="text-white">{profileData?.[0]?.rating}</span>
                      <StarIcon className="h-4 w-4 ml-1" />
                    </>
                  </div>
                )}
                <p className="text-sm text-textGreyedOut absolute right-0 top-4  px-12 pt-16">
                  ID:{profileData?.[0]?.userId}
                </p>
              </div>
              <div className="px-12 pb-16 adminstaffmodals">
                <div className="relative  flex-auto">
                  <Form
                    data={profileData}
                    isEdit={isEdit}
                    setShowModal={setShowModal}
                    userImage={staffImage}
                    fetchProfileData={fetchProfileData}
                  />
                </div>
              </div>
            </div>
          </Modal>
        </div>
      )}
      <header className="flex justify-around md:justify-end items-center mx-12 my-4">
        {isEnabled && (
          <>
            {' '}
            <p className="text-gray70 mr-4">We’ve updated the physician portal for better reliability and speed.</p>
            <Button type="primary" onClick={() => handleClick()}>
              Use updated design
            </Button>
          </>
        )}
        <div className="relative">
          <Bell
            onClick={() => {
              setShowNotifications(!showNotifications);
              markSeenCount();
            }}
            className="mx-6 w-6 cursor-pointer relative text-gray-400"
          />
          {unReadCount === 0 || unReadCount === null ? null : (
            <p className="absolute text-center w-4 h-4 left-10 top-0 bg-red text-white text-half font-bold rounded-full">
              {unReadCount}
            </p>
          )}
          <Transition
            show={showNotifications}
            enter="transition-all duration-300"
            enterFrom="opacity-0"
            leave="transition-all duration-300"
            leaveTo="opacity-0"
          >
            <NotificationsList
              fetchNotificationLists={fetchNotificationLists}
              setShowNotifications={setShowNotifications}
            />
          </Transition>
        </div>
        <div
          tabIndex={0}
          className="relative transition duration-500 ease-in-out flex cursor-pointer items-center boder-none focus:ring-0"
          onClick={() => setExpandMenu(!expandMenu)}
          onBlur={() => setExpandMenu(false)}
        >
          <CircleAvatar src={userProfileImage ? userProfileImage : userProfileImage2} ownClasses="w-12 h-12" />
          <span className="mx-4">
            {profileData &&
              (profileData?.[0]?.prefix ?? '') + ' ' + profileData?.[0]?.firstName + ' ' + profileData?.[0]?.lastName}
          </span>
          <ChevronUpIcon className={chevronclasses} />
          <div className={accountClasses} onClick={() => setExpandMenu(true)}>
            <span
              className="cursor-pointer border-b-2 text-darkBlue py-2 px-8"
              onClick={() => {
                fetchProfileData();
                setIsEdit(true);
                setShowModal(true);
              }}
            >
              My Account
            </span>
            <span className="cursor-pointer text-darkBlue py-2 px-8" onClick={logOutHandler}>
              Logout
            </span>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
