import Select from 'react-select';
import Label from '../Label';
import { Indicator } from '../Dropdown/Dropdown';

interface IMultiSelect {
  options: { value: string; label: string }[] | undefined;
  labelText?: string;
  inputClasses?: string;
  labelClasses?: string;
  ownClasses?: string;
  value?: any;
  placeholder?: string;
  handleChange?: (value: any) => any;
}

const MultiSelect = ({
  options,
  value,
  labelText,
  inputClasses,
  labelClasses,
  handleChange,
  ownClasses = 'w-full',
  placeholder = '',
}: IMultiSelect) => {
  return (
    <div className="mb-4 flex items-center">
      {labelText && <Label labelText={labelText} labelClasses={labelClasses} />}
      <Select
        placeholder={placeholder}
        className={ownClasses}
        value={value}
        options={options}
        isMulti
        onChange={handleChange}
        components={{
          DropdownIndicator: Indicator,
        }}
        styles={{
          indicatorSeparator: (base) => ({
            ...base,
            display: 'none',
          }),
          control: (base) => ({
            ...base,
            backgroundColor: '#fafbfc',
          }),
          dropdownIndicator: (base) => ({
            ...base,
            color: '#00698B',
            padding: '1rem',
          }),
          multiValue: (base) => ({
            ...base,
            backgroundColor: '#e0f4f4',
            color: '#00698B',
          }),
          multiValueLabel: (base) => ({
            ...base,
            color: '#00698B',
          }),
          placeholder: (base) => ({
            ...base,
            paddingLeft: '1rem',
          }),
        }}
      />
    </div>
  );
};

export default MultiSelect;
