import types from './types';

export const setNotificationLists = (data: any) => ({
  type: types.SET_NOTIFICATION_LIST,
  payload: data,
});

const actions = {
  setNotificationLists,
};

export default actions;
