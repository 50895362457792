import { useState } from 'react';
import LoadingOverlay from 'react-loading-overlay-ts';
import AuthAPI from 'api/AuthAPI';
import { toast } from 'react-toastify';

const ForgotPassword: React.FC = () => {
  const [showCheckYourEmail, setShowCheckYourEmail] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const [loading, setLoading] = useState(false);

  const onFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = {
      email: userEmail,
    };
    setLoading(true);
    AuthAPI.forgotPassword(data)
      .then((res) => {
        if (res.data.errorCode === 0) {
          toast.success(res.data.message);
          setShowCheckYourEmail(true);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((error) => {
        const { response } = error;
        if (response.data.message) {
          toast.error(response.data.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="mx-auto w-2/5 px-2.5 py-44">
      {showCheckYourEmail ? (
        <div className="flex flex-col items-center text-center">
          <h1 className="my-2 font-bold text-3xl">Check your email</h1>
          <p className="my-2 text-xl">We’ve sent password recovery instructions to your email.</p>
          <p className="my-2 text-textGreyedOut">
            Didn’t receive the email? Check your spam filter, or
            <button type="button" className="underline ml-1" onClick={() => setShowCheckYourEmail(false)}>
              try another email address.
            </button>
          </p>
        </div>
      ) : (
        <LoadingOverlay active={loading} spinner>
          <form onSubmit={onFormSubmit} className="flex flex-col text-center">
            <h1 className="font-bold text-3xl">Reset password</h1>
            <p className="mt-4 mb-5 text-xl">
              Enter the email address associated with your account and we’ll send an email with instructions to reset
              your password.
            </p>
            <div className="my-10">
              <input
                id="email-address"
                name="email"
                type="email"
                autoComplete="email"
                required
                className="rounded relative block w-full px-3 py-3 mb-6 border border-gray-300 placeholder-gray-500 text-gray-900 text-sm"
                placeholder="Email address"
                onChange={(e) => setUserEmail(e.target.value)}
              />
            </div>
            <button
              type="submit"
              className="group text-xl relative w-full flex justify-center py-2 px-4 border border-transparent font-medium rounded-md text-white btn-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Send instructions
            </button>
          </form>
        </LoadingOverlay>
      )}
    </div>
  );
};

export default ForgotPassword;
