import classNames from 'classnames';
import { ButtonProps } from './button.types';

const Button: React.FC<ButtonProps> = ({ children, onClick, tooltip, bgClasses }) => {
  const classes = classNames(
    'w-9 h-9 rounded-xl flex items-center justify-center group relative',
    bgClasses || 'bg-patientInfoButton text-darkBlue hover:bg-darkBlue hover:text-white',
  );
  return (
    <button onClick={onClick} className={classes}>
      <p className="opacity-0 group-hover:opacity-100 min-w-max absolute -top-12 bg-white rounded-full p-2 px-5 shadow-common text-sm pointer-events-none">
        <span className="z-10 relative">{tooltip}</span>
        <i className="absolute -bottom-1 inset-x-0 m-auto w-4 h-4 bg-white transform rotate-45" />
      </p>
      {children}
    </button>
  );
};

export default Button;
