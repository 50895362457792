import actions from './actions';
import reducer from './reducer';

const articles = {
  actions,
  reducer,
  name: 'articles',
};

export default articles;
