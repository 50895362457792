import types from './types';

export const setFrontDeskRequestTypeLists = (data: any) => ({
  type: types.SET_FRONT_DESK_REQUEST_TYPE_LIST,
  payload: data,
});

export const setTotalFrontDeskRequestType = (data: number) => ({
  type: types.SET_TOTAL_FRONT_DESK_REQUEST_TYPE,
  payload: data,
});

export const setPhysicianRequestTypeLists = (data: any) => ({
  type: types.SET_PHYSICIAN_REQUEST_TYPE_LIST,
  payload: data,
});

export const setTotalPhysicianRequestType = (data: number) => ({
  type: types.SET_TOTAL_PHYSICIAN_REQUEST_TYPE,
  payload: data,
});
const actions = {
  setFrontDeskRequestTypeLists,
  setPhysicianRequestTypeLists,
  setTotalFrontDeskRequestType,
  setTotalPhysicianRequestType,
};

export default actions;
