import types from './types';
import { LooKUpDataActionType } from './interfaces';

const initState: any = {
  userTypeList: null,
};

const reducer = (state: any = initState, action: LooKUpDataActionType): any => {
  switch (action.type) {
    case types.SET_LOOK_UP_USER_TYPES_LIST:
      return {
        ...state,
        userTypeList: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
