import moment from 'moment';
const CanceAppointmentModal: React.FC<any> = ({
  showModal,
  setShowModal,
  onCancelAppointment,
  appointmentInformation,
  patientName,
}) => {
  return (
    <>
      {showModal ? (
        <>
          <div
            className="fixed z-10 inset-0 overflow-y-auto"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
          >
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

              <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                &#8203;
              </span>

              <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <svg
                        className="h-8 w-8 text-red "
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                        />
                      </svg>
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <h3 className="text-lg leading-6 text-gray-900 font-bold" id="modal-title">
                        Cancel appointment?
                      </h3>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          You are about to cancel this appointment with{' '}
                          <b className="font-extrabold">{patientName && patientName}</b> scheduled on{' '}
                          <b className="font-extrabold">
                            {moment(appointmentInformation?.appointmentTime).format('MMM Do') +
                              ' • ' +
                              moment(appointmentInformation.appointmentTime.startTime).format('LT')}
                          </b>
                          .This action cannot be undone.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse justify-evenly">
                  <button
                    type="button"
                    className=" px-4 py-2 w-full inline-flex text-darkBlue font-bold	justify-center rounded-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm bg-gray-200"
                    onClick={() => setShowModal(false)}
                  >
                    Keep appointment
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full bg-red text-white font-bold inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2   focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => {
                      onCancelAppointment();
                    }}
                  >
                    Cancel appointment
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default CanceAppointmentModal;
