import React from 'react';
import { ReactComponent as SubmitSvg } from '../../../assets/icons/chat/submit.svg';

export interface Props {
  sendDisabled: boolean;
  onClose: (e: any) => any;
}

const ChatSubmit: React.FC<Props> = ({ sendDisabled, onClose }) => {
  return (
    <button
      className="bg-green p-2 text-white rounded flex justify-center items-center border-none cursor-pointer"
      disabled={sendDisabled}
      type="submit"
      onClick={(e: any) => onClose(e)}
    >
      <SubmitSvg className={'w-4 h-4'} />
    </button>
  );
};

export default ChatSubmit;
