import types from './types';

export const setLoggedIn = (data: string) => ({
  type: types.SET_USER_LOGGED_IN,
  payload: data,
});
const setLoggedOut = () => ({
  type: types.SET_USER_LOGGED_IN,
});

const actions = {
  setLoggedIn,
  setLoggedOut,
};

export default actions;
