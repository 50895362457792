import types from './types';
import { RequestTypeListDataActionType, RequestTypeDetails } from './interfaces';

const initState: RequestTypeDetails = {
  frontDeskRequestTypeList: null,
  frontDeskRequestTypeTotalCount: 0,
  physicianRequestTypeList: null,
  physicianRequestTypeTotalCount: 0,
};

const reducer = (state: RequestTypeDetails = initState, action: RequestTypeListDataActionType): RequestTypeDetails => {
  switch (action.type) {
    case types.SET_FRONT_DESK_REQUEST_TYPE_LIST:
      return {
        ...state,
        frontDeskRequestTypeList: action.payload,
      };

    case types.SET_TOTAL_FRONT_DESK_REQUEST_TYPE:
      return {
        ...state,
        frontDeskRequestTypeTotalCount: action.payload,
      };

    case types.SET_PHYSICIAN_REQUEST_TYPE_LIST:
      return {
        ...state,
        physicianRequestTypeList: action.payload,
      };

    case types.SET_TOTAL_PHYSICIAN_REQUEST_TYPE:
      return {
        ...state,
        physicianRequestTypeTotalCount: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
