import { XIcon } from '@heroicons/react/solid';
import Button from 'components/common/Button';
import { ReactComponent as PlusSvg } from '../../assets/icons/lifeMD-plus.svg';
import { useEffect, useState } from 'react';
import Label from 'components/common/Label';
import uniqueId from 'lodash/uniqueId';

interface IRepeatedInput {
  labelText?: string;
  inputClasses?: string;
  labelClasses?: string;
  wrapperClasses?: string;
  onHandleChange?: (data: any, index: any) => void;
  value?: any;
  handleAdditon?: () => void;
}

const RepeatedInput = ({
  labelText,
  inputClasses,
  wrapperClasses,
  labelClasses,
  onHandleChange,
  value,
  handleAdditon,
}: IRepeatedInput) => {
  const count = value?.length;
  const arr = value?.length == 0 ? [{ data: '', key: uniqueId() }] : [];
  for (let i = 0; i < count; i++) {
    arr.push({ data: '', key: uniqueId() });
  }

  const [data, updateData] = useState<any[]>(arr);
  // const [values, valuesData] = useState<string[]>(arr);
  const addInput = () => {
    updateData([...data, { data: '', key: uniqueId() }]);
    handleAdditon?.();
  };

  const removeInput = (item: any, index: number) => {
    const newData = [...data];
    newData.length > 1 && onHandleChange?.(item, index);
    newData.splice(index, 1);
    updateData([...newData]);
  };
  return (
    <div className="flex">
      <Label labelText={labelText} labelClasses={labelClasses} />
      <div className="w-full">
        {data?.map((item, index) => {
          return (
            <div key={item.key} className="flex flex-col lg:flex-row lg:items-center mb-4">
              <span className="relative w-full lg:w-3/4">
                <input
                  className="w-full bg-background border border-lightBorder rounded-lg placeholder-text h-10 px-4 py-3 text-light"
                  placeholder="Add education"
                  onChange={(item: any) => onHandleChange?.(item, index)}
                  value={value && value[`${index}`]}
                />
                <XIcon
                  className="absolute top-3 right-2 w-3 h-3 text-textGreyedOut"
                  onClick={() => data.length > 1 && removeInput(item, index)}
                />
              </span>
              {data.length - 1 === index && (
                <Button
                  onClick={() => addInput()}
                  type="primary"
                  ownClasses="mt-4 lg:mt-0 lg:ml-2 px-3 pb-1.5 bg-white text-darkBlue rounded-full shadow-lg text-sm font-semibold"
                  disabled={false}
                >
                  <PlusSvg className="w-3 h-3 mr-1" />
                  Add education
                </Button>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default RepeatedInput;
