import actions from './actions';
import reducer from './reducer';

const tasks = {
  actions,
  reducer,
  name: 'tasks',
};

export default tasks;
