import Navcard from 'components/Admin/NavCard';
import { navItemsList } from './NavItems';
import groupBy from 'lodash.groupby';

const AdminNav: React.FC = () => {
  const newArr = Object.entries(groupBy(navItemsList, 'tag'));

  return (
    <>
      <div className="py-2 -mx-1 px-5">
        {newArr.map((category) => (
          <div className="mb-10" key={category[0]}>
            <h3 className="text-xl font-semibold capitalize mb-4 text-black">{category[0]}</h3>
            <div className="flex flex-wrap">
              {category[1]
                .filter((c) => c.tag === category[0])
                .map((card) => (
                  <Navcard key={card.title} {...card} />
                ))}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default AdminNav;
