import Button from '../common/Button';
import Table from '../common/Table';
import { ReactComponent as PlusSvg } from '../../assets/icons/lifeMD-plus.svg';
import { ReactComponent as EditSvg } from '../../assets/icons/edit.svg';
import { useEffect, useState } from 'react';
import Modal from 'components/AdminAgingAlertsModal';
import Form from '../AdminAgingAlertsModal/Form';
import StandartHeader from '../AdminAgingAlertsModal/Headers/StandartHeader';
import SearchForm from './SearchForm/';
import LoadingOverlayWrapper from 'react-loading-overlay-ts';
import { RootStateOrAny, useSelector } from 'react-redux';
import { CheckCategoryColor } from 'utils/CheckCategoryColor';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';
import AgingAlertsAPI from 'api/AgingAlertsAPI';
import { toast } from 'react-toastify';
import LookUpAPI from 'api/LookUpsAPI';

export interface AdminAgingAlertsProps {
  fetchAgingAlerts: (value?: any) => void;
  loading: boolean;
}

const StandardTable: React.FC<AdminAgingAlertsProps> = ({ fetchAgingAlerts, loading }) => {
  const agingAlertsList = useSelector((state: RootStateOrAny) => state.agingAlerts.agingAlertsList);
  const agingAlertsount = useSelector((state: RootStateOrAny) => state.agingAlerts.agingAlertsCount);
  const [showModal, setShowModal] = useState(false);
  const [inlineFilterActive, setInlineFilterActive] = useState('All');
  const [selectedPage, setSelectedPage] = useState(1);
  const [searchFilterParams, setSearchFilterParams] = useState({});
  const [pageItems, setPageItems] = useState([]);
  const [formData, setFormData] = useState({});
  const [isEditMode, setIsEditMode] = useState(false);
  const [agingAlertId, setAgingAlertId] = useState('');
  const [taskTypeOptions, setTaskTypeOptions] = useState<{ label: string; value: string }[]>();
  const [userTypeOptions, setUserTypeOptions] = useState<{ label: string; value: string }[]>();
  const [userTypeShortCodes, setUserTypeShortCodes] = useState<{ label: string; value: string }[]>();

  const [sortKey, setSortKey] = useState<string | undefined>('');
  const [sortOrder, setSortOrder] = useState<string | undefined>();
  const [params, setParams] = useState<any>();

  const totalPage =
    agingAlertsount % 10 === 0 ? Math.floor(agingAlertsount / 10) : Math.floor(agingAlertsount / 10) + 1;

  const headerItems = [
    {
      title: 'Name',
      sortKey: 'name',
    },
    {
      title: 'Task type',
      sortKey: 'taskType',
    },
    {
      title: 'Alert based on',
      sortKey: 'criterionField',
    },
    {
      title: 'Alert time',
      sortKey: 'alertAmount',
    },
    {
      title: 'Trigger to',
      sortKey: 'audience',
    },
    {
      title: 'Alert type',
      sortKey: 'alertType',
    },
    {
      title: 'Unique id',
      sortKey: 'uniqueId',
    },
    {
      title: 'Status',
      sortKey: 'status',
    },
    {
      title: 'Actions',
      align: 'center',
    },
  ];

  const onSortClick = (title?: string, key?: string, sortOrder?: string) => {
    setSelectedPage(1);
    setSortKey(key);
    setSortOrder(sortOrder);
    const filterParams: any = {
      ...searchFilterParams,
      ...(key && { sortField: key }),
      ...(sortOrder && { sortOrder }),
      ...(inlineFilterActive && inlineFilterActive !== 'All' && { status: inlineFilterActive }),
    };
    filterParams['pageNo'] = 0;
    filterParams['limit'] = 10;
    fetchAgingAlerts(filterParams);
  };

  const inlineStatusHandler = (inlineCategoryValue: string) => {
    setInlineFilterActive(inlineCategoryValue);

    const filterParams: any = {
      ...searchFilterParams,
      ...(sortKey && { sortField: sortKey }),
      ...(sortOrder && { sortOrder }),
    };
    filterParams['limit'] = 10;
    filterParams['pageNo'] = 0;
    filterParams['status'] = inlineCategoryValue !== 'All' ? inlineCategoryValue : null;

    setSelectedPage(1);
    fetchAgingAlerts(filterParams);
    setParams(filterParams);
  };

  const filterSearchTextHandler = (searchTextFilterValue?: any) => {
    setSearchFilterParams(searchTextFilterValue);
    const filterParams: any = {
      ...searchTextFilterValue,
      ...(sortKey && { sortField: sortKey }),
      ...(sortOrder && { sortOrder }),
      ...(inlineFilterActive && inlineFilterActive !== 'All' && { status: inlineFilterActive }),
    };
    filterParams['pageNo'] = 0;
    filterParams['limit'] = 10;
    setSelectedPage(1);
    console.log('filterParams', filterParams);
    fetchAgingAlerts(filterParams);
    setParams(filterParams);
  };

  const onPageClick = (e: any, value: number) => {
    e.preventDefault();
    setSelectedPage(value);
    const filterParams: any = {
      ...searchFilterParams,
      ...(sortKey && { sortField: sortKey }),
      ...(sortOrder && { sortOrder }),
      ...(inlineFilterActive && inlineFilterActive !== 'All' && { status: inlineFilterActive }),
    };
    filterParams['limit'] = 10;
    filterParams['pageNo'] = value - 1;
    fetchAgingAlerts(filterParams);
  };

  const onNextClick = (e: any) => {
    e.preventDefault();
    if (selectedPage !== totalPage) {
      setSelectedPage(selectedPage + 1);
      const filterParams: any = {
        ...searchFilterParams,
        ...(sortKey && { sortField: sortKey }),
        ...(sortOrder && { sortOrder }),
        ...(inlineFilterActive && inlineFilterActive !== 'All' && { status: inlineFilterActive }),
      };
      filterParams['limit'] = 10;
      filterParams['pageNo'] = selectedPage;
      fetchAgingAlerts(filterParams);
    }
  };

  const onPreviousClick = (e: any) => {
    e.preventDefault();
    if (selectedPage !== 1) {
      setSelectedPage(selectedPage - 1);
      const filterParams: any = {
        ...searchFilterParams,
        ...(sortKey && { sortField: sortKey }),
        ...(sortOrder && { sortOrder }),
        ...(inlineFilterActive && inlineFilterActive !== 'All' && { status: inlineFilterActive }),
      };
      filterParams['limit'] = 10;
      filterParams['pageNo'] = selectedPage - 2;
      fetchAgingAlerts(filterParams);
    }
  };

  const handleFormSubmit = (data: any) => {
    setSelectedPage(1);
    // console.log('data in API', data);
    AgingAlertsAPI.postAgingAlert(data)
      .then((res) => {
        if (res.data.errorCode === 0) {
          const filterParams: any = {
            ...searchFilterParams,
            ...(sortKey && { sortField: sortKey }),
            ...(sortOrder && { sortOrder }),
            ...(inlineFilterActive && inlineFilterActive !== 'All' && { status: inlineFilterActive }),
          };
          filterParams['pageNo'] = 0;
          filterParams['limit'] = 10;
          fetchAgingAlerts(filterParams);
          // console.log(filterParams);
          setShowModal(false);
          toast.success('Success');
        }
      })
      .catch((err) => {
        if (err?.response?.data?.message) {
          toast.error(err.response.data.message);
        } else toast.error('Failed to create automated trigger');
      });
  };

  const handleEditedFormSubmit = (data: any, id: string) => {
    setSelectedPage(1);
    AgingAlertsAPI.editAgingAlertById(data, id)
      .then((res) => {
        if (res.data.errorCode === 0) {
          fetchAgingAlerts(params);
          setShowModal(false);
          toast.success('Success');
        }
      })
      .catch((err) => {
        if (err?.response?.data?.message) {
          toast.error(err.response.data.message);
        } else toast.error('Failed to update trigger');
      });
  };

  const handleDelete = (id: string) => {
    // Delete - Aging Alert with ID
    setSelectedPage(1);
    AgingAlertsAPI.deleteAgingAlertById(id)
      .then((res) => {
        if (res.data.errorCode === 0) {
          fetchAgingAlerts(params);
          setShowModal(false);
          toast.success('Successfully deleted');
        }
      })
      .catch((err) => {
        if (err?.response?.data?.message) {
          toast.error(err.response.data.message);
        } else toast.error('Failed to delete trigger');
      });
  };

  const handleEdit = (id: any) => {
    setShowModal(true);
    setIsEditMode(true);
    setAgingAlertId(id);
    AgingAlertsAPI.fetchAgingAlertById(id)
      .then((res) => {
        if (res.data.errorCode === 0) {
          setFormData(res.data.data);
          // setShowModal(true);
        }
      })
      .catch((err) => {
        if (err?.response?.data?.message) {
          toast.error(err.response.data.message);
        } else toast.error('Failed to fetch data');
      });
  };

  useEffect(() => {
    LookUpAPI.fetchTaskCategories().then((res) => {
      if (res.data.errorCode === 0) {
        console.log('Lookup Task Type', res.data.data);
        const taskTypes = res.data.data;
        const taskTypeOptions = taskTypes.map((item: any) => ({
          value: item,
          label: item,
        }));
        console.log('Task Type Options', taskTypeOptions);
        setTaskTypeOptions(taskTypeOptions);
      }
    });
    LookUpAPI.fetchUserTypes().then((res) => {
      if (res.data.errorCode === 0) {
        console.log('Lookup User Type', res.data);
        const { data } = res.data;
        const list = Object.values(data);
        const userTypesList = list.map((item: any) => ({
          value: item.name,
          label: item.name,
        }));
        userTypesList.unshift({ value: 'Admin', label: 'Admin' });
        const userTypesShortCodes = list.map((item: any) => ({
          value: item.shortCode,
          label: item.shortCode,
        }));
        userTypesShortCodes.unshift({ value: 'AD', label: 'Admin' });
        userTypesShortCodes.unshift({ value: 'Task owner', label: 'Task Owner' });
        userTypesList.push({ value: 'Task Owner', label: 'Task Owner' });
        console.log('User Types from API', userTypesList);
        setUserTypeOptions(userTypesList);
        setUserTypeShortCodes(userTypesShortCodes);
      }
    });
  }, []);

  useEffect(() => {
    if (!showModal) {
      setFormData({});
      setIsEditMode(false);
    }
  }, [showModal]);

  useEffect(() => {
    let leftSide = selectedPage - 2;
    if (leftSide <= 0) leftSide = 1;
    let rightSide = selectedPage + 2;
    if (rightSide > totalPage) rightSide = totalPage;
    const pages: any = [];
    for (let number = leftSide; number <= rightSide; number++) {
      pages.push(number);
    }
    setPageItems(pages);
  }, [selectedPage, agingAlertsount]);

  return (
    <div className="flex flex-col flex-auto p-11 pt-4 bg-background">
      <div className="flex flex-row items-center my-5">
        <h2 className="text-black text-2xl font-medium mr-8">Aging alerts</h2>
        <Button
          type="primary"
          ownClasses=" px-4 mx-0.5 bg-white text-sm text-darkBlue rounded-full shadow-lg font-normal"
          onClick={() => setShowModal(true)}
        >
          <PlusSvg className="w-3.5 h-3.5 mr-1" />
          New
        </Button>
        {showModal && (
          <Modal showModal={showModal} setShowModal={setShowModal}>
            <div className="px-12 py-16 adminauto">
              <StandartHeader isEditMode={isEditMode} agingAlertId={agingAlertId} />
              <div className="relative  flex-auto">
                <Form
                  formData={formData}
                  handleFormSubmit={handleFormSubmit}
                  handleEditedFormSubmit={handleEditedFormSubmit}
                  handleDelete={handleDelete}
                  isEditMode={isEditMode}
                  initialTaskTypes={taskTypeOptions}
                  initialUserTypes={userTypeOptions}
                />
              </div>
            </div>
          </Modal>
        )}
      </div>
      <div className="flex flex-row flex-wrap items-center justify-between my-5">
        <div className="flex flex-row flex-wrap items-center">
          <Button
            type={`${inlineFilterActive == 'All' ? 'success' : 'secondary'}`}
            ownClasses="mr-3 pl-4 pr-5 py-1 text-lg rounded-full font-normal"
            onClick={() => inlineStatusHandler('All')}
          >
            <i className="w-2.5 h-2.5 mr-2 bg-white rounded-full" />
            All
          </Button>
          <Button
            type={`${inlineFilterActive == 'active' ? 'success' : 'secondary'}`}
            ownClasses="mr-3 pl-4 pr-5 py-1 text-lg rounded-full font-normal"
            onClick={() => inlineStatusHandler('active')}
          >
            <i className={`w-2.5 h-2.5 mr-2 ${CheckCategoryColor('active')} rounded-full`} />
            Active
          </Button>
          <Button
            type={`${inlineFilterActive == 'inactive' ? 'success' : 'secondary'}`}
            ownClasses="mr-3 pl-4 pr-5 py-1 text-lg rounded-full font-normal"
            onClick={() => inlineStatusHandler('inactive')}
          >
            <i className={`w-2.5 h-2.5 mr-2 ${CheckCategoryColor('inactive')} rounded-full`} />
            Inactive
          </Button>
        </div>
        <SearchForm
          filterSearchTextHandler={filterSearchTextHandler}
          sortField={sortKey}
          sortOrder={sortOrder}
          inlineFilterActive={inlineFilterActive}
          taskTypeOptions={taskTypeOptions}
          audienceOptions={userTypeShortCodes}
        />
      </div>
      <LoadingOverlayWrapper active={loading} spinner>
        <Table headerItems={headerItems} onSortClick={onSortClick}>
          {agingAlertsList?.map((eachAlert: any) => (
            <tr className="h-14 border-t border-lightGreyBackground" key={eachAlert?._id}>
              <td className="px-6 pb-4 pt-5 border-r border-lightGreyBackground">
                <p className="font-bold w-48">{eachAlert?.name}</p>
              </td>
              <td className="px-6 pb-4 pt-5 border-r border-lightGreyBackground">
                <p className="font-bold w-48">{eachAlert?.taskType}</p>
              </td>
              <td className="px-6 pb-4 pt-5 border-r border-lightGreyBackground">
                <p>{eachAlert?.criterionField == 'createdAt' ? 'Created date' : 'Due date'}</p>
              </td>
              <td className="px-6 pb-4 pt-5 border-r border-lightGreyBackground">
                <p>{`${eachAlert?.alertAmount}  ${eachAlert?.alertUnit} ${eachAlert?.occurenceOrder}`}</p>
              </td>
              <td className="px-6 pb-4 pt-5 border-r border-lightGreyBackground">
                <p>
                  {eachAlert?.audience
                    ?.map((item: any) => (item?.name === 'Admin' ? item.name : item?.shortCode))
                    .join(', ')}
                  {eachAlert?.alertTaskOwner ? ', Task Owner' : null}
                </p>
                {eachAlert.alertTaskOwner ? console.log('alertTaskOwner---', eachAlert?.alertTaskOwner) : null}
                {eachAlert.alertTaskOwner ? console.log('audience---', eachAlert?.audience) : null}
              </td>
              <td className="px-6 pb-4 pt-5 border-r border-lightGreyBackground">
                <p>{eachAlert?.alertType?.join(', ')}</p>
              </td>
              <td className="px-6 pb-4 pt-5 border-r border-lightGreyBackground">
                <p>{eachAlert?._id}</p>
              </td>
              <td className="px-6 pb-4 pt-5 border-r border-lightGreyBackground">
                <p className="text-midBlue capitalize">{eachAlert?.status}</p>
              </td>
              <td className="px-6 pb-4 pt-5 text-center">
                <button onClick={() => handleEdit(eachAlert?._id)}>
                  <EditSvg className="w-4" />
                </button>
              </td>
            </tr>
          ))}
        </Table>
      </LoadingOverlayWrapper>

      <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
        <div className="flex-1 flex justify-between sm:hidden">
          <a
            href="#"
            className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
          >
            Previous
          </a>
          <a
            href="#"
            className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
          >
            Next
          </a>
        </div>
        <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
          <div>
            {agingAlertsount > 0 ? (
              <p className="text-sm text-gray-700">
                Showing{' '}
                <span className="font-medium">{selectedPage === 1 ? selectedPage : (selectedPage - 1) * 10 + 1}</span>{' '}
                to{' '}
                <span className="font-medium">
                  {selectedPage * 10 > agingAlertsount ? agingAlertsount : selectedPage * 10}
                </span>{' '}
                of <span className="font-medium">{agingAlertsount}</span> Aging alerts
              </p>
            ) : (
              <p className="text-sm text-gray-700">
                Showing <span className="font-medium">{agingAlertsount}</span> Aging alerts
              </p>
            )}
          </div>
          <div>
            <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
              <a
                onClick={(e) => onPreviousClick(e)}
                href="#"
                className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
              >
                <span className="sr-only">Previous</span>
                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
              </a>
              {pageItems.map((item: any) => (
                <a
                  href="#"
                  key={item}
                  aria-current="page"
                  onClick={(e) => onPageClick(e, item)}
                  className={`z-10 ${
                    selectedPage === item ? 'bg-green text-black' : 'border-gray-300 text-gray-500 bg-white'
                  }  relative inline-flex items-center px-4 py-2 border text-sm font-medium`}
                >
                  {item}
                </a>
              ))}
              <a
                href="#"
                onClick={(e) => onNextClick(e)}
                className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
              >
                <span className="sr-only">Next</span>
                <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
              </a>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StandardTable;
