import BasicPatientInfo from 'components/common/BasicPatientInfo';
import { BasicInfoSectionProps } from './basicInfoSection.types';

const BasicInfoSection: React.FC<BasicInfoSectionProps> = ({ patientDetails }) => {
  return (
    <div className="py-8">
      <h3 className="text-darkBlue font-bold text-xl">Basic info</h3>
      <BasicPatientInfo patientDetails={patientDetails} />
    </div>
  );
};

export default BasicInfoSection;
