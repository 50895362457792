import { PharmacyInfoProps } from './pharmacyInfo.types';

const PharmacyInfo: React.FC<PharmacyInfoProps> = ({ pharmacyDetails }) => {
  const getAddress = () => {
    const address = [
      pharmacyDetails.address_line1,
      pharmacyDetails.address_line2,
      pharmacyDetails.city,
      pharmacyDetails.state,
      pharmacyDetails.zip,
    ].filter((addressItem) => addressItem);
    return address.join(', ');
  };
  const items = [
    { label: 'Email:', value: pharmacyDetails.email ?? '-' },
    { label: 'Fax:', value: pharmacyDetails.fax },
    { label: 'Phone:', value: pharmacyDetails.phone ?? '-' },
    { label: 'Address:', value: getAddress() },
    { label: 'Name:', value: pharmacyDetails.store_name },
  ];
  return (
    <div className="py-8">
      <h3 className="text-darkBlue font-bold text-xl">Pharmacy info</h3>
      <div className="flex flex-col-reverse flex-wrap-reverse content-end justify-end py-5 h-48">
        {items.map((el, index) => (
          <div key={index} className="mb-3 w-1/2">
            <span className="case-title">{el.label}</span>
            <span className="text-base">{el.value}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PharmacyInfo;
