import types from './types';
import { NotificationDataActionType, NotificationDetails } from './interfaces';

const initState: NotificationDetails = {
  notificationCount: null,
};

const reducer = (state: NotificationDetails = initState, action: NotificationDataActionType): NotificationDetails => {
  switch (action.type) {
    case types.SET_NOTIFICATION_COUNT:
      return {
        ...state,
        notificationCount: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
