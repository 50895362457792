import { useEffect, useState, useCallback, useImperativeHandle } from 'react';
import * as React from 'react';
import Button from '../../common/Button';
import { ReactComponent as SearchSvg } from '../../../assets/icons/search.svg';
import { XIcon } from '@heroicons/react/solid';
import { debounce } from 'lodash';
import Form from './Form';
export interface FilterProps {
  filterSearchTextHandler: (value?: any) => void;
  type?: string;
  sortField: any;
  sortOrder: any;
  inlineFilterActive: any;
  ref?: any;
}

// eslint-disable-next-line react/display-name
const SearchForm: React.FC<FilterProps> = React.forwardRef((props, ref) => {
  const { filterSearchTextHandler, sortField, sortOrder, inlineFilterActive } = props;
  const [showModal, setShowModal] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [intialLoadList, setIntialLoadList] = useState(true);
  const [filterFormValues, setFilterFormValues] = useState({});
  const searchHandler = (searchText: string, sortField: any, sortOrder: any, inlineFilterActive: any) => {
    const searchParams = {
      ...(searchText && { searchKey: searchText }),
      ...(sortField && { sortField }),
      ...(sortOrder && { sortOrder }),
      ...(inlineFilterActive && inlineFilterActive !== 'All' && { status: inlineFilterActive }),
    };
    filterSearchTextHandler(searchParams);
  };
  const debounceSearch = useCallback(debounce(searchHandler, 1000), []);
  const formHandler = (filterValue?: any) => {
    filterSearchTextHandler(filterValue);
    setIntialLoadList(false);
    setFilterFormValues(filterValue);
    setShowModal(false);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
  };

  const handleEnter = (e: any) => {
    if (e.key === 'Enter') {
      onChangeSearch(e.target.value);
    }
  };

  const onChangeSearch = (value: string) => {
    setSearchText(value);
    setIntialLoadList(false);
  };

  const clearSearch = () => {
    setSearchText('');
    console.log('Child Clear Search function executed');
  };

  useEffect(() => {
    if (!intialLoadList) {
      debounceSearch(searchText, sortField, sortOrder, inlineFilterActive);
    }
  }, [searchText]);

  useImperativeHandle(ref, () => ({
    clearSearch: clearSearch,
  }));

  return (
    <form className="flex flex-row flex-wrap items-center" onSubmit={handleSubmit}>
      <Button type="success" ownClasses="px-8 py-1 text-lg rounded-full" onClick={() => setShowModal(true)}>
        Filter
      </Button>

      <div className="flex ml-2 flex-row items-center px-5 bg-lightGreyBackground rounded-full">
        <SearchSvg className="w-4" />
        <input
          className="w-64 px-2 py-1 text-lg bg-lightGreyBackground focus:ring-transparent border-none"
          type="search"
          name="search"
          placeholder="Search"
          value={searchText}
          onChange={(e: any) => onChangeSearch(e.target.value)}
          onKeyUp={handleEnter}
        />
      </div>

      {showModal ? (
        <>
          <div className="flex items-center justify-center inset-0 fixed z-50 p-12">
            <div className="relative bg-white rounded-lg shadow-lg">
              <button
                className="absolute -top-6 -right-7 z-10 p-4 rounded-full bg-darkBlue"
                onClick={() => setShowModal(false)}
              >
                <XIcon className="text-white h-6 w-6" />
              </button>
              <div className="relative px-16 py-14 flex-auto">
                <h2 className="text-2xl text-green font-bold">Filters</h2>
                <div className="my-8">
                  <Form formHandler={formHandler} filterFormValues={filterFormValues} />
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-50 fixed inset-0 z-40 bg-darkBlue" />
        </>
      ) : null}
    </form>
  );
});

export default SearchForm;
