import { AxiosInstanceWebsite } from './AxiosInstance';
import config from '../config';
import { SMSBody } from 'components/Admin/Patient/PatientInfo/SMSModal/smsModal.types';

const { api } = config;

const patientAppendPath = 'patients';
const patientElationAppendPath = 'patients-elation';

export default class PatientAPI {
  static fetchPatient(data: any) {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/${patientAppendPath}`,
      method: 'GET',
      params: data,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  static fetchPatientChannels(id: string) {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/channels/${id}/patient-channels`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  static fetchPatientFrontDeskChannels(id: string, params?: any) {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/channels/${id}/frontdesk-channels`,
      method: 'GET',
      params: params,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  static fetchSinglePatientElation(id: string) {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/${patientElationAppendPath}/${id}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  // GET/patients/{patientId}/reports
  static fetchPatientReport(id: string) {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/${patientAppendPath}/${id}/reports`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  // /patients/{patientId}/reports/{reportId}
  static fetchPatientSingleReport(id: string, reportId: string) {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/${patientAppendPath}/${id}/reports/${reportId}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/pdf',
      },
      responseType: 'arraybuffer',
    });
  }

  static fetchSinglePatient(id: string) {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/${patientAppendPath}/${id}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  static fetchStaffNotes(params: any, id: string) {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/${patientAppendPath}/${id}/staff-notes`,
      method: 'GET',
      params: params,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  static AddStaffNotes(data: any, id: string) {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/${patientAppendPath}/${id}/staff-notes`,
      method: 'POST',
      data: data,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  static fetchSMS(params: { limit: number; pageNo: number }, patientId: string) {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/sms/${patientId}/history`,
      method: 'GET',
      params: params,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  static addSMS(data: SMSBody, patientId: string) {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/sms/${patientId}/send`,
      method: 'POST',
      data: data,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  static fetchViewEmr(id: string) {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/${patientAppendPath}/${id}/elationId`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  static fetchLoginAsPatient(id: string) {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/${patientAppendPath}/${id}/login-token`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }
}
