import classNames from 'classnames';
import DoctorAvatar from 'assets/icons/doc-avatar.svg';
import { AvatarProps } from './commonAvatar.types';

const Avatar: React.FC<AvatarProps> = ({ src, ownClasses }) => {
  const avatarClasses = classNames('inline-block', 'h-full', 'rounded-full', 'ring-white', 'object-cover', 'w-full');
  const styles = classNames(ownClasses);
  return (
    <div className={styles}>
      <img alt="" src={src} className={avatarClasses} />
    </div>
  );
};

export default Avatar;
