/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, useState } from 'react';
import { Connection, Device } from 'twilio-client';

export const callStates = {
  CONNECTING: 'Connecting',
  READY: 'Call patient',
  INCOMING: 'Incoming',
  ON_CALL: 'On call',
  OFFLINE: 'Offline',
};

interface CallContextProps {
  callState: string | null;
  token: string | null;
  connection: Connection | null;
  device: Device | null;
  patientId: string;
  setCallState: (state: string) => void;
  setToken: (token: string) => void;
  setConnection: (connection: Connection | null) => void;
  setDevice: (device: Device | null) => void;
  setPatientId: (id: string) => void;
  showCallCard: boolean;
  setShowCallCard: (value: boolean) => void;
}

export const CallContext = createContext<CallContextProps>({
  callState: null,
  token: null,
  connection: null,
  device: new Device(),
  patientId: '',
  showCallCard: false,
  setCallState: () => {},
  setConnection: () => {},
  setDevice: () => {},
  setPatientId: () => {},
  setShowCallCard: () => {},
  setToken: () => {},
});

export const CallProvider: React.FC = ({ children }) => {
  const [callState, setCallState] = useState<string | null>(null);
  const [token, setToken] = useState<string | null>(null);
  const [connection, setConnection] = useState<Connection | null>(null);
  const [device, setDevice] = useState<Device | null>(null);
  const [patientId, setPatientId] = useState('');
  const [showCallCard, setShowCallCard] = useState(false);

  return (
    <CallContext.Provider
      value={{
        callState,
        connection,
        device,
        patientId,
        setCallState,
        setConnection,
        setDevice,
        setPatientId,
        showCallCard,
        setShowCallCard,
        token,
        setToken,
      }}
    >
      {children}
    </CallContext.Provider>
  );
};
