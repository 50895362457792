import actions from './actions';
import reducer from './reducer';

const requestType = {
  actions,
  reducer,
  name: 'requestType',
};

export default requestType;
