export const CheckCategoryColor = (category: string) => {
  if (category === 'Messages') {
    return 'bg-green';
  } else if (category === 'Requests') {
    return 'bg-violet';
  } else if (category === 'Post-consult') {
    return 'bg-darkBlue';
  } else if (category === 'Pre-consult' || category === 'Onboarding') {
    return 'bg-patientInfoScoreMiddle';
  } else if (category === 'Results') {
    return 'bg-midBlue';
  } else if (category === 'Appointment') {
    return 'bg-yellow';
  } else if (category === 'active') {
    return 'bg-midBlue';
  } else if (category === 'inactive') {
    return 'bg-red';
  } else if (category === 'isInteractedPatients') {
    return 'bg-violet';
  } else if (category === 'History') {
    return 'bg-violet';
  } else if (category === 'Front desk') {
    return 'bg-red';
  }
};
