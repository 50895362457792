import PatientInfo from './PatientInfo';
import Appointment from './Appointment';
import { AppointmentItem, CallType } from './Appointment/appointment.types';
import NewButton from './NewButton';
import { LoginToCreateAppointment } from 'utils/LoginToCreateAppointment';
import { ReactComponent as Emr } from 'assets/icons/patientInfo/emr.svg';
import { ViewAsEmr } from 'utils/ViewAsEmr';
import { useState } from 'react';
import classNames from 'classnames';

export interface InputStateProps {
  inputsActive: boolean;
  patientDetails?: any;
}

const CaseDetails: React.FC<InputStateProps> = ({ inputsActive, patientDetails }) => {
  const handleLoginToCreateAppointment = () => {
    LoginToCreateAppointment(patientDetails._id);
  };
  const handleViewEMR = () => {
    ViewAsEmr(patientDetails._id);
  };

  const pharmacyItems = [
    {
      label: 'Name',
      value: patientDetails?.pharmacyInfo?.store_name,
    },
    { label: 'Phone', value: patientDetails?.pharmacyInfo?.phone || '' },
    {
      label: 'Address:',
      value: `${patientDetails?.pharmacyInfo?.address_line1}, ${patientDetails?.pharmacyInfo?.city}, ${patientDetails?.pharmacyInfo?.state} ${patientDetails?.pharmacyInfo?.zip}`,
    },
    { label: 'Fax', value: patientDetails?.pharmacyInfo?.fax || '' },
    { label: '', value: '' },
    { label: 'Email', value: patientDetails?.pharmacyInfo?.email || '' },
  ];

  return (
    <div>
      <PatientInfo patientDetails={patientDetails} />
      <div className="w-1/2">
        <div className="flex items-center gap-4 my-5">
          <p className="text-darkBlue font-bold text-xl">Upcoming appointments</p>
          <NewButton onClick={handleLoginToCreateAppointment} />
        </div>

        {!patientDetails?.upcomingAppointmentInfo?.length && (
          <div className="bg-noAppointmentBackground shadow-common text-center text-textGreyedOut p-7 rounded-lg">
            <p>
              You have no upcoming appointments.&nbsp;
              <button onClick={handleLoginToCreateAppointment} className="underline">
                Schedule one now.
              </button>
            </p>
          </div>
        )}
        {patientDetails?.upcomingAppointmentInfo?.map((appt: AppointmentItem, index: number) => (
          <div key={index} className="my-3">
            <Appointment {...appt} patientId={patientDetails._id} />
          </div>
        ))}
      </div>
      <div className="mt-10">
        {patientDetails?.pharmacyInfo ? (
          <>
            <h4 className="section-subheading mb-6">Pharmacy info</h4>
            <div className="flex flex-col md:flex-row flex-wrap">
              {pharmacyItems.map((el, index) => (
                <div key={index} className="mb-3 w-1/2 flex items-center">
                  <p className="font-semibold mr-4 min-w-fit w-32">{el.label}</p>
                  <p className={classNames({ underline: el.label === 'Email:' })}>{el.value}</p>
                </div>
              ))}
            </div>
          </>
        ) : (
          <div className="p-8 flex flex-col items-center justify-center">
            <p>No pharmacy added. You can add this in Elation.</p>
            <button onClick={handleViewEMR} className="flex gap-2 items-center justify-center underline">
              <Emr />
              View EMR
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default CaseDetails;
