import moment from 'moment';
import classNames from 'classnames';
import { BasicPatientInfoProps } from './basicPatientInfo.types';

export enum SexAtBirth {
  Male = '0',
  Female = '1',
  Other = '2',
  Unknown = '3',
}

const BasicPatientInfo: React.FC<BasicPatientInfoProps> = ({ patientDetails }) => {
  const addDashes = (phone: string) => {
    return `${phone.slice(1, 2)}-${phone.slice(2, 5)}-${phone.slice(5, 8)}-${phone.slice(8)}`;
  };

  const getAddress = () => {
    const address = [];
    if (patientDetails.address) {
      address.push(patientDetails.address);
    }
    if (patientDetails.city) {
      address.push(patientDetails.city);
    }
    if (patientDetails.state) {
      address.push(patientDetails.state);
    }
    if (patientDetails.zipCode) {
      address.push(patientDetails.zipCode);
    }
    return address.join(', ');
  };
  const items = [
    { label: 'DOB:', value: patientDetails.dob && moment.parseZone(patientDetails.dob).format('MM/DD/YYYY') },
    { label: 'Sex at birth:', value: Object.keys(SexAtBirth)[patientDetails.sexAtBirth] || '' },
    { label: 'Membership:', value: patientDetails.membership },
    { label: 'Physician:', value: patientDetails.doctor },
    { label: 'Address:', value: getAddress() },
    { label: 'Email:', value: patientDetails.email },
    { label: 'Phone:', value: patientDetails.phone && addDashes(patientDetails.phone?.internationalFormat) },
    { label: 'Patient id:', value: patientDetails._id },
  ];

  return (
    <div className="flex flex-col flex-wrap py-5 h-52">
      {items.map((el, index) => (
        <div key={index} className="mb-3 w-1/2 flex items-center">
          <p className="font-semibold mr-4 min-w-fit w-32">{el.label}</p>
          <p className={classNames({ underline: el.label === 'Email:' })}>{el.value}</p>
        </div>
      ))}
    </div>
  );
};

export default BasicPatientInfo;
