import { useEffect, useState } from 'react';
import Button from '../common/Button';
import Table from '../common/Table';
import SearchForm from './SearchForm';
import { RootStateOrAny, useSelector } from 'react-redux';
import { CheckPatientStatusColor } from 'utils/CheckPatientStatus';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';
import { ReactComponent as EyeSvg } from '../../assets/icons/eye.svg';
import { ReactComponent as MedicineChestSvg } from '../../assets/icons/medicine-сhest.svg';
import { ReactComponent as CircleProfileSvg } from '../../assets/icons/circle-profile.svg';
import { CheckCategoryColor } from 'utils/CheckCategoryColor';
import LoadingOverlay from 'react-loading-overlay-ts';
import { useHistory } from 'react-router';
import { dateTimeFormatter } from 'utils/dateTimeFormatter';
import { hasAccessToModule } from 'auth/authorization';
import { ViewAsEmr } from 'utils/ViewAsEmr';
import { ViewChart } from 'utils/ViewChart';
import { LoginAsPatient } from 'utils/LoginAsPatient';

export interface PatientProps {
  fetchPatientList: (value?: any) => void;
  loading: boolean;
}

const StandardTable: React.FC<PatientProps> = ({ fetchPatientList, loading }) => {
  const userRole = useSelector((state: RootStateOrAny) => state.userProfile.userDetails.userType.name);
  const patientList = useSelector((state: RootStateOrAny) => state.patients.patientList);
  const [inlineFilterActive, setInlineFilterActive] = useState('All');
  const [selectedPage, setSelectedPage] = useState(1);
  const [searchFilterParams, setSearchFilterParams] = useState({});
  const [pageItems, setPageItems] = useState([]);
  const [sortKey, setSortKey] = useState<string | undefined>('');
  const [sortOrder, setSortOrder] = useState<string | undefined>();
  const totalPatientCount = useSelector((state: RootStateOrAny) => state.patients.totalCount);
  const userInfo = useSelector((state: RootStateOrAny) => state.userProfile.userDetails);
  const totalPage =
    totalPatientCount % 10 === 0 ? Math.floor(totalPatientCount / 10) : Math.floor(totalPatientCount / 10) + 1;
  const history = useHistory();

  const headerItems = [
    {
      title: 'Patient',
      sortKey: 'patient',
    },
    {
      title: 'Date joined',
      sortKey: 'createdAt',
    },
    {
      title: 'Membership',
      sortKey: 'membership',
    },
    {
      title: 'Doctor',
      sortKey: 'doctor',
    },
    {
      title: 'Email',
      sortKey: 'email',
    },
    {
      title: 'Unique id',
      sortKey: 'uniqueId',
    },
    {
      title: 'Status',
      sortKey: 'status',
    },
    {
      title: 'Actions',
      align: 'center',
    },
  ];

  // useEffect(() => {
  //   console.log(inlineFilterActive);
  // }, [inlineFilterActive]);

  const onSortClick = (title?: string, key?: string, sortOrder?: string) => {
    setSelectedPage(1);
    setSortKey(key);
    setSortOrder(sortOrder);
    const filterParams: any = {
      ...searchFilterParams,
      ...(key && { sortField: key }),
      ...(sortOrder && { sortOrder }),
      ...(inlineFilterActive &&
        inlineFilterActive !== 'All' &&
        inlineFilterActive !== 'isInteractedPatients' && { status: inlineFilterActive }),
    };
    filterParams['pageNo'] = 0;
    filterParams['limit'] = 10;
    if (inlineFilterActive === 'isInteractedPatients') {
      filterParams['isInteractedPatients'] = true;
    }
    fetchPatientList(filterParams);
  };

  const inlineStatusHandler = (inlineCategoryValue: string) => {
    setInlineFilterActive(inlineCategoryValue);

    const filterParams: any = {
      ...searchFilterParams,
      ...(sortKey && { sortField: sortKey }),
      ...(sortOrder && { sortOrder }),
    };
    filterParams['limit'] = 10;
    filterParams['pageNo'] = 0;
    // console.log(inlineCategoryValue);
    if (inlineCategoryValue === 'isInteractedPatients') {
      filterParams['isInteractedPatients'] = true;
      filterParams['status'] = null;
    } else {
      filterParams['status'] = inlineCategoryValue !== 'All' ? inlineCategoryValue : null;
      filterParams['isInteractedPatients'] = null;
    }

    setSelectedPage(1);
    fetchPatientList(filterParams);
  };

  const filterSearchTextHandler = (searchTextFilterValue?: any) => {
    setSearchFilterParams(searchTextFilterValue);
    const filterParams: any = {
      ...searchTextFilterValue,
      ...(sortKey && { sortField: sortKey }),
      ...(sortOrder && { sortOrder }),
      ...(inlineFilterActive &&
        inlineFilterActive !== 'All' &&
        inlineFilterActive !== 'isInteractedPatients' && { status: inlineFilterActive }),
    };
    filterParams['pageNo'] = 0;
    filterParams['limit'] = 10;
    if (inlineFilterActive === 'isInteractedPatients') {
      filterParams['isInteractedPatients'] = true;
    }
    setSelectedPage(1);
    fetchPatientList(filterParams);
  };

  const onPageClick = (e: any, value: number) => {
    e.preventDefault();
    setSelectedPage(value);
    const filterParams: any = {
      ...searchFilterParams,
      ...(sortKey && { sortField: sortKey }),
      ...(sortOrder && { sortOrder }),
    };
    if (inlineFilterActive === 'isInteractedPatients') {
      filterParams['isInteractedPatients'] = true;
      filterParams['status'] = null;
    } else {
      filterParams['status'] = inlineFilterActive !== 'All' ? inlineFilterActive : null;
      filterParams['isInteractedPatients'] = null;
    }
    filterParams['limit'] = 10;
    filterParams['pageNo'] = value - 1;
    fetchPatientList(filterParams);
  };

  const onNextClick = (e: any) => {
    e.preventDefault();
    if (selectedPage !== totalPage) {
      setSelectedPage(selectedPage + 1);
      const filterParams: any = {
        ...searchFilterParams,
        ...(sortKey && { sortField: sortKey }),
        ...(sortOrder && { sortOrder }),
      };
      if (inlineFilterActive === 'isInteractedPatients') {
        filterParams['isInteractedPatients'] = true;
        filterParams['status'] = null;
      } else {
        filterParams['status'] = inlineFilterActive !== 'All' ? inlineFilterActive : null;
        filterParams['isInteractedPatients'] = null;
      }
      filterParams['limit'] = 10;
      filterParams['pageNo'] = selectedPage;
      fetchPatientList(filterParams);
    }
  };

  const onPreviousClick = (e: any) => {
    e.preventDefault();
    if (selectedPage !== 1) {
      setSelectedPage(selectedPage - 1);
      const filterParams: any = {
        ...searchFilterParams,
        ...(sortKey && { sortField: sortKey }),
        ...(sortOrder && { sortOrder }),
      };
      filterParams['limit'] = 10;
      filterParams['pageNo'] = selectedPage - 2;
      fetchPatientList(filterParams);
    }
  };

  useEffect(() => {
    let leftSide = selectedPage - 2;
    if (leftSide <= 0) leftSide = 1;
    let rightSide = selectedPage + 2;
    if (rightSide > totalPage) rightSide = totalPage;
    const pages: any = [];
    for (let number = leftSide; number <= rightSide; number++) {
      pages.push(number);
    }
    setPageItems(pages);
  }, [selectedPage, totalPatientCount]);

  const patientClickHandler = (id: string) => {
    console.log(id, 'patient id');
    history.push(`/dashboard/patient-details/${id}`);
  };
  return (
    <div className="flex flex-col flex-auto p-11 pt-4 bg-background">
      <div className="flex flex-row items-center my-5">
        <h2 className="text-black text-2xl font-bold mr-8">Patients</h2>
      </div>
      <div className="flex flex-row flex-wrap gap-y-3 items-center justify-between my-5">
        <div className="flex flex-row flex-wrap items-center">
          <Button
            type={`${inlineFilterActive == 'All' ? 'success' : 'secondary'}`}
            ownClasses="mr-3 pl-4 pr-5 py-1 text-lg rounded-full font-normal"
            onClick={() => inlineStatusHandler('All')}
          >
            <i className="w-2.5 h-2.5 mr-2 bg-white rounded-full" />
            All
          </Button>
          <Button
            type={`${inlineFilterActive == 'active' ? 'success' : 'secondary'}`}
            ownClasses="mr-3 pl-4 pr-5 py-1 text-lg rounded-full font-normal"
            onClick={() => inlineStatusHandler('active')}
          >
            <i className={`w-2.5 h-2.5 mr-2 ${CheckCategoryColor('active')} rounded-full`} />
            Active
          </Button>
          <Button
            type={`${inlineFilterActive == 'inactive' ? 'success' : 'secondary'}`}
            ownClasses="mr-3 pl-4 pr-5 py-1 text-lg rounded-full font-normal"
            onClick={() => inlineStatusHandler('inactive')}
          >
            <i className={`w-2.5 h-2.5 mr-2 ${CheckCategoryColor('inactive')} rounded-full`} />
            Inactive
          </Button>
          {!(userRole == 'Admin') && (
            <Button
              type={`${inlineFilterActive == 'isInteractedPatients' ? 'success' : 'secondary'}`}
              ownClasses="mr-3 pl-4 pr-5 py-1 text-lg rounded-full font-normal"
              onClick={() => inlineStatusHandler('isInteractedPatients')}
            >
              <i className={`w-2.5 h-2.5 mr-2 ${CheckCategoryColor('isInteractedPatients')} rounded-full`} />
              My Patients
            </Button>
          )}
        </div>
        <SearchForm
          filterSearchTextHandler={filterSearchTextHandler}
          sortField={sortKey}
          sortOrder={sortOrder}
          inlineFilterActive={inlineFilterActive}
        />
      </div>
      <LoadingOverlay active={loading} spinner>
        <Table headerItems={headerItems} onSortClick={onSortClick}>
          {patientList &&
            patientList.map((eachPatient: any) => {
              return (
                <tr
                  className="h-14 border-t border-lightGreyBackground cursor-pointer"
                  key={eachPatient._id}
                  onClick={() => patientClickHandler(eachPatient._id)}
                >
                  <td className="px-6 pb-4 pt-5 border-r border-lightGreyBackground">
                    <p className="font-bold">{eachPatient.patient}</p>
                  </td>
                  <td className="px-6 pb-4 pt-5 border-r border-lightGreyBackground">
                    <p>{dateTimeFormatter(eachPatient.createdAt)}</p>
                  </td>
                  <td className="px-6 pb-4 pt-5 border-r border-lightGreyBackground">
                    <p>{eachPatient.membership}</p>
                  </td>
                  <td className="px-6 pb-4 pt-5 border-r border-lightGreyBackground">
                    <p>{eachPatient.doctor}</p>
                  </td>
                  <td className="px-6 pb-4 pt-5 border-r border-lightGreyBackground">
                    <p>{eachPatient.email}</p>
                  </td>
                  <td className="px-6 pb-4 pt-5 border-r border-lightGreyBackground">
                    <p>{eachPatient._id}</p>
                  </td>
                  <td className="px-6 pb-4 pt-5 border-r border-lightGreyBackground">
                    <p className={`${CheckPatientStatusColor(eachPatient.status)} font-medium`}>{eachPatient.status}</p>
                  </td>
                  <td className="px-6 pb-4 pt-5">
                    <div className="flex flex-row justify-center ">
                      {hasAccessToModule(userInfo.userRoleInfo.pagePermissions, ['VIEW_PATIENT_EMR']) && (
                        <button
                          onClick={(event) => {
                            ViewChart(eachPatient?._id), event.stopPropagation();
                          }}
                        >
                          <EyeSvg className="h-4" />
                        </button>
                      )}
                      {hasAccessToModule(userInfo.userRoleInfo.pagePermissions, ['PATIENT_DETAILS_VIEW']) && (
                        <button
                          className="mx-4"
                          onClick={(event) => {
                            ViewAsEmr(eachPatient?._id), event.stopPropagation();
                          }}
                        >
                          <MedicineChestSvg className="h-4" />
                        </button>
                      )}
                      {hasAccessToModule(userInfo.userRoleInfo.pagePermissions, ['VIEW_AS_PATIENT']) && (
                        <button
                          onClick={(event) => {
                            LoginAsPatient(eachPatient?._id), event.stopPropagation();
                          }}
                        >
                          <CircleProfileSvg className="h-4" />
                        </button>
                      )}
                    </div>
                  </td>
                </tr>
              );
            })}
        </Table>
      </LoadingOverlay>
      <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
        <div className="flex-1 flex justify-between sm:hidden">
          <a
            href="#"
            className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
          >
            Previous
          </a>
          <a
            href="#"
            className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
          >
            Next
          </a>
        </div>
        <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
          <div>
            {totalPatientCount > 0 ? (
              <p className="text-sm text-gray-700">
                Showing{' '}
                <span className="font-medium">{selectedPage === 1 ? selectedPage : (selectedPage - 1) * 10 + 1}</span>{' '}
                to{' '}
                <span className="font-medium">
                  {selectedPage * 10 > totalPatientCount ? totalPatientCount : selectedPage * 10}
                </span>{' '}
                of <span className="font-medium">{totalPatientCount}</span> Patients
              </p>
            ) : (
              <p className="text-sm text-gray-700">
                Showing <span className="font-medium">{totalPatientCount}</span> Patients
              </p>
            )}
          </div>
          <div>
            <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
              <a
                onClick={(e) => onPreviousClick(e)}
                href="#"
                className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
              >
                <span className="sr-only">Previous</span>
                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
              </a>
              {pageItems.map((item: any) => (
                <a
                  href="#"
                  key={item}
                  aria-current="page"
                  onClick={(e) => onPageClick(e, item)}
                  className={`z-10 ${
                    selectedPage === item ? 'bg-green text-black' : 'border-gray-300 text-gray-500 bg-white'
                  }  relative inline-flex items-center px-4 py-2 border text-sm font-medium`}
                >
                  {item}
                </a>
              ))}
              <a
                href="#"
                onClick={(e) => onNextClick(e)}
                className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
              >
                <span className="sr-only">Next</span>
                <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
              </a>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StandardTable;
