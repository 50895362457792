import { AnimatePresence, motion } from 'framer-motion';

import { ErrorBoundaryProps } from './errorBoundary.types';
import { XIcon } from '@heroicons/react/solid';

const ErrorBoundary: React.FC<ErrorBoundaryProps> = ({ message }) => {
  return (
    <div className="bg-darkBlue bg-opacity-50 w-screen h-screen">
      <AnimatePresence>
        <motion.div key="modal" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
          <div className="absolute inset-0 bg-white w-fit h-fit p-8 rounded-xl m-auto">
            <button
              className="absolute -top-6 -right-6 z-10 p-4 rounded-full bg-darkBlue"
              onClick={() => window.location.replace('/')}
            >
              <XIcon className="text-white h-5 w-5" />
            </button>
            <h2 className="text-primary text-xl">You have encountered an error</h2>
            <p className="text-sm my-3">{message}</p>
          </div>
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

export default ErrorBoundary;
