const types = {
  SET_PATIENT_MESSAGE_TEMPLATES_LIST: 'SET_PATIENT_MESSAGE_TEMPLATES_LIST',
  SET_PATIENT_MESSAGE_TEMPLATES_COUNT: 'SET_PATIENT_MESSAGE_TEMPLATES_COUNT',
  SET_REQUEST_MESSAGE_TEMPLATES_LIST: 'SET_REQUEST_MESSAGE_TEMPLATES_LIST',
  SET_REQUEST_MESSAGE_TEMPLATES_COUNT: 'SET_REQUEST_MESSAGE_TEMPLATES_COUNT',
  SET_INTERNAL_NOTE_TEMPLATES_LIST: 'SET_INTERNAL_NOTE_TEMPLATES_LIST',
  SET_INTERNAL_NOTE_TEMPLATES_COUNT: 'SET_INTERNAL_NOTE_TEMPLATES_COUNT',
};

export default types;
