import { SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import classNames from 'classnames';
import { dateTimeFormatter } from 'utils/dateTimeFormatter';
import { PhoneProps } from '../SMSForm/smsForm.types';
import SMSForm from '../SMSForm';
import { SMS } from '../smsModal.types';
import PatientAPI from 'api/PatientAPI';
import { toast } from 'react-toastify';
import { timeInSecondsFromNow } from 'utils/timeInSecondsFromNow';

const MessagesSection: React.FC<{ patientId: string; patientPhone: PhoneProps }> = ({ patientId, patientPhone }) => {
  const userInfo = useSelector((state: RootStateOrAny) => state.userProfile.userDetails);

  const [messages, setMessages] = useState<SMS[]>([]);
  const [totalSMSCount, setTotalSMSCount] = useState<number>(0);
  const [scrollTop, setScrollTop] = useState(0);

  const messagesEndRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const smsLimit = 10;
  const smsLength = messages.length;
  let page = 0;

  const onGetSMS = ({ limit, pageNo }: { limit: number; pageNo: number }) => {
    PatientAPI.fetchSMS({ limit, pageNo }, patientId)
      .then(({ data }) => {
        if (data && data.data && data.info) {
          let newSmsArray: SMS[] = [];
          if (data.info.totalCount !== totalSMSCount) {
            // TODO: remove sort
            newSmsArray = [...data.data].sort(
              (prev, curr) => timeInSecondsFromNow(prev.createdAt) - timeInSecondsFromNow(curr.createdAt),
            );
          } else {
            newSmsArray = (data.data.concat(messages) as SMS[]).filter(
              (v, i, a) => a.findIndex((t) => t._id === v._id) === i,
            );
          }
          setMessages(newSmsArray);
          setTotalSMSCount(data.info.totalCount);
        }
      })
      .catch((errorData) => toast.error(errorData.data.message));
  };

  const handleScroll = (e: SyntheticEvent<HTMLDivElement>) => setScrollTop(e.currentTarget.scrollTop);

  const scrollToBottom = () => {
    containerRef.current?.scrollTo({ top: messagesEndRef.current?.offsetTop });
  };

  const onMessageSend = () => {
    onGetSMS({ limit: smsLimit, pageNo: 0 });
  };

  const messagesWrapperClasses = classNames('flex flex-col-reverse overflow-y-auto', 'h-96');

  const getNextMessages = useCallback(() => {
    page += 1;

    if (totalSMSCount !== smsLength) {
      onGetSMS({ limit: smsLimit, pageNo: page });
    }
  }, [messages, totalSMSCount]);

  useEffect(() => {
    onGetSMS({ limit: smsLimit, pageNo: 0 });
    scrollToBottom();
  }, []);

  useEffect(() => {
    const offsetHeight = containerRef.current?.offsetHeight || 0;
    const scrollHeight = Math.abs(scrollTop) + offsetHeight;
    if (scrollHeight === containerRef.current?.scrollHeight) {
      getNextMessages();
    }
  }, [scrollTop]);

  return (
    <div className="w-full">
      <div className="mb-4 w-full">
        <div className={messagesWrapperClasses} ref={containerRef} onScroll={handleScroll}>
          {messages && messages?.length !== 0 ? (
            messages.map((message) => {
              const isYourMessage = userInfo._id === message.senderDetails._id;

              const messageWrapperClasses = classNames(
                'w-4/5 py-2 pl-3 pr-20 mb-3 rounded-lg',
                isYourMessage ? 'self-end bg-lightGreen bg-opacity-50' : `self-start bg-lightGreyBackground`,
              );

              return (
                <div className={messageWrapperClasses} key={message._id}>
                  <div className="flex mb-2">
                    <h4 className="mr-2 font-bold">{isYourMessage ? <>Me</> : message.senderDetails.name}</h4>
                    <span className="text-myPlanLightGrey ">{dateTimeFormatter(message.createdAt)}</span>
                  </div>
                  <p>{message.message}</p>
                </div>
              );
            })
          ) : (
            <span className="text-sm ml-5">No Messages</span>
          )}

          <div ref={messagesEndRef} />
        </div>
      </div>

      <SMSForm patientId={patientId} doctorId={userInfo} patientPhone={patientPhone} onSendMessage={onMessageSend} />
    </div>
  );
};

export default MessagesSection;
