import { AxiosInstanceWebsite } from './AxiosInstance';
import config from '../config';

const { api } = config;

const availabilityAppendPath = 'physician-availability';

export default class AvailabilityAPI {
  static fetchAvailabilitys(id: string, localTz?: string) {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/${availabilityAppendPath}/${id}`,
      params: { localTz },
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  static updateAvailability(id: string, localTz?: string, data?: any) {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/${availabilityAppendPath}/${id}`,
      method: 'PATCH',
      params: { localTz },
      data: data,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  static addOverrideAvailability(id: string, localTz?: string, data?: any) {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/${availabilityAppendPath}/override/${id}`,
      method: 'PATCH',
      params: { localTz },
      data: data,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  static fetchOverrideAvailability(id: string, params?: any) {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/${availabilityAppendPath}/work-hours/${id}`,
      method: 'GET',
      params: params,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  static deleteOverrideAvailabilty(id: string, params: any, localTz?: string) {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/${availabilityAppendPath}/override/${id}`,
      method: 'DELETE',
      params: { localTz },
      data: params,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }
}
