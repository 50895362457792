import classNames from 'classnames';

const CalendarHint = () => {
  const wrapperClassnames = classNames(
    'z-20 w-screen-xs px-8 py-6 shadow-full border-t border-lightGrey fixed bg-whiteWithOpacity text-lg leading-tight transition transition-all duration-300 transform scale-0 group-hover:scale-100 group-hover:-translate-x-1/2 -translate-y-2/3 rounded-3xl',
  );

  return (
    <>
      <div className={wrapperClassnames}>
        <span className="text-lg font-semibold">Past appointments</span>
        <div className="flex items-center">
          <div className="w-8 h-4 bg-lightGreen rounded-lg"></div>
          <div className="w-8 h-4 ml-2 mr-4 flex bg-lightGreen rounded-lg">
            <div className="w-2 h-2 m-auto hint relative bg-red rounded-full border-2 border-white"></div>
          </div>
          <span>Action required</span>
        </div>
        <span className="mt-4 text-lg block font-semibold">Future appointments</span>
        <div className="flex items-center">
          <div className="w-8 h-4 bg-green rounded-lg"></div>
          <div className="w-8 h-4 ml-2 mr-4 flex bg-green rounded-lg">
            <div className="w-2 h-2 m-auto bg-red test relative rounded-full border-2 border-white"></div>
          </div>
          <span>Action required</span>
        </div>
      </div>
    </>
  );
};

export default CalendarHint;
