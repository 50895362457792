import { ChannelData } from './interfaces';
import types from './types';

export const setChannelLists = (data: ChannelData[]) => ({
  type: types.SET_CHANNEL_LIST,
  payload: data,
});

const actions = {
  setChannelLists,
};

export default actions;
