import React, { useState, useEffect } from 'react';
import Video from 'twilio-video';
import Participant from './Participant';
import './videoCall.css';
import { ReactComponent as MicSvg } from '../../../assets/icons/chat/mic.svg';
import { ReactComponent as CollSvg } from '../../../assets/icons/chat/coll.svg';
import { ReactComponent as VideoSvg } from '../../../assets/icons/chat/video.svg';
import { ReactComponent as BlockedSvg } from '../../../assets/icons/chat/blocked.svg';
import { ReactComponent as SettingsSvg } from '../../../assets/icons/chat/settings.svg';
import { ReactComponent as FullScreenSvg } from '../../../assets/icons/chat/fullScreen.svg';
import { ReactComponent as Check } from 'assets/icons/check.svg';
import LoadingOverlay from 'react-loading-overlay-ts';
import socket from 'socket/socket';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import AppointmentAPI from 'api/AppointmentAPI';
import Cookies from 'js-cookie';
import { useDispatch } from 'react-redux';
import { setSelectedTaskId } from 'redux/tasks/actions';
import Modal from 'components/Modal';
import Button from 'components/common/Button';
import * as Sentry from '@sentry/react';

const defaultBtnStyle =
  'flex items-center justify-center rounded-full outline-none w-16 h-16 m-2 bg-white' +
  ' shadow-lg hover:bg-green hover:text-white relative';

export interface RoomProps {
  roomName?: any;
  token?: any;
  consulationType?: any;
  handleLogout: () => void;
  callDetails?: any;
}

const Room: React.FC<RoomProps> = ({ roomName, token, consulationType, callDetails, handleLogout }) => {
  const [room, setRoom] = useState(null as any);
  const [participants, setParticipants] = useState([] as any);
  const [isMic, setIsMic] = useState('unmute');
  const [isVideo, setIsVideo] = useState('on');
  const [pateientEntered, setPatientEntered] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showEndPopUp, setShowEndPopUp] = useState(false);
  const [settingShow, setSettingShow] = useState(false);
  const [devices, setDevices] = useState<any>();
  const [fullscreen, setFullScreen] = useState(false);
  const [selectedMicroPhone, setSelectedMicroPhone] = useState('');
  const [selectedSpeaker, setSelectedSpeaker] = useState('');
  const [selectedCamera, setSelectedCamera] = useState('');

  const history = useHistory();
  const dispatch = useDispatch();
  const roomElem: any = document.documentElement;

  useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then(gotDevices).catch(handleError);
  }, []);

  function handleError(error: any) {
    Sentry.captureException(error);
    console.log('navigator.MediaDevices.getUserMedia error: ', error.message, error.name);
  }

  function gotDevices(deviceInfos: any) {
    setDevices(deviceInfos);
  }

  function stopTracks(tracks: any) {
    tracks.forEach(function (track: any) {
      if (track) {
        track.stop();
      }
    });
  }

  function detachTracks(tracks: any) {
    tracks.forEach(function (track: any) {
      if (track) {
        track.detach().forEach(function (detachedElement: any) {
          detachedElement.remove();
        });
      }
    });
  }
  function handleDeviceSelect(deviceInfo: any) {
    if (deviceInfo.kind === 'audioinput') {
      // console.log('audio input');
      // const tracks = Array.from(room.localParticipant.audioTracks.values()).map(function (trackPublication: any) {
      //   return trackPublication.track;
      // });
      // room.localParticipant.unpublishTrack(tracks);
      // detachTracks(tracks);
      // stopTracks(tracks);
      // Video.createLocalTracks({ audio: { deviceId: deviceInfo.deviceId }, video: true }).then((localAudioTrack) => {
      //   room.localParticipant.publishTrack(localAudioTrack);
      // room.localParticipant.
      // });
    }

    // if (deviceInfo.kind === 'videoinput') {
    //   Video.createLocalVideoTrack({
    //     deviceId: { exact: deviceInfo.deviceId },
    //   }).then(function (localVideoTrack) {
    //     const tracks = Array.from(localParticipant.videoTracks.values());
    //     localParticipant.unpublishTracks(tracks);

    // detachTracks(tracks);
    // localParticipant.publishTrack(localVideoTrack);
    // const previewContainer = document.getElementById('local-media');
    // attachTracks([localVideoTrack], previewContainer);
    // });
    // }

    if (deviceInfo.kind === 'audiooutput') {
    }
  }

  useEffect(() => {
    socket.on('joinedWaitingRoom', (data) => {
      if (data.appointmentId === callDetails.appointmentId) {
        console.log(data, 'patient joined');
        setPatientEntered(true);
        socket.off('joinedWaitingRoom');
        socket.emit(
          'doctorTriggerCall',
          {
            appointmentId: callDetails.appointmentId,
            doctorId: callDetails.doctorId,
            patientId: callDetails.patientId,
          },
          (data: any) => {
            console.log(data, 'soketemmit');
          },
        );
      }
    });

    socket.on('patientTriggeredCall', (data) => {
      if (data.appointmentId === callDetails.appointmentId) {
        setPatientEntered(true);
        socket.off('patientTriggeredCall');
        console.log(data, 'patient triggered');
      }
    });
  }, []);

  useEffect(() => {
    if (token && pateientEntered && roomName) {
      const participantConnected = (participant: any) => {
        setParticipants((prevParticipants?: any) => [...prevParticipants, participant]);
      };
      const participantDisconnected = (participant: any) => {
        setParticipants((prevParticipants?: any) => prevParticipants.filter((p: any) => p !== participant));
      };

      navigator.mediaDevices
        .getUserMedia({
          audio: true,
          video: true,
        })
        .then(function (mediaStream) {
          return Video.connect(token, {
            name: roomName,
            tracks: mediaStream.getTracks(),
          });
        })
        .then((room: any) => {
          setRoom(room);
          room.on('participantConnected', participantConnected);
          room.on('participantDisconnected', participantDisconnected);
          room.participants.forEach(participantConnected);
          setLoading(false);
        })
        .catch((e) => {
          Sentry.captureException(e);
          history.push('/dashboard/tasks');
          console.log(e.message && e.message);
          toast.error('Unable to intiate appointment, Please try again!');
          handleLogout();
          setLoading(false);
        });

      return () => {
        setRoom((currentRoom: any) => {
          if (currentRoom && currentRoom.localParticipant.state === 'connected') {
            currentRoom.localParticipant.tracks.forEach(function (trackPublication: any) {
              trackPublication.track.stop();
            });
            currentRoom.disconnect();
            return null;
          } else {
            return currentRoom;
          }
        });
      };
    }
  }, [roomName, token, pateientEntered]);

  const remoteParticipants = participants.map(
    (participant: any, index: number) =>
      index === 0 && (
        <Participant
          key={participant.sid}
          participant={participant}
          isVideo={'on'}
          mute={'unmute'}
          remote={true}
          url={callDetails?.patientImage}
          name={callDetails?.patientName}
        />
      ),
  );

  useEffect(() => {
    if (room) {
      if (isMic === 'mute') {
        room.localParticipant.audioTracks.forEach((publication: any) => publication.track.disable());
      } else if (isMic === 'unmute') {
        room.localParticipant.audioTracks.forEach((publication: any) => publication.track.enable());
      }
    }
  }, [isMic]);

  useEffect(() => {
    if (room) {
      if (isVideo === 'off') {
        room.localParticipant.videoTracks.forEach((publication: any) => publication.track.disable());
      } else if (isVideo === 'on') {
        room.localParticipant.videoTracks.forEach((publication: any) => publication.track.enable());
      }
    }
  }, [isVideo]);

  const onEndVideoCall = () => {
    const id: any = Cookies.get('appointmentId');
    setLoading(true);
    AppointmentAPI.completeAppointment(id)
      .then((res) => {
        const { data } = res.data;
        if (pateientEntered) {
          room.localParticipant.tracks.forEach((publication: any) => {
            publication.track.disable();
            publication.track.stop();
            const attachedElements = publication.track.detach();
            attachedElements.forEach((element: any) => element.remove());
          });
        }
        history.push('/dashboard/tasks');
        if (data) {
          toast.success(data.message);
          dispatch(setSelectedTaskId(data.newTaskId));
          handleLogout();
          setRoom('');
          setPatientEntered(false);
        }
      })
      .catch((e) => {
        Sentry.captureException(e);
        console.log(e.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const enterRoomForCall = () => {
    socket.emit(
      'physicianJoinWaitingRoom',
      {
        appointmentId: callDetails.appointmentId,
        doctorId: callDetails.doctorId,
        patientId: callDetails.patientId,
      },
      (data: any) => {
        console.log(data, 'soketemmit');
      },
    );
  };

  useEffect(() => {
    if (token) {
      enterRoomForCall();
    }
  }, [token]);

  function openFullscreen() {
    if (roomElem.requestFullscreen) {
      roomElem.requestFullscreen();
    } else if (roomElem.webkitRequestFullscreen) {
      /* Safari */
      roomElem.webkitRequestFullscreen();
    } else if (roomElem.msRequestFullscreen) {
      /* IE11 */
      roomElem.msRequestFullscreen();
    }
    setFullScreen(true);
  }

  /* Close fullscreen */
  function closeFullscreen() {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    }
    setFullScreen(false);
  }

  useEffect(() => {
    document.addEventListener(
      'fullscreenchange',
      function () {
        if (!document.fullscreenElement) {
          setFullScreen(false);
        }
      },
      false,
    );

    document.addEventListener(
      'mozfullscreenchange',
      function () {
        if (!roomElem.mozFullScreenElement) {
          setFullScreen(false);
        }
      },
      false,
    );

    document.addEventListener(
      'webkitfullscreenchange',
      function () {
        if (!roomElem.webkitFullscreenElement) {
          setFullScreen(false);
        }
      },
      false,
    );
  }, []);

  return (
    <>
      <div className="room">
        <div className="relative min-h-screen max-h-screen h-screen bg-gray-50">
          <LoadingOverlay active={loading} className="h-5/6 z-0">
            <div className="h-screen relative flex justify-center items-center min-h-5/6">
              {pateientEntered ? (
                <>
                  {remoteParticipants && remoteParticipants}
                  <div className="absolute left-0 bottom-0 w-64">
                    <>
                      {room ? (
                        <Participant
                          key={room.localParticipant.sid}
                          participant={room.localParticipant}
                          mute={isMic}
                          type={'video'}
                          isVideo={isVideo}
                          remote={false}
                          url={callDetails?.doctorImage}
                          name={callDetails?.doctorName}
                        />
                      ) : (
                        ''
                      )}
                    </>
                  </div>{' '}
                </>
              ) : (
                <div className="m-auto text-darkBlue font-bold text-3xl">
                  Waiting for {callDetails.patientName} to arrive....
                </div>
              )}
            </div>
          </LoadingOverlay>

          <div className="h-1/6 flex justify-center items-center absolute bottom-36  inset-x-0  ">
            <button
              className={`${defaultBtnStyle}   ${fullscreen ? 'bg-green' : 'bg-white'}`}
              disabled={false}
              onClick={() => (fullscreen ? closeFullscreen() : openFullscreen())}
            >
              <FullScreenSvg className="w-6 h-6" />
            </button>
            <button
              onClick={() => setIsMic(isMic === 'mute' ? 'unmute' : 'mute')}
              className={defaultBtnStyle}
              disabled={loading}
            >
              {isMic == 'mute' && <BlockedSvg className="absolute w-9 h-9" />}
              <MicSvg className="w-6 h-6" />
            </button>
            <button
              className="flex items-center justify-center rounded-full outline-none w-20 h-20 m-2 bg-red shadow-lg text-white"
              onClick={() => setShowEndPopUp(true)}
            >
              <CollSvg className="w-10 h-10" />
            </button>
            <button
              onClick={() => setIsVideo(isVideo === 'on' ? 'off' : 'on')}
              className={defaultBtnStyle}
              disabled={loading}
            >
              {isVideo === 'off' && <BlockedSvg className="absolute w-9 h-9" />}
              <VideoSvg className="w-6 h-6" />
            </button>

            <div className="relative bigcalkey">
              <button
                className={defaultBtnStyle}
                disabled={false}
                onClick={() => {
                  setSettingShow(!settingShow);
                }}
              >
                <SettingsSvg className="w-6 h-6" />
              </button>
              {settingShow && (
                <div className="z-20 px-6 settpopups shadow-full border-t absolute bg-whiteWithOpacity text-sm leading-tight text-white transform -translate-x-1/2 -translate-y-full rounded-lg">
                  {/* <div className="w-16 overflow-hidden opacity-90 arrow-up">
                    <div className=" h-11 w-11 bg-white drop-shadow-2xl border-2 rotate-45 transform origin-bottom-left"></div>
                  </div> */}

                  <h1 className="text-green font-bold">MICROPHONE</h1>
                  {devices &&
                    devices.map((eachDevices: any) => {
                      if (eachDevices.kind === 'audioinput')
                        return (
                          <div className="flex align-middle" onClick={() => handleDeviceSelect(eachDevices)}>
                            {eachDevices.deviceId === 'default' && <Check className="w-4 mr-2" />}
                            <p
                              className={`${
                                eachDevices.deviceId === 'default' ? 'text-darkBlue' : 'text-dashboardPrimaryGrey'
                              } py-5
                          hover:text-darkBlue font-bold`}
                            >
                              {eachDevices.label}
                            </p>
                          </div>
                        );
                    })}

                  <h1 className="text-green font-bold">SPEAKER</h1>
                  {devices &&
                    devices.map((eachDevices: any) => {
                      if (eachDevices.kind === 'audiooutput')
                        return (
                          <div className="flex align-middle" onClick={() => handleDeviceSelect(eachDevices)}>
                            {eachDevices.deviceId === 'default' && <Check className="w-4 mr-2" />}
                            <p
                              className={`${
                                eachDevices.deviceId === 'default' ? 'text-darkBlue' : 'text-dashboardPrimaryGrey'
                              } py-5
                          hover:text-darkBlue font-bold`}
                            >
                              {eachDevices.label}
                            </p>
                          </div>
                        );
                    })}

                  <h1 className="text-green font-bold">CAMERA</h1>

                  {devices &&
                    devices.map((eachDevices: any) => {
                      if (eachDevices.kind === 'videoinput')
                        return (
                          <div className="flex align-middle" onClick={() => handleDeviceSelect(eachDevices)}>
                            {eachDevices.deviceId === 'default' && <Check className="w-4 mr-2" />}
                            <p
                              className={`${
                                eachDevices.deviceId === 'default' ? 'text-darkBlue' : 'text-dashboardPrimaryGrey'
                              } py-5
                          hover:text-darkBlue font-bold`}
                            >
                              {eachDevices.label}
                            </p>
                          </div>
                        );
                    })}

                  {/* <video id="video" playsInline autoPlay></video> */}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <Modal showModal={showEndPopUp} setShowModal={() => setShowEndPopUp(false)}>
        <div className="m-auto appoint-picker z-10">
          <div className="max-w-lg w-120 flex flex-col shadow-full px-6 py-8 relative rounded-xl">
            <h2 className="mb-10 font-bold text-lg">Do you want to complete the appointment?</h2>
            <div className="flex justify-evenly">
              <Button
                type="secondary"
                onClick={() => {
                  history.push('/dashboard/tasks');
                  handleLogout();
                  setRoom('');
                  if (pateientEntered) {
                    room.localParticipant.tracks.forEach((publication: any) => {
                      publication.track.disable();
                      publication.track.stop();
                      const attachedElements = publication.track.detach();
                      attachedElements.forEach((element: any) => element.remove());
                    });
                  }
                  setPatientEntered(false);
                }}
              >
                No
              </Button>
              <Button type="primary" onClick={() => onEndVideoCall()}>
                Yes
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Room;
