import actions from './actions';
import reducer from './reducer';

const userProfile = {
  actions,
  reducer,
  name: 'userProfile',
};

export default userProfile;
