const config = {
  api: {
    baseUrlAuth: `${process.env.REACT_APP_LIFE_MD_API_URL_AUTH}`,
    baseUrlWebsite: `${process.env.REACT_APP_LIFE_MD_API_URL_WEBSITE}`,
  },
  webSocket: {
    baseUrlWebSocket: `${process.env.REACT_APP_LIFE_MD_WEB_SOCKET_URL}`,
    baseUrlWebSocketFrontDesk: `${process.env.REACT_APP_LIFE_MD_WEB_SOCKET_URL_FRONTDESK}`,
  },
  navigation: {
    viewAsEmr: `${process.env.REACT_APP_LIFE_MD_VIEW_AS_EMR}`,
    loginAsPatientUrl: `${process.env.REACT_APP_LIFE_MD_LOGIN_AS_PATIENT}`,
  },
  key: {
    apiKey: 12345,
    markerIoKey: '62462bbf5854d32e3853a74b',
  },
};

export default config;
