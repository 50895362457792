import actions from './actions';
import reducer from './reducer';

const notificationCount = {
  actions,
  reducer,
  name: 'notificationCount',
};

export default notificationCount;
