import { LoginAsPatient } from 'utils/LoginAsPatient';

import { ReactComponent as Patient } from 'assets/icons/patientInfo/patient.svg';
import { PatientInfoProps } from './patientInfo.types';
import Score from './Score';
import ContactButtons from './ContactButtons';
import BasicPatientInfo from 'components/common/BasicPatientInfo';
import PhoneCall from './PhoneCall';

const PatientInfo: React.FC<PatientInfoProps> = ({ patientDetails }) => {
  const score = 1;

  const handleLoginAsPatient = () => {
    LoginAsPatient(patientDetails._id);
  };

  return (
    <div className="w-full bg-patientInfoBackground rounded-lg px-6 py-3.5">
      <div className="flex items-center justify-between border-b py-1.5">
        <div className="flex items-center gap-4">
          <p className="text-darkBlue font-bold text-2xl">
            {patientDetails.firstName} {patientDetails.lastName}
          </p>
          <Score score={score} />
        </div>
        <ContactButtons
          patientDetails={patientDetails}
          RouteViewButtonText="Log in as Patient"
          onClickRouteViewButton={handleLoginAsPatient}
          RouteViewImagineSVG={<Patient />}
        />
      </div>
      <BasicPatientInfo patientDetails={patientDetails} />
    </div>
  );
};

export default PatientInfo;
