import React, { useRef, useEffect } from 'react';
import ReactSelect from 'react-select';
import Label from '../Label';
import { Indicator } from '../Dropdown/Dropdown';
import { XIcon } from '@heroicons/react/solid';

interface IMultiSelect {
  options: { value: string; label: string }[] | undefined;
  labelText?: string;
  inputClasses?: string;
  labelClasses?: string;
  value?: any;
  handleChange?: (value: any) => any;
  field?: string;
  onClickIconClear: () => void;
}
export const MultiSelect = (props: any) => {
  // isOptionSelected sees previous props.value after onChange
  const valueRef = useRef(props.value);
  valueRef.current = props.value;

  // useEffect(() => {
  //   console.log('valu ref', valueRef, props.options);
  // }, []);
  const selectAllOption = {
    value: '<SELECT_ALL>',
    label: valueRef?.current?.length ? `All ${props.field}` : `+ Add all ${props.field}`,
  };

  const isSelectAllSelected = () => valueRef?.current?.length === props.options.length;

  const isOptionSelected = (option: any) =>
    valueRef?.current?.some(({ value }: any) => value === option.value) || isSelectAllSelected();

  const getOptions = () => [selectAllOption, ...props.options];

  const getValue = () => (isSelectAllSelected() ? [selectAllOption] : props.value);

  const onChange = (newValue: any, actionMeta: any) => {
    const { action, option, removedValue } = actionMeta;

    if (action === 'select-option' && option.value === selectAllOption.value) {
      props.handleChange(props.options, actionMeta);
    } else if (
      (action === 'deselect-option' && option.value === selectAllOption.value) ||
      (action === 'remove-value' && removedValue.value === selectAllOption.value)
    ) {
      props.handleChange([], actionMeta);
    } else if (actionMeta.action === 'deselect-option' && isSelectAllSelected()) {
      props.handleChange(
        props.options.filter(({ value }: any) => value !== option.value),
        actionMeta,
      );
    } else {
      props.handleChange(newValue || [], actionMeta);
    }
  };

  return (
    <div className="mb-4 flex items-center">
      <Label labelText={props.labelText} labelClasses={props.labelClasses} />
      <div className="relative w-full">
        <ReactSelect
          className="w-full"
          isOptionSelected={isOptionSelected}
          options={getOptions()}
          value={getValue()}
          onChange={onChange}
          isDisabled={props.statesSet != undefined ? !props.statesSet : false}
          hideSelectedOptions={false}
          closeMenuOnSelect={false}
          components={{
            DropdownIndicator: Indicator,
          }}
          open
          isMulti
          styles={{
            indicatorSeparator: (base) => ({
              ...base,
              display: 'none',
            }),
            control: (base) => ({
              ...base,
              backgroundColor: '#fafbfc',
            }),
            dropdownIndicator: (base) => ({
              ...base,
              color: '#00698B',
              padding: '1rem',
            }),
            multiValue: (base) => ({
              ...base,
              backgroundColor: '#e0f4f4',
              color: '#00698B',
            }),
            multiValueLabel: (base) => ({
              ...base,
              color: '#00698B',
            }),
            placeholder: (base) => ({
              ...base,
              paddingLeft: '1rem',
            }),
          }}
        />

        {props?.closeIcon && props?.value?.length > 0 && (
          <button
            className="rounded-full w-5 flex flex-col justify-center items-center absolute right-2 inset-y-0"
            onClick={() => props.onClickIconClear()}
          >
            <XIcon className="h-5 w-5" />
          </button>
        )}
      </div>
    </div>
  );
};

export default MultiSelect;
