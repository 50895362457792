import types from './types';
import { AgingAlertsDataActionType, AgingAlertsDetails } from './interfaces';

const initState: AgingAlertsDetails = {
  agingAlertsList: null,
  agingAlertsCount: 0,
};

const reducer = (state: AgingAlertsDetails = initState, action: AgingAlertsDataActionType): AgingAlertsDetails => {
  switch (action.type) {
    case types.SET_AGING_ALERTS_LIST:
      return {
        ...state,
        agingAlertsList: action.payload,
      };

    case types.SET_AGING_ALERTS_COUNT:
      return {
        ...state,
        agingAlertsCount: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
