import { useHistory } from 'react-router-dom';
import './index.css';

export interface NavcardProps {
  title: string;
  description: string;
  link: string;
  tag?: string;
}

const Navcard: React.FC<NavcardProps> = ({ title, description, link }) => {
  const history = useHistory();
  return (
    <>
      <div onClick={() => history.push(link)} className="card">
        <h3 className="text-2xl mb-1 text-textDarkGrey font-bold">{title}</h3>
        <p>{description}</p>
      </div>
    </>
  );
};

export default Navcard;
