import React from 'react';
import { XIcon } from '@heroicons/react/solid';
import { FilePreviewProps } from './filePreview.types';

const FilePreview: React.FC<FilePreviewProps> = ({ src, onClose }) => {
  return (
    <>
      {typeof src === 'string' && (
        <div className="relative w-12 h-12 rounded-xl m-2 shadow overflow-hidden">
          <img src={src} alt="preview" className="h-full w-full object-cover" />
          <button
            type="button"
            onClick={onClose}
            className="absolute top-1 right-1 flex p-1 box-border border border-white bg-gray90 text-white rounded-full"
          >
            <XIcon className="h-2 w-2" />
          </button>
        </div>
      )}
    </>
  );
};

export default FilePreview;
