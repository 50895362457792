import Button from 'components/common/Button';
import { ViewAsEmr } from 'utils/ViewAsEmr';
import { LoginAsPatient } from 'utils/LoginAsPatient';
import { ViewChart } from 'utils/ViewChart';
import { ReactComponent as Eye } from 'assets/icons/eye.svg';
import { ReactComponent as Bag } from 'assets/icons/bag.svg';
import { ReactComponent as Acc } from 'assets/icons/acc.svg';
import defaultpic from 'assets/images/profile-generic.png';
import { SMSModalProps } from './smsModal.types';
import MessagesSection from './MessagesSection';

const SMSModal: React.FC<SMSModalProps> = ({ patientDetails }) => {
  const addDashes = (phone: string) => {
    return `${phone.slice(0, 3)}-${phone.slice(3, 6)}-${phone.slice(6)}`;
  };

  return (
    <div className="p-10 my-auto w-screen max-w-screen-md">
      <div className="flex justify-between gap-12">
        <div className="flex justify-start items-center min-w-fit w-1/5 px-2">
          <div className="w-16 h-16">
            <img
              src={patientDetails?.profileImage ? patientDetails?.profileImage : defaultpic}
              className="w-14 h-14 object-cover rounded-full"
              alt="Patient image"
            />
          </div>
          <div className="mx-3">
            <div className="text-darkBlue font-bold text-xl">
              {patientDetails.firstName} {patientDetails.lastName}
            </div>
            <div className="text-gray-400 font-bold">
              {patientDetails.phone.phoneNumber && addDashes(patientDetails.phone.phoneNumber)}
            </div>
          </div>
        </div>
        <div className="flex flex-col md:flex-row">
          <Button ownClasses="font-normal" type="depressed" onClick={() => ViewChart(patientDetails._id, true)}>
            <Eye className="mr-2 w-5" />
            View chart
          </Button>
          <Button ownClasses="font-normal" type="depressed" onClick={() => ViewAsEmr(patientDetails._id)}>
            <Bag className="mr-2 w-5" />
            View in EMR
          </Button>
          <Button ownClasses="font-normal" type="depressed" onClick={() => LoginAsPatient(patientDetails._id)}>
            <Acc className="mr-2 w-5" />
            Log in as Patient
          </Button>
        </div>
      </div>

      <div className="m-2 border-t" />

      <MessagesSection patientId={patientDetails._id} patientPhone={patientDetails.phone} />
    </div>
  );
};

export default SMSModal;
