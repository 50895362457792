import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';

export function decodeToken() {
  const authToken: any = window.localStorage.getItem('accessToken');
  let decodedToken = {};
  try {
    decodedToken = jwtDecode(authToken);
  } catch (error) {
    console.log('=========================== Token decoding error ==========================', error);
    return {};
  }
  return decodedToken;
}

export function decodeResetToken() {
  const resetToken: any = window.localStorage.getItem('refreshToken');
  let decodedResetToken = {};
  try {
    decodedResetToken = jwtDecode(resetToken);
  } catch (error) {
    console.log('=========================== Reset Token decoding error ==========================', error);
    return {};
  }
  return decodedResetToken;
}

// getUserType() {
//     const decodedToken = this.decodeToken();
//     const { user } = decodedToken;
//     if (user && user.type) {
//         return user.type;
//     }
//     return "no user";
// }

// getUserId() {
//     const decodedToken = this.decodeToken();
//     const { user } = decodedToken;
//     if (user && user._id) {
//         return user._id;
//     }
//     return "no user";
// }

export function getUserDetails() {
  const decodedToken = decodeToken();
  return decodedToken;
}
//   isAllowed(userType: string, featureRight: any) {
//     const userRights = userRolesAndRights[userType].rights;
//     return userRights.includes(featureRight);
//   }

export function hasAccessToModule(permissions: string[], featureRightsList: string[], userType?: string) {
  if (userType === 'Admin') {
    return true;
  } else {
    return permissions.some((right: string) => featureRightsList.includes(right));
  }
}
