import { AppointmentTypeDetails } from './interfaces';
import types from './types';

export const setAppointmentTypesList = (data: AppointmentTypeDetails[]) => ({
  type: types.SET_APPOINTMENT_TYPE_LIST,
  payload: data,
});

export const setTotalAppointmentTypes = (data: number) => ({
  type: types.SET_TOTAL_APPOINTMENT_TYPE,
  payload: data,
});
const actions = {
  setAppointmentTypesList,
  setTotalAppointmentTypes,
};

export default actions;
