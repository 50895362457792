import Button from 'components/common/Button';
import { ITabs } from 'pages/Admin/PatientSummary';
import defaultpic from 'assets/images/profile-generic.png';

export interface HeaderProps {
  activeTab: ITabs;
  setActiveTab: (type: ITabs) => void;
  patientDetails?: any;
}

const Header: React.FC<HeaderProps> = ({ activeTab, setActiveTab, patientDetails }) => {
  type BtnProps = {
    text: string;
    value: ITabs;
  };
  const btns: BtnProps[] = [
    { text: 'Overview', value: 'overview' },
    { text: 'Messages', value: 'messages' },
    { text: 'Front desk', value: 'front desk' },
    { text: 'Patient documents', value: 'patient documents' },
    { text: 'Staff notes', value: 'staff notes' },
    { text: 'Chart', value: 'chart' },
    { text: 'History', value: 'history' },
  ];

  return (
    <header className="flex justify-between lg:items-end">
      <div className="flex flex-col lg:flex-row flex-wrap items-center">
        {btns.map((b) => (
          <Button
            key={b.text}
            onClick={() => setActiveTab(b.value)}
            ownClasses="m-2 rounded-full"
            type={b.value === activeTab ? 'success' : 'secondary'}
          >
            {b.text}
          </Button>
        ))}
      </div>
      <div className="flex justify-start items-center min-w-fit w-1/5 px-2">
        <div className="mx-6">
          <div className="text-darkBlue font-bold text-xl">
            {patientDetails?.firstName} {patientDetails?.lastName}
          </div>
          <div className="text-gray-400 text-right font-bold">{patientDetails?.membership}</div>
        </div>
        <div className="w-16 h-16">
          <img
            src={patientDetails?.profileImage ? patientDetails?.profileImage : defaultpic}
            className="w-14 h-14 object-cover rounded-full"
            alt="Patient image"
          />
        </div>
      </div>
    </header>
  );
};

export default Header;
