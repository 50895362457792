import types from './types';

export const setadminAutomationsList = (data: any) => ({
  type: types.SET_ADMIN_AUTOMATION_LIST,
  payload: data,
});

export const setadminAutomationsCount = (data: number) => ({
  type: types.SET_ADMIN_AUTOMATION_COUNT,
  payload: data,
});

const actions = {
  setadminAutomationsList,
  setadminAutomationsCount,
};

export default actions;
