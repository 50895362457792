import Button from '../common/Button';
import Table from '../common/Table';
import CircleAvatar from '../common/CircleAvatar';
import SearchForm from './SearchForm';
import { ReactComponent as EyeSvg } from '../../assets/icons/eye.svg';
import { ReactComponent as MedicineChestSvg } from '../../assets/icons/medicine-сhest.svg';
import { ReactComponent as CircleProfileSvg } from '../../assets/icons/circle-profile.svg';
import { ReactComponent as ArrowsSvg } from '../../assets/icons/arrows.svg';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { useRef, useState } from 'react';
import { CheckCategoryColor } from 'utils/CheckCategoryColor';
import { CheckStatusColor } from 'utils/CheckStatusColor';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';
import PopoverTask from 'components/PopoverTask';
import { dateTimeFormatter } from 'utils/dateTimeFormatter';
import _ from 'lodash';
import { useEffect } from 'react';
import LoadingOverlay from 'react-loading-overlay-ts';
import { hasAccessToModule } from 'auth/authorization';
import Cookies from 'js-cookie';
import { LoginAsPatient } from 'utils/LoginAsPatient';
import { ViewAsEmr } from 'utils/ViewAsEmr';
import { ViewChart } from 'utils/ViewChart';
import { setSelectedTaskId } from 'redux/tasks/actions';
import Transfer from 'components/PopoverTask/components/Transfer';
import socket from 'socket/socket';
import './queuelist.css';
import TaskApi from 'api/TaskAPI';
import { toast } from 'react-toastify';
import LookUpAPI from 'api/LookUpsAPI';
import moment from 'moment';
export interface Props {
  fetchTasksList: (value?: any) => void;
  fetchUrgentCount: () => void;
  loading: boolean;
}

const QueueList: React.FC<Props> = ({ fetchTasksList, loading, fetchUrgentCount }) => {
  const dispatch = useDispatch();
  const [transferTaskId, setTransferTaskId] = useState('');
  const taskList = useSelector((state: RootStateOrAny) => state.tasks.taskList);
  const userId = useSelector((state: RootStateOrAny) => state.userProfile.userDetails._id);
  const selectedTaskId = useSelector((state: RootStateOrAny) => state.tasks.selectedTask);
  const [inlineFilterActive, setInlineFilterActive] = useState<string[]>(['All']);
  const [categories, setCategories] = useState<string[]>();
  const [filterModalCategory, setFilterModalCategory] = useState('');
  const [params, setParams] = useState({});
  const [showPopover, setShowPopover] = useState(false);
  const [popup, setPopup] = useState(false);
  const [selectedTask, setSelectedTask] = useState('');
  const [selectedPage, setSelectedPage] = useState(1);
  const [searchFilterParams, setSearchFilterParams] = useState({});
  const [pageItems, setPageItems] = useState([]);
  const totalTaskCount = useSelector((state: RootStateOrAny) => state.tasks.totalCount);
  const userInfo = useSelector((state: RootStateOrAny) => state.userProfile.userDetails);
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const [currentTime, setCurrentTime] = useState(moment().format('hh:mm A'));

  const [sortKey, setSortKey] = useState<string | undefined>('');
  const [sortOrder, setSortOrder] = useState<string | undefined>();
  const [categoryList, setCategoryList] = useState([]);
  let payload = {};
  const totalPage = totalTaskCount % 50 === 0 ? Math.floor(totalTaskCount / 50) : Math.floor(totalTaskCount / 50) + 1;
  const headerItems = [
    {
      title: 'Patient',
      sortKey: 'patientInfo.name',
    },
    {
      title: 'Due',
      sortKey: 'dueDate',
    },
    {
      title: 'Category',
      sortKey: 'category',
    },
    {
      title: 'Details',
      sortKey: 'details.note',
    },
    {
      title: 'Doctor',
      sortKey: 'doctorInfo.name',
    },
    {
      title: 'Assigned to',
      sortKey: 'assignedToInfo.name',
    },
    {
      title: 'Status',
      sortKey: 'status',
    },
    {
      title: 'Actions',
      align: 'center',
    },
  ];

  useEffect(() => {
    if (Cookies.get('newTaskId')) {
      const id: any = Cookies.get('newTaskId');
      setShowPopover(true);
      setSelectedTask(id);
    }
    if (userInfo.userType.name !== 'Admin') {
      payload = { userType: userInfo.userType.name };
    }
    LookUpAPI.fetchTaskCategories(payload)
      .then((res) => {
        if (res?.data?.errorCode === 0) {
          if (res?.data?.data) {
            console.log('LookupAPI categories', res?.data?.data);
            setCategoryList(res?.data?.data);
          }
        }
      })
      .catch((err) => {
        if (err?.response?.data?.message) {
          toast.error(err.response.data.message);
        } else toast.error('Failed to fetch Categories');
      });
    // }
  }, []);

  const onClickLinkToPreConsult = (preConsultTaskId: string) => {
    setSelectedTask(preConsultTaskId);
  };

  useEffect(() => {
    socket.off('newTaskCreated');
    socket.on('newTaskCreated', (data) => {
      if (data.taskAudience && data.taskAudience.includes(userInfo.userType.name)) {
        console.log('========task refreshed=========', data);
        taskRefreshed();
        fetchUrgentCount();
      }
    });
  }, [selectedPage, searchFilterParams, inlineFilterActive, params]);

  const taskRefreshed = () => {
    const filterParams: any = { ...params };
    filterParams['limit'] = 50;
    filterParams['pageNo'] = selectedPage - 1;
    fetchTasksList(filterParams);
  };

  const inlineCategoryHandler = (inlineCategoryValue: string) => {
    let inlineCategories, inlineCategoriesHistory;

    //Enable Multiple Category
    if (inlineCategoryValue !== 'All' && !inlineFilterActive.includes(inlineCategoryValue)) {
      inlineCategories =
        inlineCategoryValue !== 'History'
          ? [...inlineFilterActive.filter((item) => item !== 'All' && item !== 'History'), inlineCategoryValue]
          : [...inlineFilterActive.filter((item) => item !== 'All' && item !== 'History')];
      setInlineFilterActive(
        inlineCategoryValue == 'History' || inlineFilterActive.includes('History')
          ? [...inlineCategories, 'History']
          : inlineCategories,
      );
      inlineCategoriesHistory =
        inlineCategoryValue == 'History' || inlineFilterActive.includes('History')
          ? [...inlineCategories, 'History']
          : inlineCategories;

      setCategories(inlineCategoriesHistory);
      if (filterModalCategory) {
        inlineCategories = [...new Set([...inlineCategories, filterModalCategory])];
      }
    }

    //Disable Category
    if (inlineFilterActive.includes(inlineCategoryValue)) {
      inlineCategories = [...inlineFilterActive.filter((item) => item !== inlineCategoryValue && item !== 'History')];
      setCategories(inlineCategories);
      setInlineFilterActive(
        inlineCategoryValue !== 'History' && inlineFilterActive.includes('History')
          ? [...inlineCategories, 'History']
          : inlineCategories,
      );
      inlineCategoriesHistory =
        inlineFilterActive.includes('History') && inlineCategoryValue !== 'History'
          ? [...inlineCategories, 'History']
          : [...inlineCategories];

      if (inlineCategories.length == 0) {
        if (
          (inlineCategoriesHistory &&
            inlineCategoriesHistory.includes('History') &&
            inlineCategoryValue == 'History') ||
          !inlineCategoriesHistory.includes('History')
        ) {
          inlineCategories = inlineCategoriesHistory = ['All'];
          setInlineFilterActive(['All']);
        }
      }
      if (!inlineCategories?.includes('All') && filterModalCategory) {
        inlineCategories = [...new Set([...inlineCategories, filterModalCategory])];
      }
    }

    //Enabling All
    if (inlineCategoryValue == 'All') {
      inlineCategories = ['All'];
      setCategories([]);
      setInlineFilterActive(['All']);
    }

    const filterParams: any = {
      ...searchFilterParams,
      ...(sortKey && { sortField: sortKey }),
      ...(sortOrder && { sortOrder }),
      ...(inlineCategoriesHistory && inlineCategoriesHistory.includes('History') && { completed: true }),
      ...(inlineCategories &&
        inlineCategories.length > 0 &&
        !inlineCategories?.includes('All') && { category: `${inlineCategories}` }),
      ...(inlineCategories?.includes('All') && filterModalCategory !== '' && { category: `${filterModalCategory}` }),
    };
    setParams(filterParams);
    filterParams['limit'] = 50;
    filterParams['pageNo'] = 0;
    setSelectedPage(1);
    console.log('inlineCategoryHandler() filterParams:', filterParams);
    fetchTasksList(filterParams);
  };

  const filterSearchTextHandler = (searchTextFilterValue?: any) => {
    //Get inline category filters
    let filterCategories = categories ? [...categories] : [];
    let filterValues;

    //Get filter values on search
    if (!_.isEmpty(searchTextFilterValue)) {
      if (Object.keys(searchTextFilterValue).includes('category')) {
        filterCategories = [...new Set([...filterCategories, searchTextFilterValue.category])];
        setFilterModalCategory(searchTextFilterValue.category);
        if (searchFilterParams) filterValues = searchFilterParams;
      }
      if (!_.isEmpty(_.omit(searchTextFilterValue, 'category'))) {
        filterValues = _.omit(searchTextFilterValue, 'category');
        if (!_.isEmpty(filterValues)) setSearchFilterParams(filterValues);
      }
      if (_.isEmpty(_.omit(searchTextFilterValue, 'category'))) {
        filterValues = null;
        setSearchFilterParams({});
      }
      if (_.isEmpty(searchTextFilterValue.category)) setFilterModalCategory('');
    } else {
      setSearchFilterParams({});
      setFilterModalCategory('');
    }

    const filterParams: any = {
      ...(filterValues && filterValues),
      ...(sortKey && { sortField: sortKey }),
      ...(sortOrder && { sortOrder }),
      ...(inlineFilterActive && inlineFilterActive.includes('History') && { completed: true }),
      ...(filterCategories.length !== 0 &&
        !filterCategories.includes('History') &&
        !filterCategories.includes('All') && {
          category: `${filterCategories}`,
        }),
      ...(filterCategories &&
        filterCategories.includes('History') &&
        filterCategories.length > 1 && { category: `${filterCategories.filter((item) => item !== 'History')}` }),
      ...(!_.isEmpty(searchTextFilterValue) &&
        filterModalCategory &&
        filterCategories &&
        searchTextFilterValue.category == filterModalCategory &&
        !_.isEqual(filterModalCategory, filterCategories) && {
          category: `${[...new Set([...filterCategories, filterModalCategory])].filter((item) => item !== 'History')}`,
        }),
    };
    setParams(filterParams);
    filterParams['pageNo'] = 0;
    filterParams['limit'] = 50;
    setSelectedPage(1);
    fetchTasksList(filterParams);
    console.log('filterSearchTextHandler() filterParams:', filterParams);
  };

  const taskClickHandler = (id: string) => {
    setSelectedTask(id);
    setShowPopover(true);
  };

  const popoverCancelClickHandler = () => {
    setShowPopover(false);
    setSelectedTask('');
    dispatch(setSelectedTaskId(null));
  };

  const popoverCancelComplete = () => {
    setShowPopover(false);
    setSelectedTask('');
    dispatch(setSelectedTaskId(null));
  };

  const onSortClick = (title?: string, key?: string, sortOrder?: string) => {
    setSelectedPage(1);
    setSortKey(key);
    setSortOrder(sortOrder);
    let filterCategories = categories ? [...categories] : [];
    if (filterModalCategory !== '')
      filterCategories = [...new Set([...filterCategories, filterModalCategory])].filter((item) => item !== 'History');

    const filterParams: any = {
      ...searchFilterParams,
      ...(key && { sortField: key }),
      ...(sortOrder && { sortOrder }),
      ...(inlineFilterActive && inlineFilterActive.includes('History') && { completed: true }),
      ...(inlineFilterActive &&
        filterCategories.length > 0 && {
          category: `${filterCategories}`,
        }),
    };
    setParams(filterParams);
    filterParams['pageNo'] = 0;
    filterParams['limit'] = 50;
    fetchTasksList(filterParams);
  };

  const onPageClick = (e: any, value: number) => {
    e.preventDefault();
    setSelectedPage(value);
    const filterParams: any = { ...params };
    filterParams['limit'] = 50;
    filterParams['pageNo'] = value - 1;
    fetchTasksList(filterParams);
  };

  const onNextClick = (e: any) => {
    e.preventDefault();
    if (selectedPage !== totalPage) {
      setSelectedPage(selectedPage + 1);
      const filterParams: any = { ...params };
      filterParams['limit'] = 50;
      filterParams['pageNo'] = selectedPage;
      fetchTasksList(filterParams);
    }
  };

  const onPreviousClick = (e: any) => {
    e.preventDefault();
    if (selectedPage !== 1) {
      setSelectedPage(selectedPage - 1);
      const filterParams: any = { ...params };
      filterParams['limit'] = 50;
      filterParams['pageNo'] = selectedPage - 2;
      fetchTasksList(filterParams);
    }
  };

  const handleTransfer = (e: any, taskId: string) => {
    console.log('Transfer Clicked');
    popup ? setTransferTaskId('') : setTransferTaskId(taskId);
    setPopup(!popup);
    e.stopPropagation();
  };

  useEffect(() => {
    let leftSide = selectedPage - 2;
    if (leftSide <= 0) leftSide = 1;
    let rightSide = selectedPage + 2;
    if (rightSide > totalPage) rightSide = totalPage;
    const pages: any = [];
    for (let number = leftSide; number <= rightSide; number++) {
      pages.push(number);
    }
    setPageItems(pages);
  }, [selectedPage, totalTaskCount]);

  useEffect(() => {
    if (selectedTaskId) {
      console.log('Selected Task ID is :', selectedTaskId);
      taskClickHandler(selectedTaskId);
    }
  }, [selectedTaskId]);

  useEffect(() => {
    tableContainerRef?.current?.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [taskList]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(moment().format('hh:mm A'));
      return () => clearInterval(interval);
    }, 30000);
    console.log('moment currentTime---', currentTime);
  }, [currentTime]);

  return (
    <div className="flex flex-col flex-auto p-11 pt-4 bg-background relative">
      <h2 className="text-black text-2xl font-medium my-5">Tasks</h2>
      <div className="flex flex-row flex-wrap gap-y-3 items-center justify-between my-5">
        <div className="flex flex-row flex-nowrap items-center">
          <Button
            type={`${inlineFilterActive.includes('All') ? 'success' : 'secondary'}`}
            ownClasses="mr-3 pl-4 pr-5 py-1 text-lg rounded-full font-normal"
            onClick={() => inlineCategoryHandler('All')}
          >
            <i className="w-2.5 h-2.5 mr-2 bg-white rounded-full" />
            All
          </Button>

          {categoryList &&
            categoryList.map((eachItem: any) => {
              return (
                <div key={eachItem}>
                  <Button
                    type={`${inlineFilterActive.includes(`${eachItem}`) ? 'success' : 'secondary'}`}
                    ownClasses="mr-3 pl-4 pr-5 py-1 text-lg rounded-full font-normal"
                    onClick={() => inlineCategoryHandler(`${eachItem}`)}
                  >
                    <i
                      className={`w-2.5 h-2.5 mr-2 ${CheckCategoryColor(eachItem)} rounded-full ${
                        inlineFilterActive === eachItem && eachItem === 'Messages' && `border`
                      }`}
                    />
                    {eachItem}
                  </Button>
                </div>
              );
            })}

          <Button
            type={`${inlineFilterActive.includes('History') ? 'success' : 'secondary'}`}
            ownClasses="mr-3 pl-4 pr-5 py-1 text-lg rounded-full font-normal"
            onClick={() => inlineCategoryHandler('History')}
          >
            <i className="w-2.5 h-2.5 mr-2 bg-violet rounded-full" />
            History
          </Button>
        </div>
        <SearchForm
          filterSearchTextHandler={filterSearchTextHandler}
          sortField={sortKey}
          sortOrder={sortOrder}
          inlineFilterActive={inlineFilterActive}
          categories={categories}
          filterModalCategory={filterModalCategory}
        />
      </div>
      {showPopover ? (
        <>
          <PopoverTask
            onCancel={popoverCancelClickHandler}
            onClickLinkToPreConsult={onClickLinkToPreConsult}
            taskId={selectedTask}
            onCanelComplete={popoverCancelComplete}
          />
        </>
      ) : null}
      <LoadingOverlay active={loading} spinner className="loaderOverlay">
        <div ref={tableContainerRef} style={{ overflowX: 'hidden' }}>
          <Table headerItems={headerItems} onSortClick={onSortClick}>
            {taskList &&
              taskList.map((eachTask: any) => {
                return (
                  <tr
                    className={`h-14 ${
                      eachTask?.status === 'URGENT'
                        ? 'border-t border-lightGreyBackground bg-red bg-opacity-5'
                        : 'border-t border-lightGreyBackground'
                    } cursor-pointer`}
                    key={eachTask?._id}
                    onClick={() =>
                      hasAccessToModule(userInfo.userRoleInfo.pagePermissions, ['TASK_DETAILS_VIEW']) &&
                      taskClickHandler(eachTask?._id)
                    }
                  >
                    <td className="px-6 pb-4 pt-5 border-r border-lightGreyBackground relative">
                      {eachTask?.status === 'URGENT' && <div className="absolute left-0 top-0 h-full bg-red w-1" />}
                      <p className={`${eachTask?.isInteractedPatient && 'font-bold'}`}>{eachTask?.patientInfo?.name}</p>
                    </td>
                    <td className="px-6 pb-4 pt-5 border-r border-lightGreyBackground">
                      <p className="text-tableLightGrey">
                        {eachTask?.dueDate && currentTime ? dateTimeFormatter(eachTask?.dueDate) : 'No due date'}
                      </p>
                    </td>
                    <td className="px-6 pb-4 pt-5 border-r border-lightGreyBackground">
                      <div className="flex flex-row items-center">
                        <i className={`w-2.5 h-2.5 mr-2 ${CheckCategoryColor(eachTask?.category)} rounded-full`} />
                        {eachTask?.category === 'Appointment' ? (
                          eachTask?.categoryInfo ? (
                            <p>{eachTask?.categoryInfo}</p>
                          ) : (
                            <p>{eachTask?.category}</p>
                          )
                        ) : (
                          <p>{eachTask?.category}</p>
                        )}
                      </div>
                    </td>
                    <td className="px-6 pb-4 pt-5 border-r border-lightGreyBackground">
                      {eachTask?.isAutomated ? (
                        <p className="w-56 truncate underline">
                          {eachTask?.details?.note ? `[Automated] ${eachTask?.details?.note}` : `[Automated]`}
                        </p>
                      ) : (
                        <p className="w-56 truncate underline max-w-xs">{eachTask?.details?.note}</p>
                      )}
                    </td>
                    <td className="px-6 pb-4 pt-5 border-r border-lightGreyBackground">
                      <p>{eachTask?.doctorInfo?.name}</p>
                    </td>
                    <td className="px-6 pb-4 pt-5 border-r border-lightGreyBackground">
                      {eachTask.assignedToInfo?._id ? (
                        <div className="flex flex-row items-center">
                          <div className="relative mr-4">
                            <CircleAvatar src={eachTask?.assignedToInfo?.profileImage} ownClasses="h-7 w-7" />
                            <p
                              className={`absolute top-4 -right-1.5 px-1 ${
                                eachTask?.assignedToInfo?.userRoleInfo &&
                                'bg-' + eachTask?.assignedToInfo?.userRoleInfo?.color
                              } text-white uppercase text-half rounded-2xl`}
                            >
                              {eachTask?.assignedToInfo?.userRoleInfo?.shortName}
                            </p>
                          </div>
                          <p className="mr-2">
                            {eachTask?.assignedToInfo?._id === userId ? 'Me' : eachTask?.assignedToInfo?.name}
                          </p>
                          {eachTask?.status !== 'Completed' && (
                            <div className="relative">
                              <button onClick={(e) => handleTransfer(e, eachTask?._id)}>
                                <ArrowsSvg className="h-2.5" />
                              </button>
                              {popup && eachTask?._id === transferTaskId && (
                                <Transfer
                                  popup={popup}
                                  setPopup={setPopup}
                                  taskId={eachTask?._id}
                                  category={eachTask?.category}
                                  assignmentUser={eachTask?.assignedToInfo?.userRoleInfo?.userType?.shortCode}
                                  appointmentStatus={eachTask?.requestInfo?.appointmentStatus}
                                  onCancel={popoverCancelComplete}
                                />
                              )}
                            </div>
                          )}
                        </div>
                      ) : (
                        'Unassigned'
                      )}
                    </td>
                    <td className="px-6 pb-4 pt-5 border-r border-lightGreyBackground">
                      <p className={`${CheckStatusColor(eachTask?.status)}`}>{eachTask?.status}</p>
                    </td>
                    <td className="px-6 pb-4 pt-5 flex justify-evenly">
                      {hasAccessToModule(userInfo.userRoleInfo.pagePermissions, ['VIEW_PATIENT_EMR']) && (
                        <button
                          onClick={(event) => {
                            ViewChart(eachTask?.patientInfo?._id), event.stopPropagation();
                          }}
                        >
                          <EyeSvg className="h-4" />
                        </button>
                      )}
                      {hasAccessToModule(userInfo.userRoleInfo.pagePermissions, ['PATIENT_DETAILS_VIEW']) && (
                        <button
                          className="mx-4"
                          onClick={(event) => {
                            ViewAsEmr(eachTask?.patientInfo?._id), event.stopPropagation();
                          }}
                        >
                          <MedicineChestSvg className="h-4" />
                        </button>
                      )}
                      {hasAccessToModule(userInfo.userRoleInfo.pagePermissions, ['VIEW_AS_PATIENT']) && (
                        <button
                          onClick={(event) => {
                            LoginAsPatient(eachTask?.patientInfo?._id), event.stopPropagation();
                          }}
                        >
                          <CircleProfileSvg className="h-4" />
                        </button>
                      )}
                    </td>
                  </tr>
                );
              })}
          </Table>
        </div>
      </LoadingOverlay>
      <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
        <div className="flex-1 flex justify-between sm:hidden">
          <a
            href="#"
            className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
          >
            Previous
          </a>
          <a
            href="#"
            className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
          >
            Next
          </a>
        </div>
        <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
          <div>
            {totalTaskCount > 0 ? (
              <p className="text-sm text-gray-700">
                Showing{' '}
                <span className="font-medium">{selectedPage === 1 ? selectedPage : (selectedPage - 1) * 50 + 1}</span>{' '}
                to{' '}
                <span className="font-medium">
                  {selectedPage * 50 > totalTaskCount ? totalTaskCount : selectedPage * 50}
                </span>{' '}
                of <span className="font-medium">{totalTaskCount}</span> tasks
              </p>
            ) : (
              <p className="text-sm text-gray-700">
                Showing <span className="font-medium">{totalTaskCount}</span> tasks
              </p>
            )}
          </div>
          <div>
            <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
              <a
                onClick={(e) => onPreviousClick(e)}
                href="#"
                className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
              >
                <span className="sr-only">Previous</span>
                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
              </a>
              {pageItems.map((item: any) => (
                <a
                  href="#"
                  key={item}
                  aria-current="page"
                  onClick={(e) => onPageClick(e, item)}
                  className={`z-10 ${
                    selectedPage === item ? 'bg-green text-black' : 'border-gray-300 text-gray-500 bg-white'
                  }  relative inline-flex items-center px-4 py-2 border text-sm font-medium`}
                >
                  {item}
                </a>
              ))}
              <a
                href="#"
                onClick={(e) => onNextClick(e)}
                className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
              >
                <span className="sr-only">Next</span>
                <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
              </a>
            </nav>
          </div>
        </div>
      </div>
      <div className="w-full bg-background h-8 fixed bottom-0 left-1 z-10" />
    </div>
  );
};

export default QueueList;
