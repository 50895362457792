import PatientAPI from 'api/PatientAPI';
import config from '../config';

const { navigation } = config;

export const LoginAsPatient = (id: string) => {
  PatientAPI.fetchLoginAsPatient(id).then((res) => {
    const { data } = res.data;
    if (data) {
      window.open(`${navigation.loginAsPatientUrl}?login-token=${data.patientViewToken}`, '_blank');
    }
  });
};
