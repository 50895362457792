import React, { useState, useRef, useEffect } from 'react';
import ChatSubmit from '../ChatSubmit';
import ChatInputFile from '../ChatInputFile';
import './ChatInput.css';
import ChannelApi from 'api/ChannelApi';
import { toast } from 'react-toastify';
import FilePreview from '../FilePreview';

export interface ChatInputProps {
  chatSendMessage: (value: string, image: any) => void;
  clearNotification: () => void;
}

const ChatInput: React.FC<ChatInputProps> = ({ chatSendMessage, clearNotification }) => {
  const [filePreview, setFilePreview] = useState<string | ArrayBuffer | null>(null);
  const [fileSelected, setFileSelected] = useState<File | null>(null);
  const [sendDisabled, setSendDisabled] = useState<boolean>(false);
  const [input, setInput] = useState('');
  const [files, setFiles] = useState<FileList | null>(null);
  const inp: any = useRef();

  const handleSubmit = async (e: any, value?: any) => {
    if (!value) {
      value = e.target.value;
      e.target.value = '';
    }
    let b = false;
    setSendDisabled(true);
    if (e.keyCode === 13) {
      b = true;
    }
    const message = e?.target?.message?.value;
    inp?.current?.reset();
    e.preventDefault();
    const file = fileSelected && (await uploadFileForChat(fileSelected));
    const data = file?.data?.data;

    if ((b ? value : message) || data) {
      if ((b ? !value.trim().length : !message.trim().length) && !data) {
        return;
      }
      chatSendMessage(b ? value : message, data);
      clearFile();
    }

    if (input || files) {
      setInput('');
      setFiles(null);
    }
    !b && e?.target?.reset?.();
  };

  const onEnterPress = (e: any) => {
    if (e.keyCode == 13 && e.shiftKey == false) {
      const value = e.target.value;
      e.target.value = '';
      e.preventDefault();
      {
        !sendDisabled ? handleSubmit(e, value) : null;
      }
      e.target.value = '';
      setFilePreview(null);
    }
  };

  useEffect(() => {
    {
      files ? setSendDisabled(false) : setSendDisabled(true);
    }
  }, [files]);

  const uploadFileForChat = (fileSelected: File): any => {
    return ChannelApi.uploadImage({
      chatFile: fileSelected,
    }).catch(() => toast.error('Unable upload image please try again!'));
  };

  const onChangeFileHandler = (file: File | null, preview: string | ArrayBuffer | null) => {
    setFilePreview(preview);
    setFileSelected(file);
  };

  const clearFile = () => {
    setSendDisabled(false);
    setFiles(null);
    onChangeFileHandler(null, null);
  };

  const clear = (e: any) => {
    setSendDisabled(false);
    setFiles(null);
    e.target.value = '';
    setFilePreview(null);
  };

  useEffect(() => {
    const file = files && files[0];
    let preview: string | ArrayBuffer | null;
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        preview = reader.result;
        onChangeFileHandler(file, preview);
      };

      reader.readAsDataURL(file);
    }
  }, [files]);

  return (
    <>
      <form onSubmit={handleSubmit} ref={inp} className="bg-white p-6 pt-4">
        <div className="bg-white shadow rounded py-3 px-4">
          <FilePreview src={filePreview} onClose={clearFile} />
          <div className="flex justify-between items-center">
            <div className="flex items-center w-full">
              <label className="label w-full align-top items-stretch relative after:w-full focus:outline-none">
                <textarea
                  className="textarea max-h-20 resize-none w-full focus:outline-none overflow-auto border-none background-none"
                  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
                  onInput={(e: any) => {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    setSendDisabled(false);
                    {
                      e.target.value === '' ? setSendDisabled(true) : setSendDisabled(false);
                    }
                    e.target.parentNode.dataset.value = e.target.value;
                  }}
                  rows={1}
                  name="message"
                  placeholder="Write your message..."
                  onClick={() => clearNotification()}
                  onFocus={() => clearNotification()}
                  onKeyDown={(e: any) => onEnterPress(e)}
                />
              </label>
              <ChatInputFile files={files} setFiles={setFiles} />
            </div>
            <ChatSubmit sendDisabled={sendDisabled} onClose={clear} />
          </div>
        </div>
      </form>
    </>
  );
};

export default ChatInput;
