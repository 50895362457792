import Button from 'components/common/Button';
import { ReactComponent as PlusSvg } from 'assets/icons/lifeMD-plus.svg';
import ExpandableElement from '../ExpandableContent';
import LoadingOverlay from 'react-loading-overlay-ts';
import { useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { ReactComponent as ArrowUpSvg } from '../../../assets/icons/arrow-up.svg';
import MessageCard, { IMessage } from 'components/Admin/Patient/Messages/MessageItem';
import avatar from 'assets/images/avatar.png';
import MessagePatientForm from './Messages/MessagePatientForm';
import socket from 'socket/socket';
import { timeInSecondsFromNow } from 'utils/timeInSecondsFromNow';
import { dateTimeFormatter } from 'utils/dateTimeFormatter';
import { useSelector, RootStateOrAny } from 'react-redux';
import { toast } from 'react-toastify';
import Modal from '../../PatientMessagePopup/Modal';
import ChannelApi from 'api/ChannelApi';
import Input from 'components/common/Input';
import { MessagesFromBackend } from './Messages/message.types';
import moment from 'moment';
import PatientAPI from 'api/PatientAPI';
import { ChannelData } from 'redux/channels/interfaces';
import * as Sentry from '@sentry/react';
import { debounce } from 'lodash';
import { isUserHasPermission } from 'components/PopoverTask/Popover';
import { useParams } from 'react-router-dom';

export interface IThread {
  subject: string;
  id: string;
  newMessagesAmount: number;
  messages: IMessage[];
}

export interface MessageProps {
  patientDetails: any;
  getPatientDetials: any;
}

export interface receiverDetailsItem {
  id: string;
}

export const usersHasPermissionToSentMessage = [
  'Physician',
  'Medical Assistant',
  'Nurse Practitioner',
  'Customer Service Rep',
];

const Messages: React.FC<MessageProps> = ({ patientDetails, getPatientDetials }) => {
  const [activeThread, setActiveThread] = useState<string | undefined>();
  const [channelListInfo, setChannelListInfo] = useState<IThread[] | []>([]);
  const [messages, setMessages] = useState<MessagesFromBackend[]>([]);
  const userInfo = useSelector((state: RootStateOrAny) => state.userProfile.userDetails);
  const [messageSend, setMessageSend] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [channelTopics, setChannelTopics] = useState<any>();
  const [channelName, setChannelName] = useState<any>('');
  const [channelAdded, setChannelAdded] = useState<any>(false);
  const [loading, setLoading] = useState(false);
  const [scrollHeight, setScrollHeight] = useState(0);
  const [page, setPage] = useState(0);
  const [oldMessagesList, setOldMessagesList] = useState<MessagesFromBackend[]>([]);
  const [loadings, setLoadings] = useState(true);
  const containerRef = useRef<HTMLDivElement>(null);
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    if (patientDetails) {
      console.log('patient details is', patientDetails);
      const data: IThread[] = [];
      PatientAPI.fetchPatientChannels(id).then((res) => {
        const { data: resData } = res.data;
        if (resData) {
          patientDetails?.channels?.map((eachItem: ChannelData) => {
            data.push({
              subject: eachItem.channelTitle,
              id: eachItem.channelId,
              newMessagesAmount: resData.find((item: ChannelData) => item.channelId === eachItem.channelId)
                ?.unreadMessageCount,
              messages: [],
            });
          });

          setChannelListInfo(data);
        }
      });
    }
  }, [patientDetails]);

  const ChannelInfoSetter = () => {
    console.log('hi test');
  };

  const socketEmitMessage = (id: string) => {
    setLoadings(true);
    try {
      if (id && id !== '0') {
        socket.emit('joinRoom', id, (data: any) => {
          console.log('=======joined channel==========');
        });
        socket.emit('markSeen', {
          channelId: id,
          userId: userInfo._id,
        });
      }
    } catch (e) {
      Sentry.captureException(e);
      console.log('error');
    }
    socket.emit(
      'getNextMessage',
      {
        limit: 10,
        skip: 0,
        channelId: id,
      },
      (data: any) => {
        console.log(data, 'soketemmit');
      },
    );
  };

  const markSeenMessage = () => {
    console.log('on Focus');
  };

  useEffect(() => {
    if (messageSend) {
      activeThread && socketEmitMessage(activeThread);
    }
  }, [messageSend]);
  const onSendMessage = (channelId: string, channelMessage: string, buttons: any) => {
    setMessageSend(false);
    try {
      activeThread &&
        socket.emit(
          'newMessageToServer',
          {
            message: channelMessage,
            receiverDetails: [{ id: patientDetails._id }],
            senderDetails: { id: userInfo._id, userType: userInfo.userType },
            channelId: activeThread,
            buttons: buttons,
          },
          true,
          function (data: any) {
            if (data.status) {
              setMessageSend(true);
            } else {
              toast.error(data.message);
              setMessageSend(false);
            }
          },
        );
    } catch (e) {
      Sentry.captureException(e);
      toast.error('Unbale to send message, Please try again!');
      setMessageSend(false);
    }
  };

  const Header: React.FC<IThread> = ({ id, subject, newMessagesAmount }) => {
    return (
      <>
        <div className="cursor-pointer flex py-6 px-10 justify-between items-center bg-lightGreyBackground">
          <div>
            <div className="flex items-center">
              <h3 className="text-textDarkGrey font-semibold text-xl">Subject: {subject}</h3>
              {newMessagesAmount ? (
                <span className="mx-4 bg-red w-6 p-1 text-xs text-white text-center rounded-full">
                  {newMessagesAmount}
                </span>
              ) : null}
            </div>
          </div>
          <ArrowUpSvg
            className={classNames(
              'flex-none text-darkBlue stroke-current h-4 w-4 transition-all transform ml-2',
              activeThread === id ? 'rotate-0' : '-rotate-90',
            )}
          />
        </div>
      </>
    );
  };
  const Content: React.FC<IThread> = ({ messages }) => {
    const index = messages.findIndex((message) => message.isNew);
    const oldMessages = [...messages];
    let newMessages: IMessage[] = [];
    if (index !== -1) {
      newMessages = oldMessages.splice(index);
    }

    const messagesEndRef = useRef<HTMLDivElement>(null);

    const getNextMessage = ({ channelId, limit, skip }: { channelId: string; limit: number; skip: number }) => {
      socket.emit('getNextMessage', {
        limit,
        skip,
        channelId,
      });
    };

    const debounceOnScroll = () => {
      const isScrollTop = containerRef?.current?.scrollTop === 0;
      if (!!containerRef?.current && isScrollTop) {
        setScrollHeight(containerRef.current.scrollHeight);
        const newPageCount = page + 1;

        setPage(newPageCount);
        activeThread && getNextMessage({ channelId: activeThread, limit: 10, skip: newPageCount });
      }
    };

    const scrollToBottom = () => {
      containerRef.current?.scrollTo({ top: messagesEndRef.current?.offsetTop });
    };

    const debouncedScrollTop = useCallback(debounce(debounceOnScroll, 1000), [getNextMessage]);

    useEffect(() => {
      if (containerRef?.current && messages.length) {
        const scrollToY = containerRef.current.scrollHeight - scrollHeight;

        containerRef.current?.scrollTo(0, scrollToY);
      } else {
        scrollToBottom();
      }
    }, [messages]);

    return (
      <div className="px-20 w-full">
        <div
          className={'relative md:w-full flex flex-col overflow-y-auto h-96'}
          ref={containerRef}
          onScroll={debouncedScrollTop}
        >
          {messages.length ? (
            <div className="w-full flex flex-col mt-12 mb-6">
              {oldMessages.map((m) => (
                <MessageCard key={m.id} {...m} />
              ))}
              {!!newMessages.length && (
                <div className="flex">
                  <span className="text-red font-semibold">NEW</span>
                  <div className="border-b flex-grow border-red my-4 ml-2"></div>
                </div>
              )}
              {newMessages.map((m) => (
                <MessageCard key={m.id} {...m} />
              ))}
              <div ref={messagesEndRef} />
            </div>
          ) : (
            !loadings && <p className="text-textGreyedOut text-xl px-20 py-6">No messages yet</p>
          )}
        </div>
        <div className="mb-8">
          {isUserHasPermission(usersHasPermissionToSentMessage, userInfo.userType?.name) && (
            <MessagePatientForm
              onSendMessage={onSendMessage}
              markSeenMessage={markSeenMessage}
              messsageSend={messageSend}
              patientId={patientDetails && patientDetails._id}
              doctorId={userInfo._id}
              afterCreationChannel={getPatientDetials}
            />
          )}
        </div>
      </div>
    );
  };

  const threads = [
    {
      subject: 'Skin care',
      id: '1',
      newMessagesAmount: 1,
      messages: [
        {
          id: '1',
          authorName: 'Evan Bates',
          authorRole: 'Patient',
          messageDate: 'Yesterday • 3:48p',
          isNew: false,
          text: `Hi, I’m having an issue with my acne, and I need some advice. \nWhat I’m seeing is a lorem ipsum dolor sit amet, consectetur  turpis ac consequat facilisis. Nullam nisi elit, luctus eleifend dolor quis, tincidunt variuis placerat neque at purus rutrum, id fringilla massa vestibulum orem ipsum dolor sit amet, consectetur  turpis ac consequat facilisis. `,
          avatarUrl: avatar,
        },
        {
          id: '2',
          authorName: 'Dr Jannel Giles',
          authorRole: 'MD',
          messageDate: 'Today • 10:15a',
          isNew: false,
          text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin bibendum tellus sit amet nibh tristique dignissim. Fusce sed elit tempor, bibendum ligula at, laoreet leo. Ut ultricies augue in urna dignissim auctor. Phasellus maximus pellentesque iaculis. Quisque euismod ac felis non sollicitudin. Pellentesque feugiat risus et diam vulputate placerat. Integer pulvinar, lorem ut facilisis iaculis, erat mauris sodales lacus, sed ultrices mauris elit nec velit. ',
          avatarUrl: avatar,
        },
        {
          id: '3',
          authorName: 'Mia Hay',
          authorRole: 'CS',
          messageDate: 'Yesterday • 1:22p',
          isNew: false,
          text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin bibendum tellus sit amet nibh tristique dignissim. Fusce sed elit tempor, bibendum ligula at, laoreet leo. Ut ultricies augue in urna dignissim auctor. Phasellus maximus pellentesque iaculis. Quisque euismod ac felis non sollicitudin. Pellentesque feugiat risus et diam vulputate placerat. Integer pulvinar, lorem ut facilisis iaculis, erat mauris sodales lacus, sed ultrices mauris elit nec velit. ',
          avatarUrl: avatar,
        },
        {
          id: '4',
          authorName: 'Evan Bates',
          authorRole: 'Patient',
          messageDate: 'Yesterday • 3:48p',
          isNew: true,
          text: 'Ok, thank you for a lorem ipsum dolor sit amet, consectetur  turpis ac consequat facilisis. Nullam nisi elit, luctus eleifend dolor quis, tincidunt variuis placerat neque at purus rutrum, id fringilla massa vestibulum orem ipsum dolor sit amet, consectetur  turpis ac consequat facilisis. ',
          avatarUrl: avatar,
        },
      ],
    },
    {
      id: '2',
      subject: 'General health',
      newMessagesAmount: 0,
      messages: [],
    },
    {
      id: '3',
      subject: 'Allergies',
      newMessagesAmount: 0,
      messages: [],
    },
  ];

  useEffect(() => {
    if (activeThread) {
      setMessages([]);
      socketEmitMessage(activeThread);
    }
  }, [activeThread]);

  useEffect(() => {
    if (oldMessagesList) {
      let data: MessagesFromBackend[] = [];
      const isMustReverse =
        moment((oldMessagesList[0] as MessagesFromBackend)?.messageStatus.sent).diff(
          moment(messages[0]?.messageStatus.sent),
        ) > 0;

      if (isMustReverse) {
        data = [...messages, ...oldMessagesList].filter((v, i, a) => a.findIndex((t) => t._id === v._id) === i);
      } else {
        data = [...oldMessagesList, ...messages].filter((v, i, a) => a.findIndex((t) => t._id === v._id) === i);
      }

      setMessages(data);
    }
  }, [oldMessagesList]);

  useEffect(() => {
    setLoadings(true);
    try {
      socket.on('oldMessages', (data: MessagesFromBackend[]) => {
        if (data?.[0]) {
          const sortedData = data.sort(
            (prev, curr) =>
              timeInSecondsFromNow(curr.messageStatus.sent) - timeInSecondsFromNow(prev.messageStatus.sent),
          );
          setOldMessagesList(sortedData);
          setLoadings(false);
        } else setLoadings(false);
      });
    } catch (err) {
      Sentry.captureException(err);
      console.log(err);
      setLoadings(false);
    }
  }, []);

  useEffect(() => {
    try {
      socket.on('messageToClients', (data) => {
        setMessages((prevMessages) => [...prevMessages, data as never]);
        activeThread && socketEmitMessage(activeThread);
      });
    } catch (err) {
      Sentry.captureException(err);
      console.log(err);
    }
  }, []);

  useEffect(() => {
    try {
      socket.on('unreadMessageUpdated', (unreadMessageUpdatedData) => {
        if (channelListInfo?.length !== 0) {
          const channels: any = [...channelListInfo];
          channels.map((eachItem: any) => {
            if (eachItem.id == unreadMessageUpdatedData.channelId) {
              eachItem.newMessagesAmount = unreadMessageUpdatedData.unreadMessageCount;
            }
          });
          setChannelListInfo(channels);
        }
      });
    } catch (e) {
      Sentry.captureException(e);
      toast.error('Something went wrong...');
    }
  }, [channelListInfo]);

  useEffect(() => {
    const messageData: any = [];
    if (messages[0]) {
      messages.map((eachMessage: any) => {
        const seenInfo = eachMessage.receiverDetails.find(
          (receiverDetailsItem: receiverDetailsItem) => receiverDetailsItem.id === userInfo._id,
        );

        messageData.push({
          id: eachMessage._id,
          authorName: eachMessage.senderDetails.name,
          authorRole: eachMessage.senderDetails.userType.shortCode,
          messageDate: dateTimeFormatter(eachMessage.messageStatus.sent),
          isNew: seenInfo ? !seenInfo.messageStatus?.seen : false,
          text: eachMessage.message,
          filePath: eachMessage.filePath,
          fileName: eachMessage.fileName,
          avatarUrl: eachMessage.senderDetails.profileImage,
          authorId: eachMessage.senderDetails.id,
        });
      });

      if (channelListInfo?.length !== 0) {
        const channels: any = [...channelListInfo];
        if (channels) {
          channels.map((eachItem: any) => {
            if (eachItem.id === activeThread) {
              eachItem['messages'] = messageData;
            }
          });

          setChannelListInfo(channels);
        }
      }
    }
  }, [messages]);

  const createNewChannel = () => {
    setShowModal(false);

    const data = {
      patientId: patientDetails?._id,
      channelTitle: channelName,
    };
    ChannelApi.createChannel(data)
      .then((res) => {
        console.log('response from channel creation', res);
        setChannelAdded(true);
        setChannelName('');
        getPatientDetials();
        // setShowTopics(!showTopics);
        // onChannelSelect(topic.value);
      })
      .catch((err) => {
        Sentry.captureException(err);
        console.log(err.data.message);
      });
  };

  return (
    <div className="lg:w-9/12">
      <Button
        type="primary"
        onClick={() => setShowModal(true)}
        ownClasses="ml-auto text-sm bg-white text-darkBlue font-semibold rounded-full shadow-lg self-start mb-6"
      >
        <PlusSvg className="w-3 h-3 mr-1" />
        New Channel
      </Button>

      {showModal && (
        <Modal showModal={showModal} setShowModal={setShowModal}>
          <div className="px-12 py-16 ">
            <p className="mr-10 text-xl  mb-4"> new channel </p>{' '}
            <Input
              labelText="name"
              value={channelName}
              placeholder="Enter channel name"
              inputClasses="w-52  mb-4"
              labelClasses="mr-10 text-xl  mb-4"
              onChange={(e: any) => setChannelName(e.target.value)}
            />
            <button onClick={() => createNewChannel()} className="btn btn-primary absolute right-4">
              save
            </button>
          </div>
          {/* <Footer handleSave={handleSave} isEdit={isEdit} handleDelete={handleDelete} appoinments={appoinments} /> */}
        </Modal>
      )}
      <div>
        {channelListInfo &&
          channelListInfo.map((el: any) => (
            <div key={el.id} className="my-8">
              <ExpandableElement
                header={<Header {...el} />}
                isShowContent={activeThread === el.id}
                toggleContent={() => setActiveThread(el.id === activeThread ? '' : el.id)}
                content={<Content {...el} />}
                loadings={loadings}
              />
            </div>
          ))}
      </div>
    </div>
  );
};

export default Messages;
