import PatientAPI from 'api/PatientAPI';
import config from '../config';

const { navigation } = config;

export const ViewAsEmr = (id: string) => {
  PatientAPI.fetchViewEmr(id).then((res) => {
    const { data } = res.data;
    if (data) {
      window.open(`${navigation.viewAsEmr}/${data.elationId}`, '_blank');
    }
  });
};
