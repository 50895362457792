import actions from './actions';
import reducer from './reducer';

const staffCostManagement = {
  actions,
  reducer,
  name: 'staffCostManagement',
};

export default staffCostManagement;
