import Button from '../common/Button';
import Table from '../common/Table';
import { ReactComponent as PlusSvg } from '../../assets/icons/lifeMD-plus.svg';
import { ReactComponent as EditSvg } from '../../assets/icons/edit.svg';
import React, { useEffect, useState } from 'react';
import Modal from 'components/AdminModal';
import Form from '../AdminModal/Form';
import StandartHeader from '../AdminModal/Headers/StandartHeader';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { CheckCategoryColor } from 'utils/CheckCategoryColor';
import LoadingOverlay from 'react-loading-overlay-ts';
import SearchForm from './SearchForm';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';
import { toast } from 'react-toastify';
import AdminAppointmentAPI from 'api/AdminAppointmentApi';

interface AppointmentTypeProps {
  appointmentType: string;
  category: string;
  createdAt: Date;
  displayName: string;
  status: string;
  triggers: Array<string>;
  uploadRequired: boolean;
  _id: any;
}
export interface StantardTableProps {
  fetchAppointmentList: (value?: any) => void;
  loading: boolean;
}

const StandardTable: React.FC<StantardTableProps> = ({ fetchAppointmentList, loading }) => {
  const appoinmentType: [] | AppointmentTypeProps[] | any = useSelector(
    (state: RootStateOrAny) => state.appointmentTypesData.appointmentType,
  );
  const headerItems = [
    {
      title: 'Appointment type',
      sortKey: 'displayName',
    },
    {
      title: 'Category',
      sortKey: 'category',
    },
    {
      title: 'Triggers',
      sortKey: 'triggers',
    },
    {
      title: 'Upload required',
      sortKey: 'uploadRequired',
    },
    {
      title: 'Unique id',
      sortKey: 'uniqueId',
    },
    {
      title: 'Status',
      sortKey: 'status',
    },
    {
      title: 'Actions',
      align: 'center',
    },
  ];
  const [showModal, setShowModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [appoinments, setAppoinments] = useState<AppointmentTypeProps[]>([]);
  const [inlineFilterActive, setInlineFilterActive] = useState('All');
  const [searchFilterParams, setSearchFilterParams] = useState({});
  const totalAppointmentTypeCount = useSelector((state: RootStateOrAny) => state.appointmentTypesData.totalCount);
  const [pageItems, setPageItems] = useState([]);
  const [selectedPage, setSelectedPage] = useState(1);
  const [sortKey, setSortKey] = useState<string | undefined>('');
  const [sortOrder, setSortOrder] = useState<string | undefined>();
  const [searchText, setSearchText] = useState('');
  const [params, setParams] = useState<any>();
  const [appointmentLength, setAppointmentLength] = useState([]);
  const [initialLength, setInitialLength] = useState([]);
  const [subsequentLength, setsubsequentLength] = useState([]);

  const totalPage =
    totalAppointmentTypeCount % 10 === 0
      ? Math.floor(totalAppointmentTypeCount / 10)
      : Math.floor(totalAppointmentTypeCount / 10) + 1;
  useEffect(() => {
    const initial: any = [];
    const subsequent: any = [];
    AdminAppointmentAPI.getAppointmentTypeLenghths({})
      .then((res) => {
        if (res?.data?.errorCode == 0) {
          setAppointmentLength(res?.data?.data);
          res?.data?.data?.map((item: any) => {
            initial.push({
              id: item._id,
              label: item.appointmentLengths.initial['label'],
              length: item.appointmentLengths.initial['length'],
            });
            subsequent.push({
              id: item._id,
              label: item.appointmentLengths.subsequent['label'],
              length: item.appointmentLengths.subsequent['length'],
            });
          });
          setInitialLength(initial);
          setsubsequentLength(subsequent);
        } else toast.error('Error! Could Not Add');
      })
      .catch((err) => {
        if (err?.response?.data?.message) {
          toast.error(err.response.data.message);
        } else toast.error(`Error! Could Not Add ${err?.response?.data?.message}`);
      });
  }, []);
  const onSortClick = (title?: string, key?: string, sortOrder?: string) => {
    console.log('testing appointment type component', key, sortOrder);
    setSelectedPage(1);
    setSortKey(key);
    setSortOrder(sortOrder);
    const filterParams: any = {
      ...searchFilterParams,
      ...(key && { sortField: key }),
      ...(sortOrder && { sortOrder }),
      ...(inlineFilterActive && inlineFilterActive !== 'All' && { status: inlineFilterActive }),
    };
    filterParams['pageNo'] = 0;
    filterParams['limit'] = 10;
    fetchAppointmentList(filterParams);
    setParams(filterParams);
  };
  const inlineStatusHandler = (inlineCategoryValue: string) => {
    setInlineFilterActive(inlineCategoryValue);
    const filterParams: any = {
      ...searchFilterParams,
      ...(sortKey && { sortField: sortKey }),
      ...(sortOrder && { sortOrder }),
    };
    filterParams['limit'] = 10;
    filterParams['pageNo'] = 0;
    filterParams['status'] = inlineCategoryValue !== 'All' ? inlineCategoryValue : null;
    setSelectedPage(1);
    fetchAppointmentList(filterParams);
    setParams(filterParams);
  };

  const filterSearchTextHandler = (searchTextFilterValue?: any) => {
    setSearchFilterParams(searchTextFilterValue);
    const filterParams: any = {
      ...searchTextFilterValue,
      ...(sortKey && { sortField: sortKey }),
      ...(sortOrder && { sortOrder }),
      ...(inlineFilterActive && inlineFilterActive !== 'All' && { status: inlineFilterActive }),
    };

    filterParams['pageNo'] = 0;
    filterParams['limit'] = 10;
    setSelectedPage(1);
    fetchAppointmentList(filterParams);
    setParams(filterParams);
  };

  const onPageClick = (e: any, value: number) => {
    e.preventDefault();
    setSelectedPage(value);
    const filterParams: any = {
      ...searchFilterParams,
      ...(sortKey && { sortField: sortKey }),
      ...(sortOrder && { sortOrder }),
      ...(inlineFilterActive && inlineFilterActive !== 'All' && { status: inlineFilterActive }),
    };
    filterParams['limit'] = 10;
    filterParams['pageNo'] = value - 1;
    fetchAppointmentList(filterParams);
  };

  const onNextClick = (e: any) => {
    e.preventDefault();
    if (selectedPage !== totalPage) {
      setSelectedPage(selectedPage + 1);
      const filterParams: any = {
        ...searchFilterParams,
        ...(sortKey && { sortField: sortKey }),
        ...(sortOrder && { sortOrder }),
        ...(inlineFilterActive && inlineFilterActive !== 'All' && { status: inlineFilterActive }),
      };
      filterParams['limit'] = 10;
      filterParams['pageNo'] = selectedPage;
      fetchAppointmentList(filterParams);
    }
  };

  const onPreviousClick = (e: any) => {
    e.preventDefault();
    if (selectedPage !== 1) {
      setSelectedPage(selectedPage - 1);
      const filterParams: any = {
        ...searchFilterParams,
        ...(sortKey && { sortField: sortKey }),
        ...(sortOrder && { sortOrder }),
        ...(inlineFilterActive && inlineFilterActive !== 'All' && { status: inlineFilterActive }),
      };
      filterParams['limit'] = 10;
      filterParams['pageNo'] = selectedPage - 2;
      fetchAppointmentList(filterParams);
    }
  };

  useEffect(() => {
    let leftSide = selectedPage - 2;
    if (leftSide <= 0) leftSide = 1;
    let rightSide = selectedPage + 2;
    if (rightSide > totalPage) rightSide = totalPage;
    const pages: any = [];
    for (let number = leftSide; number <= rightSide; number++) {
      pages.push(number);
    }
    setPageItems(pages);
  }, [selectedPage, totalAppointmentTypeCount]);

  const handleDelete = (id: string) => {
    setSelectedPage(1);
    setSearchText('');
    AdminAppointmentAPI.deleteAppointmentType(id).then((res) => {
      if (res.data.errorCode === 0) {
        fetchAppointmentList(params);
        setShowModal(false);
        toast.success('Successfully deleted');
      } else toast.error('Error! Could Not Delete');
    });
  };

  useEffect(() => {
    !showModal && setAppoinments([]);
    !showModal && setIsEdit(false);
  }, [showModal]);

  return (
    <div className="flex flex-col flex-auto p-11 pt-4 bg-background">
      <div className="flex flex-row items-center my-5">
        <h2 className="text-black text-2xl font-medium mr-8">Appointment types</h2>
        <Button
          type="primary"
          ownClasses=" px-4 mx-0.5 bg-white text-sm text-darkBlue rounded-full shadow-lg font-normal"
          onClick={() => setShowModal(true)}
        >
          <PlusSvg className="w-3.5 h-3.5 mr-1" />
          New
        </Button>
        {showModal && (
          <Modal showModal={showModal} setShowModal={setShowModal}>
            <div className="px-12 py-16 adminapplist">
              <StandartHeader data={appoinments} />
              <div className="relative  flex-auto">
                <Form
                  data={appoinments}
                  isEdit={isEdit}
                  fetchAppointmentList={fetchAppointmentList}
                  setShowModal={setShowModal}
                  handleDelete={handleDelete}
                  appoinments={appoinments}
                  setSelectedPage={setSelectedPage}
                  setSearchText={setSearchText}
                  filterParams={params}
                  appointmentLength={appointmentLength}
                  initialLength={initialLength}
                  subsequentLength={subsequentLength}
                />
              </div>
              {/* <Footer handleSave={handleSave} isEdit={isEdit} handleDelete={handleDelete} appoinments={appoinments} /> */}
            </div>
          </Modal>
        )}
      </div>
      <div className="flex flex-row flex-wrap gap-y-3 items-center justify-between my-5">
        <div className="flex flex-row flex-wrap items-center">
          <Button
            type={`${inlineFilterActive == 'All' ? 'success' : 'secondary'}`}
            ownClasses="mr-3 pl-4 pr-5 py-1 text-lg rounded-full font-normal"
            onClick={() => inlineStatusHandler('All')}
          >
            <i className={`w-2.5 h-2.5 mr-2 bg-white rounded-full `} />
            All
          </Button>
          <Button
            type={`${inlineFilterActive == 'active' ? 'success' : 'secondary'}`}
            ownClasses="mr-3 pl-4 pr-5 py-1 text-lg rounded-full font-normal"
            onClick={() => inlineStatusHandler('active')}
          >
            <i className={`w-2.5 h-2.5 mr-2 ${CheckCategoryColor('active')} rounded-full `} />
            Active
          </Button>
          <Button
            type={`${inlineFilterActive == 'inactive' ? 'success' : 'secondary'}`}
            ownClasses="mr-3 pl-4 pr-5 py-1 text-lg rounded-full font-normal"
            onClick={() => inlineStatusHandler('inactive')}
          >
            <i className={`w-2.5 h-2.5 mr-2 ${CheckCategoryColor('inactive')} rounded-full `} />
            Inactive
          </Button>
        </div>
        <SearchForm
          filterSearchTextHandler={filterSearchTextHandler}
          sortField={sortKey}
          sortOrder={sortOrder}
          inlineFilterActive={inlineFilterActive}
          searchText={searchText}
          setSearchText={setSearchText}
        />
      </div>
      <LoadingOverlay active={loading} spinner>
        <Table headerItems={headerItems} tableClassName="" onSortClick={onSortClick}>
          {appoinmentType?.map((item: any, index: number) => {
            return (
              <tr className="h-14 border-t border-lightGreyBackground" key={index}>
                <td className="px-6 pb-4 pt-5 border-r border-lightGreyBackground">
                  <p className="font-bold">{item?.displayName}</p>
                </td>
                <td className="px-6 pb-4 pt-5 border-r border-lightGreyBackground">
                  <p className="font-bold">{item?.category}</p>
                </td>
                <td className="px-6 pb-4 pt-5 border-r border-lightGreyBackground">
                  <p className="w-52 truncate">
                    {' '}
                    {item?.triggers
                      ?.map((eachTrigger: any) => {
                        return eachTrigger;
                      })
                      ?.join(', ')}
                  </p>
                </td>
                <td className="px-6 pb-4 pt-5 border-r border-lightGreyBackground">
                  <p>{item?.uploadRequired ? 'true' : 'false'}</p>
                </td>
                <td className="px-6 pb-4 pt-5 border-r border-lightGreyBackground">
                  <p>{item?._id}</p>
                </td>
                <td className="px-6 pb-4 pt-5 border-r border-lightGreyBackground">
                  <p className="text-midBlue capitalize">{item?.status}</p>
                </td>
                <td className="px-6 pb-4 pt-5">
                  <button
                    onClick={() => {
                      setAppoinments(item);
                      setIsEdit(true);
                      setShowModal(true);
                    }}
                  >
                    <EditSvg className="w-4" />
                  </button>
                </td>
              </tr>
            );
          })}
        </Table>
      </LoadingOverlay>
      <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
        <div className="flex-1 flex justify-between sm:hidden">
          <a
            href="#"
            className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
          >
            Previous
          </a>
          <a
            href="#"
            className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
          >
            Next
          </a>
        </div>
        <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
          <div>
            {totalAppointmentTypeCount > 0 ? (
              <p className="text-sm text-gray-700">
                Showing{' '}
                <span className="font-medium">{selectedPage === 1 ? selectedPage : (selectedPage - 1) * 10 + 1}</span>{' '}
                to{' '}
                <span className="font-medium">
                  {selectedPage * 10 > totalAppointmentTypeCount ? totalAppointmentTypeCount : selectedPage * 10}
                </span>{' '}
                of <span className="font-medium">{totalAppointmentTypeCount}</span> Appointment Types
              </p>
            ) : (
              <p className="text-sm text-gray-700">
                Showing <span className="font-medium">{totalAppointmentTypeCount}</span> Appointment Types
              </p>
            )}
          </div>
          <div>
            <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
              <a
                onClick={(e) => onPreviousClick(e)}
                href="#"
                className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
              >
                <span className="sr-only">Previous</span>
                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
              </a>
              {pageItems.map((item: any) => (
                <a
                  href="#"
                  key={item}
                  aria-current="page"
                  onClick={(e) => onPageClick(e, item)}
                  className={`z-10 ${
                    selectedPage === item ? 'bg-green text-black' : 'border-gray-300 text-gray-500 bg-white'
                  }  relative inline-flex items-center px-4 py-2 border text-sm font-medium`}
                >
                  {item}
                </a>
              ))}
              <a
                href="#"
                onClick={(e) => onNextClick(e)}
                className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
              >
                <span className="sr-only">Next</span>
                <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
              </a>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StandardTable;
