import types from './types';
import { AdminAutomationDataActionType, AdminAutomationDetails } from './interfaces';

const initState: AdminAutomationDetails = {
  adminAutomationList: null,
  adminAutomationCount: 0,
};

const reducer = (
  state: AdminAutomationDetails = initState,
  action: AdminAutomationDataActionType,
): AdminAutomationDetails => {
  switch (action.type) {
    case types.SET_ADMIN_AUTOMATION_LIST:
      return {
        ...state,
        adminAutomationList: action.payload,
      };

    case types.SET_ADMIN_AUTOMATION_COUNT:
      return {
        ...state,
        adminAutomationCount: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
