import LookUpAPI from 'api/LookUpsAPI';
import classNames from 'classnames';
import Button from 'components/common/Button';
import Dropdown from 'components/common/Dropdown';
import Input from 'components/common/Input';
import MultiSelect from 'components/common/MultiSelect';
import _ from 'lodash';
import { useEffect, useState } from 'react';

export interface FormProps {
  handleFormSubmit?: (data: any) => void;
  handleEditedFormSubmit?: (data: any, id: string) => void;
  handleDelete?: (id: string) => void;
  hasDeleteAccess?: boolean;
  formData?: any;
  isEditMode?: any;
  initialTaskTypes?: any;
  initialUserTypes?: any;
}

const statusOptions = [
  { value: 'active', label: 'Active' },
  { value: 'inactive', label: 'Inactive' },
];

const alertTypeOptions = [
  { value: 'Notification', label: 'Notification' },
  { value: 'Mark as urgent', label: 'Mark as Urgent' },
];

const alertBasedOnOptions = [
  { value: 'createdAt', label: 'Created date' },
  { value: 'dueDate', label: 'Due date' },
];

const initialAlertUnitOptions = [
  { value: 'hrsbefore', label: 'Hours (before)' },
  { value: 'hrsafter', label: 'Hours (after)' },
  { value: 'minbefore', label: 'Mins (before)' },
  { value: 'minafter', label: 'Mins (after)' },
];

const Form: React.FC<FormProps> = ({
  handleFormSubmit,
  handleEditedFormSubmit,
  handleDelete,
  hasDeleteAccess,
  formData,
  isEditMode,
  initialTaskTypes,
  initialUserTypes,
}) => {
  const [name, setName] = useState('');
  const [alertAmount, setAlertAmount] = useState('');
  const [taskTypeOptions, setTaskTypeOptions] = useState<any>();
  const [taskType, setTaskType] = useState<{ label: string; value: string } | null>();
  const [alertType, setAlertType] = useState<{ label: string; value: string }[] | null>();
  const [alertBasedOn, setAlertBasedOn] = useState<{ label: string; value: string } | null>();
  const [alertUnit, setAlertUnit] = useState<{ label: string; value: string } | null>();
  const [alertUnitOptions, setAlertUnitOptions] = useState<{ label: string; value: string }[]>();
  const [audience, setAudience] = useState<{ label: string; value: string }[] | null>();
  const [audienceOptions, setAudienceOptions] = useState<any>();
  const [status, setStatus] = useState<{ label: string; value: string } | null>();

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const data = {
      name,
      ...(taskType?.value && { taskType: taskType?.value }),
      alertType: alertType?.map((item: any) => item.value),
      ...(alertBasedOn?.value && { criterionField: alertBasedOn?.value }),
      ...(alertUnit?.value && { occurenceOrder: alertUnit?.value?.includes('before') ? 'before' : 'after' }),
      ...(alertUnit?.value && { alertUnit: alertUnit?.label?.includes('Mins') ? 'min' : 'hrs' }),
      alertAmount: Number(alertAmount),
      alertTaskOwner: audience ? audience?.some((item: any) => item.value === 'Task Owner') : false,
      audience: audience ? audience?.map((item: any) => item.value)?.filter((item: any) => item !== 'Task Owner') : [],
      ...(status?.value && { status: status?.value }),
    };
    isEditMode ? handleEditedFormSubmit?.(data, formData._id) : handleFormSubmit?.(data);
  };

  const handleAlertBasedOn = (e: any) => {
    setAlertBasedOn(e);
    setAlertUnit(null);
    console.log('Alert Base on Event', e);
    if (e.value === 'createdAt') {
      const modifiedAlertUnitList = initialAlertUnitOptions.filter((item: any) => item?.value?.includes('after'));
      console.log('modified Alert Unit List', modifiedAlertUnitList);
      setAlertUnitOptions(modifiedAlertUnitList);
    } else {
      setAlertUnitOptions(initialAlertUnitOptions);
    }
  };

  useEffect(() => {
    if (!_.isEmpty(formData)) {
      console.log('Form Data GET', formData);
      handleAlertBasedOn({
        value: formData?.criterionField,
        label: formData?.criterionField == 'dueDate' ? 'Due date' : 'Created date ',
      });
      const audienceList = formData?.audience?.map((item: any) => ({ value: item.name, label: item.name }));
      formData?.alertTaskOwner && audienceList.push({ value: 'Task Owner', label: 'Task Owner' });

      const modifiedAlertUnitList = initialAlertUnitOptions.filter((item: any) =>
        item?.value?.includes(formData?.occurenceOrder),
      );
      setName(formData?.name);
      setTaskType(taskTypeOptions?.find((item: any) => item.value === formData?.taskType));
      setAlertType(formData?.alertType?.map((item: any) => ({ value: item, label: item })));
      setAlertBasedOn(alertBasedOnOptions?.find((item: any) => item.value === formData?.criterionField));
      setAlertUnit(modifiedAlertUnitList?.find((item) => item.value?.includes(formData?.alertUnit)));
      setAlertAmount(formData?.alertAmount);
      setAudience(audienceList);
      setStatus(statusOptions?.find((item) => item.value === formData?.status));
    }
  }, [formData]);

  useEffect(() => {
    console.log('Task type is', taskType);
    if (taskType?.value) {
      LookUpAPI.fetchUserTypes({ taskCategory: taskType.value }).then((res) => {
        if (res.data.errorCode === 0) {
          const userTypes = res.data.data;
          const userTypesOptions = userTypes.map((item: any) => ({
            value: item.name,
            label: item.name,
          }));
          userTypesOptions.unshift({ value: 'Admin', label: 'Admin' });
          userTypesOptions.push({ value: 'Task Owner', label: 'Task Owner' });
          console.log('Audience Options', userTypesOptions);
          setAudienceOptions(userTypesOptions);
        }
      });
    }
  }, [taskType]);

  useEffect(() => {
    setTaskTypeOptions(initialTaskTypes);
    setAlertUnitOptions(initialAlertUnitOptions);
    if (!isEditMode) {
      setAudienceOptions(initialUserTypes);
    }
  }, []);

  const handleTaskChange = (e: any) => {
    setAudience(null);
    setTaskType(e);
  };

  return (
    <form className="mt-10" onSubmit={handleSubmit}>
      <Input
        labelText="Name"
        wrapperClasses="mb-4"
        placeholder="Name"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <div className="mb-4">
        <Dropdown
          labelText="Task type"
          placeholderText="Select Task type"
          options={taskTypeOptions}
          value={taskType}
          onChange={handleTaskChange}
        />
      </div>
      <div className="mb-4">
        <MultiSelect
          labelText="Alert type"
          placeholder="Select Alert type"
          ownClasses="w-full"
          options={alertTypeOptions}
          value={alertType}
          handleChange={(e) => setAlertType(e)}
        />
      </div>
      <div className="mb-4">
        <Dropdown
          labelText="Alert based on"
          placeholderText="Select Alert based on"
          // labelClasses="mr-14"
          // ownClasses="pl-5"
          options={alertBasedOnOptions}
          value={alertBasedOn}
          handleChange={handleAlertBasedOn}
        />
      </div>
      <div className="mb-4">
        <Dropdown
          labelText="Alert unit"
          placeholderText="Select Alert unit"
          options={alertUnitOptions}
          value={alertUnit}
          onChange={(e) => setAlertUnit(e)}
        />
      </div>
      <div className="mb-4">
        <Input
          labelText="Alert amount"
          wrapperClasses="mb-4"
          placeholder="Enter Alert amount"
          type="number"
          // min={1}
          value={alertAmount}
          onChange={(e) => setAlertAmount(e.target.value)}
        />
      </div>
      <MultiSelect
        labelText="Audience"
        placeholder="Select Audience"
        ownClasses="w-full"
        options={audienceOptions}
        value={audience}
        handleChange={(e) => setAudience(e)}
      />
      <div className="mb-4">
        <Dropdown
          labelText="Status"
          placeholderText="Select Status"
          options={statusOptions}
          value={status}
          onChange={(e) => setStatus(e)}
        />
      </div>
      <div
        className={classNames('flex items-center mt-8', {
          'justify-end': !isEditMode,
          ' justify-between': isEditMode,
        })}
      >
        {isEditMode && (
          <Button
            ownClasses="rounded-full p-0 font-medium"
            type="depressed"
            onClick={() => handleDelete?.(formData?._id)}
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="red">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
            Delete trigger
          </Button>
        )}
        <Button ownClasses="rounded-full " type="success" formType>
          Save
        </Button>
      </div>
    </form>
  );
};

export default Form;
