import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import Dropdown from 'components/common/Dropdown';
import Input from 'components/common/Input';
import Checkbox from 'components/common/Checkbox';
import { useHistory, useParams } from 'react-router';
import ManageRolesAPI from 'api/ManageRolesAPI';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { hasAccessToModule } from 'auth/authorization';
import { RootStateOrAny, useSelector } from 'react-redux';
import Button from 'components/common/Button';
import LoadingOverlayWrapper from 'react-loading-overlay-ts';

const roleTemplateOptions = [
  { label: 'None', value: 'None' },
  { label: 'Admin', value: 'Admin' },
  { label: 'Physician', value: 'Physician' },
  { label: 'Nurse Practitioner', value: 'Nurse Practitioner' },
  { label: 'Medical Assistant', value: 'Medical Assistant' },
  { label: 'Customer Service Rep', value: 'Customer Service Rep' },
];

const statusOptions = [
  { value: 'active', label: 'Active' },
  { value: 'inactive', label: 'Inactive' },
];
export interface ParamTypes {
  id: string;
}

const RolesManagement: React.FC = () => {
  const userInfo = useSelector((state: RootStateOrAny) => state.userProfile.userDetails);
  const tabLinks = ['Role information', 'Page permissions', 'Editing permissions'];
  const [activeLink, setActiveLink] = useState('Role information');
  const roleRef = useRef<HTMLHeadingElement>(null);
  const pageRef = useRef<HTMLHeadingElement>(null);
  const editingRef = useRef<HTMLHeadingElement>(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [loading, setLoading] = useState(false);

  const [name, setName] = useState('');
  const [userType, setUserType] = useState<{ label: string; value: string } | null>();
  const [shortName, setShortName] = useState('');
  const [color, setColor] = useState('');
  const [status, setStatus] = useState<{ label: string; value: string } | null>();
  const [pagePermissions, setPagePermissions] = useState([]);
  const [editPermissions, setEditPermissions] = useState([]);
  const [formData, setFormData] = useState<any>({});

  const [postPagePermissions, setPostPagePermissions] = useState<any[]>([]);
  const [postEditPermissions, setPostEditPermissions] = useState<any[]>([]);

  const [hasDeleteAccess, setHasDeleteAccess] = useState(false);
  const history = useHistory();
  const { id } = useParams<ParamTypes>();

  const handleActiveLink = (v: string) => {
    console.log('Active link', v);
    setActiveLink(v);
    if (v === 'Role information') {
      roleRef?.current?.scrollIntoView?.({ behavior: 'smooth' });
    } else if (v === 'Page permissions') {
      pageRef?.current?.scrollIntoView?.({ behavior: 'smooth' });
    } else if (v === 'Editing permissions') {
      editingRef?.current?.scrollIntoView?.({ behavior: 'smooth' });
    }
  };

  const handlePagePermissions = (value: boolean, name: any) => {
    console.log('Page Permission: Name and Value', name, value);
    postPagePermissions.includes(name)
      ? setPostPagePermissions((prevPagePermissions) => prevPagePermissions.filter((p) => p !== name))
      : setPostPagePermissions((prevPagePermissions) => [...prevPagePermissions, name]);
  };
  const handleEditingPermissions = (value: boolean, name: any) => {
    console.log('Edit Permissions: Name and Value', name, value);
    postEditPermissions.includes(name)
      ? setPostEditPermissions((prevPagePermissions) => prevPagePermissions.filter((p) => p !== name))
      : setPostEditPermissions((prevPagePermissions) => [...prevPagePermissions, name]);
  };

  const handleFormSubmit = (data: any) => {
    ManageRolesAPI.postUserRoles(data)
      .then((res) => {
        if (res.data.errorCode === 0) {
          toast.success('Success');
        }
      })
      .catch((err) => {
        if (err?.response?.data?.message) {
          toast.error(err.response.data.message);
        } else toast.error('Failed to create new user role');
      })
      .finally(() => {
        history.replace('/dashboard/manage-roles');
      });
  };

  const handleEditedFormSubmit = (data: any, id: string) => {
    ManageRolesAPI.editUserRolesById(data, id)
      .then((res) => {
        if (res.data.errorCode === 0) {
          toast.success('Success');
        }
      })
      .catch((err) => {
        if (err?.response?.data?.message) {
          toast.error(err.response.data.message);
        } else toast.error('Failed to edit user role');
      })
      .finally(() => {
        history.replace('/dashboard/manage-roles');
      });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const data = {
      name,
      userType: userType?.value,
      shortName,
      color,
      status: status?.value,
      pagePermissions: postPagePermissions,
      editingPermissions: postEditPermissions,
    };
    console.log('POST Data: ', data);
    isEditMode ? handleEditedFormSubmit?.(data, id) : handleFormSubmit?.(data);
  };

  const handleDelete = (id: string) => {
    console.log('Deled role with id: ', id);
    ManageRolesAPI.deleteUserRolesById(id)
      .then((res) => {
        if (res.data.errorCode === 0) {
          toast.success('Successfully deleted');
        }
      })
      .catch((err) => {
        if (err?.response?.data?.message) {
          toast.error(err.response.data.message);
        } else toast.error('Failed to delete user role');
      })
      .finally(() => {
        history.replace('/dashboard/manage-roles');
      });
  };

  useEffect(() => {
    setLoading(true);
    ManageRolesAPI.fetchPermissions()
      .then((res) => {
        console.log('Fetch Permissions');
        if (res.data.errorCode === 0) {
          setPagePermissions(res.data.data.pagePermissions);
          setEditPermissions(res.data.data.editPermissions);
        }
      })
      .catch((err) => {
        if (err?.response?.data?.message) {
          toast.error(err.response.data.message);
        } else toast.error('Failed to fetch Permissions');
      })
      .finally(() => {
        setLoading(false);
      });
    setHasDeleteAccess(hasAccessToModule(userInfo.userRoleInfo.editingPermissions, ['USER_ROLE_DELETE']));
  }, []);

  useEffect(() => {
    console.log('Params id', id);
    if (id !== 'new') {
      setIsEditMode(true);
    }
  }, [id]);

  // useEffect(() => {
  //   console.log('Page Permissions:', pagePermissions);
  //   console.log('Edit Permissions:', editPermissions);
  // }, [pagePermissions, editPermissions]);

  useEffect(() => {
    console.log('Post Page Permissions:', postPagePermissions);
    console.log('Post Edit Permissions:', postEditPermissions);
  }, [postPagePermissions, postEditPermissions]);

  useEffect(() => {
    console.log('Edit Mode is', isEditMode);
    if (isEditMode) {
      ManageRolesAPI.fetchUserRolesById(id)
        .then((res) => {
          if (res.data.errorCode === 0) {
            setFormData(res.data.data);
            toast.success('Successfully Fetched User Data');
          }
        })
        .catch((err) => {
          if (err?.response?.data?.message) {
            toast.error(err.response.data.message);
          } else toast.error('Failed to fetch User Data');
        });
    }
  }, [isEditMode]);

  useEffect(() => {
    console.log('Form Data is', formData);
    if (!_.isEmpty(formData)) {
      setName(formData?.name);
      setColor(formData?.color);
      setShortName(formData?.shortName);
      setStatus(statusOptions.find((item) => item.value === formData.status));
      setUserType(roleTemplateOptions.find((item) => item.value === formData.userType.name));
      setPostPagePermissions(formData?.pagePermissions);
      setPostEditPermissions(formData?.editingPermissions);
    }
  }, [formData]);

  return (
    <>
      <h3 className="mb-8 text-2xl font-semibold ml-14">
        Manage roles
        <span
          onClick={() => history.go(-1)}
          className="cursor-pointer underline ml-4 text-xl text-darkBorder font-normal"
        >
          {'< Back to list'}
        </span>
      </h3>
      <div className="flex flex-wrap justify-between bg-white rounded-xl px-16 py-12 ml-14">
        <div className="w-4/5 pr-16">
          <form onSubmit={handleSubmit}>
            <div className="flex justify-between items-center">
              <h2 className="section-heading mb-12">Master admin</h2>
              <div
                className={classNames('w-1/3 mb-10 flex  items-center', {
                  'justify-end': !isEditMode,
                  ' justify-between': isEditMode,
                })}
              >
                {isEditMode && hasDeleteAccess && (
                  <Button
                    ownClasses="rounded-full p-0 font-medium"
                    type="depressed"
                    onClick={() => handleDelete?.(formData._id)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="red"
                    >
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    Delete User Role
                  </Button>
                )}
                <Button ownClasses="rounded-full " type="success" formType>
                  Save
                </Button>
                {/* make this button as form submit: "formType" attribute */}
              </div>
            </div>
            <div>
              <h3 ref={roleRef} className="section-subheading mb-6">
                Role information
              </h3>
              <div className="flex flex-col lg:flex-row justify-start items-start lg:items-end mb-4">
                <div className="mr-20">
                  <div className="mb-4">
                    <Dropdown
                      labelText="Role Template"
                      placeholder="Select"
                      labelClasses="mr-10 text-xl"
                      width="52"
                      options={roleTemplateOptions}
                      value={userType}
                      onChange={(e) => setUserType(e)}
                    />
                  </div>
                  <Input
                    wrapperClasses="mb-4"
                    labelText="Name"
                    placeholder="Enter name"
                    inputClasses="w-52"
                    labelClasses="mr-20 text-xl"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />

                  <Input
                    wrapperClasses="mb-4"
                    labelText="Color"
                    placeholder="Enter color"
                    inputClasses="w-52"
                    labelClasses="mr-20 text-xl"
                    value={color}
                    onChange={(e) => setColor(e.target.value)}
                  />
                </div>
                <div className="flex flex-col justify-end flex-shrink-0">
                  <Input
                    wrapperClasses="mb-4"
                    labelText="Shortname"
                    placeholder="Enter shortname"
                    inputClasses="w-52"
                    labelClasses="mr-10 text-xl"
                    value={shortName}
                    onChange={(e) => setShortName(e.target.value)}
                  />
                  <div className="mb-4">
                    <Dropdown
                      labelText="Status"
                      placeholder="Select"
                      labelClasses="mr-20 text-xl"
                      width="52"
                      options={statusOptions}
                      value={status}
                      onChange={(e) => setStatus(e)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="border-t my-8"></div>
            <div>
              <LoadingOverlayWrapper active={loading} spinner>
                <h3 ref={pageRef} className=" min-w- section-subheading mb-6">
                  Page permissions
                </h3>
                <div className="flex flex-wrap justify-between">
                  {pagePermissions?.map((permission: any, index) => {
                    console.log(postPagePermissions.includes(permission.name));
                    return (
                      <div
                        style={{ minWidth: '250px' }}
                        key={index}
                        className={classNames('flex-grow w-1/3 mb-4', `2xl:pl-${(index % 3) * 12}`)}
                      >
                        <Checkbox
                          labelClasses="text-xl font-semibold min-w-full w-60"
                          labelText={permission?.label}
                          name={permission?.name}
                          onChecked={handlePagePermissions}
                          value={postPagePermissions.includes(permission.name)}
                        />
                      </div>
                    );
                  })}
                </div>
              </LoadingOverlayWrapper>
            </div>
            <div className="my-6"></div>
            <div>
              <LoadingOverlayWrapper active={loading} spinner>
                <h3 ref={editingRef} className="section-subheading mb-6">
                  Editing permissions
                </h3>
                <div className="flex flex-wrap justify-between">
                  {editPermissions?.map((permission: any, index) => (
                    <div
                      style={{ minWidth: '250px' }}
                      key={index}
                      className={classNames('flex-grow w-1/3 mb-4', `2xl:pl-${(index % 3) * 12}`)}
                    >
                      <Checkbox
                        labelClasses="text-xl font-semibold min-w-full w-60"
                        labelText={permission?.label}
                        name={permission?.name}
                        onChecked={handleEditingPermissions}
                        value={postEditPermissions.includes(permission.name)}
                      />
                    </div>
                  ))}
                </div>
              </LoadingOverlayWrapper>
            </div>
          </form>
        </div>

        <div className="w-1/5 px-1">
          <h3 className="font-semibold mb-7 mt-4 text-xl">Quick links</h3>
          {tabLinks.map((link) => (
            <h3
              onClick={() => handleActiveLink(link)}
              className={classNames('cursor-pointer text-xl mb-7', { 'text-darkBlue': activeLink === link })}
              key={link}
            >
              {link}
            </h3>
          ))}
        </div>
      </div>
    </>
  );
};

export default RolesManagement;
