const Links = () => {
  return (
    <div className="relative flex-auto py-6 border-t border-solid border-blueGray-200 rounded-b">
      <p>
        <span className="text-darkBlue underline">
          <span className="font-bold text-darkBlue">25</span> currently active cases
        </span>{' '}
        •{' '}
        <span className="text-darkBlue underline">
          <span className="font-bold text-darkBlue">277 </span>
          patients
        </span>{' '}
        •{' '}
        <span className="text-darkBlue underline">
          <span className="font-bold text-darkBlue">54</span> completed cases
        </span>{' '}
        <span className="text-textGreyedOut">
          | <span className="font-bold text-textGreyedOut">7 days</span>{' '}
          <span className="underline text-textGreyedOut">30 days</span>{' '}
          <span className="underline text-textGreyedOut">365 days</span>
        </span>
      </p>
    </div>
  );
};

export default Links;
