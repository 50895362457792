import Button from '../../common/Button';
import { ViewAsEmr } from 'utils/ViewAsEmr';
import { ReactComponent as Bag } from 'assets/icons/bag.svg';
import { ReactComponent as Phone } from 'assets/icons/patientInfo/phone.svg';
import Avatar from 'pages/VideoCall/videoCall/CommonAvatar';
import GenericAvatar from 'assets/images/profile-generic.png';

const ChatHeader = ({
  patientInformation,
  handlePhoneCall,
}: {
  tab: 'messages' | 'chat';
  setTab: (tab: 'messages' | 'chat') => void;
  patientInformation?: any;
  handlePhoneCall?: () => void;
}) => {
  return (
    <div className="p-6 bg-white">
      <div className="pb-3 border-b">
        <div className="flex items-center border-b pb-3 mb-3">
          <Avatar
            src={patientInformation.profileImage || GenericAvatar}
            ownClasses="pointer-events-none w-14 h-14 mr-4"
          />
          <div>
            <p className="text-darkBlue font-bold">
              {patientInformation.firstName + ' ' + patientInformation.lastName}
            </p>
            <p className="text-gray-400 font-bold text-sm">{patientInformation.membership}</p>
          </div>
        </div>
        {/*{hasAccessToModule(userInfo.userRoleInfo.pagePermissions, ['VIEW_PATIENT_EMR']) && (*/}
        <div className="flex items-center justify-between">
          <Button
            ownClasses="font-normal m-auto p-0"
            type="depressed"
            onClick={() => ViewAsEmr(patientInformation?._id)}
          >
            <Bag className="mr-2 w-5" />
            View in EMR
          </Button>
          {handlePhoneCall && (
            <Button ownClasses="font-normal m-auto p-0" type="depressed" onClick={handlePhoneCall}>
              <Phone className="mr-2 w-5" />
              Call patient
            </Button>
          )}
        </div>
        {/*)}*/}
        {/* <div className="mt-6 flex">
          <Button
            onClick={() => setTab('messages')}
            ownClasses="rounded-full text-lg hover:bg-green focus:outline-none px-6 font-medium"
            type={tab === 'messages' ? 'success' : 'secondary'}
          >
            Messages
          </Button>
          <Button
            onClick={() => setTab('chat')}
            ownClasses="rounded-full text-lg ml-6 hover:bg-green focus:outline-none font-medium"
            type={tab === 'chat' ? 'success' : 'secondary'}
          >
            Chart
          </Button>
        </div> */}
      </div>
    </div>
  );
};

export default ChatHeader;
