import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'react-day-picker/lib/style.css';
import { Provider } from 'react-redux';
import store from 'redux/store';
import { AxiosResponse } from 'axios';
import { AxiosInstanceAuth, AxiosInstanceWebsite } from 'api/AxiosInstance';
import Cookies from 'js-cookie';
import { BrowserRouter as Router } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from 'react-toastify';
// import 'react-overlay-loader/styles.css';
import { socket_close } from 'socket/socket';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import LogRocket from 'logrocket';
import ErrorBoundary from './components/ErrorBoundary';
import FlagProvider from '@unleash/proxy-client-react';

Sentry.init({
  dsn: `${process.env.REACT_APP_LIFE_MD_SENTRY_DSN}`,
  integrations: [new BrowserTracing()],
  environment: process.env.REACT_APP_ENV,
  tracesSampleRate: 1.0,
});

LogRocket.init(`${process.env.REACT_APP_LIFE_MD_LOGROCKET_ID}`, {
  dom: {
    textSanitizer: true,
    inputSanitizer: true,
  },
});

const config = {
  url: process.env.REACT_APP_UNLEASH_PROXY_URL,
  clientKey: process.env.REACT_APP_UNLEASH_CLIENT_KEY,
  refreshInterval: 15,
  appName: 'LifeMD MVP Physician Portal',
  environment: process.env.REACT_APP_UNLEASH_ENV,
};

LogRocket.getSessionURL((sessionURL) => {
  Sentry.configureScope((scope) => {
    scope.setExtra('sessionURL', sessionURL);
  });
});

ReactDOM.render(
  <Sentry.ErrorBoundary fallback={({ error }) => <ErrorBoundary message={error.message} />}>
    <React.StrictMode>
      <FlagProvider config={config}>
        <Router>
          <Provider store={store}>
            <App />
            <ToastContainer
              position="bottom-right"
              autoClose={2000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </Provider>
        </Router>
      </FlagProvider>
    </React.StrictMode>
  </Sentry.ErrorBoundary>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// get count of unathorized requests, to show message properly.
let countUnauthorized = 0;
/* eslint-disable  @typescript-eslint/no-explicit-any */
const successHandler = (response: AxiosResponse<any>) => {
  if (countUnauthorized >= 1) {
    countUnauthorized = 0;
  }
  return response;
};

// moving the axios inteceptor attachment to global place where the fuction will have access to redux store
// Its done to trigger the logout action and clean the redux store, when any of the API respond with 401 or 403 response

AxiosInstanceWebsite.interceptors.response.use(
  (response) => successHandler(response),
  (error) => {
    // whatever you want to do with the error
    if (error.response && error.response.status === 401) {
      let url = '';

      countUnauthorized = countUnauthorized + 1;

      if (error.response.config) {
        url = error.response.config.url;
      }

      if (url !== '/') {
        window.localStorage.clear();
        if (countUnauthorized === 1) {
          console.log('Login session expired. Please login again!');
          window.localStorage.removeItem('accessToken');
          window.localStorage.removeItem('refreshToken');
          window.location.href = '/';
          socket_close();
        }
      }
    }

    if (error.response && error.response.status === 403) {
      toast.error('Unable to access the Functionality, Permission Denied!');
    }
    throw error;
  },
);

// moving the axios inteceptor attachment to global place where the fuction will have access to redux store
// Its done to trigger the logout action and clean the redux store, when any of the API respond with 401 or 403 response

AxiosInstanceAuth.interceptors.response.use(
  (response) => successHandler(response),
  (error) => {
    // whatever you want to do with the error
    if (error.response && error.response.status === 401) {
      let url = '';

      countUnauthorized = countUnauthorized + 1;

      if (error.response.config) {
        url = error.response.config.url;
      }

      if (url !== '/') {
        window.localStorage.clear();
        if (countUnauthorized === 1) {
          console.log('Login session expired. Please login again!');
          window.location.href = '/';
        }
      }
    }

    if (error.response && error.response.status === 403) {
      toast.error('Unable to access the Functionality, Permission Denied!');
    }
    throw error;
  },
);
