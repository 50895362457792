import TaskApi from 'api/TaskAPI';
import Button from 'components/common/Button';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { ReactComponent as Arrow } from 'assets/icons/arrow-right.svg';
import { ReactComponent as Check } from 'assets/icons/check.svg';
import LookUpAPI from 'api/LookUpsAPI';
import LoadingOverlayWrapper from 'react-loading-overlay-ts';
import { XIcon } from '@heroicons/react/solid';
import * as Sentry from '@sentry/react';

export interface TransferProps {
  popup: boolean;
  setPopup: any;
  taskId: string;
  assignmentUser: string;
  appointmentStatus: string;
  category: string;
  onCancel: () => void;
}

const Transfer: React.FC<TransferProps> = ({
  popup,
  setPopup,
  taskId,
  category,
  assignmentUser,
  appointmentStatus,
  onCancel,
}) => {
  const [transferToOption, setTransferToOption] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [assignmentToUser, setAssignmentToUser] = useState('');
  const [unmountedStyle, setUnmountedStyle] = useState({
    visibility: 'hidden',
    animation: 'outAnimation 270ms ease-out',
    animationFillMode: 'forwards',
  });

  const mountedStyle = {
    animation: 'inAnimation 250ms ease-in',
  };

  const onSelectUserType = (e: any, value: string) => {
    e.stopPropagation();
    setAssignmentToUser(value);
  };

  const onTaskToQueueClick = (e: any) => {
    e.stopPropagation();
    const payload = {
      releaseTask: true,
    };

    TaskApi.setTaskAssign(taskId, payload)
      .then((res) => {
        const { data } = res;
        toast.success(data.message);
        onCancel();
      })
      .catch((e) => {
        Sentry.captureException(e);
        toast.error(e.response.data.message);
      });
  };

  const onTransferClick = (e: any) => {
    e.stopPropagation();
    const payload = {
      audience: [assignmentToUser],
    };

    TaskApi.setTaskAssign(taskId, payload)
      .then((res) => {
        const { data } = res;
        toast.success(data.message);
        onCancel();
      })
      .catch((e) => {
        Sentry.captureException(e);
        toast.error(e.response.data.message);
      });
  };

  const fetchUserTypeLookUp = () => {
    const params = {
      taskCategory: category,
      isUserExist: true,
    };
    LookUpAPI.fetchUserTypes(params)
      .then((res) => {
        const { data } = res.data;
        const list = Object.values(data);
        setTransferToOption(list);
        setLoading(false);
      })
      .catch((e) => {
        Sentry.captureException(e);
        toast.error(e.response.data.message);
      });
  };

  const handleClose = (e: any) => {
    setPopup(false);
    e.stopPropagation();
  };

  useEffect(() => {
    setTimeout(() => {
      setUnmountedStyle({
        visibility: 'visible',
        animation: 'outAnimation 270ms ease-out',
        animationFillMode: 'forwards',
      });
    }, 500);
  }, []);

  useEffect(() => {
    fetchUserTypeLookUp();
  }, []);

  return (
    <div
      style={popup ? mountedStyle : unmountedStyle}
      className="headerslide absolute w-60 p-5 top-8 rounded-xl bg-background flex flex-col border border-gray-200"
    >
      <button
        className="rounded-full h-8 w-8 bg-darkBlue flex justify-center items-center absolute -top-4 -right-4"
        onClick={(e) => handleClose(e)}
      >
        <XIcon className="text-white h-5 w-5" />
      </button>
      <LoadingOverlayWrapper active={loading} spinner>
        <div className="flex items-center cursor-pointer" onClick={(e) => onTaskToQueueClick(e)}>
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="red">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
          <span className="my-4 text-dashboardPrimaryGrey">Release task to queue</span>
        </div>
        <div className="border-t flex flex-col mb-6">
          <span className="uppercase font-bold text-darkBorder my-4 dashboard-label">transfer to:</span>
          <span className="text-darkBlue mb-4">Staff</span>
          <div className="text-sm flex flex-col">
            {transferToOption &&
              transferToOption.map((eachItem: any) => {
                return (
                  eachItem.shortCode !== 'AD' &&
                  eachItem.shortCode !== 'PA' && (
                    <div
                      className="cursor-pointer flex items-center mb-2"
                      key={eachItem.shortCode}
                      onClick={(e) =>
                        assignmentUser !== eachItem.shortCode
                          ? onSelectUserType(e, eachItem.shortCode)
                          : e.stopPropagation()
                      }
                    >
                      {assignmentUser === eachItem.shortCode && <Check className="w-4 mr-2" />}
                      {assignmentToUser === eachItem.shortCode && <Arrow className="w-4 mr-2" />}
                      <span
                        className={`${
                          assignmentToUser === eachItem.shortCode ? 'text-darkBlue' : 'text-dashboardPrimaryGrey'
                        } hover:text-darkBlue font-bold`}
                      >
                        {eachItem.name}
                      </span>
                    </div>
                  )
                );
              })}
          </div>
        </div>
        <Button
          disabled={appointmentStatus === 'cancelled' || appointmentStatus === 'Missed'}
          ownClasses="self-start text-base rounded-full"
          type="primary"
          onClick={(e) => onTransferClick(e)}
        >
          Transfer
        </Button>
      </LoadingOverlayWrapper>
    </div>
  );
};

export default Transfer;
