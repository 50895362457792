import React, { ReactElement } from 'react';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import AdminManageStaff from 'components/AdminManageStaff';
import StaffAPI from 'api/StaffManagementApi';
import { setStaffLists, setTotalStaff } from '../../redux/staffManagement/actions';

const StaffsManagement: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const fetchStaffs = (value?: any) => {
    setLoading(true);
    StaffAPI.fetchStaff(value)
      .then((res) => {
        console.log('response from staff api', res);
        if (res.data.errorCode === 0) {
          dispatch(setStaffLists(res.data.data));
          dispatch(setTotalStaff(res.data.info.totalCount));
          setLoading(false);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    fetchStaffs({ pageNo: 0, limit: 10 });
  }, [dispatch]);

  return (
    <div>
      <AdminManageStaff fetchStaffs={fetchStaffs} loading={loading} />
    </div>
  );
};
export default StaffsManagement;
