import classNames from 'classnames';
import { useSelector, RootStateOrAny } from 'react-redux';
import Seen from '../Seen';
import { IMessage } from '../ChatWindow/Messages.types';
import { useEffect } from 'react';

const Message = ({ message }: { message: IMessage }) => {
  const userInfo = useSelector((state: RootStateOrAny) => state.userProfile.userDetails);
  const self = userInfo._id === message.userId || userInfo.userId === message.userId;

  return (
    <div className={classNames('mt-3 flex flex-col', self ? 'ml-4 items-end' : 'mr-4 items-start')}>
      <div
        className={classNames('rounded-lg p-6', self ? 'rounded-br-none bg-lightGreen' : 'rounded-bl-none bg-gray-100')}
      >
        {message.imageUrl && <img src={message.imageUrl} alt="Avatar" className="w-52 m-auto" />}
        {message.message && (
          <div className={`${message.imageUrl && 'mt-10'} whitespace-pre-line`}>{message.message}</div>
        )}
      </div>
      <Seen self={self} author={message.author} date={message.date} seen={message.seen} />
    </div>
  );
};

export default Message;
