import { AxiosInstanceWebsite } from './AxiosInstance';
import config from '../config';

const { api } = config;

const lookUpAppendPath = 'lookup';
export default class LookUpAPI {
  static fetchVariablesAndButtons(data: any) {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/${lookUpAppendPath}/variables-buttons`,
      method: 'GET',
      params: data,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  static fetchPermissions() {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/${lookUpAppendPath}/permissions`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }
  static fetchUserTypes(params?: any) {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/${lookUpAppendPath}/user-type-access`,
      method: 'GET',
      params: params,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  static fetchTaskCategories(data?: any) {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/${lookUpAppendPath}/task-categories`,
      method: 'GET',
      ...(data && { params: data }),
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  static fetchPayTypes(data?: any) {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/lookup/pay-types`,
      method: 'GET',
      ...(data?.userType && { params: data }),
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  static fetchUsers(data?: any) {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/lookup/users`,
      method: 'GET',
      ...(data?.userType && { params: data }),
    });
  }
  static fetchSearchUsers(data?: any) {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/lookup/users`,
      method: 'GET',
      params: data,
    });
  }
}
