import { ReactComponent as Logo } from 'assets/images/logo.svg';
const Header: React.FC = () => {
  return (
    <header>
      <div className="flex justify-between">
        <Logo className="mx-auto my-12 w-36" />
      </div>
    </header>
  );
};

export default Header;
