import ArticleAPI from 'api/ArticleAPI';
import { useState } from 'react';
import { useEffect } from 'react';
import { dateFormatter } from 'utils/dateFormatter';
import { Editor } from '@tinymce/tinymce-react';
import moment from 'moment';

export interface ArticleViewProp {
  articleId?: string;
  articleData: any;
}

const ArticleView: React.FC<ArticleViewProp> = ({ articleId, articleData }) => {
  return (
    <div className="flex flex-col flex-auto  bg-background">
      <div className=" bg-white mt-1 p-14">
        <div className="flex-wrap">
          <h1 className="text-green text-3xl font-bold">{articleData && articleData?.title}</h1>
          <label className="text-xl font-bold mt-2 block text-darkBlue">
            {articleData && moment(articleData.createdAt).format('MMM D • YYYY')}{' '}
            {articleData && '- By' + ' ' + articleData.author.name}
          </label>
          <p className="font-medium mt-5 text-lg pb-8 border-b border-gray-100 text-black text-opacity-70">
            {articleData && articleData.description}
          </p>
          <div className="pt-6">
            <Editor
              initialValue={articleData ? articleData.content : ''}
              disabled={true}
              init={{
                height: 600,
                readonly: true,
                menubar: 'false',
                plugin: 'false',
                statusbar: false,
                toolbar: false,
                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArticleView;
