import Dropdown from 'components/common/Dropdown';
import Input from 'components/common/Input';

const Form = () => {
  const clients = [
    { value: 'client 1', label: 'client 1' },
    { value: 'client 2', label: 'client 2' },
    { value: 'client 3', label: 'client 3' },
  ];
  const categories = [
    { value: 'post-consult', label: 'Post-consult' },
    { value: 'messages', label: 'Messages' },
    { value: 'results', label: 'Results' },
    { value: 'pre-consult', label: 'Pre-consult' },
    { value: 'lab reports', label: 'Lab reports' },
    { value: 'prescriptions', label: 'Prescriptions' },
  ];
  const states = [
    { value: 'California', label: 'California' },
    { value: 'Florida', label: 'Florida' },
    { value: 'New York', label: 'New York' },
    { value: 'South Carolina', label: 'South Carolina' },
    { value: 'South Dakota', label: 'South Dakota' },
  ];

  const statuses = [
    { value: 'active', label: 'Active' },
    { value: 'inactive', label: 'Inactive' },
  ];

  return (
    <form>
      <div className="flex flex-col lg:flex-row justify-center items-center">
        <div className="mr-20">
          <Input
            wrapperClasses="mb-4"
            labelText="Case ID"
            placeholder="Enter case ID…"
            inputClasses="w-52"
            labelClasses="mr-10 text-xl"
          />
          <Input
            wrapperClasses="mb-4"
            labelText="Physician ID"
            placeholder="Enter physician ID…"
            inputClasses="w-52"
            labelClasses="mr-10 text-xl"
          />
          <Input
            wrapperClasses="mb-4"
            labelText="Patient ID"
            placeholder="Enter patient ID…"
            inputClasses="w-52"
            labelClasses="mr-10 text-xl"
          />
          <div></div>{' '}
          <Dropdown
            labelText="Clients"
            placeholder="Clients"
            labelClasses="mr-10 text-xl"
            width="52"
            options={clients}
          />
        </div>
        <div>
          <div className="mb-4">
            <Dropdown
              labelText="Category"
              placeholder="Select Category"
              labelClasses="mr-10 text-xl"
              width="52"
              options={categories}
            />
          </div>
          <div className="mb-4">
            <Dropdown
              labelText="States"
              placeholder="Select States"
              labelClasses="mr-10 text-xl"
              width="52"
              options={states}
            />
          </div>
          <Input
            wrapperClasses="mb-4"
            labelText="Creation date"
            placeholder="Select date…"
            inputClasses="w-52"
            labelClasses="mr-10 text-xl"
          />
          <div className="mb-4">
            <Dropdown
              labelText="Status"
              placeholder="Status"
              labelClasses="mr-10 text-xl"
              width="52"
              options={statuses}
            />
          </div>
        </div>
      </div>
    </form>
  );
};

export default Form;
