import { XIcon } from '@heroicons/react/solid';
import NotificationAPI from 'api/NotificationAPI';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { setSelectedTaskId } from 'redux/tasks/actions';
import { dateTimeFormatter } from 'utils/dateTimeFormatter';
import Modal from 'components/PatientMessagePopup';
import Button from '../../common/Button';
import SMSModal from 'components/Admin/Patient/PatientInfo/SMSModal';
import { PatientDetails } from 'components/Admin/Patient/PatientInfo/patientInfo.types';
import PatientAPI from 'api/PatientAPI';

export interface HeaderNotificationProps {
  notificationTitle: string;
  notificationType: string;
  notificationContent: string;
  createdAt: string;
  buttons: any;
  _id: string;
  fetchNotificationLists: () => any;
  setShowNotifications: (show: boolean) => void;
}

const HeaderNotification: React.FC<HeaderNotificationProps> = ({
  notificationTitle,
  notificationType,
  notificationContent,
  createdAt,
  buttons,
  _id,
  fetchNotificationLists,
  setShowNotifications,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [patientDetails, setPatientDetails] = useState<PatientDetails | null>(null);
  const [showPopover, setShowPopover] = useState(false);

  const onClickClear = (id: string) => {
    const payload = {
      notificationIds: [id],
    };
    NotificationAPI.markSeenNotifications(payload)
      .then((res) => {
        console.log('Marked Seen');
        fetchNotificationLists();
      })
      .catch((err) => {
        console.log(err.data.message);
      });
  };

  const onClickNotificationSection = (referenceId: any) => {
    if (referenceId) {
      if (notificationType !== 'SMS') {
        dispatch(setSelectedTaskId(referenceId));
        setShowNotifications(false);
        history.push('/dashboard/tasks');
      } else {
        PatientAPI.fetchSinglePatient(referenceId).then((res) => {
          const { data } = res.data;
          if (data) {
            setPatientDetails(data);
            setShowModal(true);
          }
        });
      }
    }
  };

  return (
    <>
      {showModal && patientDetails && (
        <div className="fixed top-0 left-0 right-0 h-screen z-50">
          <Modal showModal={showModal} setShowModal={setShowModal}>
            <SMSModal patientDetails={patientDetails} />
          </Modal>
        </div>
      )}

      <div className="flex items-center py-3 2xl:py-5 border-b" style={{ width: '410px' }}>
        <XIcon className="mx-4 text-dashboardLightGrey cursor-pointer" width="15" onClick={() => onClickClear(_id)} />
        <div className="flex  text-text flex-grow items-center justify-between">
          <div className="flex flex-col">
            <h4 className="font-bold">{notificationTitle}</h4>
            <span className="mb-2">{notificationContent}</span>
            <span className="text-dashboardLightGrey text-xs">{dateTimeFormatter(createdAt)}</span>
          </div>
          {buttons &&
            buttons.map((eachItem: any) => {
              return (
                eachItem?.referenceId && (
                  <Button
                    ownClasses="ml-4"
                    type="primary"
                    key={eachItem.variable}
                    onClick={() => onClickNotificationSection(eachItem?.referenceId)}
                  >
                    {eachItem.label}
                  </Button>
                )
              );
            })}
        </div>
      </div>
    </>
  );
};

export default HeaderNotification;
