import { useEffect, useState } from 'react';

const StopWatch = () => {
  const [time, setTime] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setTime((prevTime) => prevTime + 1);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className=" mr-3 text-text w-11">
      <span className="text-sm">
        {('0' + Math.floor((time / 60) % 60)).slice(-2)} : {('0' + (time % 60)).slice(-2)}
      </span>
    </div>
  );
};

export default StopWatch;
