import moment from 'moment';
import 'moment-timezone';

const timeZone = moment.tz.guess();

export const dateTimeFormatter = (date?: any, missed?: boolean) => {
  /* Important***: Dont change the order of execution here */
  if (missed) {
    return moment(date).fromNow();
  }

  /* <-------- Past Dates --------> */
  if (moment(date).calendar().includes('Yesterday')) {
    return moment(date).tz(timeZone).calendar({
      lastDay: '[yesterday •] h:mma z',
    });
  }
  if (
    moment(date).fromNow().endsWith('days ago') ||
    moment(date).fromNow().endsWith('month ago') ||
    moment(date).fromNow().endsWith('months ago') ||
    moment(date).fromNow().endsWith('year ago') ||
    moment(date).fromNow().endsWith('years ago')
  ) {
    return moment(date).tz(timeZone).format('MMM D • h:mma z');
  }

  /* <-------- Today --------> */
  if (moment(date).calendar().includes('Today')) {
    if (
      moment(date).fromNow().includes('second') ||
      moment(date).fromNow().includes('minute') ||
      moment(date).fromNow().endsWith('hour ago') ||
      moment(date).fromNow().endsWith('in an hour')
    ) {
      return moment(date)
        .fromNow()
        .replace(/minute[s]?/g, 'min');
    }
    return moment(date).tz(timeZone).format('h:mma z');
  }

  /* <-------- Future Dates --------> */
  if (moment(date).calendar().includes('Tomorrow')) {
    return moment(date).tz(timeZone).calendar({
      nextDay: '[Tomorrow •] h:mma z',
    });
  }
  if (
    (moment(date).fromNow().includes('in') && moment(date).fromNow().includes('days')) ||
    moment(date).fromNow().includes('month') ||
    moment(date).fromNow().includes('year')
  ) {
    return moment(date).tz(timeZone).format('MMM D • h:mma z');
  }

  // return moment(date).fromNow();
  // return moment(date).calendar();
  // return moment(date).format('MMM D • h:mm a);
  return `${moment(date).format('lll')}`;
};

// Note : includes('day') => matches 'day' and 'days'
// Also : includes('month') => matches 'month' and 'months' | same goes for years & minutes
// But : includes('days') => matches only 'days' not 'day' | same goes for minutes, months & years
// And : endsWith('string') => perfectly matches ends with 'string'
