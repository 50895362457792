import types from './types';
import { PatientListDataActionType, PatientDetails } from './interfaces';

const initState: PatientDetails = {
  patientList: null,
  totalCount: 0,
};

const reducer = (state: PatientDetails = initState, action: PatientListDataActionType): PatientDetails => {
  switch (action.type) {
    case types.SET_PATIENT_LIST:
      return {
        ...state,
        patientList: action.payload,
      };
    case types.SET_TOTAL_PATIENT:
      return {
        ...state,
        totalCount: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
