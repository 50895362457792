import AdminAppointmentAPI from 'api/AdminAppointmentApi';
import StandardTable from 'components/AdminAppointmentList';
import React, { ReactElement } from 'react';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setAppointmentTypesList, setTotalAppointmentTypes } from 'redux/appointmentTypes/actions';

function AppointmentTypes(): ReactElement {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const fetchAppointmentList = (value?: any) => {
    setLoading(true);
    AdminAppointmentAPI.fetchAppointmentTypes(value)
      .then((res) => {
        if (res.data.errorCode === 0) {
          console.log('dispatch', res);

          dispatch(setAppointmentTypesList(res.data.data));
          dispatch(setTotalAppointmentTypes(res.data.info.totalCount));
          setLoading(false);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    fetchAppointmentList();
  }, [dispatch]);
  return (
    <div>
      <StandardTable fetchAppointmentList={fetchAppointmentList} loading={loading} />;
    </div>
  );
}

export default AppointmentTypes;
