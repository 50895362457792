import Button from '../common/Button';
import { ReactComponent as PlusSvg } from '../../assets/icons/plus.svg';
import { ReactComponent as TrashSvg } from '../../assets/icons/trash.svg';
import { useState } from 'react';
import CalendarPopup from 'components/CalendarPopup';
import Modal from 'components/Modal';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import moment from 'moment-timezone';
import AvailabilityAPI from 'api/AvailabilityAPI';
import { toast } from 'react-toastify';
import { useParams } from 'react-router';
import Avatar from '../../pages/VideoCall/videoCall/CommonAvatar';
import GenericAvatar from '../../assets/images/profile-generic.png';
import classNames from 'classnames';
import { RootStateOrAny, useSelector } from 'react-redux';
import StaffAPI from '../../api/StaffManagementApi';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(timezone);
dayjs.extend(utc);

const Availability: React.FC = () => {
  const [showCalendar, setShowCalendar] = useState(false);
  const [availabiltyDays, setAvailabiltyDays] = useState([] as any);
  const [exceptionDays, setExceptionDays] = useState([] as any);
  const [timeZone, setTimeZone] = useState('');
  const history = useHistory();
  const [disableSaveButton, setDisableSaveButton] = useState(true);
  const { id: userId } = useParams<{ id: string }>();
  const { _id: id } = useSelector((state: RootStateOrAny) => state.userProfile.userDetails);
  const [tabsData, setTabsData] = useState<{ label: string; value: string }[]>();
  const [activeTab, setActiveTab] = useState<{ label: string; value: string }>();
  const [staffDetails, setStaffDetails] = useState<any>();

  const getLocalTz = () => (activeTab?.value === 'physician' || id === userId ? undefined : dayjs.tz.guess());

  const fetchAvailabilityTime = () => {
    const intialData: any = {
      Sunday: { timeRange: [], checked: false },
      Monday: { timeRange: [], checked: false },
      Tuesday: { timeRange: [], checked: false },
      Wednesday: { timeRange: [], checked: false },
      Thursday: { timeRange: [], checked: false },
      Friday: { timeRange: [], checked: false },
      Saturday: { timeRange: [], checked: false },
    };
    AvailabilityAPI.fetchAvailabilitys(userId, getLocalTz())
      .then((res) => {
        const { workHours, overRide, timezone } = res.data.data;
        if (workHours) {
          dayKeys.map((eachKey) => {
            if (workHours[eachKey]) {
              intialData[eachKey].timeRange = workHours[eachKey].map((eachItem: any) => {
                return {
                  startTime: eachItem.startTime,
                  endTime: eachItem.endTime,
                };
              });
              intialData[eachKey].checked = true;
            }
          });
        }

        if (overRide) {
          setExceptionDays(overRide);
        }
        setAvailabiltyDays(intialData);
        setTimeZone(timezone);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchStaff = () => {
    StaffAPI.fetchSingleStaff(userId).then((res) => {
      setStaffDetails(res.data.data[0]);
    });
  };

  useEffect(() => {
    if (id !== userId) {
      fetchStaff();
    }
  }, []);

  useEffect(() => {
    if (staffDetails) {
      const tabs = [
        { label: `Local time (${dayjs().tz().offsetName()})`, value: 'local' },
        { label: `Physician’s time (${dayjs().tz(staffDetails.timezone).offsetName()})`, value: 'physician' },
      ];
      setTabsData(tabs);
      setActiveTab(tabs[0]);
    }
  }, [staffDetails]);

  useEffect(() => {
    fetchAvailabilityTime();
  }, [activeTab]);

  const data = {
    Sunday: [
      {
        startTime: 'string',
        endTime: 'string',
      },
    ],
    Monday: [
      {
        startTime: 'string',
        endTime: 'string',
      },
    ],
    Tuesday: [
      {
        startTime: 'string',
        endTime: 'string',
      },
    ],
    Wednesday: [
      {
        startTime: 'string',
        endTime: 'string',
      },
    ],
    Thursday: [
      {
        startTime: 'string',
        endTime: 'string',
      },
    ],
    Friday: [
      {
        startTime: 'string',
        endTime: 'string',
      },
    ],
    Saturday: [
      {
        startTime: 'string',
        endTime: 'string',
      },
    ],
  };
  const dayKeys = Object.keys(data);
  const startPicker = (time: any, day: string, index: number) => {
    const timeList: any = { ...availabiltyDays };
    setDisableSaveButton(false);

    if (timeList[day].timeRange.length !== 0) {
      timeList[day].timeRange[index] = { startTime: time, endTime: timeList[day].timeRange[index].endTime };
      setAvailabiltyDays(timeList);
    }
  };

  const endPicker = (time: any, day: string, index: number) => {
    const timeList: any = { ...availabiltyDays };
    setDisableSaveButton(false);
    if (timeList[day].timeRange.length !== 0) {
      timeList[day].timeRange[index] = { startTime: timeList[day].timeRange[index].startTime, endTime: time };
      setAvailabiltyDays(timeList);
    }
  };

  const onAddTime = (value: string) => {
    const timeList: any = { ...availabiltyDays };
    timeList[value].timeRange.push({ startTime: null, endTime: null });
    setAvailabiltyDays(timeList);
    setDisableSaveButton(false);
  };

  const deleteTimeRange = (index: number, value: string) => {
    const timeList: any = { ...availabiltyDays };
    timeList[value].timeRange.splice(index, 1);
    setAvailabiltyDays(timeList);
    setDisableSaveButton(false);
  };

  const checkedDayHandler = (day: any, checked: boolean) => {
    const daysValues = { ...availabiltyDays };
    daysValues[day].checked = checked;
    setAvailabiltyDays(daysValues);
    setDisableSaveButton(false);
  };

  const submitAvailabiliy = () => {
    const data: any = {};
    dayKeys.map((eachKey) => {
      data[eachKey] = availabiltyDays[eachKey].checked
        ? availabiltyDays[eachKey].timeRange.map((eachItem: any) => {
            return {
              startTime: eachItem.startTime,
              endTime: eachItem.endTime,
            };
          })
        : [];
    });
    AvailabilityAPI.updateAvailability(userId, getLocalTz(), data)
      .then((res: any) => {
        toast.success(res.data.message);
        fetchAvailabilityTime();
      })
      .catch((e) => {
        toast.error(e.response.data.message);
      });
  };

  const addException = (data: any) => {
    AvailabilityAPI.addOverrideAvailability(userId, getLocalTz(), data)
      .then((res: any) => {
        setShowCalendar(false);
        fetchAvailabilityTime();
        toast.success(res.data.message);
      })
      .catch((e) => {
        toast.error(e.response.data.message);
      });
  };

  const convertToTimeZone = (time: any) => {
    const momentData = moment(time);
    const value = momentData.tz(timeZone).format('HH:mm');
    return value;
  };

  const onDeleteEaception = (value: string) => {
    const params = {
      date: value,
    };
    AvailabilityAPI.deleteOverrideAvailabilty(userId, params, getLocalTz())
      .then((res) => {
        const { data } = res;
        if (data) {
          toast.success(data.message);
        }

        fetchAvailabilityTime();
      })
      .catch((err) => {
        toast.error('Unable to remove exceptions, Please try again!');
      });
  };

  return (
    <div className="min-h-screen w-full p-11 pt-0 bg-gray-50">
      <div className="flex flex-row items-center justify-between my-7">
        <h2 className="text-black text-2xl font-semibold">Appointment availability</h2>
        <Button
          type="success"
          ownClasses="px-8 py-1.5 text-lg rounded-full"
          onClick={() => history.push('/dashboard/appointments')}
        >
          Back to calendar
        </Button>
      </div>
      <Button
        type="success"
        ownClasses="px-8 py-1.5 text-lg rounded-full mb-3"
        disabled={disableSaveButton}
        onClick={() => submitAvailabiliy()}
      >
        Save availability
      </Button>
      <div className="grid grid-cols-3 w-full px-8 py-7 bg-white rounded-2xl divide-x">
        <div className="col-span-2 pr-10 divide-y">
          {staffDetails && (
            <div>
              <div className="flex items-center gap-3 bg-secondary20 py-6 px-7 rounded-lg mb-6 font-semibold">
                <Avatar ownClasses="w-7 h-7" src={staffDetails.profileImage || GenericAvatar} />
                <span className="text-xl">
                  Viewing availability for {staffDetails.firstName} {staffDetails.lastName}.
                </span>
              </div>
              <div className="flex mb-6 shadow w-fit rounded-full p-1.5">
                {tabsData?.map((el) => (
                  <button
                    key={el.label}
                    className={classNames(
                      'flex items-center gap-2 text-sm px-6 py-3 rounded-full hover:text-secondary',
                      activeTab?.label === el.label ? 'bg-secondary20 text-secondary' : 'text-gray70',
                    )}
                    onClick={() => setActiveTab(el)}
                  >
                    {el.label}
                  </button>
                ))}
              </div>
            </div>
          )}
          {dayKeys.map((eachDay: string) => {
            return (
              <div className="flex flex-row items-center py-8 px-3 justify-between" key={eachDay}>
                <input
                  type="checkbox"
                  checked={availabiltyDays[eachDay] && availabiltyDays[eachDay].checked}
                  className="form-checkbox h-5 w-5 mr-5 text-green rounded-sm focus:ring-transparent"
                  onChange={(e) => checkedDayHandler(eachDay, e.target?.checked)}
                />
                <p className="text-lg mr-12 uppercase w-16 font-bold">{eachDay}</p>
                {availabiltyDays[eachDay] && availabiltyDays[eachDay].timeRange.length !== 0 ? (
                  <div className="flex flex-col w-3/5">
                    {availabiltyDays[eachDay].timeRange.map((eachTime: any, index: number) => {
                      return (
                        <div key={eachTime} className="my-2">
                          <div className="flex flex-row flex-wrap flex-auto items-center justify-center">
                            <input
                              type="time"
                              className="w-36 px-2 py-1.5 text-lg border border-darkBorder rounded-xl outline-none"
                              value={eachTime.startTime}
                              onChange={(e) => startPicker(e.target.value, eachDay, index)}
                            ></input>
                            <div className="mx-5 w-2.5 h-0.5 bg-darkBorder" />
                            <input
                              type="time"
                              className="w-36 px-2 py-1.5 text-lg border border-darkBorder rounded-xl outline-none"
                              value={eachTime.endTime}
                              onChange={(e) => endPicker(e.target.value, eachDay, index)}
                            ></input>
                            <button className="ml-5" onClick={() => deleteTimeRange(index, eachDay)}>
                              <TrashSvg className="h-5" />
                            </button>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div className="w-3/5">
                    <p className="text-lg text-center text-tableLightGrey">Unavailable</p>
                  </div>
                )}
                <button onClick={() => onAddTime(eachDay)}>
                  <PlusSvg className="w-4 ml-1" />
                </button>
              </div>
            );
          })}
        </div>
        <div className="flex flex-col pl-16 pr-10 py-6">
          <h2 className="my-1.5 text-black text-2xl font-semibold">Vacation/ exceptions</h2>
          <p className="my-9 text-base font-medium">Add dates when your availability changes from your weekly hours.</p>
          <Button
            onClick={() => setShowCalendar(true)}
            type="success"
            ownClasses="my-7 mx-auto px-8 py-1.5 text-lg rounded-full"
          >
            Add an exception
          </Button>

          {Object.keys(exceptionDays) &&
            timeZone &&
            Object.keys(exceptionDays).map((eachKey) => {
              return (
                <>
                  <div className="flex justify-between mt-8">
                    <p className="mb-4 font-bold">{moment(eachKey).format('MM-DD-YYYY')}</p>
                    <button className="ml-5" onClick={() => onDeleteEaception(eachKey)}>
                      <TrashSvg className="h-5" />
                    </button>
                  </div>
                  {exceptionDays[eachKey].length !== 0 ? (
                    exceptionDays[eachKey].map((eachitem: any, index: number) => {
                      return (
                        <div
                          className="flex flex-row items-center py-8 px-3 justify-between border-b-2 editavail"
                          key={eachitem}
                        >
                          <input
                            type="time"
                            className="w-36 px-2 py-1.5 text-lg border border-darkBorder rounded-xl outline-none"
                            value={convertToTimeZone(eachitem.startTime)}
                            disabled={true}
                          ></input>
                          <div className="mx-5 w-2.5 h-0.5 bg-darkBorder" />

                          <input
                            type="time"
                            className="w-36 px-2 py-1.5 text-lg border border-darkBorder rounded-xl outline-none"
                            value={convertToTimeZone(eachitem.endTime)}
                            disabled={true}
                          ></input>
                        </div>
                      );
                    })
                  ) : (
                    <div className="flex flex-row items-center py-8 px-3 justify-between border-b-2 editavail">
                      <p className="text-lg text-center text-tableLightGrey">Unavailable</p>
                    </div>
                  )}
                </>
              );
            })}

          <Modal showModal={showCalendar} setShowModal={() => setShowCalendar(false)}>
            <div className="m-auto appoint-picker">
              <CalendarPopup
                onSave={addException}
                onCancel={() => setShowCalendar(false)}
                exceptionDays={exceptionDays}
                timeZone={timeZone}
              />
            </div>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default Availability;
