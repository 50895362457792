import { useLocation } from 'react-router-dom';
import LoadingOverlay from 'react-loading-overlay-ts';
import { useEffect, useState } from 'react';
import AuthAPI from '../../api/AuthAPI';
import { toast } from 'react-toastify';
import classNames from 'classnames';
import { useHistory } from 'react-router';

export const AT_LEAST_ONE_LOWERCASE_REGEXP = /.*[a-z]+.*/;
export const AT_LEAST_ONE_UPPERCASE_REGEXP = /.*[A-Z]+.*/;
export const AT_LEAST_ONE_SPEC_CHAR_REGEXP = /[*@!#%$&()^~{}+_\-=|\\:;"'<>?,./]/;

const CreateNewPassword: React.FC = () => {
  const query = new URLSearchParams(useLocation().search);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [newPassword, setNewPassword] = useState<string | null>(null);
  const [confirmPassword, setConfirmPassword] = useState<string | null>(null);
  const [minLength, setMinLength] = useState<boolean | null>(null);
  const [specialChar, setSpecialChar] = useState<boolean | null>(null);
  const [lowerCase, setLowerCase] = useState<boolean | null>(null);
  const [upperCase, setUpperCase] = useState<boolean | null>(null);
  const [equal, setEqual] = useState<boolean | null>(null);
  const [isValid, setIsValid] = useState<boolean>(false);

  const onFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = {
      password: confirmPassword ?? '',
      token: query.get('token') ?? '',
    };
    setLoading(true);
    AuthAPI.resetPassword(data)
      .then((res) => {
        if (res.data.errorCode === 0) {
          toast.success(res.data.message);
          history.push('/login');
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((error) => {
        const { response } = error;
        if (response.data.message) {
          toast.error(response.data.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (newPassword) {
      setMinLength(newPassword.length > 7);
      setSpecialChar(AT_LEAST_ONE_SPEC_CHAR_REGEXP.test(newPassword));
      setLowerCase(AT_LEAST_ONE_LOWERCASE_REGEXP.test(newPassword));
      setUpperCase(AT_LEAST_ONE_UPPERCASE_REGEXP.test(newPassword));
    }
  }, [newPassword]);

  useEffect(() => {
    if (newPassword && confirmPassword) {
      setEqual(newPassword === confirmPassword);
    }
  }, [newPassword, confirmPassword]);

  const getColor = (valid: boolean | null) => {
    if (valid == null) {
      return '';
    }
    return valid ? 'text-green' : 'text-red';
  };

  return (
    <div className="mx-auto w-2/5 px-2.5 py-44">
      <LoadingOverlay active={loading} spinner>
        <form onSubmit={onFormSubmit}>
          <h1 className="font-bold text-3xl">Create a new password</h1>
          <p className="mt-4 mb-5 text-xl">Your new password may not be the same as any of your previous passwords.</p>
          <div className="flex gap-4 mb-6">
            <div className="flex-1">
              <input
                id="newPassword"
                name="newPassword"
                type="password"
                required
                className="placeholder-black-500 rounded relative block w-full px-3 py-3 border border-gray-300 placeholder-gray-500 text-gray-900  focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                placeholder="New password"
                onChange={(e) => setNewPassword(e.target.value)}
              />
              <p className={getColor(minLength)}>At least 8 characters</p>
              <p className={getColor(specialChar)}>Contains a special character (e.g. ! + &)</p>
              <p className={getColor(lowerCase)}>Contains uppercase character</p>
              <p className={getColor(upperCase)}>Contains a lowercase character</p>
              <p className={getColor(equal)}>Passwords must match</p>
            </div>
            <div className="flex-1">
              <input
                id="newPassword"
                name="newPassword"
                type="password"
                required
                className="placeholder-black-500 rounded relative block w-full px-3 py-3 border border-gray-300 placeholder-gray-500 text-gray-900  focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                placeholder="Confirm password"
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </div>
          </div>
          <button
            type="submit"
            disabled={!(!!minLength && !!specialChar && !!lowerCase && !!upperCase && !!equal)}
            className={classNames(
              'group text-xl relative w-full flex justify-center py-2 px-4 border border-transparent font-medium rounded-md text-white btn-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
              { 'opacity-50': !(!!minLength && !!specialChar && !!lowerCase && !!upperCase && !!equal) },
            )}
          >
            Reset password
          </button>
        </form>
      </LoadingOverlay>
    </div>
  );
};

export default CreateNewPassword;
