import React, { useState, useEffect, useRef } from 'react';
import { AudioTrack, VideoTrack, Participant as VideoParticipant } from 'twilio-video';
import Avatar from '../../../assets/images/avatar.png';
import Draggable from 'react-draggable';

export interface ParticipantProps {
  mute?: any;
  participant: VideoParticipant;
  type?: any;
  name?: any;
  isVideo?: any;
  remote?: boolean;
  url?: any;
}

const Participant: React.FC<ParticipantProps> = ({ participant, mute, type, name, isVideo, remote, url }) => {
  const [videoTracks, setVideoTracks] = useState<(VideoTrack | null)[]>([]);
  const [audioTracks, setAudioTracks] = useState<(AudioTrack | null)[]>([]);
  const [videochanged, setVideoChanged] = useState(false);
  const [audioChanged, setAudioChanged] = useState(false);

  const videoRef = useRef<HTMLVideoElement>(null);
  const audioRef = useRef<HTMLMediaElement>(null);

  const trackpubsToTracks = (trackMap: any) =>
    Array.from(trackMap.values())
      .map((publication: any) => publication.track)
      .filter((track) => track !== null);

  useEffect(() => {
    setVideoTracks(trackpubsToTracks(participant.videoTracks));
    setAudioTracks(trackpubsToTracks(participant.audioTracks));

    const trackSubscribed = (track: AudioTrack | VideoTrack) => {
      if (track.kind === 'video') {
        setVideoTracks((videoTracks) => [...videoTracks, track]);
      } else if (track.kind === 'audio') {
        setAudioTracks((audioTracks) => [...audioTracks, track]);
      }
    };

    const trackUnsubscribed = (track: AudioTrack | VideoTrack) => {
      if (track.kind === 'video') {
        setVideoTracks((videoTracks) => videoTracks.filter((v) => v !== track));
      } else if (track.kind === 'audio') {
        setAudioTracks((audioTracks) => audioTracks.filter((a) => a !== track));
      }
    };

    participant.on('trackSubscribed', trackSubscribed);
    participant.on('trackUnsubscribed', trackUnsubscribed);

    return () => {
      setVideoTracks([]);
      setAudioTracks([]);
      participant.removeAllListeners();
    };
  }, [participant]);

  function videoDisabled() {
    setVideoChanged(true);
  }

  function videoEnabled() {
    setVideoChanged(false);
  }

  function audioDisabled() {
    setAudioChanged(true);
  }

  function audioEnabled() {
    setAudioChanged(false);
  }
  useEffect(() => {
    const videoTrack = videoTracks[0];
    if (videoRef && videoRef.current) {
      if (videoTrack) {
        videoTrack.attach(videoRef.current);
        videoTrack.on('disabled', videoDisabled);
        videoTrack.on('enabled', videoEnabled);
        return () => {
          videoTrack.detach();
        };
      }
    }
  }, [videoTracks]);

  useEffect(() => {
    const videoTrack = videoTracks[0];
    if (videoRef && videoRef.current) {
      if (videoTrack) {
        videoTrack.attach(videoRef.current);
        videoTrack.on('disabled', videoDisabled);
        videoTrack.on('enabled', videoEnabled);
        return () => {
          videoTrack.detach();
        };
      }
    }
  }, [videoTracks, videochanged]);

  useEffect(() => {
    if (audioTracks[0]) {
      const audioTrack = audioTracks[0];
      if (audioRef && audioRef.current) {
        if (audioTrack) {
          audioTrack.attach(audioRef.current);
          // audioTrack.on('disabled', audioDisabled);
          // audioTrack.on('enabled', audioEnabled);
          return () => {
            audioTrack.detach();
          };
        }
      }
    }
  }, [audioTracks]);

  return (
    <Draggable>
      <div className="participant">
        {!videochanged ? (
          <video className={`${!videochanged ? 'block' : 'hidden'} object-cover`} ref={videoRef} autoPlay={true} />
        ) : (
          <div
            className={`${videochanged ? 'block' : 'hidden'} bg-white flex justify-center`}
            style={{ height: 'inherit', flex: 1, minHeight: '172px', alignItems: 'center' }}
          >
            {url ? (
              <img src={url} className="rounded-full w-20 h-20 m-auto" alt="Avatar" />
            ) : (
              <div
                className="rounded-full bg-gray-500  w-20 h-20 text-white flex justify-center align-middle text-xl"
                style={{ alignItems: 'center' }}
              >
                {name.charAt(0)}
              </div>
            )}
          </div>
        )}
        <audio ref={audioRef} autoPlay={true} />
      </div>
    </Draggable>
  );
};

export default Participant;
