import { VideoCallProvider } from './videoCallContext/videoCallContext';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import Lobby from './videoCall/Lobby';
import useLocationParams from './hooks/location/useLocationParams';
import {
  connect,
  createLocalAudioTrack,
  createLocalVideoTrack,
  LocalAudioTrack,
  LocalParticipant as TwilioLocalParticipant,
  LocalVideoTrack,
  RemoteParticipant,
  Room as TwilioRoom,
} from 'twilio-video';
import Loader from './videoCall/Loader';
import { useFullscreen, useToggle } from 'react-use';
import Controls from './videoCall/Controls';
import Chat from './videoCall/Сhat';
import AppointmentAPI from 'api/AppointmentAPI';
import { toast } from 'react-toastify';
import { setSelectedTaskId } from 'redux/tasks/actions';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import TaskApi from 'api/TaskAPI';
import PatientAPI from 'api/PatientAPI';
import VoiceCallAPI from 'api/VoiceCallAPI';
import socket from '../../socket/socket';
import Timer from './videoCall/Timer';
import LocalParticipant from './videoCall/LocalParticipant';
import RemoteParticipants from './videoCall/RemoteParticipants';
import { CallContext } from 'contexts/CallContext';
import CallNotificationCard from '../../components/CallNotificationCard';
import ProfileAPI from '../../api/profileApi';
import { setProfileInformation } from '../../redux/userProfile/actions';
import { setPatientLists } from 'redux/patient/actions';
import * as Sentry from '@sentry/react';

const VideoCall: React.FC = () => {
  const history = useHistory();
  const ref = useRef(null);
  const { callId: [callId = ''] = [] } = useLocationParams();
  const { setPatientId, setShowCallCard, setToken, showCallCard, token } = useContext(CallContext);
  const [room, setRoom] = useState<TwilioRoom | null>(null);
  const [localParticipant, setLocalParticipant] = useState<TwilioLocalParticipant | null>(null);
  const [remoteParticipant, setRemoteParticipant] = useState<RemoteParticipant | null>(null);
  const [videoTracks, setVideoTracks] = useState<(LocalVideoTrack | null)[]>([]);
  const [audioTracks, setAudioTracks] = useState<(LocalAudioTrack | null)[]>([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [callDetails, setCallDetails] = useState({} as any);
  const [remoteParticipantDisconnected, setRemoteParticipantDisconnected] = useState(false);
  const [isFullscreen, handleFullScreen] = useToggle(false);
  const [channelList, setChannelList] = useState([]);
  const userInfo = useSelector((state: RootStateOrAny) => state.userProfile.userDetails);

  useFullscreen(ref, isFullscreen, { onClose: () => handleFullScreen(false) });

  const showTimer = remoteParticipant && localParticipant && !showCallCard;
  const isShowRoom = showTimer && !remoteParticipantDisconnected;

  const handleConnect = (token: string) => {
    connect(token, {
      name: callId,
      preferredVideoCodecs: ['VP8'],
    }).then(setRoom);
  };

  const fetchProfileData = () => {
    ProfileAPI.fetchProfile().then((res) => {
      const user = res?.data?.data[0];
      user.userRoleInfo.pagePermissions = [];
      dispatch(setProfileInformation(user));
    });
  };

  const getCallToken = (id: string) => {
    VoiceCallAPI.fetchCallToken(id).then((res) => {
      const { data } = res.data;
      if (data) {
        setToken(data.token);
      }
    });
  };

  const handleLogout = useCallback(() => onEndVideoCall(), [callDetails]);
  /**
   * Unpublish old video track and publish a new
   * Stop new track when previous track was stopped
   */
  const changeVideoId = useCallback(
    (deviceId, videoEnabled) => {
      videoTracks.forEach((track) => {
        if (track) {
          localParticipant?.unpublishTrack(track);
        }
      });
      createLocalVideoTrack({ deviceId }).then((localVideoTrack) => {
        localParticipant?.publishTrack(localVideoTrack);
        if (!videoEnabled) {
          localVideoTrack.stop();
        }
        setVideoTracks([localVideoTrack]);
      });
    },
    [videoTracks, localParticipant],
  );
  /**
   * Unpublish old audio track and publish a new
   * Stop new track when previous track was stopped
   */
  const changeAudioId = useCallback(
    (deviceId, audioEnabled) => {
      audioTracks.forEach((track) => {
        if (track) {
          localParticipant?.unpublishTrack(track);
        }
      });
      createLocalAudioTrack({ deviceId }).then((localAudioTrack) => {
        localParticipant?.publishTrack(localAudioTrack);
        if (!audioEnabled) {
          localAudioTrack.stop();
        }
        setAudioTracks([localAudioTrack]);
      });
    },
    [audioTracks, localParticipant],
  );

  /**
   * Set local and remote participant
   */
  useEffect(() => {
    room?.on('participantConnected', (remoteParticipantData) => {
      setRemoteParticipant(remoteParticipantData);
      setRemoteParticipantDisconnected(false);
    });
    room?.on('participantDisconnected', () => setRemoteParticipantDisconnected(true));
    room?.participants.forEach(setRemoteParticipant);
    setLocalParticipant(room?.localParticipant || null);
    return () => {
      room?.disconnect();
    };
  }, [room]);

  /**
   * Set video and audio tracks
   */
  useEffect(() => {
    if (localParticipant) {
      setVideoTracks(
        [...localParticipant.videoTracks.values()]
          .map((publication) => publication.track)
          .filter((track) => track !== null),
      );
      setAudioTracks(
        [...localParticipant.audioTracks.values()]
          .map((publication) => publication.track)
          .filter((track) => track !== null),
      );
    }
  }, [localParticipant]);

  useEffect(() => {
    return () => {
      videoTracks.forEach((track) => track?.stop());
    };
  }, [videoTracks]);

  useEffect(() => {
    return () => {
      audioTracks.forEach((track) => track?.stop());
    };
  }, [audioTracks]);

  const getRoomToken = (appointmentId: string) => {
    AppointmentAPI.fetchCallToken(appointmentId)
      .then((res) => {
        const { data } = res.data;
        if (data) {
          handleConnect(data.token);
        } else {
          history.push('/dashboard/tasks');
          toast.error('Unable to intiate appointment, Please try again!');
        }
      })
      .catch((e) => {
        Sentry.captureException(e);
        history.push('/dashboard/tasks');
        toast.error('Unable to intiate appointment, Please try again!');
      });
  };

  const fetchChannellist = (category: string, patientId: string) => {
    if (category === 'Front desk') {
      PatientAPI.fetchPatientFrontDeskChannels(patientId, { fetchFirstMessage: false })
        .then((res) => {
          const { data: resData } = res.data.data;
          if (resData) {
            setChannelList(resData);
          }
        })
        .catch((e) => {
          Sentry.captureException(e);
          if (e.response?.data?.message) {
            toast.error(e.response.data.message);
          }
        });
    } else {
      PatientAPI.fetchPatientChannels(patientId)
        .then((res) => {
          const { data: resData } = res.data;
          if (resData) {
            setChannelList(resData);
          }
        })
        .catch((e) => {
          Sentry.captureException(e);
          if (e.response?.data?.message) {
            toast.error(e.response.data.message);
          }
        });
    }
  };

  useEffect(() => {
    if (channelList) {
      const callData = { ...callDetails };
      const generalChannel: any = channelList.find(
        (eachItem: any) => eachItem.channelTitle.toLowerCase() === 'General health'.toLowerCase(),
      );
      callData['channelId'] = generalChannel && generalChannel.channelId;
      setCallDetails(callData);
    }
  }, [channelList]);

  const fetchTaskDetails = () => {
    TaskApi.fetchSingleTask(callId)
      .then((res) => {
        if (res.data) {
          const { data } = res.data;
          getRoomToken(data.appointmentInfo._id);

          const callInfo = {
            taskId: data._id,
            doctorId: data.appointmentInfo.doctorId,
            patientId: data.patientId,
            appointmentId: data.appointmentInfo._id,
            category: data.category,
            appointmentMethod: data.appointmentInfo.appointmentMethod,
            staffName: data.appointmentInfo.staffName,
            patientName: data.personalInfo.firstName,
            patientImage: data.personalInfo?.profileImage,
            channelId: '',
            appointmentTime: data.appointmentInfo.appointmentTime,
            gender: null,
          };
          setPatientId(data.patientId);
          getCallToken(data.patientId);
          socket.emit('physicianJoinWaitingRoom', {
            appointmentId: callInfo.appointmentId,
            doctorId: callInfo.doctorId,
            patientId: callInfo.patientId,
          });
          socket.on('joinedWaitingRoom', (data) => {
            if (data.appointmentId === callInfo.appointmentId) {
              socket.off('joinedWaitingRoom');
              socket.emit('doctorTriggerCall', {
                appointmentId: callInfo.appointmentId,
                doctorId: callInfo.doctorId,
                patientId: callInfo.patientId,
              });
            }
          });
          PatientAPI.fetchSinglePatient(data.patientId).then((res) => {
            if (res.data) {
              const { data } = res.data;
              callInfo.gender = data.gender;
              fetchChannellist(data.category, callInfo.patientId);
              setCallDetails(callInfo);
            }
          });
        }
      })
      .catch((e) => {
        Sentry.captureException(e);
        history.push('/dashboard/tasks');
        toast.error('Unable to intiate appointment, Please try again!');
      });
  };

  const onEndVideoCall = () => {
    setLoading(true);
    AppointmentAPI.completeAppointment(callDetails.appointmentId)
      .then((res) => {
        const { data } = res.data;
        history.push('/dashboard/tasks');
        if (data) {
          toast.success(data.message);
          dispatch(setSelectedTaskId(data.newTaskId));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handlePhoneCall = () => {
    token && setShowCallCard(true);
  };

  useEffect(() => {
    fetchProfileData();
    fetchTaskDetails();
  }, []);

  if (!callId) {
    return <Redirect to={'/'} />;
  }
  return (
    <VideoCallProvider>
      <Loader isVisible={!room || loading} />
      <div ref={ref} className="flex h-screen bg-background">
        <div className="relative h-full w-full">
          {showTimer && <Timer />}
          {remoteParticipant && isShowRoom ? (
            <RemoteParticipants participant={remoteParticipant} callDetails={callDetails} />
          ) : (
            <Lobby
              callDetails={callDetails}
              handlePhoneCall={handlePhoneCall}
              showCallCard={showCallCard}
              isRemoteParticipantDisconnected={remoteParticipantDisconnected}
            />
          )}
          {localParticipant && !showCallCard && (
            <LocalParticipant participant={localParticipant} videoTracks={videoTracks} audioTracks={audioTracks} />
          )}
          <Controls
            room={room}
            handleFullScreen={handleFullScreen}
            handleLogout={handleLogout}
            changeVideoId={changeVideoId}
            changeAudioId={changeAudioId}
            videoTracks={videoTracks}
            audioTracks={audioTracks}
            showCallCard={showCallCard}
            callDetails={callDetails}
          />
        </div>
        {callDetails.patientId && callDetails.channelId !== '' && (
          <Chat callDetails={callDetails} handlePhoneCall={handlePhoneCall} />
        )}
      </div>
      <CallNotificationCard position="center" />
    </VideoCallProvider>
  );
};

export default VideoCall;
