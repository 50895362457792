import Button from 'components/common/Button';

const Footer = () => {
  return (
    <div className="flex items-center justify-between mt-4 pb-14">
      <Button ownClasses="rounded-full p-0 font-medium" type="depressed">
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="red">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
        </svg>
        Delete trigger
      </Button>
      <Button ownClasses="rounded-full " type="success">
        Save
      </Button>
    </div>
  );
};

export default Footer;
