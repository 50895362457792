import Button from 'components/common/Button';
import DateTimePicker from 'components/common/DateTimePicker';
import Dropdown from 'components/common/Dropdown';
import Input from 'components/common/Input';
import { useEffect } from 'react';
import { useState } from 'react';
import moment from 'moment';
import { RootStateOrAny, useSelector } from 'react-redux';

export interface FormProps {
  formHandler: (value?: any) => void;
  filterFormValues?: any;
  audienceOptions?: any;
  searchFilterParams?: any;
}

const Form: React.FC<FormProps> = ({ formHandler, filterFormValues, audienceOptions, searchFilterParams }) => {
  const userRole = useSelector((state: RootStateOrAny) => state.userProfile.userDetails.userType.name);

  const [title, setTitle] = useState('');
  const [uniqueId, setUniqueId] = useState('');
  const [category, setCategory] = useState('');
  const [author, setAuthor] = useState('');
  const [audience, setAudience] = useState<{ label: string; value: string } | null>();
  const [creationDate, setCreationDate] = useState('');
  const [status, setStatus] = useState<{ label: string; value: string } | null>();
  const [filterCount, setFilterCount] = useState(Number);
  const [data, setData] = useState();

  const membershipOptions = [
    { value: 'permium', label: 'Premium' },
    { value: 'elite', label: 'Elite' },
  ];

  const statusOptions = [
    { value: 'active', label: 'Active' },
    { value: 'inactive', label: 'Inactive' },
  ];

  useEffect(() => {
    setTitle(searchFilterParams ? searchFilterParams.title : filterFormValues.title);
    setStatus(
      searchFilterParams
        ? statusOptions.find((item) => item.value === searchFilterParams.status)
        : statusOptions.find((item) => item.value === filterFormValues.status),
    );
    setUniqueId(searchFilterParams ? searchFilterParams.uniqueId : filterFormValues.uniqueId);
    setCategory(searchFilterParams ? searchFilterParams.category : filterFormValues.category);
    // setMembership(membershipOptions.find((item) => item.value === filterFormValues.membership));
    setCreationDate(searchFilterParams ? searchFilterParams.creationDate : filterFormValues.createdAt);
    setAuthor(searchFilterParams ? searchFilterParams['author.name'] : filterFormValues['author.name']);
    setAudience(
      searchFilterParams
        ? audienceOptions.find((item: any) => item.value === searchFilterParams['audience.shortCode'])
        : audienceOptions.find((item: any) => item.value === filterFormValues['audience.shortCode']),
    );
    checkFilterApplied();
  }, [filterFormValues, searchFilterParams]);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const filterData: any = {
      ...(title && { title }),
      ...(category && { category }),
      ...(author && { 'author.name': author }),
      ...(audience?.value && { 'audience.shortCode': audience.value }),
      ...(status?.value && { status: status.value }),
      ...(uniqueId && { uniqueId }),
      ...(creationDate && { createdAt: creationDate && moment(creationDate).format('YYYY-MM-DD') }),
    };

    formHandler(filterData);
  };

  const handleClear = (e: any) => {
    e.preventDefault();
    formHandler({});
  };

  const checkFilterApplied = () => {
    let count = 0;
    if (searchFilterParams.title || title) {
      count += 1;
    }
    if (searchFilterParams.category || category) {
      count += 1;
    }
    if (searchFilterParams.uniqueId || uniqueId) {
      count += 1;
    }
    if (searchFilterParams['author.name'] || author) {
      count += 1;
    }
    if (searchFilterParams['audience.shortCode'] || audience) {
      count += 1;
    }
    if (searchFilterParams.creationDate || creationDate) {
      count += 1;
    }
    if (searchFilterParams.status || status) {
      count += 1;
    }
    setFilterCount(count);
  };

  useEffect(() => {
    checkFilterApplied();
  }, [title, category, creationDate, uniqueId, author, audience, status]);
  return (
    <form>
      <div className="flex flex-col lg:flex-row justify-center items-center">
        <div className="mr-20 mb-4">
          <Input
            labelText="Title"
            name="title"
            placeholder="Enter title"
            inputClasses="w-52 mb-4 pr-8"
            labelClasses="mr-10 text-xl  mb-4"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            onClickIconClear={() => setTitle('')}
            closeIcon={true}
          />
          <Input
            labelText="Category"
            name="category"
            placeholder="Enter Category"
            inputClasses="w-52 mb-4 pr-8"
            labelClasses="mr-10 text-xl  mb-4"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            onClickIconClear={() => setCategory('')}
            closeIcon={true}
          />
          {userRole === 'Admin' && (
            <Input
              labelText="Author"
              name="author"
              placeholder="Enter author"
              inputClasses="w-52 mb-4 pr-8"
              labelClasses="mr-10 text-xl  mb-4"
              value={author}
              onChange={(e) => setAuthor(e.target.value)}
              onClickIconClear={() => setAuthor('')}
              closeIcon={true}
            />
          )}
          {userRole === 'Admin' && (
            <Dropdown
              labelText="Audience"
              placeholder="Select"
              labelClasses="mr-10 mb-4 text-xl"
              width="52"
              options={audienceOptions}
              value={audience}
              onChange={(e) => setAudience(e)}
              onClickIconClear={() => setAudience(null)}
              closeIcon={true}
            />
          )}
        </div>
        <div>
          {userRole !== 'Admin' && (
            <Input
              labelText="Author"
              name="author"
              placeholder="Enter author"
              inputClasses="w-52 mb-4 pr-8"
              labelClasses="mr-10 text-xl  mb-4"
              value={author}
              onChange={(e) => setAuthor(e.target.value)}
              onClickIconClear={() => setAuthor('')}
              closeIcon={true}
            />
          )}
          {userRole === 'Admin' && (
            <Input
              labelText="Unique Id"
              name="created"
              placeholder="Enter Id"
              inputClasses="w-52 mb-4 pr-8"
              labelClasses="mr-10 text-xl  mb-4"
              value={uniqueId}
              onChange={(e) => setUniqueId(e.target.value)}
              onClickIconClear={() => setUniqueId('')}
              closeIcon={true}
            />
          )}
          <DateTimePicker
            placeholder="Select date"
            name="created"
            labelText="Creation Date"
            inputClasses="w-52  mb-4"
            labelClasses="mr-10 text-xl  mb-4"
            format="YYYY-MM-DD"
            value={searchFilterParams ? searchFilterParams.creationDate : creationDate}
            onChange={(e) => setCreationDate(e)}
          />
          {userRole === 'Admin' && (
            <div className="mb-4">
              <Dropdown
                labelText="Status"
                placeholder="Status"
                labelClasses="mr-10 text-xl  mb-4"
                width="52"
                value={status}
                options={statusOptions}
                onChange={(e: any) => setStatus(e)}
                onClickIconClear={() => setStatus(null)}
                closeIcon={true}
              />
            </div>
          )}
        </div>
      </div>
      <div className="flex items-center justify-end">
        <p>{filterCount} filters applied</p>
        <Button
          ownClasses="px-7 mx-2.5 font-semibold text-dashboardPrimaryGrey"
          type="depressed"
          onClick={(e) => handleClear(e)}
        >
          Clear all
        </Button>
        <Button ownClasses="px-7 font-semibold rounded-full" type="success" onClick={(e) => handleSubmit(e)}>
          Update
        </Button>
      </div>
    </form>
  );
};

export default Form;
