import Button from 'components/common/Button';
import DateTimePicker from 'components/common/DateTimePicker';
import Dropdown from 'components/common/Dropdown';
import Input from 'components/common/Input';
import React, { useEffect } from 'react';
import { useState } from 'react';
import moment from 'moment';
import LookUpAPI from 'api/LookUpsAPI';
import MultiSelect from 'components/common/MultiSelect/MultiSelectV2';
import StaffAPI from 'api/StaffManagementApi';

export interface FormProps {
  formHandler: (value?: any) => void;
  filterFormValues?: any;
}

type IOBJ = {
  label: string;
  value: string;
};
const userTypeData = [
  { value: 'Nurse Practitioner', label: 'Nurse Practitioner' },
  { value: 'Physician', label: 'physician' },
  { value: 'Medical Assistant', label: 'Medical Assistant' },
  { value: 'Admin', label: 'Admin' },
  { value: 'Customer Service Rep', label: 'Customer Service Rep' },
];

const statuses = [
  { value: 'active', label: 'Active' },
  { value: 'inactive', label: 'Inactive' },
];
const Form: React.FC<FormProps> = ({ formHandler, filterFormValues }) => {
  const [name, setName] = useState('');
  const [newactiveState, setnewactiveStates] = useState<any>();
  const [lastLogin, setLastLogin] = useState('');
  const [userType, setUserType] = useState<{ label: string; value: string } | null>();
  const [uniqueId, setUniqueId] = useState('');
  const [activeState, setactiveStates] = useState<{ label: string; value: string }[] | null>();
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState<{ label: string; value: string } | null>();
  const [stateListData, setStateListData] = useState<IOBJ[]>([{ value: '', label: '' }]);
  const [statesSet, setStatesSet] = useState(false);

  const [filterCount, setFilterCount] = useState(Number);
  const [userData, setUserData] = useState<any>([]);

  useEffect(() => {
    console.log('filterform values', filterFormValues, userTypeData);
    const data: IOBJ[] = [];
    filterFormValues?.activeStates?.split(',')?.map((item: any) => {
      if (item.length > 0) {
        data.push({ value: item, label: item });
      }
    });
    setStatus(statuses?.find((item) => item.value === filterFormValues.status));
    setUserType(userTypeData?.find((item) => item.value === filterFormValues['userType.name']));
    setEmail(filterFormValues.email);
    setLastLogin(filterFormValues.lastLogin);

    setactiveStates(data);
    setName(filterFormValues.name);
    setUniqueId(filterFormValues.uniqueId);
  }, [filterFormValues]);

  useEffect(() => {
    StaffAPI.stateListLookup({}).then((res) => {
      setStateListData(res?.data?.data);
      CustomDataMapper(res?.data?.data, 'stateList');
    });
  }, [userData]);
  const CustomDataMapper = (input: any, key: any) => {
    const data: IOBJ[] = [];
    input.map((item: any) => {
      data.push({ value: item?.name, label: item?.name });
    });
    if (key === 'stateList') {
      setStateListData(data);
      setStatesSet(true);
    }
  };

  const valueMap = (data: any) => {
    const testData: any = [];
    data.map((item: any) => {
      if (item.value.length > 0) testData.push(item.value);
    });
    setnewactiveStates(testData);
  };

  useEffect(() => {
    activeState && valueMap(activeState);
  }, [activeState]);

  const handleSubmit = (e: any) => {
    const data = newactiveState && newactiveState.toString();
    e.preventDefault();
    const filterData: any = {
      ...(name && { name }),
      ...(status?.value && { status: status?.value }),
      ...(activeState && activeState.length !== 0 && { activeStates: data }),
      ...(lastLogin && { lastLogin }),
      ...(userType?.value && { 'userType.name': userType?.value }),
      ...(uniqueId && { uniqueId }),
      ...(email && { email }),
    };
    formHandler(filterData);
  };

  const handleClear = (e: any) => {
    e.preventDefault();
    formHandler({});
  };

  const checkFilterApplied = () => {
    let count = 0;
    if (name) {
      count += 1;
    }

    if (uniqueId) {
      count += 1;
    }

    if (email) {
      count += 1;
    }
    if (userType) {
      count += 1;
    }
    if (status) {
      count += 1;
    }
    if (lastLogin) {
      count += 1;
    }
    if (activeState?.length && activeState?.length > 0) {
      count += 1;
    }
    setFilterCount(count);
  };

  useEffect(() => {
    checkFilterApplied();
    console.log('xxx', email, userType, uniqueId, length, status, name, activeState, lastLogin);
  }, [email, userType, uniqueId, length, status, name, activeState, lastLogin]);
  return (
    <form>
      <div className="flex flex-col lg:flex-row justify-center items-center">
        <div className="mr-20 mb-4">
          <Input
            labelText="Name"
            name="name"
            placeholder="Enter name"
            inputClasses="w-52 mb-4 pr-8"
            labelClasses="mr-10 text-xl  mb-4"
            value={name}
            onChange={(e) => setName(e.target.value)}
            onClickIconClear={() => setName('')}
            closeIcon={true}
          />
          <div className="mb-4">
            <Dropdown
              labelText="User Type"
              placeholder="Select Usertype"
              labelClasses="mr-10 text-xl  mb-4"
              width="52"
              value={userType}
              options={userTypeData}
              onClickIconClear={() => setUserType(null)}
              closeIcon={true}
              onChange={(e: any) => setUserType(e)}
            />
          </div>
          <Input
            labelText="Email"
            name="email"
            placeholder="Enter Email"
            inputClasses="w-52 mb-4 pr-8"
            labelClasses="mr-10 text-xl  mb-4"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onClickIconClear={() => setEmail('')}
            closeIcon={true}
          />
          <div className="mb-4">
            <DateTimePicker
              labelText="Last Active"
              name="due"
              placeholder="Enter  date"
              inputClasses="w-52  mb-4"
              labelClasses="mr-10 text-xl  mb-4"
              value={lastLogin}
              format="YYYY-MM-DD"
              onChange={(e) => setLastLogin(moment(e).format('YYYY-MM-DD'))}
            />
          </div>
        </div>
        <div>
          <Input
            labelText="Unique Id"
            name="uniqueId"
            placeholder="Enter Id"
            inputClasses="w-52 mb-4 pr-8"
            labelClasses="mr-10 text-xl  mb-4"
            value={uniqueId}
            onChange={(e) => setUniqueId(e.target.value)}
            onClickIconClear={() => setUniqueId('')}
            closeIcon={true}
          />

          <div className="mb-4">
            <Dropdown
              labelText="Status"
              placeholder="Select status"
              labelClasses="mr-10 text-xl  mb-4"
              width="52"
              value={status}
              options={statuses}
              onClickIconClear={() => setStatus(null)}
              closeIcon={true}
              onChange={(e: any) => setStatus(e)}
            />
          </div>

          <div className="mb-4">
            <MultiSelect
              labelText="Licensed in"
              labelClasses="text-xl mr-14"
              options={stateListData}
              value={statesSet ? activeState : null}
              handleChange={(value: any) => setactiveStates(value)}
              field="states"
              statesSet={statesSet}
              onClickIconClear={() => setactiveStates(null)}
              closeIcon={true}
            />
          </div>
        </div>
      </div>
      <div className="flex items-center justify-end">
        <p>{filterCount} filters applied</p>
        <Button
          ownClasses="px-7 mx-2.5 font-semibold text-dashboardPrimaryGrey"
          type="depressed"
          onClick={(e) => handleClear(e)}
        >
          Clear all
        </Button>
        <Button ownClasses="px-7 font-semibold rounded-full" type="success" onClick={(e) => handleSubmit(e)}>
          Update
        </Button>
      </div>
    </form>
  );
};

export default Form;
