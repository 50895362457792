import actions from './actions';
import reducer from './reducer';

const userRoles = {
  actions,
  reducer,
  name: 'userRoles',
};

export default userRoles;
