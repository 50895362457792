import { useEffect, useState } from 'react';
import dayjs from 'dayjs';

const Timer: React.FC = () => {
  const [callTime, setCallTime] = useState('00:00');
  const [startTime, setStartTime] = useState(dayjs());

  const getTime = () => {
    if (startTime) {
      const id = setInterval(() => {
        const minutes = dayjs().diff(startTime, 'm');
        const seconds = dayjs().diff(dayjs(startTime), 's') - minutes * 60;

        setCallTime(`${`${minutes}`.padStart(2, '0')}:${`${seconds}`.padStart(2, '0')}`);
      }, 1000);

      return () => clearInterval(id);
    }
  };

  useEffect(() => {
    setStartTime(dayjs(new Date()));
    return getTime();
  }, []);

  return (
    <div className="absolute rounded-lg text-white bg-darkCharcoal bg-opacity-40 py-1.5 px-5 w-20 text-center z-20 top-4 left-4 md:left-10 md:top-8">
      {callTime}
    </div>
  );
};

export default Timer;
