import Button from 'components/common/Button';
import Dropdown from 'components/common/Dropdown';
import Input from 'components/common/Input';
import MultiSelect from 'components/common/MultiSelect';
import moment from 'moment';
import * as _ from 'lodash';

import { useEffect, useState } from 'react';

const typeOptionsInternal = [
  { value: 'Task', label: 'Task' },
  { value: 'Notification', label: 'Notification' },
];

const typeOptionsPatient = [
  { value: 'Webhook', label: 'Webhook' },
  { value: 'Patient Message', label: 'Patient Message' },
];

export interface FormProps {
  formHandler: (value?: any) => void;
  filterFormValues?: any;
  automationType?: any;
}

const Form: React.FC<FormProps> = ({ formHandler, filterFormValues, automationType }) => {
  const [name, setName] = useState('');
  // const [type, setType] = useState('');
  const [type, setType] = useState<{ label: string; value: string } | null>();
  const [uniqueId, setUniqueId] = useState('');
  const [audience, setAudience] = useState<{ label: string; value: string } | null>();
  const [trigger, setTrigger] = useState<[]>();

  const [timingUnits, setTimingUnits] = useState<{ label: string; value: string } | null>();
  const [timingAmount, setTimingAmount] = useState('');
  const [status, setStatus] = useState<{ label: string; value: string } | null>();
  const [filterCount, setFilterCount] = useState(0);

  const audienceOptions = [
    { value: 'MA', label: 'MA' },
    { value: 'NP', label: 'NP' },
    { value: 'PH', label: 'PH' },
    { value: 'CS', label: 'CS' },
    { value: 'AD', label: 'AD' },
  ];
  const timingUnitsOptions = [
    { value: 'hours before', label: 'Hours (before)' },
    { value: 'hours after', label: 'Hours (after)' },
    { value: 'minutes before', label: 'Mins (before)' },
    { value: 'minutes after', label: 'Mins (after)' },
  ];
  const states = [
    { value: 'California', label: 'California' },
    { value: 'Florida', label: 'Florida' },
    { value: 'New York', label: 'New York' },
    { value: 'South Carolina', label: 'South Carolina' },
    { value: 'South Dakota', label: 'South Dakota' },
  ];

  const statuses = [
    { value: 'active', label: 'Active' },
    { value: 'inactive', label: 'Inactive' },
  ];

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const filterData = {
      ...(name && { name }),
      ...(type?.value && { triggerType: type.value }),
      ...(uniqueId && { uniqueId }),
      ...(audience?.value && { audience: audience.value }),
      ...(timingUnits?.value && {
        'triggerTime.occurenceOrder': timingUnits.value.includes('before') ? 'before' : 'after',
      }),
      ...(timingAmount && { 'triggerTime.timeInHours': timingAmount }),
      ...(timingUnits?.value && { triggerUnit: timingUnits.label.includes('Mins') ? 'minutes' : 'hours' }),
      ...(status?.value && { status: status.value }),
    };
    formHandler(filterData);
  };

  const handleClear = (e: any) => {
    e.preventDefault();
    formHandler({});
  };

  const checkFilterApplied = () => {
    let count = 0;
    if (name) {
      count += 1;
    }
    if (type) {
      count += 1;
    }
    if (uniqueId) {
      count += 1;
    }
    if (audience) {
      count += 1;
    }
    if (timingUnits) {
      count += 1;
    }
    if (timingAmount) {
      count += 1;
    }

    if (status) {
      count += 1;
    }
    setFilterCount(count);
  };

  useEffect(() => {
    checkFilterApplied();
  }, [name, type, uniqueId, audience, timingUnits, timingAmount, status]);

  useEffect(() => {
    if (!_.isEmpty(filterFormValues)) {
      setName(filterFormValues.name);
      setType(
        automationType == 'internal'
          ? typeOptionsInternal.find((item) => item.value === filterFormValues.triggerType)
          : typeOptionsPatient.find((item) => item.value === filterFormValues.triggerType),
      );
      setUniqueId(filterFormValues.uniqueId);
      setAudience(audienceOptions.find((item) => item.value === filterFormValues.audience));
      setStatus(statuses.find((item) => item.value === filterFormValues.status));
      setTimingAmount(filterFormValues['triggerTime.timeInHours']);
      timingUnitSetter();
      // setTrigger(() => filterFormValues.audience.map((item: any) => ({ value: item, label: item })));
    }
  }, [filterFormValues]);

  const timingUnitSetter = () => {
    timingUnitsOptions?.map((item: any) => {
      item?.value?.includes(filterFormValues?.triggerUnit) &&
      item?.value?.includes(filterFormValues['triggerTime.occurenceOrder'])
        ? setTimingUnits(item)
        : null;
    });
  };

  const handleAudienceSelection = (e: any) => {
    console.log('Trigger MultiSelect');
    console.log(e);
    setAudience(() => e.map((item: any) => item.value));
    setTrigger(e);
  };

  return (
    <form>
      <div className="flex flex-col lg:flex-row justify-center items-center mb-4">
        <div className="mr-20">
          <Input
            wrapperClasses="mb-4"
            labelText="Name"
            placeholder="Enter name"
            inputClasses="w-52 pr-8"
            labelClasses="mr-10 text-xl"
            value={name}
            onChange={(e) => setName(e.target.value)}
            onClickIconClear={() => setName('')}
            closeIcon={true}
          />
          {/* <Input
            wrapperClasses="mb-4"
            labelText="Type"
            placeholder="Enter type"
            inputClasses="w-52"
            labelClasses="mr-10 text-xl"
            value={type}
            onChange={(e) => setType(e.target.value)}
          /> */}
          <Input
            wrapperClasses="mb-4"
            labelText="Unique ID"
            placeholder="Enter Unique ID"
            inputClasses="w-52 pr-8"
            labelClasses="mr-10 text-xl"
            value={uniqueId}
            onChange={(e) => setUniqueId(e.target.value)}
            onClickIconClear={() => setUniqueId('')}
            closeIcon={true}
          />
          <div className="mb-4">
            <Dropdown
              labelText="Type"
              placeholder="Select"
              labelClasses="mr-10 text-xl"
              width="52"
              options={automationType == 'internal' ? typeOptionsInternal : typeOptionsPatient}
              value={type}
              onClickIconClear={() => setType(null)}
              closeIcon={true}
              onChange={(e) => setType(e)}
            />
          </div>

          {automationType == 'internal' && (
            <Dropdown
              labelText="Audience"
              placeholder="Select"
              labelClasses="mr-10 text-xl"
              width="52"
              options={audienceOptions}
              value={audience}
              onClickIconClear={() => setAudience(null)}
              closeIcon={true}
              onChange={(e) => setAudience(e)}
            />
          )}
          {/* <div className="mb-4">
            <MultiSelect
              labelText="Audience"
              options={audienceOptions}
              value={trigger}
              handleChange={handleAudienceSelection}
            />
          </div> */}
        </div>
        <div>
          <div className="mb-4">
            <Input
              labelText="Timing (Amount)"
              placeholder="Enter amount"
              inputClasses="w-52 pr-8"
              labelClasses="mr-10 text-xl"
              width="52"
              value={timingAmount}
              onChange={(e) => setTimingAmount(e.target.value)}
              onClickIconClear={() => setTimingAmount('')}
              closeIcon={true}
            />
          </div>
          <div className="mb-4">
            <Dropdown
              labelText="Timing (Units)"
              placeholder="Select"
              labelClasses="mr-10 text-xl"
              width="52"
              options={timingUnitsOptions}
              value={timingUnits}
              onClickIconClear={() => setTimingUnits(null)}
              closeIcon={true}
              onChange={(e) => setTimingUnits(e)}
            />
          </div>

          <div className="mb-4">
            <Dropdown
              labelText="Status"
              placeholder="Status"
              labelClasses="mr-10 text-xl  mb-4"
              width="52"
              value={status}
              options={statuses}
              onClickIconClear={() => setStatus(null)}
              closeIcon={true}
              onChange={(e: any) => setStatus(e)}
            />
          </div>
        </div>
      </div>
      <div className="flex items-center justify-end">
        <p>{filterCount} filters applied</p>
        <Button
          ownClasses="px-7 mx-2.5 font-semibold text-dashboardPrimaryGrey"
          type="depressed"
          onClick={(e) => handleClear(e)}
        >
          Clear all
        </Button>
        <Button ownClasses="px-7 font-semibold rounded-full" type="success" onClick={(e) => handleSubmit(e)}>
          Update
        </Button>
      </div>
    </form>
  );
};

export default Form;
