import Button from 'components/common/Button';
import Dropdown from 'components/common/Dropdown';
import Input from 'components/common/Input';
import React, { useEffect } from 'react';
import { useState } from 'react';

export interface FormProps {
  formHandler: (value?: any) => void;
  filterFormValues?: any;
  payTypeOptions?: IOBJ[];
}

type IOBJ = {
  label: string;
  value: string;
};
const userTypeData = [
  { value: 'Nurse Practitioner', label: 'Nurse Practitioner' },
  { value: 'Physician', label: 'physician' },
  { value: 'Medical Assistant', label: 'Medical Assistant' },
  { value: 'Admin', label: 'Admin' },
  { value: 'Customer Service Rep', label: 'Customer Service Rep' },
];

const statuses = [
  { value: 'active', label: 'Active' },
  { value: 'inactive', label: 'Inactive' },
];
const Form: React.FC<FormProps> = ({ formHandler, filterFormValues, payTypeOptions }) => {
  const [staff, setStaff] = useState<{ label: string; value: string } | null>();
  const [status, setStatus] = useState<{ label: string; value: string } | null>();
  const [payAmount, setPayAmount] = useState('');
  const [payType, setPayType] = useState<{ label: string; value: string } | null>();
  const [filterCount, setFilterCount] = useState(Number);

  useEffect(() => {
    const data: IOBJ[] = [];

    setStatus(statuses?.find((item) => item.value === filterFormValues.status));
    setPayType(payTypeOptions?.find((item) => item.value === filterFormValues?.payType));
    setStaff(userTypeData?.find((item) => item.value === filterFormValues?.staff));
    setPayAmount(filterFormValues.payAmount);
  }, [filterFormValues, payTypeOptions]);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const filterData: any = {
      ...(staff?.value && { staff: staff?.value }),
      ...(status?.value && { status: status?.value }),
      ...(payType?.value && { payType: payType?.value }),
      ...(payAmount && { payAmount }),
    };
    formHandler(filterData);
  };

  const handleClear = (e: any) => {
    e.preventDefault();
    formHandler({});
  };

  const checkFilterApplied = () => {
    let count = 0;

    if (status) {
      count += 1;
    }
    if (payType) {
      count += 1;
    }
    if (payAmount) {
      count += 1;
    }
    if (staff) {
      count += 1;
    }

    setFilterCount(count);
  };

  useEffect(() => {
    checkFilterApplied();
  }, [payType, payAmount, staff, status]);
  return (
    <form>
      <div className="flex flex-col lg:flex-row justify-center items-center">
        <div className="mr-20 mb-4">
          <Dropdown
            labelText="Staff"
            placeholder="Select staff"
            labelClasses="mr-10 text-xl  mb-4 whitespace-nowrap"
            width="52"
            value={staff}
            options={userTypeData}
            onChange={(e: any) => setStaff(e)}
            onClickIconClear={() => setStaff(null)}
            closeIcon={true}
          />
          <Dropdown
            labelText="PayType"
            name="payType"
            placeholder="Enter pay type"
            inputClasses="w-52  mb-4"
            labelClasses="mr-10 text-xl  mb-4 whitespace-nowrap"
            width="52"
            value={payType}
            options={payTypeOptions}
            onChange={(e) => setPayType(e)}
            onClickIconClear={() => setPayType(null)}
            closeIcon={true}
          />
          <div className="mb-4">
            <Input
              labelText="Pay Amount"
              name="payAmount"
              placeholder="Enter pay amount"
              inputClasses="w-52 mb-4 pr-8"
              labelClasses="mr-8 text-xl  mb-4 whitespace-nowrap"
              value={payAmount}
              onChange={(e) => setPayAmount(e.target.value)}
              onClickIconClear={() => setPayAmount('')}
              closeIcon={true}
            />
          </div>
          <div className="mb-4">
            <Dropdown
              labelText="Status"
              placeholder="Status"
              labelClasses="mr-10 text-xl  mb-4 whitespace-nowrap"
              width="52"
              value={status}
              options={statuses}
              onClickIconClear={() => setStatus(null)}
              closeIcon={true}
              onChange={(e: any) => setStatus(e)}
            />
          </div>
        </div>
        <div className="w-52"></div>
      </div>
      <div className="flex items-center justify-end">
        <p>{filterCount} filters applied</p>
        <Button
          ownClasses="px-7 mx-2.5 font-semibold text-dashboardPrimaryGrey"
          type="depressed"
          onClick={(e) => handleClear(e)}
        >
          Clear all
        </Button>
        <Button ownClasses="px-7 font-semibold rounded-full" type="success" onClick={(e) => handleSubmit(e)}>
          Update
        </Button>
      </div>
    </form>
  );
};

export default Form;
