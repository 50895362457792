import Label from '../Label';
import classNames from 'classnames';
import { InputHTMLAttributes } from 'react';
import { XIcon } from '@heroicons/react/solid';

interface IInput extends InputHTMLAttributes<HTMLInputElement> {
  labelText?: string;
  inputClasses?: string;
  labelClasses?: string;
  wrapperClasses?: string;
  name?: string;
  value?: string | number;
  handleChange?: (value: any) => any;
  onClickIconClear?: () => void;
  closeIcon?: boolean;
}

const Input: React.FC<IInput> = ({
  labelText,
  inputClasses,
  wrapperClasses,
  labelClasses,
  name,
  value,
  handleChange,
  onClickIconClear,
  closeIcon,
  ...rest
}) => {
  return (
    <div className={classNames('flex items-center justify-between', wrapperClasses)}>
      <Label labelText={labelText} labelClasses={labelClasses} />
      {closeIcon ? (
        <div className={`relative`}>
          <input
            placeholder={rest.placeholder}
            className={classNames(
              'pl-4 py-1 mb-3 bg-background border border-lightBorder rounded-lg placeholder-text text-medium font-light',
              inputClasses,
            )}
            name={name}
            value={value}
            onChange={handleChange}
            required
            {...rest}
          />
          {closeIcon && value && (
            <button
              className="rounded-full w-5 flex justify-center items-center absolute right-2 inset-y-0 mb-3.5"
              onClick={() => {
                onClickIconClear && onClickIconClear();
              }}
            >
              <XIcon className="h-5 w-5" />
            </button>
          )}
        </div>
      ) : (
        <div className={`relative w-full`}>
          <input
            placeholder={rest.placeholder}
            className={classNames(
              'pl-4 py-1 bg-background border border-lightBorder rounded-lg placeholder-text text-medium font-light',
              inputClasses,
            )}
            name={name}
            value={value}
            onChange={handleChange}
            required
            {...rest}
          />
        </div>
      )}
    </div>
  );
};

export default Input;
