import AgingAlertsAPI from 'api/AgingAlertsAPI';
import AdminAgingAlerts from 'components/AdminAgingAlerts';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setAgingAlertsCount, setAgingAlertsList } from 'redux/agingAlerts/actions';

const AgingAlerts: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const fetchAgingAlerts = (value?: any) => {
    setLoading(true);
    AgingAlertsAPI.fetchAgingAlerts(value)
      .then((res) => {
        if (res.data.errorCode === 0) {
          dispatch(setAgingAlertsList(res.data.data));
          dispatch(setAgingAlertsCount(res.data.info.totalCount));
        }
      })
      .finally(() => {
        setLoading(false);
      });
    // }
  };

  useEffect(() => {
    fetchAgingAlerts({ pageNo: 0, limit: 10 });
  }, [dispatch]);
  return <AdminAgingAlerts fetchAgingAlerts={fetchAgingAlerts} loading={loading} />;
};

export default AgingAlerts;
