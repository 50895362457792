import { XIcon } from '@heroicons/react/solid';

interface IModal {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const Modal: React.FC<IModal> = ({ children, showModal, setShowModal }) => {
  return (
    <>
      {showModal ? (
        <>
          <div className="absolute inset-0 z-50 overflow-auto p-12">
            <div className="flex items-center justify-center">
              <div className="relative bg-white rounded-lg shadow-lg">
                <button
                  className="absolute -top-6 -right-7 z-10 p-4 rounded-full bg-darkBlue"
                  onClick={() => setShowModal(false)}
                >
                  <XIcon className="text-white h-6 w-6" />
                </button>
                <div className="relative flex-auto">
                  {/*content*/}
                  {children}
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-50 fixed inset-0 z-40 bg-darkBlue" />
        </>
      ) : null}
    </>
  );
};

export default Modal;
