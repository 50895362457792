import types from './types';

export const setAgingAlertsList = (data: any) => ({
  type: types.SET_AGING_ALERTS_LIST,
  payload: data,
});

export const setAgingAlertsCount = (data: number) => ({
  type: types.SET_AGING_ALERTS_COUNT,
  payload: data,
});

const actions = {
  setAgingAlertsList,
  setAgingAlertsCount,
};

export default actions;
