import { AxiosInstanceWebsite } from './AxiosInstance';
import config from '../config';

const { api } = config;

const physicianRequestTypeAppendPath = 'physician-request-type';
const frontDeskRequestTypeAppendthPath = 'front-desk-request-type';
export default class RequestTypeAPI {
  static fetchPhysicianRequestTypes(data: any) {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/${physicianRequestTypeAppendPath}`,
      method: 'GET',
      params: data,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  static fetchFrontDeskRequestTypes(data: any) {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/${frontDeskRequestTypeAppendthPath}`,
      method: 'GET',
      params: data,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  static editFrontDeskRequestTypes(data: any, requestTypeId: any) {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/${frontDeskRequestTypeAppendthPath}/${requestTypeId}`,
      method: 'PUT',
      data: data,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }
  static postFrontDeskRequestTypes(data: any) {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/${frontDeskRequestTypeAppendthPath}`,
      method: 'POST',
      data: data,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  static deleteFrontDeskRequestTypes(requestTypeId: any) {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/${frontDeskRequestTypeAppendthPath}/${requestTypeId}`,
      method: 'DELETE',
      // params: data,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  static fetchSingleFrontDeskRequestTypes(requestTypeId: any) {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/${frontDeskRequestTypeAppendthPath}/${requestTypeId}`,
      method: 'GET',
      // params: data,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }
}
