import ArticleAPI from 'api/ArticleAPI';
import ArticleTable from 'components/ArticleTable';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setArticleLists, setTotalArticle } from 'redux/articles/actions';

const Article: React.FC = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const fetchArticlesList = (value?: any) => {
    setLoading(true);
    ArticleAPI.fetchArticles(value)
      .then((res) => {
        if (res.data.errorCode === 0) {
          dispatch(setArticleLists(res.data.data));
          dispatch(setTotalArticle(res.data.info.totalCount));
          setLoading(false);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    fetchArticlesList({ pageNo: 0, limit: 10 });
  }, [dispatch]);
  return <ArticleTable fetchArticlesList={fetchArticlesList} loading={loading} />;
};

export default Article;
