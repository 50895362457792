import AppointmentCategoryAPI from 'api/AppointmentCategories';
import Button from 'components/common/Button';
import Input from 'components/common/Input';
import { useEffect } from 'react';
import { useState } from 'react';
import { toast } from 'react-toastify';

export interface FormProps {
  setShowModal3: (value?: any) => void;
}

const VideoForm: React.FC<FormProps> = ({ setShowModal3 }) => {
  const [primaryCare, setPrimaryCare] = useState('');
  const [chronicCare, setChronicCare] = useState('');
  const [urgentCare, setUrgentCare] = useState('');
  const [primaryCareId, setPrimaryCareId] = useState('');
  const [chronicCareId, setChronicCareId] = useState('');
  const [urgentCareId, setUrgentCareId] = useState('');
  const [data, setData] = useState<any>();

  useEffect(() => {
    AppointmentCategoryAPI.fetchAppointmentCategories().then((res: any) => {
      if (res.data.errorCode === 0) {
        setData(res?.data?.data);
      } else toast.error('Error! Could Not Delete');
    });
  }, []);

  useEffect(() => {
    console.log('Data id', data);
    data?.map((item: any) => {
      if (item.name == 'Chronic care') {
        setChronicCare(item.referenceUrl);
        setChronicCareId(item._id);
      }
      if (item.name == 'Primary care') {
        setPrimaryCare(item.referenceUrl);
        setPrimaryCareId(item._id);
      }
      if (item.name == 'Urgent care') {
        setUrgentCare(item.referenceUrl);
        setUrgentCareId(item._id);
      }
    });
  }, [data]);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const payload = [
      {
        _id: primaryCareId,
        referenceUrl: primaryCare,
      },
      {
        _id: chronicCareId,
        referenceUrl: chronicCare,
      },
      {
        _id: urgentCareId,
        referenceUrl: urgentCare,
      },
    ];

    const final = { categories: payload };
    AppointmentCategoryAPI.editAppointmentCategories(final)
      .then((res: any) => {
        if (res?.data?.errorCode === 0) {
          toast.success('Successfully updated');
          setShowModal3(false);
        } else toast.error('Error! Could Not Edit');
      })
      .catch((err: any) => {
        toast.error(err.response.data.message);
      });
  };

  return (
    <form>
      <div className="flex flex-col  justify-start items-start ">
        <Input
          wrapperClasses="mb-4 w-full"
          labelText="Primary care"
          placeholder="Enter Primary care"
          inputClasses="w-full"
          labelClasses="mr-10 text-xl whitespace-nowrap	"
          value={primaryCare}
          onChange={(e) => setPrimaryCare(e.target.value)}
        />
        <Input
          wrapperClasses="mb-4 w-full"
          labelText="Urgent care"
          placeholder="Enter Urgent care"
          inputClasses="w-full"
          labelClasses="mr-12 text-xl whitespace-nowrap	"
          value={urgentCare}
          onChange={(e) => setUrgentCare(e.target.value)}
        />
        <Input
          wrapperClasses="mb-4 w-full"
          labelText="Chronic care"
          placeholder="Enter Chronic care"
          inputClasses="w-full"
          labelClasses="mr-10 text-xl whitespace-nowrap	"
          value={chronicCare}
          onChange={(e) => setChronicCare(e.target.value)}
        />
      </div>
      <div className="flex items-center justify-end">
        <Button ownClasses="px-7 font-semibold rounded-full" type="success" onClick={(e) => handleSubmit(e)}>
          Save
        </Button>
      </div>
    </form>
  );
};

export default VideoForm;
