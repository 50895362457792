import types from './types';

export const setPatientMessageTemplatesList = (data: any) => ({
  type: types.SET_PATIENT_MESSAGE_TEMPLATES_LIST,
  payload: data,
});

export const setPatientMessageTemplatesCount = (data: number) => ({
  type: types.SET_PATIENT_MESSAGE_TEMPLATES_COUNT,
  payload: data,
});

export const setRequestMessageTemplatesList = (data: any) => ({
  type: types.SET_REQUEST_MESSAGE_TEMPLATES_LIST,
  payload: data,
});

export const setRequestMessageTemplatesCount = (data: number) => ({
  type: types.SET_REQUEST_MESSAGE_TEMPLATES_COUNT,
  payload: data,
});

export const setInternalNoteTemplatesList = (data: any) => ({
  type: types.SET_INTERNAL_NOTE_TEMPLATES_LIST,
  payload: data,
});

export const setInternalNoteTemplatesCount = (data: number) => ({
  type: types.SET_INTERNAL_NOTE_TEMPLATES_COUNT,
  payload: data,
});
const actions = {
  setPatientMessageTemplatesList,
  setPatientMessageTemplatesCount,
  setRequestMessageTemplatesList,
  setRequestMessageTemplatesCount,
  setInternalNoteTemplatesList,
  setInternalNoteTemplatesCount,
};

export default actions;
