import { useState, useEffect } from 'react';
import Button from '../../common/Button';
import CircleAvatar from '../../common/CircleAvatar/CircleAvatar';
import { SwitchHorizontalIcon } from '@heroicons/react/solid';
import { ReactComponent as Arrow } from 'assets/icons/arrow-right.svg';
import { ReactComponent as Check } from 'assets/icons/check.svg';
import { CheckCategoryColor } from 'utils/CheckCategoryColor';
import { dateTimeFormatter } from 'utils/dateTimeFormatter';
import TaskApi from 'api/TaskAPI';
import { ReactComponent as Bag } from 'assets/icons/bag.svg';
import Checkbox from 'components/common/Checkbox';
import { toast } from 'react-toastify';
import { useSelector, RootStateOrAny } from 'react-redux';
import { useHistory } from 'react-router';
import { ViewAsEmr } from 'utils/ViewAsEmr';
import { hasAccessToModule } from 'auth/authorization';
import AppointmentAPI from 'api/AppointmentAPI';
import classNames from 'classnames';
import Transfer from './Transfer';
import defaultpic from 'assets/images/profile-generic.png';
import Score from 'components/Admin/Patient/PatientInfo/Score';
import Modal from './Modal';
import DoctorAvatar from 'assets/icons/doc-avatar.svg';
import { XIcon } from '@heroicons/react/solid';
import * as Sentry from '@sentry/react';

export interface Props {
  headerProps?: any;
  appointmentInformation?: any;
  messageSent?: any;
  markCompleteEnabled?: any;
}

const notDisplayDueDate = ['Appointment', 'Post-consult', 'Pre-consult', 'Onboarding'];
const onboardingAndPreConsult = ['Pre-consult', 'Onboarding'];

const Heading: React.FC<Props> = ({ headerProps, appointmentInformation, messageSent, markCompleteEnabled }) => {
  const [popup, setPopup] = useState(false);
  const [disableMarkComplete, setDisableMarkComplete] = useState(true);
  const [markUrgentResult, setMarkUrgentResult] = useState(false);
  const [simpleVistType, setSimpleVisitType] = useState(false);
  const [complexVisitType, setComplexVisitType] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const history = useHistory();
  const lookUp = useSelector((state: RootStateOrAny) => state.lookUp);
  const userInfo = useSelector((state: RootStateOrAny) => state.userProfile.userDetails);

  //TODO get score from api
  const score = 6;

  const isCategoriesListHasCategory = (CategoriesList: string[], category: string) => {
    return CategoriesList.includes(category);
  };

  useEffect(() => {
    if (headerProps) {
      if (headerProps.category === 'Post-consult') {
        setDisableMarkComplete(true);
      }
      if (headerProps.visitTypeRequired === true) {
        if (headerProps?.visitType === 'complex') {
          setComplexVisitType(true);
          setDisableMarkComplete(false);
        }
        if (headerProps?.visitType === 'simple') {
          setSimpleVisitType(true);
          setDisableMarkComplete(false);
        }
      } else setDisableMarkComplete(true);
    }
  }, [headerProps]);

  const onCliCkComplete = () => {
    TaskApi.setTaskComplete(headerProps.taskId)
      .then((res) => {
        const { data } = res;
        toast.success(data.message);
        headerProps.onCancel();
      })
      .catch((e) => {
        toast.error(e.response.data.message);
        setDisableMarkComplete(true);
      });
  };

  useEffect(() => {
    {
      !markCompleteEnabled ? setDisableMarkComplete(true) : setDisableMarkComplete(false);
    }
  }, [markCompleteEnabled]);

  const onClickTaskOver = () => {
    TaskApi.setTaskRequestTakeOver(headerProps.taskId)
      .then((res) => {
        const { data } = res;
        toast.success(data.message);
        headerProps.fetchTaskDetails();
      })
      .catch((e) => {
        Sentry.captureException(e);
        toast.error(e.response.data.message);
      });
  };

  const onClickSendToPhysician = () => {
    const payload = {
      markUrgent: markUrgentResult,
    };
    TaskApi.sendTaskToPhysician(headerProps.taskId, payload)
      .then((res) => {
        const { data } = res;
        toast.success(data.message);
        headerProps.onCancel();
      })
      .catch((e) => {
        Sentry.captureException(e);
        toast.error(e.response.data.message);
      });
  };

  const onCheckVisitType = (value: boolean, name?: string) => {
    const data = {
      visitType: name,
    };
    if (name === 'complex') {
      setComplexVisitType(!complexVisitType);
      if (simpleVistType) {
        setSimpleVisitType(false);
      }
    } else {
      setSimpleVisitType(!simpleVistType);
      if (complexVisitType) {
        setComplexVisitType(false);
      }
    }
    AppointmentAPI.visitTypeAppointment(headerProps.appointmentId, data)
      .then((res) => {
        if (res.data.errorCode === 0) {
          setDisableMarkComplete(false);
        }
      })
      .catch((err) => {
        Sentry.captureException(err);
        toast.error(err.response.data.message);
      });
  };

  const isAllIntakeItemsCheck = () => {
    return headerProps.intakeItemsInfo?.every((item: { active: boolean }) => item.active);
  };

  const showMarkCompleteButton = () => {
    const preConsultAllIntakeItemsCheck =
      headerProps.category === 'Post-consult' ||
      (isCategoriesListHasCategory(onboardingAndPreConsult, headerProps.category) && isAllIntakeItemsCheck());

    return (
      headerProps.status !== 'Completed' &&
      headerProps.assignedTo?._id === userInfo._id &&
      preConsultAllIntakeItemsCheck
    );
  };

  const checkAgingAlertAudience = () => {
    if (headerProps.agingAlertAudience) {
      const isAvailable = headerProps.agingAlertAudience.includes(userInfo.userType.shortCode);
      if (isAvailable) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  return (
    <>
      <div className="flex flex-wrap flex-col md:flex-row justify-between items-center mb-10 md:mb-0">
        <div className="flex items-center mb-2">
          <div className="">
            <CircleAvatar
              src={headerProps.patientProfileUrl ? headerProps.patientProfileUrl : defaultpic}
              ownClasses="w-16 h-16"
            />
          </div>
          <div className="flex flex-col m-2">
            <span className="text-darkBlue text-xl font-bold">{headerProps.patientName}</span>
            <span className=" text-textGreyedOut md:font-medium">{headerProps.plan}</span>
          </div>
          <Score score={score} />
        </div>
        <div className="header-button-report">
          {userInfo.userType?.name !== 'Admin' &&
            hasAccessToModule(userInfo.userRoleInfo.pagePermissions, ['VIEW_PATIENT_EMR']) && (
              <Button ownClasses="font-normal" type="depressed" onClick={() => ViewAsEmr(headerProps.patientId)}>
                <Bag className="mr-2 w-5" />
                View EMR
              </Button>
            )}
          {/* /61685705b1b332cd26495b0f  "61685705b1b332cd26495b0f"*/}
          {/* for appointment missed and cancelled */}
          {headerProps.status !== 'Completed' &&
            (headerProps?.categoryInfo == `Appointment: Missed` ||
              headerProps?.categoryInfo == `Appointment: Cancelled`) &&
            headerProps?.assignedTo?._id === userInfo._id && (
              <div>
                <Button
                  ownClasses="rounded-full"
                  type="success"
                  disabled={disableMarkComplete}
                  onClick={() => onCliCkComplete()}
                  // onClick={messageInformation?.length == 0 ? () => setShowModal(true) : () => onCliCkComplete()}
                >
                  Mark complete
                </Button>
              </div>
            )}
          {/* ------------------------------ */}
          {headerProps.assignedTo &&
            headerProps.assignedTo._id === userInfo._id &&
            headerProps.status !== 'Completed' &&
            headerProps.category !== 'Appointment' &&
            headerProps.category !== 'Post-consult' &&
            userInfo.userType?.name !== 'Medical Assistant' &&
            userInfo.userType?.name !== 'Nurse Practitioner' &&
            userInfo.userType?.name !== 'Customer Service Rep' && (
              <Button
                ownClasses="rounded-full"
                type="success"
                disabled={disableMarkComplete}
                onClick={() => onCliCkComplete()}
              >
                Mark complete
              </Button>
            )}
          {userInfo.userType?.name !== 'Admin' &&
            headerProps.status !== 'Completed' &&
            headerProps.assignedTo &&
            headerProps.assignedTo._id !== userInfo._id && (
              <Button ownClasses="rounded-full" type="success" onClick={() => onClickTaskOver()}>
                Take over task
              </Button>
            )}

          {headerProps.category === 'Results' &&
            (userInfo.userType?.name === 'Medical Assistant' || userInfo.userType?.name === 'Nurse Practitioner') &&
            headerProps.assignedTo &&
            headerProps.assignedTo._id === userInfo._id &&
            !headerProps.isReview && (
              <div>
                <Button ownClasses="rounded-full" type="success" onClick={() => onClickSendToPhysician()}>
                  Send to Physician
                </Button>
                <div className="header-checkbox-report mt-6">
                  <Checkbox
                    labelText="Mark as Urgent"
                    value={markUrgentResult}
                    onChecked={() => setMarkUrgentResult(!markUrgentResult)}
                  />
                </div>
              </div>
            )}

          {headerProps.category !== 'Post-consult' &&
            (headerProps.category === 'Messages' ||
              headerProps.category === 'Requests' ||
              headerProps.category === 'Front desk') &&
            (userInfo.userType?.name === 'Medical Assistant' ||
              userInfo.userType?.name === 'Nurse Practitioner' ||
              userInfo.userType?.name === 'Customer Service Rep') &&
            headerProps.assignedTo &&
            headerProps.assignedTo._id === userInfo._id && (
              <div>
                <Button
                  ownClasses="rounded-full"
                  type="success"
                  disabled={disableMarkComplete}
                  onClick={() => onCliCkComplete()}
                >
                  Mark complete
                </Button>
              </div>
            )}
          {showMarkCompleteButton() && (
            <div>
              <Button
                ownClasses={`rounded-full ml-auto ${
                  disableMarkComplete && 'text-white bg-gray-600 cursor-not-allowed'
                }`}
                type="success"
                disabled={disableMarkComplete}
                onClick={() => onCliCkComplete()}
              >
                Mark complete
              </Button>
              {headerProps.category === 'Post-consult' && userInfo.userType?.name !== 'Medical Assistant' && (
                <div className="header-checkbox-report  mt-5">
                  <div className={classNames({ 'pointer-events-none cursor-not-allowed': complexVisitType })}>
                    <Checkbox
                      labelText="Complex visit"
                      name="complex"
                      labelClasses="mr-8 form-checkbox text-green-500 "
                      value={complexVisitType}
                      onChecked={onCheckVisitType}
                    />
                  </div>
                  <div className={classNames({ 'pointer-events-none cursor-not-allowed': simpleVistType })}>
                    <Checkbox
                      labelText="Simple visit"
                      name="simple"
                      value={simpleVistType}
                      onChecked={onCheckVisitType}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      <div className="flex flex-col md:flex-row items-center">
        {!isCategoriesListHasCategory(notDisplayDueDate, headerProps?.category || '') && (
          <div className="mr-16">
            <span className="text-sm uppercase mr-2">Due</span>
            <span className="text-xl text-textGreyedOut">
              {headerProps.dueDate ? dateTimeFormatter(headerProps.dueDate) : 'No due date'}
            </span>
          </div>
        )}
        <div className="mr-16 flex items-center">
          <span className="text-sm uppercase mr-2">category</span>
          <span className={`w-2 h-2 rounded-full inline-block ${CheckCategoryColor(headerProps.category)} mr-2`}></span>
          <span className="subheading">{headerProps.category}</span>
        </div>
        <div className="flex items-center relative">
          <span className="text-sm uppercase">Assigned to</span>
          <div className="flex items-center relative">
            <CircleAvatar
              src={(headerProps.assignedTo && headerProps.assignedTo?.profileImage) || DoctorAvatar}
              ownClasses="w-7 h-7 mx-2"
            />
            <span className="text-gray-900 text-xl font-semibold ml-2">
              {headerProps.assignedTo && headerProps.assignedTo._id === userInfo._id
                ? 'Me'
                : headerProps.assignedTo && headerProps.assignedTo.displayName}
            </span>
            {headerProps.status !== 'Completed' && (
              <SwitchHorizontalIcon
                width={12}
                className="text-textGreyedOut mx-2 cursor-pointer"
                onClick={() =>
                  ((headerProps.assignedTo && headerProps.assignedTo._id === userInfo._id) ||
                    userInfo?.userType?.name === 'Admin') &&
                  setPopup(!popup)
                }
              />
            )}
            {popup && (
              <Transfer
                popup={popup}
                setPopup={setPopup}
                taskId={headerProps.taskId}
                category={headerProps.category}
                assignmentUser={headerProps?.assignedTo?.userRoleInfo?.userType?.shortCode}
                appointmentStatus={appointmentInformation?.appointmentStatus}
                onCancel={headerProps?.onCancel}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Heading;
