import types from './types';

export const setProfileInformation = (data: any) => ({
  type: types.SET_PROFILE_INFORMATION,
  payload: data,
});

const actions = {
  setProfileInformation,
};

export default actions;
