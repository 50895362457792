import Button from 'components/common/Button';
import DateTimePicker from 'components/common/DateTimePicker';
import Dropdown from 'components/common/Dropdown';
import Input from 'components/common/Input';
import { useEffect } from 'react';
import { useState } from 'react';
import moment from 'moment';

export interface FormProps {
  formHandler: (value?: any) => void;
  filterFormValues?: any;
  type?: string;
}

const Form: React.FC<FormProps> = ({ formHandler, filterFormValues, type }) => {
  const [request, setRequest] = useState('');
  const [uniqueId, setUniqueId] = useState('');
  const [category, setCategory] = useState('');
  const [routeTo, setRouteTo] = useState('');
  const [membership, setMembership] = useState<{ label: string; value: string } | null>();
  const [creationDate, setCreationDate] = useState('');
  const [status, setStatus] = useState<{ label: string; value: string } | null>();
  const [filterCount, setFilterCount] = useState(0);

  const membershipOptions = [
    { value: 'permium', label: 'Premium' },
    { value: 'elite', label: 'Elite' },
  ];

  const statuses = [
    { value: 'active', label: 'Active' },
    { value: 'inactive', label: 'Inactive' },
  ];

  useEffect(() => {
    setRequest(filterFormValues.requestType);
    setCategory(filterFormValues.category);
    setStatus(statuses.find((item) => item.value === filterFormValues.status));
    setUniqueId(filterFormValues.uniqueId);
    setRouteTo(filterFormValues.routeTo);
    setMembership(membershipOptions.find((item) => item.value === filterFormValues.membership));
    setCreationDate(filterFormValues.createdAt);
  }, [filterFormValues]);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const filterData = {
      request: type === 'physician' ? request : null,
      requestType: type !== 'physician' ? request : null,
      category: category,
      status: status ? status.value : null,
      uniqueId: uniqueId,
      routeTo: routeTo,
      membership: membership ? membership.value : null,
      createdAt: creationDate && moment(creationDate).format('YYYY-MM-DD'),
    };
    formHandler(filterData);
  };

  const handleClear = (e: any) => {
    e.preventDefault();
    formHandler({});
  };

  const checkFilterApplied = () => {
    let count = 0;
    if (request) {
      count += 1;
    }
    if (category) {
      count += 1;
    }
    if (uniqueId) {
      count += 1;
    }
    if (routeTo) {
      count += 1;
    }
    if (creationDate) {
      count += 1;
    }
    if (status) {
      count += 1;
    }
    if (membership) {
      count += 1;
    }
    setFilterCount(count);
  };

  useEffect(() => {
    checkFilterApplied();
  }, [request, category, membership, status, creationDate, uniqueId, routeTo]);
  return (
    <form>
      <div className="flex flex-col lg:flex-row justify-center items-center">
        <div className="mr-20 mb-4">
          <Input
            labelText="Request"
            name="request"
            placeholder="Enter Request"
            inputClasses="w-52 mb-4 pr-8"
            labelClasses="mr-10 text-xl  mb-4"
            value={request}
            onChange={(e) => setRequest(e.target.value)}
            onClickIconClear={() => setRequest('')}
            closeIcon={true}
          />
          {type === 'physician' && (
            <Input
              labelText="Category"
              name="category"
              placeholder="Enter category"
              inputClasses="w-52 mb-4 pr-8"
              labelClasses="mr-10 text-xl  mb-4"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              onClickIconClear={() => setCategory('')}
              closeIcon={true}
            />
          )}
          <Input
            labelText="Route To"
            name="routeTo"
            placeholder="Enter Route to"
            inputClasses="w-52 mb-4 pr-8"
            labelClasses="mr-10 text-xl  mb-4"
            value={routeTo}
            onChange={(e) => setRouteTo(e.target.value)}
            onClickIconClear={() => setRouteTo('')}
            closeIcon={true}
          />
        </div>
        <div>
          <Input
            labelText="Unique Id"
            name="created"
            placeholder="Enter Id"
            inputClasses="w-52 mb-4 pr-8"
            labelClasses="mr-10 text-xl  mb-4"
            value={uniqueId}
            onChange={(e) => setUniqueId(e.target.value)}
            onClickIconClear={() => setUniqueId('')}
            closeIcon={true}
          />
          <div className="mb-4">
            <Dropdown
              labelText="Status"
              placeholder="Status"
              labelClasses="mr-10 text-xl  mb-4"
              width="52"
              value={status}
              options={statuses}
              onClickIconClear={() => setStatus(null)}
              closeIcon={true}
              onChange={(e: any) => setStatus(e)}
            />
          </div>
        </div>
      </div>
      <div className="flex items-center justify-end">
        <p>{filterCount} filters applied</p>
        <Button
          ownClasses="px-7 mx-2.5 font-semibold text-dashboardPrimaryGrey"
          type="depressed"
          onClick={(e) => handleClear(e)}
        >
          Clear all
        </Button>
        <Button ownClasses="px-7 font-semibold rounded-full" type="success" onClick={(e) => handleSubmit(e)}>
          Update
        </Button>
      </div>
    </form>
  );
};

export default Form;
