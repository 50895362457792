import { ReactComponent as CameraIcon } from 'assets/icons/camera.svg';

export interface StandartHeaderProps {
  isEditMode?: boolean;
  automationId?: string;
  automationType?: string;
}

const StandartHeader: React.FC<StandartHeaderProps> = ({ isEditMode, automationId, automationType }) => {
  return (
    <div className="w-full">
      <span className="flex justify-between items-center">
        <h2 className="text-2xl text-green font-bold">
          {automationType == 'internal' ? 'Internal Automation' : 'Patient Automation'}
        </h2>
        {isEditMode && <p className="text-text text-sm">{`ID: ${automationId}`}</p>}
      </span>
    </div>
  );
};

export default StandartHeader;
