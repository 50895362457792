import { Column, useTable } from 'react-table';

import SearchForm from 'components/common/SearchForm';
import Dropdown from 'components/common/Dropdown';

import { ReactComponent as SearchSvg } from 'assets/icons/search.svg';
import { HistoryProps } from './history.types';
import classNames from 'classnames';

const mockData = [
  {
    date: 'Aug 5 • 6:20p',
    event: 'Appointment created',
    details: 'Aug 10 • 4:00p – Everyday care: I’m having an issue with…',
  },
];

const eventOptions = [
  { value: 'appointmentsTask', label: 'Appointments task' },
  { value: 'preConsultTask', label: 'Pre-consult task' },
  { value: 'onboardingTask', label: 'Onboarding task' },
  { value: 'messagesTaskOrResponse', label: 'Messages task or response' },
  { value: 'frontDeskTask', label: 'Front desk task' },
  { value: 'requestsTask', label: 'Requests task' },
  { value: 'results', label: 'Results' },
  { value: 'postConsultTask', label: 'Post-consult task' },
  { value: 'notifications', label: 'Notifications' },
  { value: 'app/patientActivity', label: 'App/Patient Activity' },
  { value: 'app/patientActivity', label: 'App/Patient Activity' },
  { value: 'billing/account', label: 'Billing/Account' },
  { value: 'systemEvents', label: 'System events' },
  { value: 'automations', label: 'Automations' },
  { value: 'other', label: 'Other' },
];

const columns: Column[] = [
  { Header: 'Date', accessor: 'date' },
  // eslint-disable-next-line react/display-name
  { Header: 'Event', accessor: 'event', Cell: ({ value }: never) => <span className="">{value}</span> },
  { Header: 'Details', accessor: 'details' },
];

const thClasses = 'text-left font-bold pt-4 pb-3 pl-4';

const History: React.FC<HistoryProps> = ({ data }) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    columns,
    data: mockData,
  });

  const hiddenContent = classNames(data ? '' : 'hidden');
  const isNoData = classNames(data ? 'hidden' : 'section-subheading mr-4 text-center');

  return (
    <>
      <h1 className={isNoData}>Patient history coming soon...</h1>
      <div className={`mb-5 flex items-center justify-between ${hiddenContent}`}>
        <div className="flex items-center">
          <span className="font-bold text-lg mr-3">Filter by</span>
          <Dropdown placeholderText="Event Type" className="w-44" options={eventOptions} />
        </div>
        <div className="flex">
          <SearchForm />
          <div className="flex ml-2 flex-row items-center px-5 bg-lightGreyBackground rounded-full">
            <SearchSvg className="w-4" />
            <input
              className="w-64 px-2 py-1 text-lg bg-lightGreyBackground focus:ring-transparent border-none"
              type="search"
              name="search"
              placeholder="Search"
            />
          </div>
        </div>
      </div>
      <table
        {...getTableProps()}
        className={`table-auto w-full border-collapse overflow-hidden rounded-t-lg shadow-sm rounded-lg ${hiddenContent}`}
      >
        <thead className="bg-lightGreyBackground">
          {headerGroups.map((headerGroup, idx) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={idx}>
              {headerGroup.headers.map((column, idx) => (
                <th {...column.getHeaderProps()} key={idx} className={thClasses}>
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps} className="divide-y divide-gray-100">
          {rows.map((row, idx) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} key={idx}>
                {row.cells.map((cell, idx) => (
                  <td {...cell.getCellProps()} key={idx} className="p-4 font-medium border-r border-gray-100">
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

export default History;
