import { useEffect, useState } from 'react';
import Button from '../common/Button';
import Table from '../common/Table';
import SearchForm from './SearchForm';
import { ReactComponent as PlusSvg } from '../../assets/icons/lifeMD-plus.svg';
import { ReactComponent as EditSvg } from '../../assets/icons/edit.svg';
import { RootStateOrAny, useSelector } from 'react-redux';
import { hasAccessToModule } from 'auth/authorization';
import { toast } from 'react-toastify';
import LoadingOverlayWrapper from 'react-loading-overlay-ts';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';
import { useHistory } from 'react-router';
import { CheckCategoryColor } from 'utils/CheckCategoryColor';
import { dateTimeFormatter } from 'utils/dateTimeFormatter';

export interface AdminManageRolesListProps {
  fetchUserRolesList: (value?: any) => void;
  loading: boolean;
}

const StandardTable: React.FC<AdminManageRolesListProps> = ({ fetchUserRolesList, loading }) => {
  const history = useHistory();
  const userInfo = useSelector((state: RootStateOrAny) => state.userProfile.userDetails);
  const userRolesList = useSelector((state: RootStateOrAny) => state.userRoles.userRolesList);
  const userRolesCount = useSelector((state: RootStateOrAny) => state.userRoles.userRolesCount);

  const [inlineFilterActive, setInlineFilterActive] = useState('All');
  const [selectedPage, setSelectedPage] = useState(1);
  const [searchFilterParams, setSearchFilterParams] = useState({});
  const [pageItems, setPageItems] = useState([]);
  const [sortKey, setSortKey] = useState<string | undefined>('');
  const [sortOrder, setSortOrder] = useState<string | undefined>();

  const [hasEditAccess, setHasEditAccess] = useState(false);
  const [hasDeleteAccess, setHasDeleteAccess] = useState(false);

  const totalPage = userRolesCount % 10 === 0 ? Math.floor(userRolesCount / 10) : Math.floor(userRolesCount / 10) + 1;

  const headerItems = [
    {
      title: 'Role',
      sortKey: 'name',
    },
    {
      title: 'Role template',
      sortKey: 'userType.name',
    },
    {
      title: 'Date created',
      sortKey: 'createdAt',
    },
    {
      title: 'Color',
      sortKey: 'color',
    },
    {
      title: 'Unique id',
      sortKey: 'uniqueId',
    },
    {
      title: 'Status',
      sortKey: 'status',
    },
    {
      title: 'Actions',
      align: 'center',
    },
  ];

  const onSortClick = (title?: string, key?: string, sortOrder?: string) => {
    setSelectedPage(1);
    setSortKey(key);
    setSortOrder(sortOrder);
    const filterParams: any = {
      ...searchFilterParams,
      ...(key && { sortField: key }),
      ...(sortOrder && { sortOrder }),
      ...(inlineFilterActive && inlineFilterActive !== 'All' && { status: inlineFilterActive }),
    };
    filterParams['limit'] = 10;
    filterParams['pageNo'] = 0;
    fetchUserRolesList(filterParams);
  };

  const inlineStatusHandler = (inlineCategoryValue: string) => {
    setInlineFilterActive(inlineCategoryValue);
    const filterParams: any = {
      ...searchFilterParams,
      ...(sortKey && { sortField: sortKey }),
      ...(sortOrder && { sortOrder }),
    };
    filterParams['limit'] = 10;
    filterParams['pageNo'] = 0;
    filterParams['status'] = inlineCategoryValue !== 'All' ? inlineCategoryValue : null;

    setSelectedPage(1);
    fetchUserRolesList(filterParams);
  };

  const filterSearchTextHandler = (searchTextFilterValue?: any) => {
    setSearchFilterParams(searchTextFilterValue);
    const filterParams: any = {
      ...searchTextFilterValue,
      ...(sortKey && { sortField: sortKey }),
      ...(sortOrder && { sortOrder }),
      ...(inlineFilterActive && inlineFilterActive !== 'All' && { status: inlineFilterActive }),
    };
    filterParams['pageNo'] = 0;
    filterParams['limit'] = 10;
    setSelectedPage(1);
    console.log('filterParams', filterParams);
    fetchUserRolesList(filterParams);
  };

  const onPageClick = (e: any, value: number) => {
    e.preventDefault();
    setSelectedPage(value);
    const filterParams: any = {
      ...searchFilterParams,
      ...(sortKey && { sortField: sortKey }),
      ...(sortOrder && { sortOrder }),
      ...(inlineFilterActive && inlineFilterActive !== 'All' && { status: inlineFilterActive }),
    };
    filterParams['limit'] = 10;
    filterParams['pageNo'] = value - 1;
    fetchUserRolesList(filterParams);
  };

  const onNextClick = (e: any) => {
    e.preventDefault();
    if (selectedPage !== totalPage) {
      setSelectedPage(selectedPage + 1);
      const filterParams: any = {
        ...searchFilterParams,
        ...(sortKey && { sortField: sortKey }),
        ...(sortOrder && { sortOrder }),
        ...(inlineFilterActive && inlineFilterActive !== 'All' && { status: inlineFilterActive }),
      };
      filterParams['limit'] = 10;
      filterParams['pageNo'] = selectedPage;
      fetchUserRolesList(filterParams);
    }
  };

  const onPreviousClick = (e: any) => {
    e.preventDefault();
    if (selectedPage !== 1) {
      setSelectedPage(selectedPage - 1);
      const filterParams: any = {
        ...searchFilterParams,
        ...(sortKey && { sortField: sortKey }),
        ...(sortOrder && { sortOrder }),
        ...(inlineFilterActive && inlineFilterActive !== 'All' && { status: inlineFilterActive }),
      };
      filterParams['limit'] = 10;
      filterParams['pageNo'] = selectedPage - 2;
      fetchUserRolesList(filterParams);
    }
  };

  const handleNew = () => {
    history.push(`/dashboard/edit-manage-role/new`);
  };

  const handleEdit = (id: any) => {
    history.push(`/dashboard/edit-manage-role/${id}`);
  };

  // useEffect(() => {
  //   setHasEditAccess(hasAccessToModule(userInfo.userRoleInfo.editingPermissions, ['TEMPLATE_MESSAGE_EDIT']));
  //   setHasDeleteAccess(hasAccessToModule(userInfo.userRoleInfo.editingPermissions, ['TEMPLATE_MESSAGE_DELETE']));
  // }, []);

  useEffect(() => {
    let leftSide = selectedPage - 2;
    if (leftSide <= 0) leftSide = 1;
    let rightSide = selectedPage + 2;
    if (rightSide > totalPage) rightSide = totalPage;
    const pages: any = [];
    for (let number = leftSide; number <= rightSide; number++) {
      pages.push(number);
    }
    setPageItems(pages);
  }, [selectedPage, userRolesCount]);

  // useEffect(() => {
  //   console.log('userRolesList', userRolesList);
  // }, [userRolesList]);
  // useEffect(() => {
  //   console.log('userRolesCount', userRolesCount);
  // }, [userRolesCount]);

  return (
    <div className="flex flex-col flex-auto p-11 pt-4 bg-background">
      <div className="flex flex-row items-center my-5">
        <h2 className="text-black text-2xl font-bold mr-8">Manage Roles</h2>
        <Button
          type="primary"
          ownClasses="px-4 mx-0.5 bg-white text-sm text-darkBlue rounded-full shadow-lg font-bold"
          onClick={handleNew}
        >
          <PlusSvg className="w-3.5 h-3.5 mr-1" />
          New
        </Button>
      </div>
      <div className="flex flex-row flex-wrap gap-y-3 items-center justify-between my-5">
        <div className="flex flex-row flex-wrap items-center">
          <Button
            type={`${inlineFilterActive == 'All' ? 'success' : 'secondary'}`}
            ownClasses="mr-3 pl-4 pr-5 py-1 text-lg rounded-full font-normal"
            onClick={() => inlineStatusHandler('All')}
          >
            <i className="w-2.5 h-2.5 mr-2 bg-white rounded-full" />
            All
          </Button>
          <Button
            type={`${inlineFilterActive == 'active' ? 'success' : 'secondary'}`}
            ownClasses="mr-3 pl-4 pr-5 py-1 text-lg rounded-full font-normal"
            onClick={() => inlineStatusHandler('active')}
          >
            <i className={`w-2.5 h-2.5 mr-2 ${CheckCategoryColor('active')} rounded-full`} />
            Active
          </Button>
          <Button
            type={`${inlineFilterActive == 'inactive' ? 'success' : 'secondary'}`}
            ownClasses="mr-3 pl-4 pr-5 py-1 text-lg rounded-full font-normal"
            onClick={() => inlineStatusHandler('inactive')}
          >
            <i className={`w-2.5 h-2.5 mr-2 ${CheckCategoryColor('inactive')} rounded-full`} />
            Inactive
          </Button>
        </div>
        <SearchForm
          filterSearchTextHandler={filterSearchTextHandler}
          sortField={sortKey}
          sortOrder={sortOrder}
          inlineFilterActive={inlineFilterActive}
        />
      </div>
      <LoadingOverlayWrapper active={loading} spinner>
        <Table headerItems={headerItems} onSortClick={onSortClick}>
          {userRolesList?.map((userRole: any) => (
            <tr className="h-14 border-t border-lightGreyBackground" key={userRole?._id}>
              <td className="px-6 pb-4 pt-5 border-r border-lightGreyBackground">
                <p className="font-bold">{userRole?.name}</p>
              </td>
              <td className="px-6 pb-4 pt-5 border-r border-lightGreyBackground">
                <p className="font-bold">{userRole?.userType?.name}</p>
              </td>
              <td className="px-6 pb-4 pt-5 border-r border-lightGreyBackground">
                <p>{dateTimeFormatter(userRole?.createdAt)} </p>
              </td>
              <td className="px-6 pb-4 pt-5 border-r border-lightGreyBackground">
                <p>{userRole?.color}</p>
              </td>
              <td className="px-6 pb-4 pt-5 border-r border-lightGreyBackground">
                <p>{userRole?._id}</p>
              </td>
              <td className="px-6 pb-4 pt-5 border-r border-lightGreyBackground">
                <p className="text-midBlue capitalize">{userRole?.status}</p>
              </td>
              <td className="px-6 pb-4 pt-5 text-center">
                <button onClick={() => handleEdit(userRole?._id)}>
                  <EditSvg className="w-4" />
                </button>
              </td>
            </tr>
          ))}
        </Table>
      </LoadingOverlayWrapper>
      <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
        <div className="flex-1 flex justify-between sm:hidden">
          <a
            href="#"
            className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
          >
            Previous
          </a>
          <a
            href="#"
            className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
          >
            Next
          </a>
        </div>
        <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
          <div>
            {userRolesCount > 0 ? (
              <p className="text-sm text-gray-700">
                Showing{' '}
                <span className="font-medium">{selectedPage === 1 ? selectedPage : (selectedPage - 1) * 10 + 1}</span>{' '}
                to{' '}
                <span className="font-medium">
                  {selectedPage * 10 > userRolesCount ? userRolesCount : selectedPage * 10}
                </span>{' '}
                of <span className="font-medium">{userRolesCount}</span> user roles
              </p>
            ) : (
              <p className="text-sm text-gray-700">
                Showing <span className="font-medium">{userRolesCount}</span> user roles
              </p>
            )}
          </div>
          <div>
            <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
              <a
                onClick={(e) => onPreviousClick(e)}
                href="#"
                className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
              >
                <span className="sr-only">Previous</span>
                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
              </a>
              {pageItems.map((item: any) => (
                <a
                  href="#"
                  key={item}
                  aria-current="page"
                  onClick={(e) => onPageClick(e, item)}
                  className={`z-10 ${
                    selectedPage === item ? 'bg-green text-black' : 'border-gray-300 text-gray-500 bg-white'
                  }  relative inline-flex items-center px-4 py-2 border text-sm font-medium`}
                >
                  {item}
                </a>
              ))}
              <a
                href="#"
                onClick={(e) => onNextClick(e)}
                className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
              >
                <span className="sr-only">Next</span>
                <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
              </a>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StandardTable;
