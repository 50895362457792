import classNames from 'classnames';
import { ReactComponent as ArrowUpSvg } from '../../../assets/icons/arrow-up.svg';
import { useEffect, useState } from 'react';
export interface TableProps {
  headerItems: Array<{ title: string; align?: string; sortKey?: string }>;
  tableClassName?: string;
  onSortClick?: (title?: any, key?: any, sortOrder?: any) => void;
}

const Table: React.FC<TableProps> = ({ headerItems, children, tableClassName, onSortClick }) => {
  const tableWrapperStyle = classNames('rounded-2xl', 'relative', tableClassName);
  const [tableItems, setTableItems] = useState<any>();
  const sortBy = 'status';

  const handleSort = (title: string, sortKey: any, sortOrder: string) => {
    const newItems = tableItems.map((item: any) => {
      if (item.title === title) {
        const updatedItem = {
          ...item,
          toggleSort: !item.toggleSort,
        };
        return updatedItem;
      }
      return { ...item, toggleSort: false };
    });

    setTableItems(newItems);
    onSortClick?.(title, sortKey, sortOrder);
  };

  useEffect(() => {
    const items = headerItems.map((item) => ({
      ...item,
      toggleSort: false,
    }));

    setTableItems(items);
  }, []);

  return (
    <div className={tableWrapperStyle}>
      <table className="w-full table-auto">
        <thead className="text-textDarkGrey text-base">
          <tr>
            {tableItems?.map((item: any) => (
              <th key={item.title} className="px-3 pb-3.5 pt-5 bg-lightGreyBackground sticky top-0 z-10">
                <div className={classNames('flex flex-row items-center text-textDarkGrey', `justify-${item.align}`)}>
                  <p>{item.title}</p>

                  {item.sortKey &&
                    (item.toggleSort ? (
                      <ArrowUpSvg
                        className={classNames('stroke-current h-2.5 ml-2 transform rotate-180', {
                          'cursor-pointer': item.sortKey,
                        })}
                        onClick={() => handleSort(item.title, item.sortKey, 'ASC')}
                      />
                    ) : (
                      <ArrowUpSvg
                        className={classNames('stroke-current h-2.5', 'ml-2', {
                          'cursor-pointer': item.sortKey,
                        })}
                        onClick={() => handleSort(item.title, item.sortKey, 'DESC')}
                      />
                    ))}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="bg-white text-text">{children}</tbody>
      </table>
    </div>
  );
};

export default Table;
