import { AxiosInstanceWebsite } from './AxiosInstance';
import config from '../config';

const { api } = config;

const appointmentAppendPath = 'appointment-types';

export default class AdminAppointmentAPI {
  static fetchAppointmentTypes(data: any) {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/${appointmentAppendPath}`,
      method: 'GET',
      params: data,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }
  static postAppointmentTypes(data: any) {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/${appointmentAppendPath}`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    });
  }

  static deleteAppointmentType(id: string) {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/${appointmentAppendPath}/${id}`,
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }
  // /appointment-types
  static fetchSingleAppointmentType(appointmentTypeId: string) {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/${appointmentAppendPath}/${appointmentTypeId}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  static editAppointmentType(payload: any, id: string) {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/${appointmentAppendPath}/${id}`,
      method: 'PUT',
      data: payload,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  static postAppointmentTypeLenghths(data: any) {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/appointment-lengths`,
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    });
  }

  static getAppointmentTypeLenghths(data: any) {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/appointment-lengths`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    });
  }
}
