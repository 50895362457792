import actions from './actions';
import reducer from './reducer';

const lookUp = {
  actions,
  reducer,
  name: 'lookUp',
};

export default lookUp;
