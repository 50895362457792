import { ReactComponent as CameraIcon } from 'assets/icons/camera.svg';

const StandartHeader = () => {
  return (
    <div className="w-full">
      <span className="flex justify-between items-center">
        <h2 className="text-2xl text-green font-bold">New staff member</h2>
        <p className="text-text text-sm">ID: 91323231</p>
      </span>
      <div className="flex items-center mt-7">
        <span className="text-text mr-6">Label (upload)</span>
        <div className="flex justify-center items-center border border-textGreyedOut-100 rounded-full w-16 h-16">
          <CameraIcon width="1.5rem" height="1.5rem" />
        </div>
        <span className="underline ml-6 text-sm text-dashboardPrimaryGrey">Upload picture</span>
      </div>
    </div>
  );
};

export default StandartHeader;
