import TaskApi from 'api/TaskAPI';
import classNames from 'classnames';
import StyledCheckbox from 'components/common/StyledCheckbox';
import Item from 'pages/VideoCall/videoCall/SettingsPopup/Item';
import { ChangeEventHandler, useState } from 'react';
import { IntakeItemsProps } from './IntakeItems.types';

const IntakeItems: React.FC<IntakeItemsProps> = ({ intakeItemsList, taskId, onChangeIntakeItems, taskStatus }) => {
  const [intakeItems, setIntakeItems] = useState(intakeItemsList);
  const lengthItems = intakeItems.length - 1;
  const disabled = taskStatus.toLowerCase() === 'completed';

  const handleCheckedChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const intakeId = event.target.value;

    setIntakeItems((prevIntakeStatus) => {
      const refreshIntakeList = prevIntakeStatus.map((intakeItem) =>
        intakeId === intakeItem._id ? { ...intakeItem, active: !intakeItem.active } : intakeItem,
      );

      const countOfChecked =
        refreshIntakeList.filter((intakeItem) => intakeItem.active && intakeItem._id !== intakeItems[lengthItems]._id)
          .length - 1;
      const isAllChecked = countOfChecked === lengthItems - 1;

      refreshIntakeList[lengthItems] = { ...refreshIntakeList[lengthItems], active: isAllChecked };

      TaskApi.updateTaskIntakeItems({ id: taskId }, { intakeItems: refreshIntakeList });

      onChangeIntakeItems(refreshIntakeList);
      return refreshIntakeList;
    });
  };

  return (
    <div className="flex">
      <h1 className="md:w-1/5 mb-4 text-lg font-bold">Intake items: </h1>
      <div className="flex flex-col gap-4 mt-3">
        {intakeItems
          .sort((currItem, prevItem) => currItem.sortNum - prevItem.sortNum)
          .map((IntakeItem, indexIntake) => {
            const labelClasses = classNames(
              IntakeItem.active && 'text-textGreyedOut line-through',
              indexIntake === lengthItems && 'font-bold text-lg',
            );
            return (
              <StyledCheckbox
                disabled={disabled || indexIntake === lengthItems}
                key={IntakeItem._id}
                checked={IntakeItem.active}
                onChange={handleCheckedChange}
                value={IntakeItem._id}
                label={IntakeItem.title}
                labelClasses={labelClasses}
              />
            );
          })}
      </div>
    </div>
  );
};

export default IntakeItems;
