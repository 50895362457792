export const ViewChart = (patientId: string, inNewTab?: boolean) => {
  const path = `/dashboard/patient-details/${patientId}`;

  if (patientId) {
    const url = window.location.protocol + '//' + window.location.host + path;

    if (inNewTab) {
      window.open(url, '_blank');
    } else {
      window.location.href = path;
    }
  }
};
