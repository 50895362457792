import { PatientData } from './interfaces';
import types from './types';

export const setPatientLists = (data: PatientData[]) => ({
  type: types.SET_PATIENT_LIST,
  payload: data,
});

export const setTotalPatient = (data: number) => ({
  type: types.SET_TOTAL_PATIENT,
  payload: data,
});

const actions = {
  setPatientLists,
  setTotalPatient,
};

export default actions;
