import { AxiosInstanceWebsite } from './AxiosInstance';
import config from '../config';

const { api } = config;

export default class AdminAutomationAPI {
  static fetchAutomations(data: any) {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/triggers`,
      method: 'GET',
      params: data,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  static fetchAutomationById(id: string) {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/triggers/${id}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  static postAutomation(payload: any) {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/triggers`,
      method: 'POST',
      data: payload,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }
  static editAutomationById(payload: any, id: string) {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/triggers/${id}`,
      method: 'PUT',
      data: payload,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  static deleteAutomationById(id: string) {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/triggers/${id}`,
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  static fetchTaskCategories() {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/lookup/task-categories`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }
  static fetchUserTypeAccess(data: any) {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/lookup/user-type-access`,
      method: 'GET',
      params: data,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }
}
