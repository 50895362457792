import { AxiosInstanceWebsite } from './AxiosInstance';
import config from '../config';

const { api } = config;

const userRoleAppendPath = 'user-roles';

export default class UserRolesApi {
  static fetchUser(data: any) {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/${userRoleAppendPath}`,
      method: 'GET',
      params: data,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }
}
