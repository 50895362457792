import classNames from 'classnames';
import { forwardRef } from 'react';
import { CheckBoxProps } from './styledCheckbox';

// eslint-disable-next-line react/display-name
const CheckBox: React.FC<CheckBoxProps> = forwardRef<HTMLInputElement, CheckBoxProps>(
  ({ label, value, checked, onChange, disabled, labelClasses = '', ...otherProps }, ref) => {
    return (
      <div>
        <label className={classNames('flex items-start font-medium', !disabled && 'cursor-pointer')}>
          <input
            ref={ref}
            {...otherProps}
            disabled={disabled}
            value={value}
            checked={checked}
            type="checkbox"
            className={classNames(
              'form-checkbox text-green w-5 h-5 border-2 border-inputBorder rounded mr-3',
              !disabled && 'cursor-pointer',
            )}
            onChange={onChange}
          />
          <span className={labelClasses}>{label}</span>
        </label>
      </div>
    );
  },
);
export default CheckBox;
