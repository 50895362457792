import actions from './actions';
import reducer from './reducer';

const patients = {
  actions,
  reducer,
  name: 'patients',
};

export default patients;
