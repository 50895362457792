import classNames from 'classnames';
import { ScoreProps } from './score.types';

const Score: React.FC<ScoreProps> = ({ score }) => {
  return (
    <div
      className={classNames(
        'px-2.5 rounded-full border-2 font-bold border-patientInfoScoreLow text-patientInfoScoreLow hidden',
        { 'border-patientInfoScoreMiddle text-patientInfoScoreMiddle': score > 2 },
        { 'border-patientInfoScoreHigh text-patientInfoScoreHigh': score > 6 },
      )}
    >
      {score} / 10
    </div>
  );
};

export default Score;
