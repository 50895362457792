import types from './types';
import { MessageTemplatesDataActionType, MessageTemplatesDetails } from './interfaces';

const initState: MessageTemplatesDetails = {
  patientMessageTemplatesList: null,
  patientMessageTemplatesCount: 0,
  requestMessageTemplatesList: null,
  requestMessageTemplatesCount: 0,
  internalNoteTemplatesList: null,
  internalNoteTemplatesCount: 0,
};

const reducer = (
  state: MessageTemplatesDetails = initState,
  action: MessageTemplatesDataActionType,
): MessageTemplatesDetails => {
  switch (action.type) {
    case types.SET_PATIENT_MESSAGE_TEMPLATES_LIST:
      return {
        ...state,
        patientMessageTemplatesList: action.payload,
      };

    case types.SET_PATIENT_MESSAGE_TEMPLATES_COUNT:
      return {
        ...state,
        patientMessageTemplatesCount: action.payload,
      };

    case types.SET_REQUEST_MESSAGE_TEMPLATES_LIST:
      return {
        ...state,
        requestMessageTemplatesList: action.payload,
      };

    case types.SET_REQUEST_MESSAGE_TEMPLATES_COUNT:
      return {
        ...state,
        requestMessageTemplatesCount: action.payload,
      };

    case types.SET_INTERNAL_NOTE_TEMPLATES_LIST:
      return {
        ...state,
        internalNoteTemplatesList: action.payload,
      };

    case types.SET_INTERNAL_NOTE_TEMPLATES_COUNT:
      return {
        ...state,
        internalNoteTemplatesCount: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
