import { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useSelector, RootStateOrAny } from 'react-redux';
import Login from 'Login';
import ContentComponent from './content';
import ChatLayout from 'components/Chat/ChatLayout';
import VideoCall from 'pages/VideoCall';
import { CallProvider } from 'contexts/CallContext';
import ForgotPassword from './pages/ForgotPassword';
import CreateNewPassword from './pages/CreateNewPassword';
import LogRocket from 'logrocket';

function App() {
  const email = useSelector((state: RootStateOrAny) => state.userProfile.userDetails.email);

  useEffect(() => {
    LogRocket.identify(email);
  }, []);

  return (
    <div className="App">
      <Switch>
        <Route path={`/chat`}>
          <ChatLayout />
        </Route>
        <Route path={'/videoCall'}>
          <CallProvider>
            <VideoCall />
          </CallProvider>
        </Route>

        <Route path="/forgot-password">
          <ForgotPassword />
        </Route>
        <Route path="/create-new-password">
          <CreateNewPassword />
        </Route>
        <Route path="/dashboard">
          <CallProvider>
            <ContentComponent />
          </CallProvider>
        </Route>
        <Route path="/">
          <Login />
        </Route>
      </Switch>
    </div>
  );
}

export default App;
