import types from './types';
import { ProfileDataActionType, UserDetails } from './interfaces';

const initState: UserDetails = {
  userDetails: {},
};

const reducer = (state: UserDetails = initState, action: ProfileDataActionType): UserDetails => {
  switch (action.type) {
    case types.SET_PROFILE_INFORMATION:
      return {
        ...state,
        userDetails: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
