import types from './types';

export const setNotificationInformation = (data: any) => ({
  type: types.SET_NOTIFICATION_COUNT,
  payload: data,
});

const actions = {
  setNotificationInformation,
};

export default actions;
