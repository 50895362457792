export const CheckStatusColor = (status: string) => {
  if (status === 'URGENT') {
    return 'text-red uppercase';
  } else if (status === 'New') {
    return 'text-green';
  } else if (status === 'Active') {
    return 'text-yellow';
  } else if (status === 'Sent') {
    return 'text-darkBlue';
  }
};
