import { createContext, useEffect, useState } from 'react';
import { useMediaDevices } from 'react-use';
import { DevicesProps, VideoCallContextProps } from './videoCallContext.types';

const defaultValue: VideoCallContextProps = {
  audioId: '',
  videoId: '',
  speakerId: '',
  devices: { video: [], audio: [], speaker: [] },
  setAudioId: () => {
    // do nothing.
  },
  setVideoId: () => {
    // do nothing.
  },
  setSpeakerId: () => {
    // do nothing.
  },
};

export const VideoCallContext = createContext<VideoCallContextProps>(defaultValue);

export const VideoCallProvider: React.FC = ({ children }) => {
  const mediaDevices = useMediaDevices() as { devices: MediaDeviceInfo[] };
  const [audioId, setAudioId] = useState(defaultValue.audioId);
  const [videoId, setVideoId] = useState(defaultValue.videoId);
  const [speakerId, setSpeakerId] = useState(defaultValue.speakerId);
  const [devices, setDevices] = useState<DevicesProps>(defaultValue.devices);

  const getFilteredDevices = (devises: MediaDeviceInfo[], type: string) => {
    return devises.filter(({ kind }) => kind === type).filter((kind) => kind.label != '');
  };

  const setDevicesIds = (filteredDevices: DevicesProps) => {
    if (!videoId && filteredDevices.video.length) {
      setVideoId(filteredDevices.video[0].deviceId);
    }
    if (!videoId && filteredDevices.audio.length) {
      setAudioId(filteredDevices.audio[0].deviceId);
    }
    if (!speakerId && filteredDevices.speaker.length) {
      setSpeakerId(filteredDevices.speaker[0].deviceId);
    }
  };

  useEffect(() => {
    if (mediaDevices.devices) {
      console.log(mediaDevices);
      setDevices({
        video: getFilteredDevices(mediaDevices.devices, 'videoinput'),
        audio: getFilteredDevices(mediaDevices.devices, 'audioinput'),
        speaker: getFilteredDevices(mediaDevices.devices, 'audiooutput'),
      });
    }
  }, [mediaDevices]);

  useEffect(() => {
    setDevicesIds(devices);
  }, [devices]);

  return (
    <VideoCallContext.Provider
      value={{
        audioId,
        videoId,
        speakerId,
        devices,
        setAudioId,
        setVideoId,
        setSpeakerId,
      }}
    >
      {children}
    </VideoCallContext.Provider>
  );
};
