import { StarIcon } from '@heroicons/react/solid';
import CircleAvatar from 'components/common/CircleAvatar';
import avatar from 'assets/images/avatar.png';

const AdvancedHeader = () => {
  return (
    <div className="flex mb-10">
      <CircleAvatar ownClasses="w-16 h-16" src={avatar} />
      <div className="ml-5">
        <p className="text-3xl font-semibold">Dr. Jannell Giles</p>
        <p className="text-xl text-textGreyedOut">Physician</p>
      </div>
      <div className="flex justify-center items-center bg-green h-7 ml-4 text-white rounded-lg p-4">
        <span className="text-white">4.8</span>
        <StarIcon className="h-4 w-4 ml-1" />
      </div>
    </div>
  );
};

export default AdvancedHeader;
