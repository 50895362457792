import { useEffect, useState } from 'react';

import classNames from 'classnames';
import dayjs from 'dayjs';
import { nanoid } from 'nanoid';
import { useHistory } from 'react-router-dom';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import Header from './Header';
import WeekSwitch from './WeekSwitch';
import {
  DaysProps,
  TimeSlotProps,
  UsersProps,
  TimeSlotsProps,
  GlobalAvailabilityCombinedProps,
  GlobalAvailabilityProps,
} from './globalCalendar.types';
import Loader from '../../pages/VideoCall/videoCall/Loader';
import AppointmentAPI from '../../api/AppointmentAPI';
import { RootStateOrAny, useSelector } from 'react-redux';

dayjs.extend(weekOfYear);
const colors = ['#003E52', '#35A2F2', '#BA00FF', '#FF7F00', '#FFA700', '#00B167', '#F2355B', '#00698B'];
const GlobalCalendar = () => {
  const width = 4.16666666667;
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [week, setWeek] = useState<number>(dayjs().week());
  const hours = Array.from({ length: 24 }, (_, i) => dayjs().hour(i).format('ha'));
  const [selectedDay, setSelectedDay] = useState<number | null>(null);
  const [users, setUsers] = useState<UsersProps[]>([]);
  const [days, setDays] = useState<DaysProps[]>([]);
  const userInfo = useSelector((state: RootStateOrAny) => state.userProfile.userDetails);
  const localTz = userInfo?.timezone || dayjs.tz.guess();

  const timeStringToMinutes = (timeString: string) => {
    const parsedTimeString = timeString.split(':');
    return +parsedTimeString[0] * 60 + +parsedTimeString[1];
  };
  const getLeft = (timeSlots: TimeSlotsProps) => timeStringToMinutes(timeSlots.startTime) * (width / 60) + '%';
  const getRight = (timeSlots: TimeSlotsProps) =>
    (24 * 60 - timeStringToMinutes(timeSlots.endTime)) * (width / 60) + '%';

  useEffect(() => {
    setLoading(true);
    AppointmentAPI.fetchGlobalAvailabilityCombined({
      startDate: dayjs().week(week).day(0).format('YYYY-MM-DD'),
      endDate: dayjs().week(week).day(6).format('YYYY-MM-DD'),
      localTz,
    })
      .then((res) => {
        const data = res.data.data as GlobalAvailabilityCombinedProps[];
        if (data) {
          setDays(
            data.map(({ date, timeSlots }) => {
              return {
                date,
                timeSlots: timeSlots.map((timeSlot) => ({
                  left: getLeft(timeSlot),
                  right: getRight(timeSlot),
                  ...timeSlot,
                })),
              };
            }),
          );
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [week]);

  useEffect(() => {
    if (selectedDay != null) {
      setLoading(true);
      AppointmentAPI.fetchGlobalAvailability({
        date: dayjs().week(week).day(selectedDay).format('YYYY-MM-DD'),
        localTz,
      })
        .then((res) => {
          const data = res.data.data as GlobalAvailabilityProps[];
          if (data) {
            let selectedColorIndex = 0;
            setUsers(
              data.map(({ userId, doctorName, timeSlots }) => {
                selectedColorIndex++;
                if (selectedColorIndex === colors.length) {
                  selectedColorIndex = 0;
                }
                return {
                  userId,
                  doctorName,
                  color: colors[selectedColorIndex],
                  timeSlots: timeSlots.map((timeSlot) => ({
                    left: getLeft(timeSlot),
                    right: getRight(timeSlot),
                    ...timeSlot,
                  })),
                };
              }),
            );
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [selectedDay, week]);

  const divideElement = () => {
    return (
      <>
        {hours.map(() => (
          <div key={nanoid()} className="flex items-center border-l" style={{ width: width + '%' }} />
        ))}
      </>
    );
  };
  const timeSlotElement = (timeSlot: TimeSlotProps, color?: string, combined?: boolean) => {
    return (
      <p
        key={nanoid()}
        className={classNames(
          'absolute h-fit py-1.5 text-left inset-y-0 border-l-4 my-auto rounded text-xs pl-2.5 whitespace-nowrap truncate',
          color ? 'bg-gray10' : 'bg-secondary20 border-secondary',
        )}
        style={{
          left: timeSlot.left,
          right: timeSlot.right,
          borderLeftColor: color,
        }}
      >
        {combined && <span className="font-semibold text-xs">Combined:&nbsp;</span>}
        {timeSlot.startTime} - {timeSlot.endTime}
      </p>
    );
  };

  return (
    <div className="p-4">
      <Loader isVisible={loading} />
      <WeekSwitch week={week} onChange={setWeek} />
      <div className="w-full">
        <Header width={width} hours={hours} />
        {days.map((day, index) => (
          <div key={nanoid()}>
            <div
              className={classNames('border-b overflow-auto')}
              style={{ maxHeight: selectedDay === index ? '300px' : 'auto' }}
            >
              <button
                onClick={() => setSelectedDay(selectedDay === index ? null : index)}
                className="flex bg-white w-full sticky top-0 z-10"
              >
                <div className="flex flex-none items-center justify-between text-sm h-10 w-56 px-4">
                  <span className={classNames(selectedDay === index ? 'text-gray70' : 'text-gray90')}>
                    {dayjs(day.date).format('dddd')}
                  </span>
                  <span className={classNames(selectedDay === index ? 'text-gray70' : 'text-gray90')}>
                    {dayjs(day.date).format('MMM DD')}
                  </span>
                </div>
                <div className="relative flex w-full h-10">
                  {divideElement()}
                  {day.timeSlots.map((timeSlot) => timeSlotElement(timeSlot, undefined, true))}
                </div>
              </button>
              {selectedDay === index &&
                users?.map((user) => (
                  <div key={nanoid()} className="flex w-full">
                    <button
                      onClick={() => history.push(`/dashboard/appointment-availablity/${user.userId}`)}
                      className="flex items-center flex-none gap-1 text-sm px-2 h-10 w-56 px-8"
                    >
                      <i className="w-2 h-2 rounded-full" style={{ backgroundColor: user.color }} />
                      {user.doctorName}
                    </button>
                    <div className="relative flex w-full h-10">
                      {divideElement()}
                      {user.timeSlots.map((timeSlot) => timeSlotElement(timeSlot, user.color))}
                    </div>
                  </div>
                ))}
            </div>
            {selectedDay === index && selectedDay !== 6 && <Header width={width} hours={hours} />}
          </div>
        ))}
      </div>
    </div>
  );
};

export default GlobalCalendar;
