import types from './types';
import { StaffManagementListDataActionType, staffDetails } from './interface';

const initState: staffDetails = {
  staffCostList: null,
  totalStaffCost: 0,
};

const reducer = (state: staffDetails = initState, action: StaffManagementListDataActionType): staffDetails => {
  switch (action.type) {
    case types.SET_STAFF_COST_LIST:
      return {
        ...state,
        staffCostList: action.payload,
      };

    case types.SET_TOTAL_STAFF_COST:
      return {
        ...state,
        totalStaffCost: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
