import { PlusIcon } from '@heroicons/react/solid';
import { ReactComponent as Trash } from 'assets/icons/trash.svg';
import Button from 'components/common/Button';
import DayPicker, { DateUtils, DayModifiers } from 'react-day-picker';
import { useEffect, useState } from 'react';
import './Calendar.css';
import AvailabilityAPI from 'api/AvailabilityAPI';
import moment from 'moment-timezone';
import { useParams } from 'react-router';

interface IProps {
  onSave: (data: any) => void;
  onCancel: () => void;
  exceptionDays: any;
  timeZone: string;
}

const CalendarPopup: React.FC<IProps> = ({ onSave, onCancel, exceptionDays, timeZone }) => {
  const [selectedDays, setSelectedDays] = useState<Date[]>([]);
  const [availabilityLists, setAvailabilityList] = useState({} as any);
  const [currentSelected, setCurrentSelected] = useState<Date | any>(moment(new Date()).format('YYYY-MM-DD'));
  const [currentSelectedAvailability, setCurrentSelectedAvailability] = useState<any>();
  const [selectionException, setSelectionException] = useState<any>(exceptionDays);
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    const date = new Date();
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    const data = { startDate: moment(date).format('YYYY-MM-DD'), endDate: moment(lastDay).format('YYYY-MM-DD') };
    getOverideAvailability(data);
    const selectDates: any = [];
    selectDates.push(new Date());
    setSelectedDays(selectDates);
  }, []);

  useEffect(() => {
    if (availabilityLists) {
      const availabilityExecption: any = {};
      if (availabilityLists.overRide) {
        availabilityExecption[currentSelected] = availabilityLists.overRide[currentSelected] || [];
        setCurrentSelectedAvailability(availabilityExecption[currentSelected]);
      }
      setSelectionException(availabilityExecption);
    }
  }, [availabilityLists, currentSelected]);

  const getOverideAvailability = (data: any) => {
    AvailabilityAPI.fetchOverrideAvailability(id, data).then((res) => {
      setAvailabilityList(res.data.data);
    });
  };

  const handleDayClick = (day: Date, { selected }: DayModifiers) => {
    const availabilityExecption: any = {};
    if (moment(moment(new Date()).format('YYYY-MM-DD')).isSameOrBefore(moment(day).format('YYYY-MM-DD'))) {
      const dayFormat = moment(day).format('YYYY-MM-DD');
      const s: Date[] = [];
      if (selected) {
        const selectedIndex = s.findIndex((selectedDay) => DateUtils.isSameDay(selectedDay, day));
        s.splice(selectedIndex, 1);
        delete availabilityExecption[dayFormat];
        setCurrentSelected('');
        setCurrentSelectedAvailability('');
        setSelectionException(availabilityExecption);
      } else {
        s.push(day);
        availabilityExecption[dayFormat] = availabilityLists[dayFormat];
        setSelectionException(availabilityExecption);
        setCurrentSelected(dayFormat);
        setCurrentSelectedAvailability(availabilityLists[dayFormat]);
      }
      setSelectedDays(s);
    }
  };

  const deleteTimeRange = (index: number, value: string) => {
    const availability: any = [...currentSelectedAvailability];
    availability.splice(index, 1);
    setCurrentSelectedAvailability(availability);
    const timeList: any = { ...selectionException };
    timeList[value].splice(index, 1);
    setSelectionException(timeList);
  };

  const dateInputProps = {
    placeholder: 'Select time',
    className: 'w-36 px-4 py-1.5 text-lg border border-darkBorder rounded-xl outline-none',
  };

  const convertToTimeZone = (time: any) => {
    const momentData = moment(time);
    const value = momentData.tz(timeZone).format('HH:mm');
    return value;
  };

  const convertToUtc = (time: any) => {
    const timedata = currentSelected + ' ' + time;
    const value = moment.tz(timedata, timeZone);
    return value.utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
  };

  const startPicker = (value: any, index: number) => {
    const availabilities = { ...selectionException };
    availabilities[currentSelected][index].startTime = convertToUtc(value);
    setSelectionException(availabilities);
    const currentData = [...currentSelectedAvailability];
    currentData[index].startTime = convertToUtc(value);
    setCurrentSelectedAvailability(currentData);
  };

  const endPicker = (value: any, index: number) => {
    const availabilities = { ...selectionException };
    availabilities[currentSelected][index].endTime = convertToUtc(value);
    setSelectionException(availabilities);
    const currentData = [...currentSelectedAvailability];
    currentData[index].endTime = convertToUtc(value);
    setCurrentSelectedAvailability(currentData);
  };

  const onMonthChange = (month: Date) => {
    const date = new Date();
    if (new Date() <= month) {
      const lastDay = new Date(month.getFullYear(), month.getMonth() + 1, 0);
      const data = { startDate: moment(month).format('YYYY-MM-DD'), endDate: moment(lastDay).format('YYYY-MM-DD') };
      getOverideAvailability(data);
    } else {
      const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      const data = { startDate: moment(date).format('YYYY-MM-DD'), endDate: moment(lastDay).format('YYYY-MM-DD') };
      getOverideAvailability(data);
    }
  };

  const onAddTime = (value: string) => {
    const availability: any = [...currentSelectedAvailability];
    availability.push({ startTime: null, endTime: null });
    setCurrentSelectedAvailability(availability);
    const timeList: any = { ...selectionException };
    if (!timeList[value]) {
      timeList[value] = [];
    }
    timeList[value].push({ startTime: null, endTime: null });
    setSelectionException(timeList);
  };
  return (
    <>
      <div className="flex flex-col shadow-full px-6 py-8 relative rounded-xl" style={{ width: '380px' }}>
        <DayPicker
          disabledDays={{ before: new Date() }}
          onDayClick={handleDayClick}
          selectedDays={selectedDays}
          onMonthChange={onMonthChange}
        />
        <div className="border-t border-b py-4 my-4 w-full">
          <span className="block mb-4">What hours are you available?</span>
          {currentSelectedAvailability &&
            currentSelectedAvailability.map((eachItem: any, index: number) => {
              return (
                <div key={eachItem} className="flex items-center mb-2">
                  <div className="flex items-center mr-4">
                    <input
                      type="time"
                      className="w-36 px-2 py-1.5 text-lg border border-darkBorder rounded-xl outline-none"
                      value={eachItem.startTime && convertToTimeZone(eachItem.startTime)}
                      onChange={(e) => startPicker(e.target.value, index)}
                    ></input>
                    <div className="border w-4 mx-4"></div>
                    <input
                      type="time"
                      className="w-36 px-2 py-1.5 text-lg border border-darkBorder rounded-xl outline-none"
                      value={eachItem.endTime && convertToTimeZone(eachItem.endTime)}
                      onChange={(e) => endPicker(e.target.value, index)}
                    ></input>
                  </div>
                  <div className="flex justify-between items-center flex-grow">
                    <Trash
                      className="w-4 cursor-pointer text-text"
                      onClick={() => deleteTimeRange(index, currentSelected)}
                    />
                    <PlusIcon
                      onClick={() => onAddTime(currentSelected)}
                      className="w-6 cursor-pointer text-textGreyedOut"
                    />
                  </div>
                </div>
              );
            })}
          {currentSelected && currentSelectedAvailability?.length === 0 && (
            <Button type="secondary" onClick={() => onAddTime(currentSelected)}>
              Add
              <PlusIcon className="w-6 cursor-pointer text-textGreyedOut" />
            </Button>
          )}
        </div>
        <div className="mt-6 flex justify-end">
          <Button onClick={onCancel} ownClasses="rounded-full mx-4 bg-white text-green" type="primary">
            Cancel
          </Button>
          <Button onClick={() => onSave(selectionException)} ownClasses="rounded-full" type="success">
            Add exception
          </Button>
        </div>
      </div>
    </>
  );
};

export default CalendarPopup;
