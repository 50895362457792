import React, { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import Button from '../../common/Button';
import HeaderNotificationItem, { HeaderNotificationProps } from '../Item/HeaderNotificationItem';
import { useSelector, RootStateOrAny, useDispatch } from 'react-redux';
import NotificationAPI from 'api/NotificationAPI';
import { toast } from 'react-toastify';
import classNames from 'classnames';
import { setNotificationInformation } from 'redux/notification/actions';
import { setNotificationLists } from 'redux/channels copy/actions';

export interface notificationProps {
  fetchNotificationLists: () => void;
  setShowNotifications: (show: boolean) => void;
}

const NotificationsList: React.FC<notificationProps> = ({ fetchNotificationLists, setShowNotifications }) => {
  const notificationList = useSelector((state: RootStateOrAny) => state.notifications.notificationList);
  const dispatch = useDispatch();
  const [page, setpage] = useState(1);
  const limit = 20;
  const [loadMoreTemplates, setLoadMoreTemplates] = useState(true);
  const [totalNotificationCount, setTotalNotificationCount] = useState(0);

  const onClickClearAll = () => {
    const payload = {
      clearAll: true,
    };
    NotificationAPI.clearAllNotifications(payload)
      .then((res) => {
        console.log('Marked Seen');
        fetchNotificationLists();
        dispatch(setNotificationInformation(0));
      })
      .catch((e) => {
        toast.error(e.response.data.message);
      });
  };

  const fetchNotificationListsByLimit = (payload: any) => {
    NotificationAPI.fetchNotificationByLimit(payload).then((res) => {
      const totalData = notificationList.concat(res?.data?.data);
      dispatch(setNotificationLists(totalData));
      setTotalNotificationCount(res?.data?.info?.totalCount);
      if (res.data.data.length === 0) {
        setLoadMoreTemplates(false);
      } else {
        setLoadMoreTemplates(true);
      }
    });
  };

  const loadMore = () => {
    const data = {
      limit: limit,
      pageNo: page,
    };
    setpage(page + 1);
    fetchNotificationListsByLimit(data);
    setLoadMoreTemplates(false);
  };

  return (
    <div className="absolute top-16 inset-x-0 mx-auto flex justify-center z-30">
      <div
        id="headerNotification"
        className={classNames(
          'shadow-full border-t rounded-t-lg relative bg-whiteWithOpacity',
          notificationList?.length ? 'h-96' : '',
        )}
      >
        <div className="w-16 opacity-90 overflow-hidden arrow-up mx-auto inset-x-0">
          <div className="h-11 w-11 bg-whiteWithOpacity rotate-45 transform origin-bottom-left border-none" />
        </div>
        {notificationList?.length > 0 ? (
          <div id="scrollNotifications" className="overflow-y-scroll h-full">
            <InfiniteScroll
              dataLength={notificationList && notificationList.length}
              next={loadMore}
              hasMore={loadMoreTemplates}
              loader={<div className="loader"> Loading... </div>}
              scrollableTarget="scrollNotifications"
            >
              {notificationList.map((el: any) => (
                <HeaderNotificationItem
                  key={el._id}
                  {...el}
                  fetchNotificationLists={fetchNotificationLists}
                  setShowNotifications={setShowNotifications}
                />
              ))}
            </InfiniteScroll>
          </div>
        ) : (
          <div className="pl-2.5 w-48 h-10 flex justify-center items-center">
            <p className="text-lg">No notifications</p>
          </div>
        )}

        <div className="sticky top-0 z-20 pr-4 w-full box-shadow: 0 0 15px 5px rgba(31, 73, 125, 0.8) mr-1.5 border-t rounded-b-lg  bg-whiteWithOpacity">
          {notificationList?.length > 0 && (
            <Button ownClasses="mx-auto my-2 2xl:my-4 font-normal" type="depressed" onClick={() => onClickClearAll()}>
              Clear all
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
export default NotificationsList;
