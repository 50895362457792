import Button from '../../common/Button';
import { ReactComponent as SearchSvg } from '../../../assets/icons/search.svg';
import { RootStateOrAny, useSelector } from 'react-redux';
import { useState, useCallback } from 'react';
import { XIcon } from '@heroicons/react/solid';
import Form from './Form';
import { debounce, invokeMap } from 'lodash';
import { useEffect } from 'react';
import LookUpAPI from 'api/LookUpsAPI';
import { AsyncPaginate } from 'react-select-async-paginate';
import { toast } from 'react-toastify';

export interface FilterProps {
  filterSearchTextHandler: (value?: any) => void;
  sortField: any;
  sortOrder: any;
  inlineFilterActive: string[];
  categories?: string[];
  filterModalCategory?: string;
}

const SearchForm: React.FC<FilterProps> = ({
  filterSearchTextHandler,
  sortField,
  sortOrder,
  inlineFilterActive,
  categories,
  filterModalCategory,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [intialLoadList, setIntialLoadList] = useState(true);
  const [filterFormValues, setFilterFormValues] = useState({});
  const [asyncData, setAsyncData] = useState();
  const [searchData, setSearchData] = useState<any>();
  const [searchValue, setSearchValue] = useState<any>();
  const [menuOpenSearch, setMenuOpenSearch] = useState(false);
  const [categoryOptions, setCategoryOptions] = useState<{ label: string; value: string }[]>();
  const searchHandler = (searchText: string, sortField: any, sortOrder: any, inlineFilterActive: any) => {
    const searchParams = {
      ...(searchText && { searchKey: searchText }),
      ...(sortField && { sortField }),
      ...(sortOrder && { sortOrder }),
      ...(inlineFilterActive && inlineFilterActive.includes('History') && { completed: true }),
      ...(inlineFilterActive &&
        !inlineFilterActive.includes('History') &&
        !inlineFilterActive.includes('All') && {
          category: categories && filterModalCategory ? [...new Set([...categories, filterModalCategory])] : categories,
        }),
    };
    filterSearchTextHandler(searchParams);
  };
  const userInfo = useSelector((state: RootStateOrAny) => state.userProfile.userDetails);
  const debounceSearch = useCallback(debounce(searchHandler, 1000), []);
  const formHandler = (filterValue?: any) => {
    filterSearchTextHandler(searchValue ? { ...searchValue, ...filterValue } : filterValue);
    setIntialLoadList(false);
    setFilterFormValues(filterValue);
    setShowModal(false);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
  };

  useEffect(() => {
    if (!intialLoadList) {
      debounceSearch(searchText, sortField, sortOrder, inlineFilterActive);
    }
  }, [searchText]);

  useEffect(() => {
    if (searchData) {
      const searchParams = {
        searchUserId: searchData._id,
        searchUserType: searchData.userType,
      };
      filterSearchTextHandler(searchParams);
      filterSearchTextHandler(filterFormValues ? { ...searchParams, ...filterFormValues } : searchParams);
      setSearchValue(searchParams);
    }
  }, [searchData]);

  const loadOptions = async (searchQuery: string, loadedOptions: any, { page }: any) => {
    const payload = {
      limit: 50,
      pageNo: page - 1,
      searchKey: searchQuery,
    };
    let responseJSON: any = {};
    await LookUpAPI.fetchSearchUsers(payload).then((res) => {
      responseJSON = res?.data?.data;
    });
    return {
      options: responseJSON,
      hasMore: responseJSON.length >= 1,
      additional: {
        page: searchQuery ? 2 : page + 1,
      },
    };
  };

  const onInputSearchChange = (value: any) => {
    if (value) {
      setMenuOpenSearch(true);
    } else {
      setMenuOpenSearch(false);
    }
  };

  useEffect(() => {
    const payload = { userType: userInfo.userType.name };
    LookUpAPI.fetchTaskCategories(payload)
      .then((res) => {
        if (res.data.errorCode === 0) {
          if (res?.data?.data) {
            console.log('LookupAPI categories', res?.data?.data);
            const categories = res.data.data;
            const categoryList = categories.map((category: any) => ({
              label: category,
              value: category,
            }));
            setCategoryOptions(categoryList);
          }
        }
      })
      .catch((err) => {
        if (err?.response?.data?.message) {
          toast.error(err.response.data.message);
        } else toast.error('Failed to fetch Categories');
      });
  }, []);
  return (
    <form className="flex flex-row flex-wrap items-center" onSubmit={handleSubmit}>
      <Button type="success" ownClasses="mr-2 px-8 py-1 text-lg rounded-full" onClick={() => setShowModal(true)}>
        Filter
      </Button>
      <div className="flex flex-row items-center px-5 bg-lightGreyBackground rounded-full z-20">
        <SearchSvg className="w-4" />
        <AsyncPaginate
          cacheOptions
          isClearable={true}
          loadOptions={loadOptions}
          getOptionValue={(option: any) => option._id}
          getOptionLabel={(option: any) => option.name}
          className="w-64 px-2 py-1 text-lg bg-lightGreyBackground focus:ring-transparent border-none searchOptions"
          isSearchable={true}
          value={searchData && searchData}
          menuIsOpen={menuOpenSearch}
          onInputChange={onInputSearchChange}
          onChange={setSearchData}
          placeholder="Search"
          additional={{
            page: 1,
          }}
        />
        <div className="h-5 w-5">
          {searchData && (
            <button
              className="rounded-full h-5 w-5  flex justify-center items-center"
              onClick={() => {
                setSearchData(null);
                setSearchValue(null);
                filterSearchTextHandler(filterFormValues);
              }}
            >
              <XIcon className="h-5 w-5" />
            </button>
          )}
        </div>
      </div>
      {showModal && categoryOptions ? (
        <>
          <div className="flex items-center justify-center inset-0 fixed z-50 p-12">
            <div className="relative bg-white rounded-lg shadow-lg">
              <button
                className="absolute -top-6 -right-7 z-10 p-4 rounded-full bg-darkBlue"
                onClick={() => setShowModal(false)}
              >
                <XIcon className="text-white h-6 w-6" />
              </button>
              <div className="relative px-16 py-14 flex-auto">
                <h2 className="text-2xl text-green font-bold">Filters</h2>
                <div className="my-8">
                  <Form
                    formHandler={formHandler}
                    filterFormValues={filterFormValues}
                    asyncData={asyncData}
                    setAsyncData={setAsyncData}
                    categoryOptions={categoryOptions}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-50 fixed inset-0 z-40 bg-darkBlue" />
        </>
      ) : null}
    </form>
  );
};

export default SearchForm;
