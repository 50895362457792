import classNames from 'classnames';
import { useSelector, RootStateOrAny } from 'react-redux';

export interface IMessage {
  [x: string]: any;
  id: string;
  seen?: string | null;
  authorName: string;
  fileName?: string;
  filePath?: string;
  authorRole: string;
  messageDate: string;
  isNew: boolean;
  text: string;
  avatarUrl: string;
  authorId?: string;
}

const MessageCard: React.FC<IMessage> = ({ authorName, messageDate, text, authorId, filePath, fileName, buttons }) => {
  const userInfo = useSelector((state: RootStateOrAny) => state.userProfile.userDetails._id);
  const isYou = authorId === userInfo;

  const messageWrapperClasses = classNames(
    'w-4/5 py-2 pl-3 mb-3 rounded-lg',
    isYou ? 'self-end bg-lightGreen bg-opacity-25' : `self-start bg-lightGreyBackground`,
  );
  return (
    <div className={messageWrapperClasses}>
      <div className="flex mb-2">
        <h4 className="mr-2 text-sm font-bold 2xl:text-base">{isYou ? <>Me</> : authorName}</h4>
        <span className="text-myPlanLightGrey text-sm 2xl:text-base">{messageDate}</span>
      </div>
      <p className="text-sm w-5/6 2xl:text-base">{text}</p>
      {filePath && fileName && (
        <div
          className={classNames({
            'mt-3 sm:mt-4': text || !!buttons?.length,
            '2xl:mt-8': text || !!buttons?.length,
          })}
        >
          <div className={classNames('sm:mx-5')}>
            <a className="overflow-hidden max-w-full" href={filePath} target="_blank" rel="noreferrer">
              <img src={filePath} alt={fileName} className={classNames('h-20 max-w-max')} />
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default MessageCard;
