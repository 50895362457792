import types from './types';
import { StaffManagementListDataActionType, staffDetails } from './interface';

const initState: staffDetails = {
  staffManagementList: null,
  staffManagementListTotalCount: 0,
};

const reducer = (state: staffDetails = initState, action: StaffManagementListDataActionType): staffDetails => {
  switch (action.type) {
    case types.SET_STAFF_LIST:
      return {
        ...state,
        staffManagementList: action.payload,
      };

    case types.SET_TOTAL_STAFF:
      return {
        ...state,
        staffManagementListTotalCount: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
