import { useEffect, useState } from 'react';
import Zoom, { Controlled } from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import Button from 'components/common/Button';
import { useCallback } from 'react';
import att1 from 'assets/images/att-1.png';
import att2 from 'assets/images/att-2.png';
import moment from 'moment';
import { useHistory } from 'react-router';
import Cookies from 'js-cookie';
import AppointmentAPI from 'api/AppointmentAPI';
import { toast } from 'react-toastify';
import { each } from 'lodash';
import { dateTimeFormatter } from 'utils/dateTimeFormatter';
import CanceAppointmentModal from './CanceAppointmentModal';
import 'moment-timezone';
import * as Sentry from '@sentry/react';
import { ReactComponent as ArrowRight } from 'assets/icons/sidebar/arrow-right.svg';

const timeZone = moment.tz.guess();

const MA = 'Medical Assistant';
const PH = 'Physician';

export interface MeetingDetailsProps {
  appointmentInformation?: any;
  taskId: string;
  userType?: string;
  userId?: any;
  fetchTaskDetails: () => void;
  channelList?: any;
  category: string;
  taskDetails?: any;
  onSuccessCancel?: () => void;
}

const MeetingDetails: React.FC<MeetingDetailsProps> = ({
  appointmentInformation,
  taskId,
  userType,
  userId,
  fetchTaskDetails,
  channelList,
  category,
  taskDetails,
  onSuccessCancel,
}) => {
  const dummyImgs = [att1, att2];
  const [isZoomed, setIsZoomed] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [appointmentTimer, setAppointmentTimer] = useState<any>();
  const isPhysician = userType === PH;

  const history = useHistory();
  const handleImgLoad = useCallback(() => {
    setIsZoomed(true);
  }, []);

  const handleZoomChange = useCallback((shouldZoom) => {
    setIsZoomed(shouldZoom);
  }, []);

  const onCancelAppointment = () => {
    AppointmentAPI.cancelAppointment(appointmentInformation._id)
      .then((res) => {
        const { data } = res;
        toast.success(data.message);
        fetchTaskDetails();
        onSuccessCancel && onSuccessCancel();
        setShowModal(false);
      })
      .catch((e) => {
        Sentry.captureException(e);
        toast.error(e.response.data.message);
      });
  };

  useEffect(() => {
    console.log('appointment info', appointmentInformation);
    const d = new Date();
    const now = d.toISOString();
    const d1: any = new Date(now); // 10:09 to
    const d2: any = new Date(appointmentInformation?.appointmentTime?.startTime);
    const difference = d1 - d2;
    if (difference > 60e3) {
      console.log('date diff is', Math.floor(difference / 60e3), 'minutes ago');
      setAppointmentTimer(difference / 60e3);
    } else console.log('date diff is', Math.floor(difference / 1e3), 'seconds ago');
  }, [appointmentInformation]);

  useEffect(() => {
    const m1 = moment(appointmentInformation?.appointmentTime?.startTime, 'DD-MM-YYYY HH:mm');
    const m2 = moment(appointmentInformation?.appointmentTime?.endTime, 'DD-MM-YYYY HH:mm');
    const m3 = m2.diff(m1, 'minutes');
    const m4 = m2.diff(m1, 'h');

    const numdays = Math.floor(m3 / 1440);
    const numhours = Math.floor((m3 % 1440) / 60);
    const numminutes = Math.floor((m3 % 1440) % 60);
    console.log('answer', numdays + ' day(s) ' + numhours + 'hours ago ' + numminutes + 'min ago');
  }, [appointmentInformation]);
  const onStartAppointment = () => {
    // history.push(`/chat`);
    history.push(`/videoCall?callId=${taskId}`);
    // const generalChannel = channelList.find((eachItem: any) => eachItem.channelTitle === 'General health');
    // Cookies.set('appointmentId', appointmentInformation._id);
    // Cookies.set('doctorId', appointmentInformation.doctorId);
    // Cookies.set('patientId', appointmentInformation.patientId);
    // Cookies.set('taskId', taskId);
    // Cookies.set('channelGeneral', generalChannel?.channelId);
  };
  const APPOINTMENT = 'Appointment';

  return appointmentInformation ? (
    <>
      <div
        className={`flex flex-col md:flex-row justify-between items-center ${
          appointmentInformation?.appointmentStatus === 'cancelled' || category === 'Appointment: Missed'
            ? 'bg-red bg-opacity-20'
            : 'bg-lightGreen'
        }  p-5`}
      >
        <>
          {showModal && (
            <>
              <CanceAppointmentModal
                onCancelAppointment={onCancelAppointment}
                showModal={showModal}
                setShowModal={setShowModal}
                appointmentInformation={appointmentInformation}
                patientName={
                  taskDetails && taskDetails?.personalInfo?.firstName + ' ' + taskDetails?.personalInfo?.lastName
                }
              />
            </>
          )}
        </>
        <div className="flex items-center">
          <span className="text-xl font-bold">
            {appointmentInformation.appointmentTime && (
              <div className="flex text-xl font-bold">
                {moment(appointmentInformation.appointmentTime.startTime).tz(timeZone).format('MMM D • h:mm a z') + ' '}
                {appointmentInformation.appointmentStatus !== 'cancelled' &&
                  category !== 'Appointment: Missed' &&
                  moment(appointmentInformation.appointmentTime.startTime).calendar().includes('Today') && (
                    <p className="text-lg ml-2 text-gray-400">
                      {moment(appointmentInformation.appointmentTime.startTime).fromNow()}
                    </p>
                  )}
                {appointmentInformation.appointmentStatus === 'cancelled' && (
                  <p className="text-red text-xl font-bold ml-5">Cancelled</p>
                )}
                {category === 'Appointment: Missed' && (
                  <p className="text-red text-xl font-bold ml-5">
                    Missed {dateTimeFormatter(appointmentInformation?.appointmentTime?.startTime, true)}
                  </p>
                )}
              </div>
            )}
          </span>
        </div>
        {taskDetails.showBackToCall && isPhysician && (
          <Button
            ownClasses="bg-transparent text-green mr-4 rounded-xl"
            type="primary"
            onClick={() => onStartAppointment()}
          >
            <ArrowRight className="mr-1.5 w-4 h-4 transform rotate-180" />
            Back to call
          </Button>
        )}
        {appointmentInformation?.appointmentStatus === 'pending' &&
          taskDetails.category.includes(APPOINTMENT) &&
          userType !== 'Admin' &&
          userId == appointmentInformation.doctorId &&
          category !== 'Appointment: Missed' && (
            <div className="flex flex-wrap">
              <Button
                ownClasses="bg-transparent text-green mr-4 rounded-xl"
                type="primary"
                onClick={() => setShowModal?.(true)}
              >
                Cancel appointment
              </Button>

              {userType !== MA && (
                <Button ownClasses="rounded-full" type="success" onClick={() => onStartAppointment()}>
                  Start appointment
                </Button>
              )}
            </div>
          )}
      </div>
      <div className="flex text-text my-4">
        <div className="mr-8">
          <span className="font-semibold mr-1">Appointment category: </span>
          <span>{appointmentInformation.appointmentType && appointmentInformation.appointmentType.category}</span>
        </div>
        <div>
          <span className="font-semibold mr-1">Subject:</span>
          <span>{appointmentInformation?.appointmentType?.displayName}</span>
        </div>
      </div>
      <p className="text-text text-xl mb-4">{appointmentInformation.appointmentDescription}</p>
      <div>
        <div className="flex items-center">
          <span className="font-semibold text-text mr-1">Attachment: </span>
          {appointmentInformation.images &&
            appointmentInformation.images.map((img: any, i: number) => (
              <Zoom key={i}>
                <img onLoad={handleImgLoad} className="cursor-pointer mx-2 w-10" src={img} alt="" />
              </Zoom>
            ))}
        </div>
      </div>
    </>
  ) : null;
};

export default MeetingDetails;
