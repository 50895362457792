import { TaskData } from './interfaces';
import types from './types';

export const setTaskLists = (data: TaskData[]) => ({
  type: types.SET_TASK_LIST,
  payload: data,
});

export const setTotalTask = (data: number) => ({
  type: types.SET_TOTAL_TASK,
  payload: data,
});

export const setSelectedTaskId = (data: string | null) => ({
  type: types.SET_SELECTED_TASK,
  payload: data,
});

export const setUrgentTaskCount = (data: string | null) => ({
  type: types.SET_URGENT_TASK_COUNT,
  payload: data,
});

const actions = {
  setTaskLists,
  setTotalTask,
  setSelectedTaskId,
  setUrgentTaskCount,
};

export default actions;
