import { AxiosInstanceWebsite } from './AxiosInstance';
import config from '../config';

const { api } = config;

const profileAppendPath = '/my-profile';
const profileImageAppendPath = '/my-profile/profile-image';

export default class ProfileAPI {
  static fetchProfile() {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}${profileAppendPath}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  static editProfile(payload: any) {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}${profileAppendPath}`,
      method: 'PATCH',
      data: payload,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }
  static postProfileImage(data: any) {
    console.log('incoming data for image upload', data);
    const formData = new FormData();
    formData.append('profileImage', data);
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}${profileImageAppendPath}`,
      method: 'POST',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }
}
