import { AxiosInstanceWebsite } from './AxiosInstance';
import config from '../config';

const { api } = config;

const notificationAppendPath = 'notifications';

export default class NotificationAPI {
  static fetchNotifications() {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/${notificationAppendPath}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  static fetchNotificationByLimit(data: any) {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/${notificationAppendPath}`,
      method: 'GET',
      params: data,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  static fetchUnreadCount() {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/${notificationAppendPath}/unread-count`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  static markSeenNotificationCount() {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/${notificationAppendPath}/mark-seen`,
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  static clearAllNotifications(payload: any) {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/${notificationAppendPath}/clear`,
      method: 'PATCH',
      data: payload,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }
  static markSeenNotifications(payload: any) {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/${notificationAppendPath}/clear`,
      method: 'PATCH',
      data: payload,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  // static dismisNotification(payload: any) {
  //   return AxiosInstanceWebsite({
  //     url: `${api.baseUrlWebsite}/${notificationAppendPath}/clear`,
  //     method: 'PATCH',
  //     data: payload,
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //   });
  // }
}
