import Button from 'components/common/Button';
import { ReactComponent as PlusSvg } from 'assets/icons/lifeMD-plus.svg';
import { ReactComponent as ExpMark } from 'assets/icons/exclamation-mark.svg';

import DocItem from './PatientDocuments/DocItem';
import PatientAPI from 'api/PatientAPI';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';

export interface IDocItem {
  id: string;
  title: string;
  size: string;
  date: string;
}

const PatientDocuments = ({ docData }: any) => {
  const dummyLabReports: IDocItem[] = [
    { id: '1', title: 'Lorem ipsum report', size: '230KB', date: 'January 23rd, 2021' },
    { id: '2', title: 'Lorem ipsum report', size: '230KB', date: 'January 23rd, 2021' },
    { id: '3', title: 'Lorem ipsum report', size: '230KB', date: 'January 23rd, 2021' },
    { id: '4', title: 'Lorem ipsum report', size: '230KB', date: 'January 23rd, 2021' },
  ];
  const { id }: any = useParams();

  const [base, setBase] = useState<any>(null);
  const downloadPDF = (reportId: any) => {
    PatientAPI.fetchPatientSingleReport(id, reportId).then((res) => {
      console.log('fetch rep xxx', res.data);

      console.log('fetch rep called');
      const { data } = res.data;
      if (res.data) {
        setBase(res.data);
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'file.pdf'); //or any other extension
        document.body.appendChild(link);
        link.click();
      }
    });
  };
  function reportViewHandler(reportId: any) {
    PatientAPI.fetchPatientSingleReport(id, reportId).then((res) => {
      const { data } = res.data;
      console.log('pdf response', res);
      if (res?.data) {
        const file = new Blob([res.data], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      }
    });
  }

  const dummyImages: IDocItem[] = [{ id: '1', title: 'Lorem ipsum report', size: '230KB', date: 'January 23rd, 2021' }];
  return (
    <div className="p-4 pr-0">
      {docData?.map((item: any, index: any) => (
        <div key={index}>
          <h4 className="section-subheading mr-4">{item?.title} reports</h4>

          <div className="divide-y">
            {item?.data?.map((report: any) => (
              <DocItem key={report.id} {...report} reportViewHandler={reportViewHandler} downloadPDF={downloadPDF} />
            ))}
          </div>
          {console.log('item is', item?.data)}
          <div className="flex items-center">
            {/* <Button type="primary" ownClasses="bg-white text-darkBlue font-semibold rounded-full shadow-lg self-start">
          <PlusSvg className="w-3 h-3 mr-1" />
          New
        </Button> */}
          </div>
        </div>
      ))}
      <div className="border mt-4 mb-10"></div>

      <div className="flex items-center mb-6">
        <h4 className="section-subheading mr-4">Additional documents</h4>
        {/* <Button type="primary" ownClasses="bg-white text-darkBlue font-semibold rounded-full shadow-lg self-start">
          <PlusSvg className="w-3 h-3 mr-1" />
          New
        </Button> */}
      </div>
      <div className="flex items-center px-8 py-5 shadow-md">
        <ExpMark className="w-7 mr-8" />
        <span className="text-lg">No additional documents have been uploaded</span>
      </div>
    </div>
  );
};

export default PatientDocuments;
