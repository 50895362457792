import { AxiosInstanceWebsite } from './AxiosInstance';
import config from '../config';

const { api } = config;

const voiceCallAppendPath = 'patients/voice-calls';

export default class VoiceCallAPI {
  static fetchCallToken(id: string) {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/${voiceCallAppendPath}/${id}/token`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }
}
