import AdminAutomationAPI from 'api/AdminAutomationAPI';
import AutomationList from 'components/AdminAutomation';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setadminAutomationsCount, setadminAutomationsList } from 'redux/adminAutomation/actions';

export interface AdminAutomationProps {
  automationType: string;
}
const AdminAutomation: React.FC<AdminAutomationProps> = ({ automationType }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const fetchAutomationList = (value?: any) => {
    setLoading(true);
    AdminAutomationAPI.fetchAutomations(value)
      .then((res) => {
        if (res.data.errorCode === 0) {
          dispatch(setadminAutomationsList(res.data.data));
          res.data.data.length > 0
            ? dispatch(setadminAutomationsCount(res.data.info.totalCount))
            : dispatch(setadminAutomationsCount(0));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchAutomationList({
      pageNo: 0,
      limit: 10,
      triggerModule: automationType,
    });
  }, [dispatch]);
  return <AutomationList fetchAutomationList={fetchAutomationList} loading={loading} automationType={automationType} />;
};

export default AdminAutomation;
