import PatientAPI from 'api/PatientAPI';
import StandardTable from 'components/StandardTable';
import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setPatientLists, setTotalPatient } from 'redux/patient/actions';

const Patient: React.FC = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const fetchPatientList = (value?: any) => {
    setLoading(true);
    PatientAPI.fetchPatient(value)
      .then((res) => {
        if (res.data.errorCode === 0) {
          dispatch(setPatientLists(res.data.data));
          dispatch(setTotalPatient(res.data.info.totalCount));
          setLoading(false);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    fetchPatientList({ pageNo: 0, limit: 10 });
  }, [dispatch]);
  return (
    <div>
      <StandardTable fetchPatientList={fetchPatientList} loading={loading} />
    </div>
  );
};

export default Patient;
