import React, { useEffect, useState } from 'react';
import { RemoteAudioTrack, RemoteVideoTrack } from 'twilio-video';
import Participant from '../Participant';
import { RemoteParticipantsProps } from './remoteParticipants.types';
import GenericAvatar from 'assets/images/profile-generic.png';

const RemoteParticipants: React.FC<RemoteParticipantsProps> = ({ participant, callDetails }) => {
  const [videoTracks, setVideoTracks] = useState<(RemoteVideoTrack | null)[]>([]);
  const [audioTracks, setAudioTracks] = useState<(RemoteAudioTrack | null)[]>([]);

  const trackSubscribed = (track: RemoteVideoTrack | RemoteAudioTrack) => {
    if (track.kind === 'video') {
      setVideoTracks([track]);
    }
    if (track.kind === 'audio') {
      setAudioTracks([track]);
    }
  };

  const trackUnsubscribed = (track: RemoteVideoTrack | RemoteAudioTrack) => {
    if (track.kind === 'video') {
      setVideoTracks(videoTracks.filter((v) => v !== track));
    } else if (track.kind === 'audio') {
      setAudioTracks(audioTracks.filter((a) => a !== track));
    }
  };

  /**
   * Set and subscribe video and audio tracks
   */
  useEffect(() => {
    setVideoTracks(
      [...participant.videoTracks?.values()].map((publication) => publication.track).filter((track) => track !== null),
    );
    setAudioTracks(
      [...participant.audioTracks?.values()].map((publication) => publication.track).filter((track) => track !== null),
    );
    participant.on('trackSubscribed', trackSubscribed);
    participant.on('trackUnsubscribed', trackUnsubscribed);

    return () => {
      setVideoTracks([]);
      setAudioTracks([]);
      participant.removeAllListeners();
    };
  }, [participant]);

  return (
    <div className="w-full h-full flex items-center justify-center">
      <Participant
        participant={participant}
        profileImage={callDetails?.patientImage || GenericAvatar}
        videoTrack={videoTracks[0]}
        audioTrack={audioTracks[0]}
        isLocal={false}
      />
    </div>
  );
};

export default RemoteParticipants;
