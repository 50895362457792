import classNames from 'classnames';

import { ItemProps } from './item.types';

const Item: React.FC<ItemProps> = ({ device, isSelected, setSelectedId, label, volume }) => {
  const getPoint = (size: number) => {
    return (
      <span
        className={classNames(
          'leading-2 text-xs transition-all',
          (volume || 0) > size ? 'text-green' : 'text-textGreyedOut',
        )}
      >
        ●
      </span>
    );
  };
  return (
    <div className="mb-4 flex justify-between items-center">
      <button
        onClick={() => setSelectedId(device?.deviceId || '')}
        className={classNames('text-left leading-4 flex items-start mr-4 transition-all text-lg', {
          'font-bold': isSelected,
        })}
      >
        <div className="w-4 h-4 mr-2 text-green font-bold text-center flex items-center">{isSelected ? '✓' : ''}</div>
        {label}
      </button>
      {device?.kind === 'audioinput' && (
        <div className="text-center flex items-end w-8">
          {isSelected && (
            <>
              {getPoint(2.5)}
              {getPoint(5)}
              {getPoint(7.5)}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default Item;
