import moment from 'moment';
import { NavLink, useHistory } from 'react-router-dom';
import classNames from 'classnames';
import ActionButton from './ActionButtons';
import { AppointmentItem } from './appointment.types';

const Appointment: React.FC<AppointmentItem> = ({
  staffName,
  appointmentTime,
  appointmentType,
  missedAppointment,
  isStartsSoon,
  appointmentId,
  taskId,
  isAllAppointmentsPage = false,
}) => {
  const appointmentWrapperClasses = classNames(
    'w-full flex px-7 py-6 cursor-pointer shadow-animation',
    missedAppointment ? 'bg-customPink' : isStartsSoon ? 'bg-lightGreen' : 'bg-white',
    { 'border-2 border-green': isStartsSoon && !missedAppointment },
  );

  const getStyles = () => {
    return isAllAppointmentsPage
      ? {
          apptNameClasses: 'text-lg w-60',
          doctorNameClasses: '',
          timeClasses: 'text-lg',
        }
      : {
          apptNameClasses: 'w-auto text-lg',
          doctorNameClasses: 'text-base',
          timeClasses: 'text-lg',
        };
  };

  const parseTime = (type: 'hours' | 'day' | 'month', date: string) => {
    //TODO: timezone should be from backend;
    const timezone = 'America/New_York';
    const operations = {
      hours: () => moment.tz(date, timezone).format('h:mma z'),
      day: () => moment(date).format('DD'),
      month: () => moment(date).format('MMM'),
    };
    return operations[type]();
  };

  const actionButton = () => (
    <ActionButton
      isStartsSoon={isStartsSoon}
      _id={appointmentId}
      missedAppointment={missedAppointment}
      isAllAppointmentsPage={isAllAppointmentsPage}
    />
  );

  return (
    <NavLink to={`/videoCall?callId=${taskId}`}>
      <div className={classNames('bg-white shadow-common rounded-lg', appointmentWrapperClasses)}>
        <div
          className={classNames(
            'flex flex-col items-center justify-center w-14 h-14 mr-6',
            missedAppointment ? 'bg-red' : 'bg-green',
          )}
        >
          <span className="text-white block capitalize text-xs font-bold">
            {parseTime('month', appointmentTime.startTime)}
          </span>
          <span className="text-2xl text-white leading-6">{parseTime('day', appointmentTime.startTime)}</span>
        </div>
        <div className="flex flex-wrap justify-between items-center flex-grow">
          <div className="mb-2">
            <h4
              className={classNames(
                'font-bold whitespace-nowrap overflow-hidden truncate',
                getStyles().apptNameClasses,
                missedAppointment ? 'text-red' : 'text-appointmentPrimary',
              )}
            >
              {appointmentType.displayName}
            </h4>
            <span className={getStyles().doctorNameClasses}>{staffName}</span>
          </div>
          <div className={classNames('flex flex-wrap items-center', isAllAppointmentsPage && 'ml-auto')}>
            <span className={classNames('min-w-fit mr-4 mb-0 text-appointmentPrimary', getStyles().timeClasses)}>
              {parseTime('hours', appointmentTime.startTime)}
            </span>
            {actionButton()}
          </div>
        </div>
      </div>
    </NavLink>
  );
};

export default Appointment;
