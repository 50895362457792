import dayjs from 'dayjs';
import Countdown, { CountdownRenderProps, zeroPad } from 'react-countdown';

const AppointmentTimer: React.FC<{ actionTime?: string; handleCompleted: () => void }> = ({
  actionTime,
  handleCompleted,
}) => {
  const TIME_GAP = dayjs(actionTime).diff(dayjs());

  const TIMESTAMP = TIME_GAP > 0 ? new Date().getTime() + TIME_GAP : 0;

  const renderer = ({ hours, minutes, seconds }: CountdownRenderProps) => {
    return (
      <div className="w-24 h-8 text-xl flex items-center justify-center bg-white shadow-common rounded-lg text-darkBlue">
        {hours ? `${zeroPad(hours)}:` : null}
        {zeroPad(minutes)}:{zeroPad(seconds)}
      </div>
    );
  };

  return (
    <>
      {!!TIMESTAMP && (
        <div className="flex mt-7 flex-col items-center gap-3 font-bold text-darkBlue sm:flex-row sm:text-xl md:text-2xl">
          Appointment begins in
          <Countdown date={TIMESTAMP} renderer={renderer} onComplete={handleCompleted} />
        </div>
      )}
    </>
  );
};

export default AppointmentTimer;
