import actions from './actions';
import reducer from './reducer';

const authentication = {
  actions,
  reducer,
  name: 'authentication',
};

export default authentication;
