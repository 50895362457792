import { NavcardProps } from 'components/Admin/NavCard';

export const navItemsList: NavcardProps[] = [
  {
    tag: 'aging',
    title: 'Tasks by date',
    description: 'View and manage old tasks.',
    link: '/tasks',
  },
  {
    tag: 'aging',
    title: 'Aging configuration',
    description: 'Configure alerts for staff.',
    link: '/dashboard/age-configuration',
  },
  {
    tag: 'appointment-types',
    title: 'Appointment types',
    description: 'View and manage appoint types and routing.',
    link: '/dashboard/appointment-types/',
  },
  {
    tag: 'content',
    title: 'Articles',
    description: 'View and manage clinical protocols and training.',
    link: '/dashboard/articles',
  },
  // {
  //   tag: 'requests',
  //   title: 'Physician request types',
  //   description: 'View and manage physician request types and routing to staff.',
  //   link: '/dashboard/physician-request-type',
  // },

  {
    tag: 'requests',
    title: 'Front desk request types',
    description: 'View and manage Front desk request types and routing to staff.',
    link: '/dashboard/front-desk-request-type',
  },
  {
    tag: 'templates',
    title: 'Patient message templates',
    description: 'Create templated messages to send to the patient.',
    link: '/dashboard/patient-message-templates',
  },
  // {
  //   tag: 'templates',
  //   title: 'Request message templates',
  //   description: 'Create templated messages to send along with staff requests.',
  //   link: '/dashboard/request-message-templates',
  // },
  {
    tag: 'templates',
    title: 'Internal note templates',
    description: 'Create templated messages for internal staff communication.',
    link: '/dashboard/internal-note-templates',
  },
  {
    tag: 'Users & roles',
    title: 'Patients',
    description: 'Manage and View patients.',
    link: '/dashboard/patients',
  },
  {
    tag: 'Users & roles',
    title: 'Staff',
    description: 'View and manage staff.',
    link: '/dashboard/manage-staff',
  },
  {
    tag: 'Users & roles',
    title: 'Roles',
    description: 'View and manage user types and roles.',
    link: '/dashboard/manage-roles',
  },
  {
    tag: 'automation',
    title: 'Internal Automations',
    description: 'Create automated triggers based on requests or appointments.',
    link: '/dashboard/internal-automations',
  },
  {
    tag: 'automation',
    title: 'Patient Automations',
    description: 'Create automated triggers based on requests or appointments.',
    link: '/dashboard/patient-automations',
  },
  {
    tag: 'billing',
    title: 'Billing configuration',
    description: 'Set default prices for billing.',
    link: '/billing',
  },
  {
    tag: 'billing',
    title: 'Staff costs configuration',
    description: 'Update staff pricing defaults.',
    link: '/dashboard/staff-cost',
  },
];
