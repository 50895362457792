import types from './types';
import { ArticleListDataActionType, ArticleDetails } from './interfaces';

const initState: ArticleDetails = {
  articleList: null,
  totalCount: 0,
};

const reducer = (state: ArticleDetails = initState, action: ArticleListDataActionType): ArticleDetails => {
  switch (action.type) {
    case types.SET_ARTICLE_LIST:
      return {
        ...state,
        articleList: action.payload,
      };

    case types.SET_TOTAL_ARTICLE:
      return {
        ...state,
        totalCount: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
