import { AxiosInstanceWebsite } from './AxiosInstance';
import config from '../config';

const { api } = config;

const articleAppendPath = 'articles';

export default class ArticleAPI {
  static fetchArticles(data: any) {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/${articleAppendPath}`,
      method: 'GET',
      params: data,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  static fetchSingleArticle(id: string) {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/${articleAppendPath}/${id}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  static createArticle(data: any) {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/${articleAppendPath}`,
      method: 'POST',
      data: data,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  static editArticle(data: any, id: string) {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/${articleAppendPath}/${id}`,
      method: 'PATCH',
      data: data,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  static deleteArticleById(id: string) {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/${articleAppendPath}/${id}`,
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  static uploadImages(data: any) {
    const bodyFormData = new FormData();
    for (const key in data) {
      bodyFormData.append(key, data[key]);
    }
    console.log(bodyFormData, 'formdata');
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/${articleAppendPath}/images`,
      method: 'POST',
      data: bodyFormData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  static fetchStaffs() {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/staffs`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  static fetchUserRole() {
    return AxiosInstanceWebsite({
      url: `${api.baseUrlWebsite}/user-roles`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }
}
