import types from './types';

export const setUserRolesList = (data: any) => ({
  type: types.SET_USER_ROLES_LIST,
  payload: data,
});

export const setUserRolesCount = (data: number) => ({
  type: types.SET_USER_ROLES_COUNT,
  payload: data,
});

const actions = {
  setUserRolesList,
  setUserRolesCount,
};

export default actions;
