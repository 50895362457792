import Button from 'components/common/Button';
import DateTimePicker from 'components/common/DateTimePicker';
import Dropdown from 'components/common/Dropdown';
import Input from 'components/common/Input';
import { useEffect } from 'react';
import { useState } from 'react';
import moment from 'moment';

export interface FormProps {
  formHandler: (value?: any) => void;
  filterFormValues?: any;
}

const Form: React.FC<FormProps> = ({ formHandler, filterFormValues }) => {
  const [message, setMessage] = useState('');
  const [shortCode, setShortCode] = useState('');
  const [uniqueId, setUniqueId] = useState('');
  const [status, setStatus] = useState<{ label: string; value: string } | null>();
  const [filterCount, setFilterCount] = useState(0);

  const statuses = [
    { value: 'active', label: 'Active' },
    { value: 'inactive', label: 'Inactive' },
  ];

  useEffect(() => {
    setMessage(filterFormValues.message);
    setShortCode(filterFormValues.shortCode);
    setUniqueId(filterFormValues.uniqueId);
    setStatus(statuses.find((item) => item.value === filterFormValues.status));
  }, [filterFormValues]);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const filterData = {
      ...(message && { message }),
      ...(shortCode && { shortCode }),
      ...(uniqueId && { uniqueId }),
      ...(status?.value && { status: status.value }),
    };
    formHandler(filterData);
  };

  const handleClear = (e: any) => {
    e.preventDefault();
    formHandler({});
  };

  const checkFilterApplied = () => {
    let count = 0;
    if (message) {
      count += 1;
    }
    if (shortCode) {
      count += 1;
    }
    if (uniqueId) {
      count += 1;
    }

    if (status) {
      count += 1;
    }

    setFilterCount(count);
  };

  useEffect(() => {
    checkFilterApplied();
  }, [message, shortCode, status, uniqueId]);
  return (
    <form>
      <div className="flex flex-col lg:flex-row justify-center items-center">
        <div className="mr-20 mb-4">
          <Input
            labelText="Message"
            name="message"
            placeholder="Enter message"
            inputClasses="w-52 mb-4 pr-8"
            labelClasses="mr-10 text-xl  mb-4"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onClickIconClear={() => setMessage('')}
            closeIcon={true}
          />
          <Input
            labelText="ShortCode"
            name="shortCode"
            placeholder="Enter shortCode"
            inputClasses="w-52 mb-4 pr-8"
            labelClasses="mr-10 text-xl  mb-4"
            value={shortCode}
            onChange={(e) => setShortCode(e.target.value)}
            onClickIconClear={() => setShortCode('')}
            closeIcon={true}
          />
        </div>
        <div>
          <Input
            labelText="Unique Id"
            name="created"
            placeholder="Enter Id"
            inputClasses="w-52 mb-4 pr-8"
            labelClasses="mr-10 text-xl  mb-4"
            value={uniqueId}
            onChange={(e) => setUniqueId(e.target.value)}
            onClickIconClear={() => setUniqueId('')}
            closeIcon={true}
          />

          <div className="mb-4">
            <Dropdown
              labelText="Status"
              placeholder="Status"
              labelClasses="mr-10 text-xl  mb-4"
              width="52"
              value={status}
              options={statuses}
              onClickIconClear={() => setStatus(null)}
              closeIcon={true}
              onChange={(e: any) => setStatus(e)}
            />
          </div>
        </div>
      </div>
      <div className="flex items-center justify-end">
        <p>{filterCount} filters applied</p>
        <Button
          ownClasses="px-7 mx-2.5 font-semibold text-dashboardPrimaryGrey"
          type="depressed"
          onClick={(e) => handleClear(e)}
        >
          Clear all
        </Button>
        <Button ownClasses="px-7 font-semibold rounded-full" type="success" onClick={(e) => handleSubmit(e)}>
          Update
        </Button>
      </div>
    </form>
  );
};

export default Form;
